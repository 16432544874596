import {Box, List, ListItem, Typography, useMediaQuery, useTheme} from '@mui/material';
import {ElementType, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as AcceptIcon} from '../../../assets/icons/accept.svg';
import {ReactComponent as AddIcon} from '../../../assets/icons/add.svg';
import {ReactComponent as ArrowLeftIcon} from '../../../assets/icons/arrow-left.svg';
import {ReactComponent as ArrowRightIcon} from '../../../assets/icons/arrow-right.svg';
import {ReactComponent as DeclineIcon} from '../../../assets/icons/decline.svg';
import {ReactComponent as NumberFiveIcon} from '../../../assets/icons/number-five.svg';
import {ReactComponent as NumberFourIcon} from '../../../assets/icons/number-four.svg';
import {ReactComponent as NumberOneIcon} from '../../../assets/icons/number-one.svg';
import {ReactComponent as NumberSixIcon} from '../../../assets/icons/number-six.svg';
import {ReactComponent as NumberThreeIcon} from '../../../assets/icons/number-three.svg';
import {ReactComponent as NumberTwoIcon} from '../../../assets/icons/number-two.svg';
import Recipe from '../../../models/entities/recipe';
import stringUtils from '../../../utils/strings';
import ConfirmPopover from '../../common/ConfirmPopover';
import HorizontalGradientOverflow from '../../common/HorizontalGradientOverflow';
import Icon from '../../common/Icon';
import IconButton from '../../common/IconButton';
import Span from '../../common/Span';
import {RecipeSettingsAction, RecipeSettingsUiState} from './RecipeSettings';

function getPhaseIcon(index: number) {
  switch (index) {
    case 0:
    default:
      return NumberThreeIcon;
    case 1:
      return NumberFourIcon;
    case 2:
      return NumberFiveIcon;
    case 3:
      return NumberSixIcon;
  }
}

type MenuItemProps = {
  color?: string;
  IconComponent: ElementType<any>;
  title: string;
  isSelected?: boolean;
  onClick: () => void;
  disabled?: boolean;
};

function MenuItem(props: MenuItemProps) {
  const {
    color = 'custom.surfaceSecondary',
    IconComponent,
    title,
    isSelected,
    onClick,
    disabled,
  } = props;

  const [isHovered, setIsHovered] = useState(false);

  const isSelectedOrHovered = isSelected || isHovered;

  return (
    <ListItem sx={{margin: 0, padding: 0, width: 'fit-content', paddingRight: {md: '32px'}}}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          height: '40px',
          padding: '12px 16px',
          backgroundColor: color,
          borderRadius: '360px',
          opacity: disabled ? 0.5 : isSelected ? 1 : 0.5,
          cursor: disabled ? undefined : 'pointer',
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => {
          if (!disabled) {
            onClick();
          }
        }}>
        {!stringUtils.isNullOrWhiteSpace(title) && isSelectedOrHovered && (
          <Typography
            sx={{
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
              color: 'custom.textSecondary',
            }}>
            {title}
          </Typography>
        )}
        <Icon IconComponent={IconComponent} color="custom.iconsSecondary" />
      </Box>
    </ListItem>
  );
}

type PhaseMenuItemProps = {
  phaseIndex: number;
  isSelected: boolean;
  canDelete: boolean;
  onClick: () => void;
  onDelete: () => void;
};

function PhaseMenuItem(props: PhaseMenuItemProps) {
  const {isSelected, onClick, phaseIndex, canDelete, onDelete} = props;
  const {t} = useTranslation();

  const theme = useTheme();
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isConfirmPopoverOven, setIsConfirmPopoverOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const containerRef = useRef<HTMLLIElement | null>(null);

  const isOpen = Boolean(anchorEl);
  const isSelectedOrHovered = isSelected || isHovered;

  return (
    <ListItem
      ref={containerRef}
      sx={{
        margin: 0,
        padding: 0,
        gap: {md: '16px'},
        width: 'fit-content',
        paddingRight: {md: '32px'},
        zIndex: isOpen ? 1500 : undefined,
      }}>
      {canDelete && (
        <IconButton
          IconComponent={DeclineIcon}
          inactiveColor="custom.iconsQuaternary"
          isActive={isOpen}
          onClick={() => {
            setAnchorEl(isOpen ? null : containerRef.current);
            setIsConfirmPopoverOpen(true);
            onClick();
          }}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          height: '40px',
          padding: '12px 16px',
          backgroundColor: 'custom.surfaceSecondary',
          borderRadius: '360px',
          opacity: isSelected ? 1 : 0.5,
          cursor: isOpen ? undefined : 'pointer',
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => (isOpen ? setAnchorEl(null) : onClick())}>
        {isSelectedOrHovered && (
          <Typography
            sx={{
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
              color: 'custom.textSecondary',
            }}
            noWrap>
            {`${t('recipe_settings_phase_phase')} ${phaseIndex + 1}`}
          </Typography>
        )}
        <Icon IconComponent={getPhaseIcon(phaseIndex)} color="custom.iconsSecondary" />
      </Box>
      <ConfirmPopover
        isOpen={isLargerThanMd ? Boolean(anchorEl) : isConfirmPopoverOven}
        anchorEl={anchorEl}
        anchorReference={isLargerThanMd ? 'anchorEl' : 'anchorPosition'}
        anchorOrigin={{vertical: 'center', horizontal: 'right'}}
        transformOrigin={{vertical: 'center', horizontal: 'left'}}
        anchorPosition={{top: 264, left: isExtraSmall ? 60 : 120}}
        value={stringUtils.format(
          t('recipe_settings_phases_delete_phase_label'),
          (phaseIndex + 1).toString(),
        )}
        onChange={() => null}
        messageElement={
          <Typography
            sx={{
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
              color: 'custom.textSecondary',
            }}>
            <Span>{t('recipe_settings_phases_delete_phase_label')}</Span>
            <Span sx={{fontWeight: 500}}>{` ${t('recipe_settings_phase_label')} ${
              phaseIndex + 1
            }`}</Span>
            <Span>?</Span>
          </Typography>
        }
        onAccept={() => {
          setAnchorEl(null);
          setIsConfirmPopoverOpen(false);
          onDelete();
        }}
        onDecline={() => {
          setAnchorEl(null);
          setIsConfirmPopoverOpen(false);
        }}
      />
    </ListItem>
  );
}

type RecipeInfoMenuItemProps = {
  title: string;
  onClick: () => void;
};

function RecipeInfoMenuItem(props: RecipeInfoMenuItemProps) {
  const {title, onClick} = props;

  return (
    <ListItem
      sx={{
        margin: 0,
        padding: 0,
        gap: '16px',
        width: 'fit-content',
        paddingRight: '32px',
        cursor: 'pointer',
      }}
      onClick={onClick}>
      <IconButton
        IconComponent={ArrowLeftIcon}
        inactiveColor="custom.iconsQuaternary"
        activeColor="custom.iconsQuaternary"
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: '40px',
          padding: '12px 16px',
          backgroundColor: 'custom.surfaceSecondary',
          opacity: 0.5,
          borderRadius: '360px',
        }}>
        <Typography
          sx={{
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            color: 'custom.textSecondary',
          }}
          noWrap>
          {title}
        </Typography>
      </Box>
    </ListItem>
  );
}

type RecipeSettingsMenuProps = {
  action: RecipeSettingsAction;
  uiState: RecipeSettingsUiState;
  setUiState: (setter: (uiState: RecipeSettingsUiState) => RecipeSettingsUiState) => void;
  selectedPhaseIndex: number;
  setSelectedPhaseIndex: (index: number) => void;
  onAddPhase: () => void;
  onDeletePhase: (index: number) => void;
  recipeBuild: Recipe;
  renderNextButton: boolean;
  renderAddPhaseButton: boolean;
  canFinish: boolean;
  onFinish: () => void;
};

function RecipeSettingsMenu(props: RecipeSettingsMenuProps) {
  const {
    action,
    uiState,
    setUiState,
    selectedPhaseIndex,
    setSelectedPhaseIndex,
    onAddPhase,
    onDeletePhase,
    recipeBuild,
    renderNextButton,
    renderAddPhaseButton,
    canFinish,
    onFinish,
  } = props;

  const {t} = useTranslation();

  const theme = useTheme();
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  const renderGeneralStep =
    action !== 'locate' &&
    uiState.currentStep !== 'location' &&
    uiState.visibleSteps.includes('general');
  const renderProcedureStep =
    action !== 'locate' &&
    uiState.currentStep !== 'location' &&
    uiState.visibleSteps.includes('procedure');
  const renderPhasesStep =
    action !== 'locate' &&
    uiState.currentStep !== 'location' &&
    uiState.visibleSteps.includes('phases');

  const renderRecipeInfoButton = action === 'create' && uiState.currentStep === 'location';

  if (!isLargerThanMd && uiState.currentStep === 'location') {
    return null;
  }

  if (!isLargerThanMd) {
    return (
      <HorizontalGradientOverflow>
        <List
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            gap: '16px',
            height: '72px',
            margin: 0,
            padding: 0,
          }}>
          {renderGeneralStep && (
            <MenuItem
              IconComponent={NumberOneIcon}
              title={t('recipe_settings_phase_general')}
              isSelected={uiState.currentStep === 'general'}
              onClick={() => setUiState((uiState) => ({...uiState, currentStep: 'general'}))}
            />
          )}
          {renderProcedureStep && (
            <MenuItem
              IconComponent={NumberTwoIcon}
              title={t('recipe_settings_phase_procedure')}
              isSelected={uiState.currentStep === 'procedure'}
              onClick={() => setUiState((uiState) => ({...uiState, currentStep: 'procedure'}))}
            />
          )}
          {renderPhasesStep &&
            recipeBuild.recipePhases?.map((_, index) => (
              <PhaseMenuItem
                key={index}
                phaseIndex={index}
                isSelected={uiState.currentStep === 'phases' && index === selectedPhaseIndex}
                onClick={() => {
                  setUiState((uiState) => ({...uiState, currentStep: 'phases'}));
                  setSelectedPhaseIndex(index);
                }}
                canDelete={(recipeBuild.recipePhases?.length ?? 0) > 1}
                onDelete={() => onDeletePhase(index)}
              />
            ))}
        </List>
      </HorizontalGradientOverflow>
    );
  }

  return (
    <List
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        gap: '16px',
        margin: 0,
        padding: 0,
      }}>
      {renderGeneralStep && (
        <MenuItem
          IconComponent={NumberOneIcon}
          title={t('recipe_settings_phase_general')}
          isSelected={uiState.currentStep === 'general'}
          onClick={() => setUiState((uiState) => ({...uiState, currentStep: 'general'}))}
        />
      )}
      {renderProcedureStep && (
        <MenuItem
          IconComponent={NumberTwoIcon}
          title={t('recipe_settings_phase_procedure')}
          isSelected={uiState.currentStep === 'procedure'}
          onClick={() => setUiState((uiState) => ({...uiState, currentStep: 'procedure'}))}
        />
      )}
      {renderPhasesStep &&
        recipeBuild.recipePhases?.map((_, index) => (
          <PhaseMenuItem
            key={index}
            phaseIndex={index}
            isSelected={uiState.currentStep === 'phases' && index === selectedPhaseIndex}
            onClick={() => {
              setUiState((uiState) => ({...uiState, currentStep: 'phases'}));
              setSelectedPhaseIndex(index);
            }}
            canDelete={(recipeBuild.recipePhases?.length ?? 0) > 1}
            onDelete={() => onDeletePhase(index)}
          />
        ))}
      {renderAddPhaseButton && <MenuItem IconComponent={AddIcon} title="" onClick={onAddPhase} />}
      {renderNextButton && (
        <MenuItem
          color="custom.surfaceBrand"
          IconComponent={ArrowRightIcon}
          title={t('recipe_settings_next')}
          isSelected
          onClick={() => {
            if (!uiState.visibleSteps.includes('procedure')) {
              setUiState(() => ({
                visibleSteps: ['general', 'procedure'],
                currentStep: 'procedure',
              }));
            } else if (!uiState.visibleSteps.includes('phases')) {
              setUiState(() => ({
                visibleSteps: ['general', 'procedure', 'phases'],
                currentStep: 'phases',
              }));
            } else if (uiState.currentStep !== 'location') {
              setUiState(() => ({
                visibleSteps: ['general', 'procedure', 'phases', 'location'],
                currentStep: 'location',
              }));
            }
          }}
        />
      )}
      {renderRecipeInfoButton && (
        <RecipeInfoMenuItem
          title={t('recipe_settings_phase_recipe_info')}
          onClick={() => setUiState((uiState) => ({...uiState, currentStep: 'phases'}))}
        />
      )}
      {canFinish && (
        <MenuItem
          color="custom.surfaceBrand"
          IconComponent={AcceptIcon}
          title={t('recipe_settings_phase_save')}
          isSelected
          onClick={onFinish}
        />
      )}
    </List>
  );
}

export default RecipeSettingsMenu;
