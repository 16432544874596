import {useMediaQuery, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {ReactComponent as OvenIcon} from '../../assets/icons/oven.svg';
import {ReactComponent as RecipesIcon} from '../../assets/icons/recipes.svg';
import {ReactComponent as SettingsIcon} from '../../assets/icons/settings.svg';
import Menu from '../common/Menu';

export type BakeryMenuItem = 'settings' | 'ovens' | 'recipes';

export type BakeryMenuProps = {
  selectedItem?: BakeryMenuItem;
  onSelectItem?: (item: BakeryMenuItem) => void;
};

function BakeryMenu(props: BakeryMenuProps) {
  const {selectedItem, onSelectItem} = props;
  const {t} = useTranslation();

  const theme = useTheme();
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Menu
      direction={isLargerThanMd ? 'column' : 'row'}
      items={[
        {
          text: t('bakery_menu_settings_label'),
          hideText: !isLargerThanMd,
          IconComponent: SettingsIcon,
          isSelected: selectedItem === 'settings',
          onSelectItem: () => onSelectItem?.('settings'),
        },
        {
          text: t('bakery_menu_ovens_label'),
          hideText: !isLargerThanMd,
          IconComponent: OvenIcon,
          isSelected: selectedItem === 'ovens',
          onSelectItem: () => onSelectItem?.('ovens'),
        },
        {
          text: t('recipes'),
          hideText: !isLargerThanMd,
          IconComponent: RecipesIcon,
          isSelected: selectedItem === 'recipes',
          onSelectItem: () => onSelectItem?.('recipes'),
        },
      ]}
    />
  );
}

export default BakeryMenu;
