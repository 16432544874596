import {Box, List, ListItem, Typography} from '@mui/material';
import {Fragment, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import Oven from '../../models/entities/oven';
import OvenGroup from '../../models/entities/oven-group';
import {OvenModelId} from '../../models/entities/oven-model';
import GradientOverflow from '../common/GradientOverflow';
import Switch from '../common/Switch';
import {OvenPanelData} from './RecipeSchedule';

type OvenGroupListItemProps = {
  ovenGroup: OvenGroup;
  selectedOvenPanelIds: string[];
  onSelectOvenPanel: (ovenPanel: OvenPanelData) => void;
  isCleaning?: boolean;
};

function OvenGroupListItem(props: OvenGroupListItemProps) {
  const {ovenGroup, selectedOvenPanelIds, onSelectOvenPanel, isCleaning} = props;
  const {t} = useTranslation();

  const ovens = useMemo(
    () =>
      Array.from(ovenGroup.ovens ?? []).sort(
        (ovenA, ovenB) => (ovenA.ovenGroupOrder ?? 1) - (ovenB.ovenGroupOrder ?? 1),
      ),
    [ovenGroup],
  );

  const selectedFirstId = selectedOvenPanelIds.find((id) =>
    ovens.some((oven) => oven.ovenPanels?.some((ovenPanel) => ovenPanel.id === id)),
  );
  const selectedFirstOrder =
    ovens.find((oven) => oven.ovenPanels?.[0]?.id === selectedFirstId)?.ovenGroupOrder ?? 1;
  const selectedAll = ovens.every((oven) =>
    selectedOvenPanelIds.includes(oven.ovenPanels?.[0]?.id ?? ''),
  );

  return (
    <ListItem sx={{margin: 0, padding: 0}}>
      <Box sx={{width: '100%'}}>
        <Typography
          sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 500, lineHeight: '28px'}}>
          {ovenGroup.description}
        </Typography>
        {ovens.map((oven) => {
          const ovenPanel = oven.ovenPanels?.[0];
          if (ovenPanel == null) return null;
          return (
            <Box
              key={oven.id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '40px',
                color: 'custom.textPrimary',
                cursor: 'pointer',
                '&:hover': {
                  color: 'custom.textBrand',
                },
              }}
              onClick={() =>
                onSelectOvenPanel({
                  id: ovenPanel.id,
                  ovenChamberId: ovenPanel.ovenChamberId,
                  ovenId: oven.id,
                  ovenGroupId: ovenGroup.id,
                })
              }>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '24px',
                  color: 'inherit',
                }}>
                {`${t('programming_oven_serial_number_label')} ${oven.ovenGroupOrder ?? 1}: ${
                  oven.serialNumber
                }`}
              </Typography>
              <Switch checked={selectedOvenPanelIds.includes(ovenPanel.id)} />
            </Box>
          );
        })}
        {isCleaning && (
          <Fragment>
            <Typography
              sx={{
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px',
                color: 'custom.textTertiary',
              }}>
              {t('turboram_combi_cleaning_warning')}
            </Typography>
            {selectedAll && (
              <Typography
                sx={{
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '24px',
                  color: 'custom.textBrand',
                }}>
                {t(
                  selectedFirstOrder === 1
                    ? 'turboram_combi_cleaning_top_oven_first_warning'
                    : 'turboram_combi_cleaning_base_oven_first_warning',
                )}
              </Typography>
            )}
          </Fragment>
        )}
      </Box>
    </ListItem>
  );
}

type ChamberOvenListItemProps = {
  oven: Oven;
  selectedOvenPanelIds: string[];
  onSelectOvenPanel: (ovenPanel: OvenPanelData) => void;
};

function ChamberOvenListItem(props: ChamberOvenListItemProps) {
  const {oven, selectedOvenPanelIds, onSelectOvenPanel} = props;
  const {t} = useTranslation();

  const ovenChambers = useMemo(
    () =>
      Array.from(oven.ovenChambers ?? []).sort(
        (ovenChamberA, ovenChamberB) =>
          (ovenChamberA.ovenOrder ?? 1) - (ovenChamberB.ovenOrder ?? 1),
      ),
    [oven],
  );

  return (
    <ListItem sx={{margin: 0, padding: 0}}>
      <Box sx={{width: '100%'}}>
        <Typography
          sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 500, lineHeight: '28px'}}>
          {oven.description ?? oven.serialNumber}
        </Typography>
        {ovenChambers.map((ovenChamber) => {
          const ovenPanel = ovenChamber.ovenPanels?.[0];
          if (ovenPanel == null) return null;
          return (
            <Box
              key={ovenChamber.id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '40px',
                color: 'custom.textPrimary',
                cursor: 'pointer',
                '&:hover': {
                  color: 'custom.textBrand',
                },
              }}
              onClick={() =>
                onSelectOvenPanel({
                  id: ovenPanel.id,
                  ovenChamberId: ovenChamber.id,
                  ovenId: oven.id,
                })
              }>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '24px',
                  color: 'inherit',
                }}>
                {`${t('programming_chamber_serial_number_label')} ${ovenChamber.ovenOrder ?? 1}: ${
                  ovenChamber.serialNumber
                }`}
              </Typography>
              <Switch checked={selectedOvenPanelIds.includes(ovenPanel.id)} />
            </Box>
          );
        })}
      </Box>
    </ListItem>
  );
}

type OvenListItemProps = {
  oven: Oven;
  selectedOvenPanelIds: string[];
  onSelectOvenPanel: (ovenPanel: OvenPanelData) => void;
};

function OvenListItem(props: OvenListItemProps) {
  const {oven, selectedOvenPanelIds, onSelectOvenPanel} = props;
  const {t} = useTranslation();

  const ovenPanels = useMemo(
    () =>
      Array.from(oven.ovenPanels ?? []).sort(
        (ovenPanelA, ovenPanelB) => (ovenPanelA.ovenOrder ?? 1) - (ovenPanelB.ovenOrder ?? 1),
      ),
    [oven],
  );

  if (ovenPanels.length === 1) {
    const ovenPanel = ovenPanels[0];
    return (
      <ListItem
        sx={{margin: 0, padding: 0}}
        onClick={() =>
          onSelectOvenPanel({
            id: ovenPanel.id,
            ovenChamberId: ovenPanel.ovenChamberId,
            ovenId: oven.id,
          })
        }>
        <Box
          sx={{
            width: '100%',
            color: 'custom.textPrimary',
            cursor: 'pointer',
            '&:hover': {
              color: 'custom.textBrand',
            },
          }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: '40px',
            }}>
            <Typography
              sx={{
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '28px',
                color: 'custom.textPrimary',
              }}>
              {oven.description ?? oven.serialNumber}
            </Typography>
            <Switch checked={selectedOvenPanelIds.includes(ovenPanel.id)} />
          </Box>
          <Typography
            sx={{
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
              color: 'inherit',
            }}>
            {ovenPanels.length === 1
              ? `${t('programming_serial_number_label')}: ${oven.serialNumber}`
              : `${t('programming_chamber_label')} ${ovenPanel.ovenOrder ?? 1}`}
          </Typography>
        </Box>
      </ListItem>
    );
  }

  return (
    <ListItem sx={{margin: 0, padding: 0}}>
      <Box sx={{width: '100%'}}>
        <Typography
          sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 500, lineHeight: '28px'}}>
          {oven.description ?? oven.serialNumber}
        </Typography>
        {ovenPanels.map((ovenPanel) => (
          <Box
            key={ovenPanel.id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: '40px',
              color: 'custom.textPrimary',
              cursor: 'pointer',
              '&:hover': {
                color: 'custom.textBrand',
              },
            }}
            onClick={() =>
              onSelectOvenPanel({
                id: ovenPanel.id,
                ovenChamberId: ovenPanel.ovenChamberId,
                ovenId: oven.id,
              })
            }>
            <Typography
              sx={{
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px',
                color: 'inherit',
              }}>
              {ovenPanels.length === 1
                ? `${t('programming_serial_number_label')}: ${oven.serialNumber}`
                : `${t('programming_chamber_label')} ${ovenPanel.ovenOrder ?? 1}`}
            </Typography>
            <Switch checked={selectedOvenPanelIds.includes(ovenPanel.id)} />
          </Box>
        ))}
      </Box>
    </ListItem>
  );
}

type OvenListProps = {
  ovenGroups: OvenGroup[];
  ovens: Oven[];
  selectedOvenPanelIds: string[];
  onSelectOvenPanel: (ovenPanel: OvenPanelData) => void;
  isCleaning?: boolean;
  ovensNotFoundMessage?: string;
};

function OvenList(props: OvenListProps) {
  const {
    ovenGroups,
    ovens,
    selectedOvenPanelIds,
    onSelectOvenPanel,
    isCleaning,
    ovensNotFoundMessage,
  } = props;

  return (
    <Box sx={{height: {xs: 'calc(100vh - 464px)', md: 'calc(100vh - 424px)'}, minHeight: '256px'}}>
      <GradientOverflow hideScrollbar>
        <List
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '32px',
            margin: 0,
            padding: 0,
            paddingInline: '32px',
          }}>
          {ovens.length === 0 && ovenGroups.length === 0 ? (
            <ListItem sx={{margin: 0, padding: 0}}>
              <Typography
                sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 500, lineHeight: '28px'}}>
                {ovensNotFoundMessage}
              </Typography>
            </ListItem>
          ) : (
            <Fragment>
              {ovens.map((oven) =>
                oven.ovenModelId === OvenModelId.Modulram ? (
                  <ChamberOvenListItem
                    key={oven.id}
                    oven={oven}
                    selectedOvenPanelIds={selectedOvenPanelIds}
                    onSelectOvenPanel={onSelectOvenPanel}
                  />
                ) : (
                  <OvenListItem
                    key={oven.id}
                    oven={oven}
                    selectedOvenPanelIds={selectedOvenPanelIds}
                    onSelectOvenPanel={onSelectOvenPanel}
                  />
                ),
              )}
              {ovenGroups.map((ovenGroup) => (
                <OvenGroupListItem
                  key={ovenGroup.id}
                  ovenGroup={ovenGroup}
                  selectedOvenPanelIds={selectedOvenPanelIds}
                  onSelectOvenPanel={onSelectOvenPanel}
                  isCleaning={isCleaning}
                />
              ))}
            </Fragment>
          )}
        </List>
      </GradientOverflow>
    </Box>
  );
}

export default OvenList;
