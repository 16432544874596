import {ListItem, ListItemIcon, Typography} from '@mui/material';
import {ElementType, useState} from 'react';
import Icon from '../common/Icon';

type DrawerMenuItemProps = {
  icon: ElementType<any>;
  text: string;
  onClick?: () => void;
  disabled?: boolean;
};

function DrawerMenuItem(props: DrawerMenuItemProps) {
  const {icon, text, onClick, disabled} = props;

  const [isHovered, setIsHovered] = useState(false);

  return (
    <ListItem
      sx={{
        maxWidth: '304px',
        opacity: disabled ? 0.5 : 1,
        cursor: disabled ? undefined : 'pointer',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        if (!disabled) {
          onClick?.();
        }
      }}>
      <ListItemIcon>
        <Icon
          IconComponent={icon}
          size="40px"
          color={
            disabled
              ? 'custom.iconsPrimary'
              : isHovered
              ? 'custom.iconsBrand'
              : 'custom.iconsPrimary'
          }
        />
      </ListItemIcon>
      {isHovered && (
        <Typography
          sx={{
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '32px',
            color: disabled ? 'custom.textPrimary' : 'custom.textBrand',
          }}>
          {text}
        </Typography>
      )}
    </ListItem>
  );
}

export default DrawerMenuItem;
