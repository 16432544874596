import Response from '../models/responses/response';
import httpClient from '../utils/http-client';

const baseService = {
  setAuthorizationHeader: function (value: string) {
    httpClient.setAuthorizationHeader(value);
  },
  removeAuthorizationHeader: function () {
    httpClient.setAuthorizationHeader('');
  },
  get: async function <TResponse, TQuery = unknown>(
    url: string,
    query?: TQuery,
  ): Promise<TResponse> {
    return this.getResult(httpClient.get(url, query));
  },
  post: async function <TResponse, TRequest = unknown, TQuery = unknown>(
    url: string,
    request: TRequest,
    query?: TQuery,
  ): Promise<TResponse> {
    return this.getResult(httpClient.post(url, request, query));
  },
  patch: async function <TResponse, TRequest = unknown, TQuery = unknown>(
    url: string,
    request: TRequest,
    query?: TQuery,
  ): Promise<TResponse> {
    return this.getResult(httpClient.patch(url, request, query));
  },
  put: async function <TResponse, TRequest = unknown, TQuery = unknown>(
    url: string,
    request: TRequest,
    query?: TQuery,
  ): Promise<TResponse> {
    return this.getResult(httpClient.put(url, request, query));
  },
  delete: async function <TResponse, TQuery = unknown, TRequest = unknown>(
    url: string,
    query?: TQuery,
    request?: TRequest,
  ): Promise<TResponse> {
    return this.getResult(httpClient.delete(url, query, request));
  },
  getResult: async function <TResponse>(request: Promise<Response<TResponse>>) {
    const response = await request;

    if (response.error != null) {
      throw response.error;
    }

    return response.data;
  },
};

export default baseService;
