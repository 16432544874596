import {Box} from '@mui/material';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import paths from '../../routes/paths';
import useBreadcrumbsStore from '../../state/breadcrumbs';
import Menu from '../common/Menu';
import About from './About';
import LanguageSettings from './LanguageSettings';
import NotificationSettings from './NotificationSettings';

function getTabIndex(tab?: string) {
  switch (tab) {
    case 'notifications':
      return 1;
    case 'about':
      return 2;
    case 'language':
    default:
      return 0;
  }
}

function Settings() {
  const navigate = useNavigate();
  const {tab} = useParams();
  const {t} = useTranslation();

  const setBreadcrumbs = useBreadcrumbsStore((state) => state.setBreadcrumbs);

  const tabs = [
    {value: 'language', label: t('profile_settings_tab_language_label')},
    {value: 'notifications', label: t('profile_settings_tab_notifications_label')},
    {value: 'about', label: t('profile_settings_tab_about_label')},
  ];
  const selectedTabIndex = getTabIndex(tab);
  const selectedTab = tabs[selectedTabIndex];

  useEffect(() => {
    setBreadcrumbs([
      {
        title: t('profile_breadcrumb'),
        onClick: () => navigate(paths.profile),
      },
      {
        title: t('profile_settings_breadcrumb'),
        onClick: () => navigate(`${paths.profileSettings}/language`),
      },
      {title: selectedTab.label},
    ]);
    return () => setBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: {xs: 'calc(100vw - 120px)', sm: 'calc(100vw - 240px)', md: 'calc(100vw - 445px)'},
        marginTop: {xs: '16px', md: '24px'},
        marginLeft: {xs: '60px', sm: '120px', md: '32px'},
      }}>
      <Menu
        direction="row"
        showUnselectedText
        items={[
          {
            text: tabs[0].label,
            isSelected: selectedTabIndex === 0,
            onSelectItem: () => navigate(`${paths.profileSettings}/${tabs[0].value}`),
          },
          {
            text: tabs[1].label,
            isSelected: selectedTabIndex === 1,
            onSelectItem: () => navigate(`${paths.profileSettings}/${tabs[1].value}`),
          },
          {
            text: tabs[2].label,
            isSelected: selectedTabIndex === 2,
            onSelectItem: () => navigate(`${paths.profileSettings}/${tabs[2].value}`),
          },
        ]}
      />
      {selectedTabIndex === 0 && <LanguageSettings />}
      {selectedTabIndex === 1 && <NotificationSettings />}
      {selectedTabIndex === 2 && <About />}
      <Box sx={{minHeight: '64px'}} />
    </Box>
  );
}

export default Settings;
