import {Box, Typography} from '@mui/material';
import {Validator} from 'jsonschema';
import {Fragment, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';
import {ReactComponent as AcceptIcon} from '../../../assets/icons/accept.svg';
import {ReactComponent as AddIcon} from '../../../assets/icons/add.svg';
import {ReactComponent as ArrowRightIcon} from '../../../assets/icons/arrow-right.svg';
import {ReactComponent as DeclineIcon} from '../../../assets/icons/decline.svg';
import useSplashScreen from '../../../hooks/common/use-splash-screen';
import useUser from '../../../hooks/common/use-user';
import Recipe from '../../../models/entities/recipe';
import RecipePhase from '../../../models/entities/recipe-phase';
import RecipeType from '../../../models/entities/recipe-type';
import paths from '../../../routes/paths';
import services from '../../../services/provider';
import useBreadcrumbsStore, {Breadcrumb} from '../../../state/breadcrumbs';
import arrayUtils from '../../../utils/arrays';
import cryptoUtils from '../../../utils/crypto';
import numberUtils from '../../../utils/numbers';
import ovenModelUtils from '../../../utils/oven-models';
import stringUtils from '../../../utils/strings';
import ActionBanner from '../../common/ActionBanner';
import Icon from '../../common/Icon';
import IconButton from '../../common/IconButton';
import LoadingBackdrop from '../../common/LoadingBackdrop';
import Span from '../../common/Span';
import RecipeSettingsMenu from './RecipeSettingsMenu';
import GeneralStep from './steps/GeneralStep';
import LocationStep from './steps/LocationStep';
import PhaseStep from './steps/PhaseStep';
import ProcedureStep from './steps/ProcedureStep';

export function getEmptyRecipePhase(
  index: number,
  recipeId: string,
  companyId: string,
): RecipePhase {
  return {
    id: cryptoUtils.uuid(),
    index,
    duration: 0,
    temperature: 0,
    topTemperature: 0,
    floorTemperature: 0,
    topPower: 0,
    floorPower: 0,
    steamInjectionNumber: 0,
    steamInjectionDuration: 0,
    steamExitValveOpened: false,
    turbineSpeed: 1,
    turbineRestSeconds: 0,
    recipeId,
    companyId,
  };
}

function getEmptyRecipe(
  ovenModelId: number,
  ovenSubModelId: number | null,
  companyId: string,
  recipeTypeId?: string | null,
): Recipe {
  const recipeId = cryptoUtils.uuid();
  return {
    id: recipeId,
    name: '',
    description: '',
    procedure: '',
    ovenModelId,
    ovenSubModelId,
    recipeSchemaId: '',
    companyId,
    recipePhases: [getEmptyRecipePhase(0, recipeId, companyId)],
    recipeTypes:
      recipeTypeId != null && recipeTypeId !== 'all' ? [{id: recipeTypeId} as RecipeType] : [],
    bakeries: [],
  };
}

function getStepBreadcrumb(
  step: RecipeSettingsStep,
  phaseIndex: number,
  t: (key: string) => string,
) {
  switch (step) {
    case 'general':
      return t('recipe_settings_phase_general');
    case 'procedure':
      return t('recipe_settings_phase_procedure');
    case 'phases':
      return `${t('recipe_settings_phase_phase')}${phaseIndex + 1}`;
    case 'location':
      return t('recipe_settings_phase_location');
  }
}

function parseRecipeSettingsAction(value?: string): RecipeSettingsAction {
  switch (value) {
    case 'create':
    case 'update':
    case 'locate':
      return value;
    default:
      return 'create';
  }
}

export type RecipeSettingsAction = 'create' | 'update' | 'locate';

export type RecipeSettingsStep = 'general' | 'procedure' | 'phases' | 'location';

export type RecipeSettingsUiState = {
  visibleSteps: RecipeSettingsStep[];
  currentStep: RecipeSettingsStep;
};

function RecipeSettings() {
  const navigate = useNavigate();
  const {ovenModelId, recipeTypeId, action, recipeId} = useParams();
  const selectedOvenModelId = numberUtils.parseInt(ovenModelId, 1);
  const selectedAction = parseRecipeSettingsAction(action);
  const queryClient = useQueryClient();
  const {t} = useTranslation();

  const {user} = useUser();
  const {splash} = useSplashScreen();

  const setBreadcrumbs = useBreadcrumbsStore((state) => state.setBreadcrumbs);

  const validator = new Validator();

  const [uiState, setUiState] = useState<RecipeSettingsUiState>({
    visibleSteps: ['general'],
    currentStep: 'general',
  });

  const [recipeBuild, setRecipeBuild] = useState<Recipe>(
    getEmptyRecipe(selectedOvenModelId, null, user?.companyId ?? '', recipeTypeId),
  );

  const [selectedPhaseIndex, setSelectedPhaseIndex] = useState(0);

  const {data: recipe, isFetching: isLoadingRecipe} = useQuery({
    enabled: user != null && recipeId != null,
    queryFn: () =>
      services.recipe.getRecipe({
        params: {recipeId: recipeId ?? ''},
        query: {expand: ['recipePhases', 'recipeTypes', 'bakeries']},
      }),
    onSuccess: (recipe) => {
      setRecipeBuild({...recipe, modifiedAt: undefined});

      if (selectedAction === 'update') {
        setUiState({visibleSteps: ['general', 'procedure', 'phases'], currentStep: 'general'});
      }

      if (selectedAction === 'locate') {
        setUiState({visibleSteps: ['location'], currentStep: 'location'});
      }
    },
    onError: () => navigate(`${paths.recipes}/${ovenModelId}/${recipeTypeId}`),
  });

  const {data: recipeSchemas = [], isLoading: isLoadingRecipeSchemas} = useQuery({
    enabled: user != null,
    queryKey: ['recipeSchema', recipeBuild.ovenModelId],
    queryFn: () =>
      services.recipeSchema.getRecipeSchemas({
        query: {
          ovenModelId: recipeBuild.ovenModelId,
          version: 'v1',
        },
      }),
    onSuccess: (recipeSchemas) => {
      if (!arrayUtils.isNullOrEmpty(recipeSchemas)) {
        setRecipeBuild((recipeBuild) => ({
          ...recipeBuild,
          recipeSchemaId: recipeSchemas[0].id,
        }));
      }
    },
  });

  const recipePhaseSchema = recipeSchemas[0]?.recipePhaseSchema;

  const {mutate: createRecipe, isLoading: isLoadingCreateRecipe} = useMutation({
    mutationFn: services.recipe.createRecipe,
    onSuccess: (_, {request: recipe}) => {
      queryClient.setQueryData<Recipe[] | undefined>(
        ['recipes', {ovenModelId: recipeBuild.ovenModelId}],
        (recipes) => (recipes != null ? [...recipes, recipe] : [recipe]),
      );
      queryClient.invalidateQueries('recipes');
      navigate(
        `${paths.recipes}/${recipeBuild.ovenModelId}/${recipe.recipeTypes?.[0]?.id ?? 'all'}`,
      );
    },
  });

  const {mutate: updateRecipe, isLoading: isLoadingUpdateRecipe} = useMutation({
    mutationFn: services.recipe.updateRecipe,
    onSuccess: (_, {request: updatedRecipe}) => {
      queryClient.setQueryData<Recipe[] | undefined>(
        ['recipes', {ovenModelId: recipeBuild.ovenModelId}],
        (recipes) =>
          recipes?.map((recipe) => (recipe.id === updatedRecipe.id ? updatedRecipe : recipe)),
      );
      queryClient.invalidateQueries('recipes');
      navigate(
        `${paths.recipes}/${recipeBuild.ovenModelId}/${
          updatedRecipe.recipeTypes?.[0]?.id ?? 'all'
        }`,
      );
    },
  });

  useEffect(() => {
    const breadcrumbs: Breadcrumb[] = [];
    breadcrumbs.push({
      title: t('recipes_breadcrumb'),
      onClick: () => navigate(paths.recipes),
    });
    if (selectedAction === 'create') {
      breadcrumbs.push({
        title: t('recipe_settings_create_recipe_breadcrumb'),
        onClick: () => {
          setUiState({visibleSteps: ['general'], currentStep: 'general'});

          setRecipeBuild(getEmptyRecipe(selectedOvenModelId, null, user?.companyId ?? ''));
        },
      });
    }

    if (selectedAction === 'update') {
      breadcrumbs.push({
        title: t('recipe_settings_update_recipe_breadcrumb'),
        onClick: () => {
          setUiState({visibleSteps: ['general', 'procedure', 'phases'], currentStep: 'general'});

          setRecipeBuild(
            recipe ?? getEmptyRecipe(selectedOvenModelId, null, user?.companyId ?? ''),
          );
        },
      });
    }

    if (selectedAction !== 'locate') {
      breadcrumbs.push({
        title: getStepBreadcrumb(uiState.currentStep, selectedPhaseIndex, t),
      });
    } else {
      breadcrumbs.push({
        title: t('recipe_settings_locate_breadcrumb'),
      });
    }

    setBreadcrumbs(breadcrumbs);
    return () => setBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uiState.currentStep, selectedPhaseIndex]);

  function handleAddPhase() {
    const length = recipeBuild.recipePhases?.length ?? 0;

    if (length < 4) {
      const emptyRecipePhase = getEmptyRecipePhase(
        recipeBuild.recipePhases?.length ?? 0,
        recipeBuild.id,
        recipeBuild.companyId,
      );

      setRecipeBuild((recipeBuild) => ({
        ...recipeBuild,
        recipePhases: [...(recipeBuild.recipePhases ?? []), emptyRecipePhase],
      }));

      setSelectedPhaseIndex(length);

      setUiState((uiState) => ({...uiState, currentStep: 'phases'}));
    }
  }

  function handleDeletePhase(index: number) {
    setRecipeBuild((recipeBuild) => ({
      ...recipeBuild,
      recipePhases: recipeBuild.recipePhases?.filter((_, phaseIndex) => phaseIndex !== index),
    }));

    if (index <= selectedPhaseIndex) {
      setSelectedPhaseIndex(selectedPhaseIndex > 0 ? selectedPhaseIndex - 1 : 0);
    }

    setUiState((uiState) => ({...uiState, currentStep: 'phases'}));
  }

  function handleCreateOrUpdateRecipe() {
    if (user == null) {
      return;
    }

    recipeBuild.companyId = user.companyId;

    if (selectedAction === 'create') {
      createRecipe({request: recipeBuild});
    } else {
      updateRecipe({
        params: {recipeId: recipeBuild.id},
        request: recipeBuild,
      });
    }
  }

  function handleCancel() {
    if (action === 'create') {
      splash({
        title: t('cancel_create_recipe_message'),
        caption: t('cancel_create_recipe_caption'),
        acceptAction: () =>
          navigate(
            `${paths.recipes}/${ovenModelId}${recipeTypeId != null ? `/${recipeTypeId}` : ''}`,
          ),
      });
    }
  }

  // #trolhice
  const hasChanges =
    JSON.stringify({
      name: recipeBuild.name,
      procedure: recipeBuild.procedure,
      recipePhases: recipeBuild.recipePhases,
      bakeries: recipeBuild.bakeries,
      recipeTypes: recipeBuild.recipeTypes,
    }) !==
    JSON.stringify({
      name: recipe?.name,
      procedure: recipe?.procedure,
      recipePhases: recipe?.recipePhases,
      bakeries: recipe?.bakeries,
      recipeTypes: recipe?.recipeTypes,
    });

  const canFinish =
    action === 'locate' ||
    (action === 'create' &&
      uiState.currentStep === 'location' &&
      !stringUtils.isNullOrWhiteSpace(recipeBuild.name) &&
      !arrayUtils.isNullOrEmpty(recipeBuild.recipeTypes) &&
      recipePhaseSchema != null &&
      !recipeBuild.recipePhases?.some(
        (recipePhase) => validator.validate(recipePhase, recipePhaseSchema).errors.length > 0,
      ) &&
      !arrayUtils.isNullOrEmpty(recipeBuild.bakeries)) ||
    (action === 'update' &&
      hasChanges &&
      !stringUtils.isNullOrWhiteSpace(recipeBuild.name) &&
      !arrayUtils.isNullOrEmpty(recipeBuild.recipeTypes) &&
      recipePhaseSchema != null &&
      !recipeBuild.recipePhases?.some(
        (recipePhase) => validator.validate(recipePhase, recipePhaseSchema).errors.length > 0,
      ));

  const isLoading =
    isLoadingRecipe || isLoadingRecipeSchemas || isLoadingCreateRecipe || isLoadingUpdateRecipe;

  const renderBanner = action !== 'locate' && uiState.currentStep !== 'location';
  const renderAddPhaseButton =
    action !== 'locate' &&
    uiState.currentStep !== 'location' &&
    uiState.visibleSteps.includes('phases') &&
    !stringUtils.isNullOrWhiteSpace(recipeBuild.name) &&
    !arrayUtils.isNullOrEmpty(recipeBuild.recipeTypes) &&
    recipePhaseSchema != null &&
    !recipeBuild.recipePhases?.some(
      (recipePhase) => validator.validate(recipePhase, recipePhaseSchema).errors.length > 0,
    ) &&
    (recipeBuild.recipePhases?.length ?? 0) < 4;
  const renderNextButton =
    action === 'create' &&
    ((!uiState.visibleSteps.includes('procedure') &&
      !stringUtils.isNullOrWhiteSpace(recipeBuild.name) &&
      !arrayUtils.isNullOrEmpty(recipeBuild.recipeTypes)) ||
      (!uiState.visibleSteps.includes('phases') &&
        !stringUtils.isNullOrWhiteSpace(recipeBuild.name) &&
        !arrayUtils.isNullOrEmpty(recipeBuild.recipeTypes)) ||
      (uiState.currentStep !== 'location' &&
        !stringUtils.isNullOrWhiteSpace(recipeBuild.name) &&
        !arrayUtils.isNullOrEmpty(recipeBuild.recipeTypes) &&
        recipePhaseSchema != null &&
        !recipeBuild.recipePhases?.some(
          (recipePhase) => validator.validate(recipePhase, recipePhaseSchema).errors.length > 0,
        )));

  return (
    <Fragment>
      <Box
        sx={{
          width: {xs: 'calc(100vw - 120px)', sm: 'calc(100vw - 240px)', md: '173px'},
          marginTop: {xs: '32px', md: '96px'},
          marginLeft: {xs: '60px', sm: '120px'},
        }}>
        <RecipeSettingsMenu
          action={selectedAction}
          uiState={uiState}
          setUiState={setUiState}
          selectedPhaseIndex={selectedPhaseIndex}
          setSelectedPhaseIndex={setSelectedPhaseIndex}
          onAddPhase={handleAddPhase}
          onDeletePhase={handleDeletePhase}
          recipeBuild={recipeBuild}
          renderNextButton={renderNextButton}
          renderAddPhaseButton={renderAddPhaseButton}
          canFinish={canFinish}
          onFinish={handleCreateOrUpdateRecipe}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: {xs: 'calc(100vw - 120px)', sm: 'calc(100vw - 240px)', md: 'calc(100vw - 445px)'},
          marginLeft: {xs: '60px', sm: '120px', md: '0px'},
        }}>
        {renderBanner && (
          <Fragment>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '40px',
                padding: '12px 32px',
                gap: '8px',
                marginTop: {xs: '16px', md: '24px'},
                backgroundColor: 'custom.surfaceBrand',
                borderRadius: '360px',
              }}>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                  color: 'custom.textSecondary',
                }}>
                <Span sx={{fontWeight: 500}}>
                  {action === 'create'
                    ? `${t('recipe_settings_new_recipe_label')} | `
                    : `${recipe?.name ?? ''} | `}
                </Span>
                {ovenModelUtils.getDescription(recipeBuild.ovenModelId)}
              </Typography>
              <IconButton
                IconComponent={DeclineIcon}
                inactiveColor="custom.iconsSecondary"
                activeColor="custom.iconsSecondary"
                onClick={handleCancel}
              />
            </Box>
          </Fragment>
        )}
        {action !== 'locate' && uiState.currentStep === 'general' && (
          <Fragment>
            <Typography
              sx={{
                marginTop: '32px',
                paddingInline: '32px',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '28px',
              }}>
              {t('recipe_settings_general_step_title')}
            </Typography>
            <GeneralStep recipeBuild={recipeBuild} setRecipeBuild={setRecipeBuild} />
          </Fragment>
        )}
        {action !== 'locate' && uiState.currentStep === 'procedure' && (
          <Fragment>
            <Typography
              sx={{
                marginTop: '32px',
                paddingInline: '32px',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '28px',
              }}>
              {t('recipe_settings_procedures_step_title')}
            </Typography>
            <ProcedureStep recipeBuild={recipeBuild} setRecipeBuild={setRecipeBuild} />
          </Fragment>
        )}
        {action !== 'locate' && uiState.currentStep === 'phases' && (
          <Fragment>
            <Typography
              sx={{
                marginTop: '32px',
                paddingInline: '32px',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '28px',
              }}>
              {`${t('recipe_settings_phases_step_title')} ${selectedPhaseIndex + 1}`}
            </Typography>
            <PhaseStep
              recipePhaseSchema={recipeSchemas[0].recipePhaseSchema ?? {}}
              selectedRecipePhaseIndex={selectedPhaseIndex}
              recipeBuild={recipeBuild}
              setRecipeBuild={setRecipeBuild}
            />
          </Fragment>
        )}
        {uiState.currentStep === 'location' && (
          <LocationStep
            recipeBuild={recipeBuild}
            setRecipeBuild={setRecipeBuild}
            onReturnToRecipeInfo={() =>
              setUiState((uiState) => ({...uiState, currentStep: 'phases'}))
            }
            canFinish={canFinish}
            onFinish={handleCreateOrUpdateRecipe}
            isFromLocation={action === 'locate'}
          />
        )}
        {renderAddPhaseButton && (
          <ActionBanner
            sx={{display: {xs: 'flex', md: 'none'}, marginTop: '64px'}}
            color="custom.surfaceTertiary"
            text={t('add_new_phase')}
            secondary={<Icon IconComponent={AddIcon} color="custom.iconsSecondary" />}
            onClick={handleAddPhase}
          />
        )}
        {renderNextButton && (
          <ActionBanner
            sx={{
              display: {xs: 'flex', md: 'none'},
              marginTop: renderAddPhaseButton ? '16px' : '64px',
            }}
            color="custom.surfaceBrand"
            text={t('recipe_settings_next')}
            secondary={<Icon IconComponent={ArrowRightIcon} color="custom.iconsSecondary" />}
            onClick={() => {
              if (!uiState.visibleSteps.includes('procedure')) {
                setUiState(() => ({
                  visibleSteps: ['general', 'procedure'],
                  currentStep: 'procedure',
                }));
              } else if (!uiState.visibleSteps.includes('phases')) {
                setUiState(() => ({
                  visibleSteps: ['general', 'procedure', 'phases'],
                  currentStep: 'phases',
                }));
              } else if (uiState.currentStep !== 'location') {
                setUiState(() => ({
                  visibleSteps: ['general', 'procedure', 'phases', 'location'],
                  currentStep: 'location',
                }));
              }
            }}
          />
        )}
        {canFinish && (
          <ActionBanner
            sx={{
              display: {xs: 'flex', md: 'none'},
              marginTop: renderAddPhaseButton ? '16px' : '64px',
            }}
            color="custom.surfaceBrand"
            text={t('recipe_settings_phase_save')}
            secondary={<Icon IconComponent={AcceptIcon} color="custom.iconsSecondary" />}
            onClick={handleCreateOrUpdateRecipe}
          />
        )}
        <Box sx={{minHeight: '64px'}} />
      </Box>
      <LoadingBackdrop isLoading={isLoading} />
    </Fragment>
  );
}

export default RecipeSettings;
