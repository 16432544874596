import {Box, InputBase, Typography} from '@mui/material';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as DeclineIcon} from '../../assets/icons/decline.svg';
import {ReactComponent as SearchIcon} from '../../assets/icons/search.svg';
import Bakery from '../../models/entities/bakery';
import City from '../../models/entities/city';
import Icon from '../common/Icon';
import IconButton from '../common/IconButton';

export type SearchFieldProps = {
  searchText: string;
  onChangeSearchText: (searchText: string) => void;
  selectedCity?: City;
  selectedBakery?: Bakery;
  onReturnToBakeries: () => void;
  onReturnToCountries: () => void;
};

function SearchField(props: SearchFieldProps) {
  const {
    searchText,
    onChangeSearchText,
    selectedCity,
    selectedBakery,
    onReturnToBakeries,
    onReturnToCountries,
  } = props;
  const {t} = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: '40px',
        padding: '12px 32px',
        backgroundColor: 'custom.surfaceQuaternary',
        borderRadius: '360px',
      }}>
      {selectedCity != null && (
        <Fragment>
          <Typography
            sx={{
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: 'normal',
              cursor: 'pointer',
            }}
            noWrap
            onClick={onReturnToBakeries}>
            {selectedCity.name}
          </Typography>
          <Typography
            sx={{
              marginInline: '4px',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: 'normal',
            }}>
            |
          </Typography>
        </Fragment>
      )}
      {selectedBakery != null ? (
        <Fragment>
          <Typography
            sx={{
              flex: 1,
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal',
            }}
            noWrap>
            {selectedBakery.name}
          </Typography>
          <IconButton
            IconComponent={DeclineIcon}
            activeColor="custom.iconsPrimary"
            onClick={onReturnToCountries}
          />
        </Fragment>
      ) : (
        <Fragment>
          <InputBase
            sx={{
              flex: 1,
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal',
            }}
            placeholder={t('programming_search_field_placeholder')}
            value={searchText}
            onChange={(event) => onChangeSearchText(event.target.value)}
          />
          <Icon IconComponent={SearchIcon} />
        </Fragment>
      )}
    </Box>
  );
}

export default SearchField;
