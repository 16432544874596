import {Box, Typography} from '@mui/material';
import {ChangeEvent} from 'react';
import {useTranslation} from 'react-i18next';
import {SmallInput} from '../../../common/Input';
import {AddOvenData} from '../AddOven';

export type DescriptionStepProps = {
  addOvenData: AddOvenData;
  setAddOvenData: (setter: (addOvenData: AddOvenData) => AddOvenData) => void;
};

function DescriptionStep(props: DescriptionStepProps) {
  const {addOvenData, setAddOvenData} = props;
  const {t} = useTranslation();

  function handleChangeDescription(event: ChangeEvent<HTMLInputElement>) {
    setAddOvenData((addOvenData) => ({...addOvenData, description: event.target.value}));
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px', paddingInline: '32px'}}>
      <Typography sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 500, lineHeight: '28px'}}>
        {t('enter_the_name_you_want_to_identify_the_oven_with')}
      </Typography>
      <SmallInput
        label={t('name')}
        value={addOvenData.description}
        onChange={handleChangeDescription}
      />
    </Box>
  );
}

export default DescriptionStep;
