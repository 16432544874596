import Oven from '../../../../../models/entities/oven';
import OvenChamber from '../../../../../models/entities/oven-chamber';
import {OvenModelId} from '../../../../../models/entities/oven-model';
import {AddOvenData, AddOvenUiState} from '../../AddOven';
import CompactramSerialNumberStep from './Compactram';
import ElectramSerialNumberStep from './Electram';
import ModulramSerialNumberStep from './Modulram';
import RotoramSerialNumberStep from './Rotoram';
import TurboramSerialNumberStep from './Turboram';

export type SerialNumberStepProps = {
  ovens: Oven[];
  ovenChambers: OvenChamber[];
  setUiState: (setter: (uiState: AddOvenUiState) => AddOvenUiState) => void;
  addOvenData: AddOvenData;
  setAddOvenData: (setter: (addOvenData: AddOvenData) => AddOvenData) => void;
};

function SerialNumberStep(props: SerialNumberStepProps) {
  const {addOvenData} = props;

  if (addOvenData.ovenModelId === OvenModelId.Turboram) {
    return <TurboramSerialNumberStep {...props} />;
  }

  if (addOvenData.ovenModelId === OvenModelId.Rotoram) {
    return <RotoramSerialNumberStep {...props} />;
  }

  if (addOvenData.ovenModelId === OvenModelId.Modulram) {
    return <ModulramSerialNumberStep {...props} />;
  }

  if (addOvenData.ovenModelId === OvenModelId.Electram) {
    return <ElectramSerialNumberStep {...props} />;
  }

  return <CompactramSerialNumberStep {...props} />;
}

export default SerialNumberStep;
