import {Box} from '@mui/material';
import {Fragment, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation, useQuery} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';
import {ReactComponent as DeclineIcon} from '../../../assets/icons/decline.svg';
import useSplashScreen from '../../../hooks/common/use-splash-screen';
import useUser from '../../../hooks/common/use-user';
import OvenChamber from '../../../models/entities/oven-chamber';
import {OvenModelId} from '../../../models/entities/oven-model';
import {OvenSubModelId} from '../../../models/entities/oven-sub-model';
import paths from '../../../routes/paths';
import services from '../../../services/provider';
import useBreadcrumbsStore, {Breadcrumb} from '../../../state/breadcrumbs';
import countryUtils from '../../../utils/countries';
import cryptoUtils from '../../../utils/crypto';
import numberUtils from '../../../utils/numbers';
import ovenModelUtils from '../../../utils/oven-models';
import stringUtils from '../../../utils/strings';
import ActionBanner from '../../common/ActionBanner';
import IconButton from '../../common/IconButton';
import LoadingBackdrop from '../../common/LoadingBackdrop';
import AddOvenMenu from './AddOvenMenu';
import DescriptionStep from './steps/DescriptionStep';
import ModelStep from './steps/ModelStep';
import OvenChambersNumberStep from './steps/OvenChambersNumberStep';
import SubModelStep from './steps/SubModelStep';
import SerialNumberStep from './steps/serial-number/SerialNumberStep';

export type AddOvenStep =
  | 'model'
  | 'sub-model'
  | 'oven-chambers-number'
  | 'serial-number'
  | 'description';

export type AddOvenUiState = {
  visibleSteps: AddOvenStep[];
  isNextButtonVisible: boolean;
  currentStep: AddOvenStep;
  nextStep: AddOvenStep;
};

export type AddOvenData = {
  ovenModelId: number | null;
  ovenSubModel: 'single' | 'combi' | 'xl' | null;
  serialNumber: string;
  topOvenSerialNumber: string;
  bottomOvenSerialNumber: string;
  ovenChamberSerialNumbers: string[];
  description: string;
};

function AddOven() {
  const navigate = useNavigate();
  const {countryId, cityId, bakeryId} = useParams();
  const {t} = useTranslation();

  const {user} = useUser();
  const {splash} = useSplashScreen();

  const setBreadcrumbs = useBreadcrumbsStore((state) => state.setBreadcrumbs);

  const [uiState, setUiState] = useState<AddOvenUiState>({
    visibleSteps: ['model'],
    isNextButtonVisible: false,
    currentStep: 'model',
    nextStep: 'sub-model',
  });
  const [addOvenData, setAddOvenData] = useState<AddOvenData>({
    ovenModelId: null,
    ovenSubModel: null,
    serialNumber: '',
    topOvenSerialNumber: '',
    bottomOvenSerialNumber: '',
    ovenChamberSerialNumbers: [],
    description: '',
  });

  const {data: countries = [], isLoading: isLoadingCountries} = useQuery({
    enabled: user != null,
    queryKey: ['companyCountries', user?.companyId],
    queryFn: () =>
      services.company.getCompanyCountries({
        params: {
          companyId: user!.companyId,
        },
        query: {
          expand: ['districts', 'cities', 'bakeries'],
        },
      }),
    select: (countries) => countryUtils.filterStockBakeries(countries, user),
  });

  const {selectedCountry, selectedCity, selectedBakery} = useMemo(() => {
    const selectedCountryId = numberUtils.parseInt(countryId);
    const selectedCountry = countries.find((country) => country.id === selectedCountryId);
    const cities = selectedCountry?.districts?.flatMap((district) => district.cities ?? []) ?? [];
    const selectedCityId = numberUtils.parseInt(cityId);
    const selectedCity = cities.find((city) => city.id === selectedCityId);
    const bakeries = selectedCity?.bakeries ?? [];
    const selectedBakery = bakeries.find((bakery) => bakery.id === bakeryId);
    return {selectedCountry, cities, selectedCity, bakeries, selectedBakery};
  }, [countries, countryId, cityId, bakeryId]);

  const {data: ovens = [], isLoading: isLoadingOvens} = useQuery({
    enabled: user != null,
    queryKey: [
      'ovens',
      {bakeryIds: [selectedBakery?.id, user?.companyId], expand: ['ovenChambers', 'ovenPanels']},
    ],
    queryFn: () =>
      services.oven.getOvens({
        query: {
          bakeryIds: [selectedBakery!.id, user!.companyId],
          expand: ['ovenChambers', 'ovenPanels'],
        },
      }),
  });

  const {data: ovenChambers = [], isLoading: isLoadingOvenChambers} = useQuery({
    enabled: user != null,
    queryKey: ['ovenChambers', {bakeryIds: [selectedBakery?.id, user?.companyId]}],
    queryFn: () =>
      services.ovenChamber.getOvenChambers({
        query: {
          bakeryIds: [selectedBakery!.id, user!.companyId],
        },
      }),
  });

  const canAddOven = useMemo(() => {
    if (addOvenData.ovenModelId === OvenModelId.Turboram && addOvenData.ovenSubModel === 'single') {
      const oven = ovens.find(
        (oven) =>
          oven.ovenModelId === OvenModelId.Turboram &&
          (oven.ovenSubModelId === OvenSubModelId.Turboram5T ||
            oven.ovenSubModelId === OvenSubModelId.Turboram9T) &&
          oven.serialNumber === addOvenData.serialNumber,
      );

      return oven != null && !stringUtils.isNullOrWhiteSpace(addOvenData.description);
    }

    if (addOvenData.ovenModelId === OvenModelId.Turboram && addOvenData.ovenSubModel === 'xl') {
      const oven = ovens.find(
        (oven) =>
          oven.ovenModelId === OvenModelId.Turboram &&
          oven.ovenSubModelId === OvenSubModelId.TurboramXL &&
          oven.serialNumber === addOvenData.serialNumber,
      );

      return oven != null && !stringUtils.isNullOrWhiteSpace(addOvenData.description);
    }

    if (addOvenData.ovenModelId === OvenModelId.Turboram && addOvenData.ovenSubModel === 'combi') {
      const topOven = ovens.find(
        (oven) =>
          oven.ovenModelId === OvenModelId.Turboram &&
          (oven.ovenSubModelId === OvenSubModelId.Turboram5T ||
            oven.ovenSubModelId === OvenSubModelId.Turboram9T) &&
          oven.serialNumber === addOvenData.topOvenSerialNumber,
      );

      const bottomOven = ovens.find(
        (oven) =>
          oven.ovenModelId === OvenModelId.Turboram &&
          (oven.ovenSubModelId === OvenSubModelId.Turboram5T ||
            oven.ovenSubModelId === OvenSubModelId.Turboram9T) &&
          oven.serialNumber === addOvenData.bottomOvenSerialNumber,
      );

      return (
        topOven != null &&
        bottomOven != null &&
        topOven.serialNumber !== bottomOven.serialNumber &&
        !stringUtils.isNullOrWhiteSpace(addOvenData.description)
      );
    }

    if (addOvenData.ovenModelId === OvenModelId.Modulram) {
      const targetOvenChambers = addOvenData.ovenChamberSerialNumbers
        .map((serialNumber) =>
          ovenChambers.find(
            (ovenChamber) =>
              ovenChamber.ovenModelId === OvenModelId.Modulram &&
              ovenChamber.serialNumber === serialNumber,
          ),
        )
        .filter(Boolean) as OvenChamber[];

      return (
        targetOvenChambers.length === addOvenData.ovenChamberSerialNumbers.length &&
        !stringUtils.isNullOrWhiteSpace(addOvenData.description)
      );
    }

    const oven = ovens.find(
      (oven) =>
        oven.ovenModelId === addOvenData.ovenModelId &&
        oven.serialNumber === addOvenData.serialNumber,
    );

    return oven != null && !stringUtils.isNullOrWhiteSpace(addOvenData.description);
  }, [ovens, ovenChambers, addOvenData]);

  const {mutate: updateOven, isLoading: isLoadingUpdateOven} = useMutation({
    mutationFn: services.oven.updateOven,
  });

  const {mutate: createOvenGroup, isLoading: isLoadingCreateOvenGroup} = useMutation({
    mutationFn: services.ovenGroup.createOvenGroup,
  });

  useEffect(() => {
    const breadcrumbs: Breadcrumb[] = [];
    breadcrumbs.push({
      title: t('bakeries_breadcrumb'),
      onClick: () => navigate(paths.bakeries),
    });
    if (selectedCountry != null) {
      breadcrumbs.push({
        title: selectedCountry.name,
        onClick: () => navigate(`${paths.bakeries}/${selectedCountry.id}`),
      });

      if (selectedCity != null) {
        breadcrumbs.push({
          title: selectedCity.name,
          onClick: () => navigate(`${paths.bakeries}/${selectedCountry.id}/${selectedCity.id}`),
        });

        if (selectedBakery != null) {
          breadcrumbs.push({
            title: selectedBakery.name,
            onClick: () =>
              navigate(
                `${paths.bakeries}/${selectedCountry.id}/${selectedCity.id}/${selectedBakery.id}`,
              ),
          });

          breadcrumbs.push({
            title: t('bakery_settings_breadcrumb'),
            onClick: () =>
              navigate(
                `${paths.bakeries}/${selectedCountry.id}/${selectedCity.id}/${selectedBakery.id}/settings`,
              ),
          });

          breadcrumbs.push({
            title: t('add_oven_breadcrumb'),
            onClick: () => {
              setUiState({
                visibleSteps: ['model'],
                isNextButtonVisible: false,
                currentStep: 'model',
                nextStep: 'sub-model',
              });
              setAddOvenData({
                ovenModelId: null,
                ovenSubModel: null,
                serialNumber: '',
                topOvenSerialNumber: '',
                bottomOvenSerialNumber: '',
                ovenChamberSerialNumbers: [],
                description: '',
              });
            },
          });

          if (addOvenData.ovenModelId != null) {
            breadcrumbs.push({
              title: ovenModelUtils.getDescription(addOvenData.ovenModelId),
            });
          }
        }
      }
    }
    setBreadcrumbs(breadcrumbs);
    return () => setBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry, selectedCity, selectedBakery, addOvenData]);

  function handleAddOven() {
    if (user == null || selectedBakery == null || addOvenData.ovenModelId == null) {
      return;
    }

    if (addOvenData.ovenModelId === OvenModelId.Turboram && addOvenData.ovenSubModel === 'combi') {
      const topOven = ovens.find((oven) => oven.serialNumber === addOvenData.topOvenSerialNumber);
      const bottomOven = ovens.find(
        (oven) => oven.serialNumber === addOvenData.bottomOvenSerialNumber,
      );

      if (topOven != null && bottomOven != null) {
        const ovenGroupId = cryptoUtils.uuid();
        topOven.ovenGroupId = ovenGroupId;
        topOven.ovenGroupOrder = 2;
        topOven.bakeryId = selectedBakery.id;
        bottomOven.ovenGroupId = ovenGroupId;
        bottomOven.ovenGroupOrder = 1;
        bottomOven.bakeryId = selectedBakery.id;

        createOvenGroup(
          {
            request: {
              id: cryptoUtils.uuid(),
              description: addOvenData.description,
              ovenModelId: OvenModelId.Turboram,
              ovenSubModelId: null,
              bakeryId: selectedBakery.id,
              companyId: user.companyId,
              ovens: [bottomOven, topOven],
            },
          },
          {
            onSuccess: () =>
              splash({
                title: `${addOvenData.description} ${t('has_been_added_to_your_list_of_ovens')}`,
                nextAction: () =>
                  navigate(
                    `${paths.bakeries}/${selectedCountry?.id}/${selectedCity?.id}/${selectedBakery?.id}/ovens`,
                  ),
              }),
          },
        );
      }

      return;
    }

    // TODO: review implementation (this implementation assumes that one of the oven chambers has an oven)
    if (addOvenData.ovenModelId === OvenModelId.Modulram) {
      const targetOvenChambers = addOvenData.ovenChamberSerialNumbers
        .map((serialNumber) =>
          ovenChambers.find((ovenChamber) => ovenChamber.serialNumber === serialNumber),
        )
        .filter(Boolean) as OvenChamber[];

      const ovenId = targetOvenChambers.find((ovenChamber) => ovenChamber.ovenId != null)?.ovenId;
      const oven = ovens.find((oven) => oven.id === ovenId);

      if (oven != null) {
        targetOvenChambers.forEach((ovenChamber, index) => {
          ovenChamber.ovenId = oven.id;
          ovenChamber.ovenOrder = index + 1;
          ovenChamber.bakeryId = selectedBakery.id;
        });

        oven.description = addOvenData.description;
        oven.bakeryId = selectedBakery.id;
        oven.ovenChambers = targetOvenChambers;

        updateOven(
          {params: {ovenId: oven.id}, request: oven},
          {
            onSuccess: () =>
              splash({
                title: `${addOvenData.description} ${t('has_been_added_to_your_list_of_ovens')}`,
                nextAction: () =>
                  navigate(
                    `${paths.bakeries}/${selectedCountry?.id}/${selectedCity?.id}/${selectedBakery?.id}/ovens/${addOvenData.ovenModelId}`,
                  ),
              }),
          },
        );
      }

      return;
    }

    const oven = ovens.find((oven) => oven.serialNumber === addOvenData.serialNumber);

    if (oven != null) {
      oven.description = addOvenData.description;
      oven.bakeryId = selectedBakery.id;

      updateOven(
        {params: {ovenId: oven.id}, request: oven},
        {
          onSuccess: () =>
            splash({
              title: `${addOvenData.description} ${t('has_been_added_to_your_list_of_ovens')}`,
              nextAction: () =>
                navigate(
                  `${paths.bakeries}/${selectedCountry?.id}/${selectedCity?.id}/${selectedBakery?.id}/ovens/${addOvenData.ovenModelId}`,
                ),
            }),
        },
      );
    }
  }

  const isLoading =
    isLoadingCountries ||
    isLoadingOvens ||
    isLoadingOvenChambers ||
    isLoadingUpdateOven ||
    isLoadingCreateOvenGroup;

  return (
    <Fragment>
      <Box
        sx={{
          width: {md: '173px'},
          marginTop: {xs: '32px', md: '96px'},
          marginLeft: {xs: '60px', sm: '120px'},
        }}>
        <AddOvenMenu
          addOvenUiState={uiState}
          setAddOvenUiState={setUiState}
          addOvenData={addOvenData}
          canFinish={canAddOven}
          onFinish={handleAddOven}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
          width: {xs: 'calc(100vw - 120px)', sm: 'calc(100vw - 240px)', md: 'calc(100vw - 445px)'},
          marginTop: {xs: '16px', md: '24px'},
          marginLeft: {xs: '60px', sm: '120px', md: '32px'},
        }}>
        <ActionBanner
          color="custom.surfaceBrand"
          text={t('bakery_add_oven_label')}
          secondary={
            <IconButton
              IconComponent={DeclineIcon}
              inactiveColor="custom.iconsSecondary"
              activeColor="custom.iconsSecondary"
              onClick={() =>
                navigate(
                  `${paths.bakeries}/${selectedCountry?.id}/${selectedCity?.id}/${selectedBakery?.id}/ovens`,
                )
              }
            />
          }
        />
        {uiState.currentStep === 'model' && (
          <ModelStep
            setUiState={setUiState}
            addOvenData={addOvenData}
            setAddOvenData={setAddOvenData}
          />
        )}
        {uiState.currentStep === 'sub-model' && (
          <SubModelStep
            setUiState={setUiState}
            addOvenData={addOvenData}
            setAddOvenData={setAddOvenData}
          />
        )}
        {uiState.currentStep === 'oven-chambers-number' && (
          <OvenChambersNumberStep
            setUiState={setUiState}
            addOvenData={addOvenData}
            setAddOvenData={setAddOvenData}
          />
        )}
        {uiState.currentStep === 'serial-number' && (
          <SerialNumberStep
            ovens={ovens}
            ovenChambers={ovenChambers}
            setUiState={setUiState}
            addOvenData={addOvenData}
            setAddOvenData={setAddOvenData}
          />
        )}
        {uiState.currentStep === 'description' && (
          <DescriptionStep addOvenData={addOvenData} setAddOvenData={setAddOvenData} />
        )}
      </Box>
      <LoadingBackdrop isLoading={isLoading} />
    </Fragment>
  );
}

export default AddOven;
