import {Box, List, ListItem} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {ReactComponent as AddIcon} from '../../assets/icons/add.svg';
import {ReactComponent as ArrowRightIcon} from '../../assets/icons/arrow-right.svg';
import OvenModel from '../../models/entities/oven-model';
import ActionBanner from '../common/ActionBanner';
import GradientOverflow from '../common/GradientOverflow';
import Icon from '../common/Icon';
import {BakeryMenuItem} from './BakeryMenu';

export type BakeryOvenModelsProps = {
  selectedMenuItem: BakeryMenuItem;
  ovenModels: OvenModel[];
  onSelectOvenModel: (ovenModel: OvenModel) => void;
  onAddOven: () => void;
};

function BakeryOvenModels(props: BakeryOvenModelsProps) {
  const {selectedMenuItem, ovenModels, onSelectOvenModel, onAddOven} = props;

  const {t} = useTranslation();

  return (
    <Box
      sx={{
        width: {xs: 'calc(100vw - 120px)', sm: 'calc(100vw - 240px)', md: 'calc(100vw - 445px)'},
        height: {xs: 'calc(100vh - 348px)', md: 'calc(100vh - 308px)'},
        minHeight: '256px',
        marginTop: '32px',
      }}>
      <GradientOverflow hideScrollbar>
        <List sx={{display: 'flex', flexDirection: 'column', gap: '32px', margin: 0, padding: 0}}>
          {ovenModels.map((ovenModel) => (
            <ListItem key={ovenModel.id} sx={{margin: 0, padding: 0}}>
              <ActionBanner
                color="custom.surfaceTertiary"
                text={ovenModel.description}
                secondary={<Icon IconComponent={ArrowRightIcon} color="custom.iconsSecondary" />}
                onClick={() => onSelectOvenModel(ovenModel)}
              />
            </ListItem>
          ))}
          {selectedMenuItem === 'ovens' && (
            <ListItem sx={{margin: 0, padding: 0}}>
              <ActionBanner
                color="custom.surfaceBrand"
                text={t('bakery_add_oven_label')}
                secondary={<Icon IconComponent={AddIcon} color="custom.iconsSecondary" />}
                onClick={onAddOven}
              />
            </ListItem>
          )}
        </List>
      </GradientOverflow>
    </Box>
  );
}

export default BakeryOvenModels;
