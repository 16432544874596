import {Box, ClickAwayListener} from '@mui/material';
import {Fragment, useState} from 'react';
import {ReactComponent as ClockIcon} from '../../assets/icons/clock.svg';
import {ReactComponent as MenuIcon} from '../../assets/icons/menu.svg';
import {ReactComponent as OvenIcon} from '../../assets/icons/oven.svg';
import IconButton from '../common/IconButton';
import {ScheduleOrderBy} from './Schedules';

type SchedulesMenuProps = {
  orderBy: ScheduleOrderBy;
  setOrderBy: (orderBy: ScheduleOrderBy) => void;
};

function SchedulesMenu(props: SchedulesMenuProps) {
  const {orderBy, setOrderBy} = props;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '12px'}}>
        <IconButton
          IconComponent={MenuIcon}
          activeColor="custom.iconsTertiary"
          inactiveColor="custom.iconsTertiary"
          isActive={isOpen}
          onClick={() => setIsOpen((previousValue) => !previousValue)}
        />
        {isOpen && (
          <Fragment>
            <IconButton
              IconComponent={ClockIcon}
              inactiveColor="custom.iconsTertiary"
              isActive={orderBy === 'time'}
              onClick={() => {
                setIsOpen(false);
                setOrderBy('time');
              }}
            />
            <IconButton
              IconComponent={OvenIcon}
              inactiveColor="custom.iconsTertiary"
              isActive={orderBy === 'oven'}
              onClick={() => {
                setIsOpen(false);
                setOrderBy('oven');
              }}
            />
          </Fragment>
        )}
      </Box>
    </ClickAwayListener>
  );
}

export default SchedulesMenu;
