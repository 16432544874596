import {Box} from '@mui/material';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import useLanguage from '../../hooks/common/use-language';
import SimpleList from '../common/SimpleList';
import SimpleSearch from '../common/SimpleSearch';

const languages = ['en', 'pt'];

function LanguageSettings() {
  const {t} = useTranslation();

  const [language, setLanguage] = useLanguage();

  const [searchText, setSearchText] = useState('');

  const filteredLanguages = languages.filter((language) =>
    t(language).toLowerCase().includes(searchText.toLowerCase()),
  );

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px', marginTop: '16px'}}>
      <SimpleSearch value={searchText} onChange={setSearchText} />
      <Box sx={{paddingInline: '32px'}}>
        <SimpleList
          options={filteredLanguages.map((language) => ({
            id: language,
            primary: t(language),
          }))}
          selectedOption={language}
          onChangeSelectedOption={setLanguage}
        />
      </Box>
    </Box>
  );
}

export default LanguageSettings;
