import {Box, Typography} from '@mui/material';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation, useQuery} from 'react-query';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {v4 as uuid} from 'uuid';
import useSplashScreen from '../../hooks/common/use-splash-screen';
import useUser from '../../hooks/common/use-user';
import paths from '../../routes/paths';
import services from '../../services/provider';
import numberUtils from '../../utils/numbers';
import stringUtils from '../../utils/strings';
import {Button} from '../common/Button';
import {Input} from '../common/Input';
import LoadingBackdrop from '../common/LoadingBackdrop';
import LoadingBox from '../common/LoadingBox';
import {SearchableSimpleList} from '../common/SimpleList';

function Name() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {t} = useTranslation();

  const [name, setName] = useState('');

  function handleNext() {
    searchParams.set('name', name);
    navigate(`${paths.bakeriesCreate}?${searchParams}`);
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '72px',
        width: {xs: '504px', md: '790px'},
        maxWidth: '80vw',
      }}>
      <Typography sx={{fontSize: '32px', fontStyle: 'normal', fontWeight: 400, lineHeight: '40px'}}>
        {t('create_bakery_title')}
      </Typography>
      <Input
        label={t('create_bakery_name_input_label')}
        value={name}
        onChange={(event) => setName(event.target.value)}
        onKeyUp={(event) => (event.key === 'Enter' ? handleNext() : null)}
      />
      <Button onClick={handleNext} disabled={stringUtils.isNullOrWhiteSpace(name)}>
        {t('create_bakery_next_button_label')}
      </Button>
    </Box>
  );
}

function Countries() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {t} = useTranslation();

  const {user} = useUser();

  const [selectedCountryId, setSelectedCountryId] = useState('');

  const {data: countries = [], isLoading} = useQuery({
    enabled: user != null,
    queryKey: ['countries'],
    queryFn: () => services.country.getCountries(),
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '72px',
        width: {xs: '504px', md: '790px'},
        maxWidth: '80vw',
      }}>
      <Typography sx={{fontSize: '32px', fontStyle: 'normal', fontWeight: 400, lineHeight: '40px'}}>
        {t('create_bakery_country_title')}
      </Typography>
      <Box sx={{height: '30vh', overflow: 'auto'}}>
        {isLoading ? (
          <LoadingBox />
        ) : (
          <SearchableSimpleList
            typographySx={{
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '32px',
            }}
            options={countries.map((country) => ({
              id: country.id.toString(),
              primary: country.name,
            }))}
            selectedOption={selectedCountryId}
            onChangeSelectedOption={setSelectedCountryId}
          />
        )}
      </Box>
      <Button
        onClick={() => {
          searchParams.set('countryId', selectedCountryId);
          navigate(`${paths.bakeriesCreate}?${searchParams}`);
        }}
        disabled={stringUtils.isNullOrWhiteSpace(selectedCountryId)}>
        {t('create_bakery_next_button_label')}
      </Button>
    </Box>
  );
}

function Cities() {
  const navigate = useNavigate();
  const {splash} = useSplashScreen();
  const {t} = useTranslation();

  const {user} = useUser();

  const [searchParams] = useSearchParams();
  const name = searchParams.get('name');
  const countryId = numberUtils.parseInt(searchParams.get('countryId'), 177);
  const fromSignup = searchParams.get('fromSignup') === 'true';

  const [selectedCityId, setSelectedCityId] = useState('');

  const {data: cities = [], isLoading: isLoadingCities} = useQuery({
    enabled: user != null,
    queryKey: ['cities', {countryId}],
    queryFn: () =>
      services.city.getCities({
        query: {
          countryId,
        },
      }),
  });

  const {mutate: createBakery, isLoading: isLoadingCreateBakery} = useMutation({
    mutationFn: services.bakery.createBakery,
    onSuccess: () => {
      if (fromSignup) {
        splash({
          title: t('splash_screen_account_created_title'),
          caption: t('splash_screen_account_created_caption'),
          nextAction: () => navigate(paths.dashboard),
        });
        return;
      }
      navigate(paths.bakeries);
    },
  });

  function handleCreateBakery() {
    if (user != null) {
      createBakery({
        request: {
          id: uuid(),
          name: name ?? '',
          cityId: numberUtils.parseInt(selectedCityId, 89695),
          companyId: user.companyId,
        },
      });
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '72px',
        width: {xs: '504px', md: '790px'},
        maxWidth: '80vw',
      }}>
      <Typography sx={{fontSize: '32px', fontStyle: 'normal', fontWeight: 400, lineHeight: '40px'}}>
        {t('create_bakery_city_title')}
      </Typography>
      <Box sx={{height: '30vh', overflow: 'auto'}}>
        {isLoadingCities ? (
          <LoadingBox />
        ) : (
          <SearchableSimpleList
            typographySx={{
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '32px',
            }}
            options={cities.map((city) => ({
              id: city.id.toString(),
              primary: city.name,
            }))}
            selectedOption={selectedCityId}
            onChangeSelectedOption={setSelectedCityId}
          />
        )}
      </Box>
      <Button
        onClick={handleCreateBakery}
        disabled={stringUtils.isNullOrWhiteSpace(selectedCityId)}>
        {t('create_bakery_next_button_label')}
      </Button>
      <LoadingBackdrop isLoading={isLoadingCreateBakery} />
    </Box>
  );
}

function CreateBakery() {
  const [searchParams] = useSearchParams();

  const renderCountries = searchParams.has('name') && !searchParams.has('countryId');
  const renderCities = searchParams.has('countryId') && !searchParams.has('cityId');

  if (renderCountries) return <Countries />;

  if (renderCities) return <Cities />;

  return <Name />;
}

export default CreateBakery;
