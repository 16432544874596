import {Box, InputBase, styled, Typography} from '@mui/material';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

const InputBaseStyled = styled(InputBase)(() => ({
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
  '& input': {
    textAlign: 'right',
  },
}));

type TurbineRestSecondsRowProps = {
  turbineRestSeconds: string;
  onChange: (turbineRestSeconds: string) => void;
  error?: boolean;
};

function TurbineRestSecondsRow(props: TurbineRestSecondsRowProps) {
  const {turbineRestSeconds, onChange, error} = props;
  const {t} = useTranslation();

  const [showPlaceholder, setShowPlaceholder] = useState(true);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const length = event.target.value.length;
    if (length <= 2) {
      onChange(event.target.value);
    }
  }

  return (
    <Box sx={{display: 'flex', justifyContent: 'space-between', gap: '16px'}}>
      <Box>
        <Typography
          sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 500, lineHeight: '28px'}}>
          {t('recipe_settings_phases_turbine_rest_seconds_label')}
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            color: error ? 'custom.textBrand' : 'custom.textTertiary',
          }}>
          {t('recipe_settings_phases_turbine_rest_seconds_message')}
        </Typography>
      </Box>
      <InputBaseStyled
        sx={{
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '28px',
          color: error ? 'custom.textBrand' : 'custom.textPrimary',
        }}
        type="number"
        placeholder={showPlaceholder ? '0' : ''}
        value={turbineRestSeconds === '0' ? '' : turbineRestSeconds}
        onChange={handleChange}
        onFocus={() => setShowPlaceholder(false)}
        onBlur={() => setShowPlaceholder(true)}
        endAdornment={
          <Typography
            sx={{
              marginLeft: '4px',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '28px',
              color: error ? 'custom.textBrand' : 'custom.textPrimary',
            }}>
            {t('recipe_settings_phases_seconds_label')}
          </Typography>
        }
      />
    </Box>
  );
}

export default TurbineRestSecondsRow;
