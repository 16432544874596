import {Box, List, ListItem, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import Cleaning, {CleaningId} from '../../models/entities/cleaning';
import GradientOverflow from '../common/GradientOverflow';
import Switch from '../common/Switch';

type CleaningListItemProps = {
  cleaning: Cleaning;
  isSelected: boolean;
  onSelectCleaning: () => void;
};

function CleaningListItem(props: CleaningListItemProps) {
  const {cleaning, isSelected, onSelectCleaning} = props;
  const {t} = useTranslation();

  function getName() {
    switch (cleaning.id) {
      case CleaningId.Soft:
        return t('soft');
      case CleaningId.Medium:
        return t('medium');
      case CleaningId.High:
        return t('high');
      default:
        return t('manual');
    }
  }

  function getDescription() {
    switch (cleaning.id) {
      case CleaningId.Soft:
        return t('suitable_for_superficial_dirt_levels');
      case CleaningId.Medium:
        return t('suitable_for_medium_dirt_levels');
      case CleaningId.High:
        return t('suitable_for_extensive_dirt_levels');
      default:
        return t('suitable_for_manual_washing');
    }
  }

  return (
    <ListItem
      sx={{
        margin: 0,
        padding: 0,
        cursor: 'pointer',
        '&:hover': {
          color: 'custom.textBrand',
        },
      }}
      onClick={onSelectCleaning}>
      <Box sx={{width: '100%'}}>
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <Typography
            sx={{
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '28px',
              color: 'inherit',
            }}>
            {getName()}
          </Typography>
          <Switch checked={isSelected} />
        </Box>
        <Typography
          sx={{
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            color: 'custom.textPrimary',
          }}>
          {getDescription()}
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            color: 'custom.textPrimary',
          }}>
          {`${cleaning.duration / 60} min | ${cleaning.temperature}°C | ${
            cleaning.waterQuantity
          } L`}
        </Typography>
      </Box>
    </ListItem>
  );
}

type CleaningListProps = {
  cleanings: Cleaning[];
  selectedCleaningId: number | null;
  onSelectCleaning: (cleaningId: number) => void;
  cleaningsNotFoundMessage?: string;
};

function CleaningList(props: CleaningListProps) {
  const {cleanings, selectedCleaningId, onSelectCleaning, cleaningsNotFoundMessage} = props;

  return (
    <Box sx={{height: {xs: 'calc(100vh - 408px)', md: 'calc(100vh - 368px)'}, minHeight: '256px'}}>
      <GradientOverflow hideScrollbar>
        <List
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
            margin: 0,
            padding: 0,
            paddingInline: '32px',
          }}>
          {cleanings.length === 0 ? (
            <ListItem sx={{margin: 0, padding: 0}}>
              <Typography
                sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 400, lineHeight: '28px'}}>
                {cleaningsNotFoundMessage}
              </Typography>
            </ListItem>
          ) : (
            cleanings.map((cleaning) => (
              <CleaningListItem
                key={cleaning.id}
                cleaning={cleaning}
                isSelected={cleaning.id === selectedCleaningId}
                onSelectCleaning={() => onSelectCleaning(cleaning.id)}
              />
            ))
          )}
        </List>
      </GradientOverflow>
    </Box>
  );
}

export default CleaningList;
