import {useMediaQuery, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {ReactComponent as AcceptIcon} from '../../../assets/icons/accept.svg';
import {ReactComponent as ArrowRightIcon} from '../../../assets/icons/arrow-right.svg';
import {ReactComponent as NumberFourIcon} from '../../../assets/icons/number-four.svg';
import {ReactComponent as NumberOneIcon} from '../../../assets/icons/number-one.svg';
import {ReactComponent as NumberThreeIcon} from '../../../assets/icons/number-three.svg';
import {ReactComponent as NumberTwoIcon} from '../../../assets/icons/number-two.svg';
import {OvenModelId} from '../../../models/entities/oven-model';
import Menu from '../../common/Menu';
import {AddOvenData, AddOvenUiState} from './AddOven';

type AddOvenMenuProps = {
  addOvenUiState: AddOvenUiState;
  setAddOvenUiState: (setter: (addOvenUiState: AddOvenUiState) => AddOvenUiState) => void;
  addOvenData: AddOvenData;
  canFinish: boolean;
  onFinish: () => void;
};

function AddOvenMenu(props: AddOvenMenuProps) {
  const {addOvenUiState, setAddOvenUiState, addOvenData, canFinish, onFinish} = props;
  const {t} = useTranslation();

  const theme = useTheme();
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Menu
      direction={isLargerThanMd ? 'column' : 'row'}
      items={[
        {
          text: t('general'),
          IconComponent: NumberOneIcon,
          isSelected: addOvenUiState.currentStep === 'model',
          hidden: !addOvenUiState.visibleSteps.includes('model'),
          onSelectItem: () =>
            setAddOvenUiState((addOvenUiState) => ({...addOvenUiState, currentStep: 'model'})),
        },
        {
          text: t('sub_model'),
          IconComponent: NumberTwoIcon,
          isSelected: addOvenUiState.currentStep === 'sub-model',
          hidden: !addOvenUiState.visibleSteps.includes('sub-model'),
          onSelectItem: () =>
            setAddOvenUiState((addOvenUiState) => ({...addOvenUiState, currentStep: 'sub-model'})),
        },
        {
          text: t('chambers'),
          IconComponent: NumberTwoIcon,
          isSelected: addOvenUiState.currentStep === 'oven-chambers-number',
          hidden: !addOvenUiState.visibleSteps.includes('oven-chambers-number'),
          onSelectItem: () =>
            setAddOvenUiState((addOvenUiState) => ({
              ...addOvenUiState,
              currentStep: 'oven-chambers-number',
            })),
        },
        {
          text: t('serial_number'),
          IconComponent:
            addOvenData.ovenModelId === OvenModelId.Turboram ||
            addOvenData.ovenModelId === OvenModelId.Modulram
              ? NumberThreeIcon
              : NumberTwoIcon,
          isSelected: addOvenUiState.currentStep === 'serial-number',
          hidden: !addOvenUiState.visibleSteps.includes('serial-number'),
          onSelectItem: () =>
            setAddOvenUiState((addOvenUiState) => ({
              ...addOvenUiState,
              currentStep: 'serial-number',
            })),
        },
        {
          text: t('name'),
          IconComponent:
            addOvenData.ovenModelId === OvenModelId.Turboram ||
            addOvenData.ovenModelId === OvenModelId.Modulram
              ? NumberFourIcon
              : NumberThreeIcon,
          isSelected: addOvenUiState.currentStep === 'description',
          hidden: !addOvenUiState.visibleSteps.includes('description'),
          onSelectItem: () =>
            setAddOvenUiState((addOvenUiState) => ({
              ...addOvenUiState,
              currentStep: 'description',
            })),
        },
        {
          color: 'custom.surfaceBrand',
          text: t('add_oven_next_step_label'),
          IconComponent: ArrowRightIcon,
          isSelected: true,
          hidden: !addOvenUiState.isNextButtonVisible,
          onSelectItem: () => {
            setAddOvenUiState((addOvenUiState) => ({
              visibleSteps: [...addOvenUiState.visibleSteps, addOvenUiState.nextStep],
              isNextButtonVisible: false,
              currentStep: addOvenUiState.nextStep,
              nextStep: addOvenUiState.nextStep,
            }));
          },
        },
        {
          color: 'custom.surfaceBrand',
          text: t('add_oven_finish_step_label'),
          IconComponent: AcceptIcon,
          isSelected: true,
          hidden: !canFinish,
          onSelectItem: onFinish,
        },
      ]}
    />
  );
}

export default AddOvenMenu;
