import {Box, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {OvenModelId} from '../../../../models/entities/oven-model';
import GradientOverflow from '../../../common/GradientOverflow';
import {AddOvenData, AddOvenUiState} from '../AddOven';

export type OvenChambersNumberStepProps = {
  setUiState: (uiState: AddOvenUiState) => void;
  addOvenData: AddOvenData;
  setAddOvenData: (setter: (addOvenData: AddOvenData) => AddOvenData) => void;
};

function OvenChambersNumberStep(props: OvenChambersNumberStepProps) {
  const {setUiState, addOvenData, setAddOvenData} = props;
  const {t} = useTranslation();

  function handleChangeOvenChambersNumber(value: number) {
    setAddOvenData(() => ({
      ovenModelId: OvenModelId.Modulram,
      ovenSubModel: null,
      serialNumber: '',
      topOvenSerialNumber: '',
      bottomOvenSerialNumber: '',
      ovenChamberSerialNumbers: Array.from({length: value}, () => ''),
      description: '',
    }));

    setUiState({
      visibleSteps: ['model', 'oven-chambers-number'],
      isNextButtonVisible: true,
      currentStep: 'oven-chambers-number',
      nextStep: 'serial-number',
    });
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px', paddingInline: '32px'}}>
      <Typography sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 500, lineHeight: '28px'}}>
        {t('select_the_number_of_chambers')}
      </Typography>
      <Box
        sx={{height: {xs: 'calc(100vh - 408px)', md: 'calc(100vh - 368px)', minHeight: '256px'}}}>
        <GradientOverflow hideScrollbar>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px'}}>
            {[
              {label: t('one'), value: 1},
              {label: t('two'), value: 2},
              {label: t('three'), value: 3},
              {label: t('four'), value: 4},
              {label: t('five'), value: 5},
            ].map((item) => (
              <Box
                key={item.value}
                sx={{
                  fontWeight:
                    item.value === addOvenData.ovenChamberSerialNumbers.length ? 500 : 400,
                  color:
                    item.value === addOvenData.ovenChamberSerialNumbers.length
                      ? 'custom.textBrand'
                      : 'custom.textPrimary',
                  cursor: 'pointer',
                  '&:hover': {
                    fontWeight: 500,
                    color: 'custom.textBrand',
                  },
                }}
                onClick={() => handleChangeOvenChambersNumber(item.value)}>
                <Typography
                  sx={{
                    fontSize: '20px',
                    fontStyle: 'normal',
                    fontWeight: 'inherit',
                    lineHeight: '28px',
                    color: 'inherit',
                  }}>
                  {item.label}
                </Typography>
              </Box>
            ))}
          </Box>
        </GradientOverflow>
      </Box>
    </Box>
  );
}

export default OvenChambersNumberStep;
