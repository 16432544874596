import {Box, Typography} from '@mui/material';
import {addSeconds, format, isSameDay, isValid, parse} from 'date-fns';
import {Fragment, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {ReactComponent as DeclineIcon} from '../../assets/icons/decline.svg';
import useSplashScreen from '../../hooks/common/use-splash-screen';
import useUser from '../../hooks/common/use-user';
import Recurrence from '../../models/entities/recurrence';
import {CreateOrUpdateSchedulesRequest} from '../../models/requests/schedule';
import paths from '../../routes/paths';
import services from '../../services/provider';
import useBreadcrumbsStore, {Breadcrumb} from '../../state/breadcrumbs';
import arrayUtils from '../../utils/arrays';
import cryptoUtils from '../../utils/crypto';
import dateUtils from '../../utils/dates';
import errorUtils, {ApplicationErrorCode} from '../../utils/errors';
import numberUtils from '../../utils/numbers';
import scheduleUtils from '../../utils/schedules';
import stringUtils from '../../utils/strings';
import ActionBanner from '../common/ActionBanner';
import IconButton from '../common/IconButton';
import LoadingBackdrop from '../common/LoadingBackdrop';
import SimpleSearch from '../common/SimpleSearch';
import CleaningList from './CleaningList';
import CreateCleaningScheduleMenu from './CleaningScheduleMenu';
import OvenList from './OvenList';
import SelectTime from './SelectTime';

function getEndTime(startTime: string, duration: number) {
  const timeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
  if (!timeRegex.test(startTime)) {
    return '-';
  }
  const endTime = format(addSeconds(parse(startTime, 'HH:mm', new Date()), duration), 'HH:mm');
  return endTime;
}

function getRecurrences(
  startDate: Date,
  startTime: string,
  weekDays: Day[],
  scheduleId: string,
  companyId: string,
) {
  const recurrences: Recurrence[] = [];

  if (!weekDays.some((weekDay) => weekDay === startDate.getDay())) {
    const recurrenceStartDate = dateUtils.setTime(startDate, startTime);

    recurrences.push({
      id: cryptoUtils.uuid(),
      startDate: format(recurrenceStartDate, 'yyyy-MM-dd'),
      startTime: startTime + ':00',
      weekDay: recurrenceStartDate.getDay() as Day,
      repeat: false,
      scheduleId,
      companyId,
    });
  }

  weekDays.forEach((day) => {
    let recurrenceStartDate = dateUtils.setTime(startDate, startTime);
    recurrenceStartDate = dateUtils.nextDateOfDay(startDate, day);

    recurrences.push({
      id: cryptoUtils.uuid(),
      startDate: format(recurrenceStartDate, 'yyyy-MM-dd'),
      startTime: startTime + ':00',
      weekDay: day,
      repeat: true,
      scheduleId,
      companyId,
    });
  });

  return recurrences;
}

export type CleaningScheduleStep = 'cleaning' | 'ovens' | 'time';

export type CleaningScheduleUiState = {
  visibleSteps: CleaningScheduleStep[];
  isNextButtonVisible: boolean;
  currentStep: CleaningScheduleStep;
  nextStep: CleaningScheduleStep;
};

export type OvenPanelData = {
  id: string;
  ovenChamberId?: string | null;
  ovenId?: string | null;
  ovenGroupId?: string | null;
};

type CleaningScheduleData = {
  cleaningId: number | null;
  ovenPanels: OvenPanelData[];
  startDate: Date;
  startTime: string;
  weekDays: Day[];
};

function CleaningSchedule() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {countryId, cityId, bakeryId, ovenModelId, scheduleId} = useParams();
  const [searchParams] = useSearchParams();
  const searchParamsDate = parse(searchParams.get('date') ?? '', 'MM-dd', new Date());
  const {t} = useTranslation();

  const {user} = useUser();
  const {splash} = useSplashScreen();

  const setBreadcrumbs = useBreadcrumbsStore((state) => state.setBreadcrumbs);

  const [uiState, setUiState] = useState<CleaningScheduleUiState>({
    visibleSteps: ['cleaning'],
    isNextButtonVisible: false,
    currentStep: 'cleaning',
    nextStep: 'ovens',
  });

  const [previousScheduleData, setPreviousScheduleData] = useState<CleaningScheduleData>({
    cleaningId: null,
    ovenPanels: [],
    startDate: new Date(),
    startTime: '00:00',
    weekDays: [],
  });

  const [scheduleData, setScheduleData] = useState<CleaningScheduleData>({
    cleaningId: null,
    ovenPanels: [],
    startDate: isValid(searchParamsDate) ? searchParamsDate : new Date(),
    startTime: '00:00',
    weekDays: [],
  });

  const [searchText, setSearchText] = useState('');
  const [endTime, setEndTime] = useState('00:00');
  const [shadowStartTime, setShadowStartTime] = useState('00:00');
  const [suggestedStartTime, setSuggestedStartTime] = useState('00:00');
  const [isValidStartTime, setIsValidStartTime] = useState(true);
  const [hasOverlap, setHasOverlap] = useState(false);

  const selectedOvenPanelIds = scheduleData.ovenPanels.map((ovenPanel) => ovenPanel.id);
  const selectedOvenPanelIdsSet = new Set(selectedOvenPanelIds);
  const selectedWeekDaysSet = new Set(scheduleData.weekDays);

  const {data: country, isLoading: isLoadingCountry} = useQuery({
    enabled: user != null && countryId != null,
    queryKey: ['countries', countryId],
    queryFn: () =>
      services.country.getCountry({
        params: {
          countryId: numberUtils.parseInt(countryId),
        },
      }),
  });

  const {data: city, isLoading: isLoadingCity} = useQuery({
    enabled: user != null && cityId != null,
    queryKey: ['cities', cityId],
    queryFn: () =>
      services.city.getCity({
        params: {
          cityId: numberUtils.parseInt(cityId),
        },
      }),
  });

  const {data: bakery, isLoading: isLoadingBakery} = useQuery({
    enabled: user != null && bakeryId != null,
    queryKey: ['bakeries', bakeryId],
    queryFn: () =>
      services.bakery.getBakery({
        params: {
          bakeryId: bakeryId!,
        },
      }),
  });

  const {data: ovenModel, isLoading: isLoadingOvenModel} = useQuery({
    enabled: user != null && bakery != null,
    queryKey: ['bakeryOvenModels', bakery?.id, ovenModelId],
    queryFn: () =>
      services.bakery.getBakeryOvenModel({
        params: {
          bakeryId: bakery!.id,
          ovenModelId: numberUtils.parseInt(ovenModelId, 1),
        },
        query: {
          expand: ['ovenGroups', 'ovens', 'ovenChambers', 'ovenPanels'],
        },
      }),
  });

  const {isFetching: isLoadingSchedule} = useQuery({
    enabled: user != null && scheduleId != null,
    queryFn: () =>
      services.schedule.getSchedule({
        params: {scheduleId: scheduleId!},
        query: {
          expand: ['recurrences', 'cleaning', 'ovenPanels'],
        },
      }),
    onSuccess: (schedule) => {
      if (schedule.cleaningId == null) {
        return;
      }

      const startTime = scheduleUtils.getStartTime(schedule);
      const endTime = getEndTime(startTime, schedule.cleaning!.duration);

      const scheduleData: CleaningScheduleData = {
        cleaningId: schedule.cleaningId,
        ovenPanels:
          schedule.ovenPanels?.map((ovenPanel) => ({
            id: ovenPanel.id,
            ovenChamberId: ovenPanel.ovenChamberId,
            ovenId: ovenPanel.ovenChamber?.ovenId ?? ovenPanel.ovenId,
            ovenGroupId: ovenPanel.ovenChamber?.oven?.ovenGroupId ?? ovenPanel.oven?.ovenGroupId,
          })) ?? [],
        startDate: new Date(schedule.recurrences![0].startDate),
        startTime,
        weekDays:
          schedule.recurrences
            ?.filter((recurrence) => recurrence.repeat)
            .map((recurrence) => recurrence.weekDay) ?? [],
      };

      setPreviousScheduleData(scheduleData);
      setScheduleData(scheduleData);

      setEndTime(endTime);
      setSuggestedStartTime(startTime);

      setUiState({
        visibleSteps: ['cleaning', 'ovens', 'time'],
        isNextButtonVisible: false,
        currentStep: 'cleaning',
        nextStep: 'cleaning',
      });
    },
    onError: () => navigate(paths.programming),
  });

  const {data: cleanings = [], isLoading: isLoadingCleanings} = useQuery({
    enabled: user != null,
    queryKey: ['cleanings', {ovenModel: ovenModel?.id}],
    queryFn: () =>
      services.cleaning.getCleanings({
        query: {ovenModelId: ovenModel?.id},
      }),
  });

  const selectedCleaning = cleanings.find((cleaning) => cleaning.id === scheduleData.cleaningId);

  const {mutate: createOrUpdateSchedules, isLoading: isLoadingCreateOrUpdateSchedules} =
    useMutation({
      mutationFn: services.schedule.createOrUpdateSchedules,
      onError: (error) =>
        errorUtils.handleMatch(error, [ApplicationErrorCode.ScheduleOverlapping], () =>
          setHasOverlap(true),
        ),
    });

  useEffect(() => {
    const breadcrumbs: Breadcrumb[] = [];
    breadcrumbs.push({
      title: t('programming_breadcrumb'),
      onClick: () => navigate(paths.programming),
    });
    if (country != null) {
      breadcrumbs.push({
        title: country.name,
        onClick: () => navigate(`${paths.programming}/${country.id}`),
      });

      if (city != null) {
        breadcrumbs.push({
          title: city.name,
          onClick: () => navigate(`${paths.programming}/${country.id}/${city.id}`),
        });

        if (bakery != null) {
          breadcrumbs.push({
            title: bakery.name,
            onClick: () => navigate(`${paths.programming}/${country.id}/${city.id}/${bakery.id}`),
          });

          if (ovenModel != null) {
            breadcrumbs.push({
              title: ovenModel.description,
              onClick: () =>
                navigate(
                  `${paths.programming}/${country.id}/${city.id}/${bakery.id}/${ovenModel.id}`,
                ),
            });

            breadcrumbs.push({title: t('cleaning')});
          }
        }
      }
    }
    setBreadcrumbs(breadcrumbs);
    return () => setBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, city, bakery, ovenModel]);

  function handleSelectCleaning(cleaningId: number) {
    const selectedCleaning = cleanings.find((cleaning) => cleaning.id === cleaningId);

    if (selectedCleaning != null) {
      setScheduleData((scheduleData) => ({...scheduleData, cleaningId}));

      const endTime = getEndTime(scheduleData.startTime, selectedCleaning.duration);
      setEndTime(endTime);

      if (!uiState.visibleSteps.includes('ovens')) {
        setUiState({
          visibleSteps: ['cleaning'],
          isNextButtonVisible: true,
          currentStep: 'cleaning',
          nextStep: 'ovens',
        });
      }
    }
  }

  function handleSelectOvenPanel(ovenPanel: OvenPanelData) {
    if (selectedOvenPanelIdsSet.has(ovenPanel.id)) {
      selectedOvenPanelIdsSet.delete(ovenPanel.id);

      setScheduleData((scheduleData) => ({
        ...scheduleData,
        ovenPanels: scheduleData.ovenPanels.filter(
          (selectedOvenPanel) => selectedOvenPanel.id !== ovenPanel.id,
        ),
      }));
    } else {
      selectedOvenPanelIdsSet.add(ovenPanel.id);

      setScheduleData((scheduleData) => ({
        ...scheduleData,
        ovenPanels: [...scheduleData.ovenPanels, ovenPanel],
      }));
    }

    if (!uiState.visibleSteps.includes('time')) {
      setUiState({
        visibleSteps: ['cleaning', 'ovens'],
        isNextButtonVisible: selectedOvenPanelIdsSet.size > 0,
        currentStep: 'ovens',
        nextStep: 'time',
      });
    }
  }

  function validateStartTime(startTime: string) {
    const timeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;

    if (selectedCleaning != null && timeRegex.test(startTime)) {
      const endTime = getEndTime(startTime, selectedCleaning.duration);

      if (endTime > startTime) {
        setEndTime(endTime);
        setIsValidStartTime(true);
      }
    }
  }

  function handleSetStartTime(time: string) {
    setScheduleData((scheduleData) => ({...scheduleData, startTime: time}));
    setShadowStartTime('');
    setIsValidStartTime(false);
    validateStartTime(time);
  }

  function handleStartTimeFocus() {
    setShadowStartTime(scheduleData.startTime);
    setScheduleData((scheduleData) => ({...scheduleData, startTime: ''}));
    setIsValidStartTime(false);
  }

  function handleStartTimeBlur() {
    if (stringUtils.isNullOrWhiteSpace(scheduleData.startTime)) {
      if (!stringUtils.isNullOrWhiteSpace(shadowStartTime)) {
        setScheduleData((scheduleData) => ({...scheduleData, startTime: shadowStartTime}));
        validateStartTime(shadowStartTime);
        return;
      }

      setScheduleData((scheduleData) => ({...scheduleData, startTime: suggestedStartTime}));
      validateStartTime(suggestedStartTime);
    }
  }

  function handleSetStartDate(date: Date) {
    if (date > new Date()) {
      setScheduleData((scheduleData) => ({...scheduleData, startDate: date}));
    }
  }

  function handleSelectWeekDay(weekDay: Day) {
    if (selectedWeekDaysSet.has(weekDay)) {
      selectedWeekDaysSet.delete(weekDay);
    } else {
      selectedWeekDaysSet.add(weekDay);
    }

    setScheduleData((scheduleData) => ({
      ...scheduleData,
      weekDays: Array.from(selectedWeekDaysSet),
    }));
  }

  function handleCreateOrUpdateSchedules() {
    if (user == null || selectedCleaning == null) {
      return;
    }

    const schedules: CreateOrUpdateSchedulesRequest = [];
    // oven groups can only have a single oven cleaning at a time,
    // if selected multiple ovens from the same group, we need to increase the time slot
    // the time increase is working only for oven groups with 2 ovens, which is the only scenario we have at this moment
    const ovenPanelIds: string[] = [];
    const ovenPanelIdsForTimeIncrease: string[] = [];
    const ovenGroupIdsSet = new Set<string>();

    scheduleData.ovenPanels.forEach((ovenPanel) => {
      let needsTimeIncrease = false;

      if (ovenPanel.ovenGroupId != null) {
        if (ovenGroupIdsSet.has(ovenPanel.ovenGroupId)) {
          needsTimeIncrease = true;
        }
        ovenGroupIdsSet.add(ovenPanel.ovenGroupId);
      }

      if (needsTimeIncrease) {
        ovenPanelIdsForTimeIncrease.push(ovenPanel.id);
      } else {
        ovenPanelIds.push(ovenPanel.id);
      }
    });

    const id = scheduleId ?? cryptoUtils.uuid();

    schedules.push({
      id,
      cleaningId: selectedCleaning.id,
      companyId: user.companyId,
      recurrences: getRecurrences(
        scheduleData.startDate,
        scheduleData.startTime,
        scheduleData.weekDays,
        id,
        user.companyId,
      ),
      ovenPanels: ovenPanelIds.map((ovenPanelId) => ({
        id: ovenPanelId,
      })),
    });

    if (!arrayUtils.isNullOrEmpty(ovenPanelIdsForTimeIncrease)) {
      let _startDate = dateUtils.setTime(scheduleData.startDate, scheduleData.startTime);
      _startDate = addSeconds(_startDate, selectedCleaning.duration + 60);

      const endDate = addSeconds(_startDate, selectedCleaning.duration);

      if (!isSameDay(endDate, scheduleData.startDate)) {
        setIsValidStartTime(false);
        return;
      }

      const _startTime = format(_startDate, 'HH:mm');

      const linkedScheduleId = cryptoUtils.uuid();

      schedules.push({
        id: linkedScheduleId,
        cleaningId: selectedCleaning.id,
        linkedScheduleId: id,
        companyId: user.companyId,
        recurrences: getRecurrences(
          _startDate,
          _startTime,
          scheduleData.weekDays,
          linkedScheduleId,
          user.companyId,
        ),
        ovenPanels: ovenPanelIdsForTimeIncrease.map((ovenPanelId) => ({
          id: ovenPanelId,
        })),
      });
    }

    createOrUpdateSchedules(
      {request: schedules},
      {
        onSuccess: () => {
          queryClient.invalidateQueries('schedules');

          if (scheduleId != null) {
            splash({
              title: t('your_programs_have_been_updated'),
              nextAction: () =>
                navigate(
                  `${paths.programming}/${country?.id}/${city?.id}/${bakery?.id}/${
                    ovenModel?.id
                  }?date=${format(scheduleData.startDate, 'MM-dd')}`,
                ),
            });
          } else {
            navigate(
              `${paths.programming}/${country?.id}/${city?.id}/${bakery?.id}/${
                ovenModel?.id
              }?date=${format(scheduleData.startDate, 'MM-dd')}`,
            );
          }
        },
      },
    );
  }

  const canFinish =
    !uiState.isNextButtonVisible &&
    selectedCleaning != null &&
    !arrayUtils.isNullOrEmpty(selectedOvenPanelIds) &&
    isValidStartTime;
  const hasChanges =
    scheduleData.cleaningId !== previousScheduleData.cleaningId ||
    !arrayUtils.isEqual(
      scheduleData.ovenPanels,
      previousScheduleData.ovenPanels,
      (a, b) => a.id === b.id,
    ) ||
    scheduleData.startDate !== previousScheduleData.startDate ||
    scheduleData.startTime !== previousScheduleData.startTime ||
    !arrayUtils.isEqual(scheduleData.weekDays, previousScheduleData.weekDays, (a, b) => a === b);

  function handleCancel() {
    if (scheduleId != null && hasChanges) {
      splash({
        title: t('discard_changes_message'),
        caption: t('discard_changes_caption'),
        acceptAction: () =>
          navigate(
            `${paths.programming}/${country?.id}/${city?.id}/${bakery?.id}/${
              ovenModel?.id
            }?date=${format(scheduleData.startDate, 'MM-dd')}`,
          ),
      });
    } else {
      navigate(
        `${paths.programming}/${country?.id}/${city?.id}/${bakery?.id}/${
          ovenModel?.id
        }?date=${format(scheduleData.startDate, 'MM-dd')}`,
      );
    }
  }

  function filterOvens() {
    return (
      ovenModel?.ovens?.filter(
        (oven) =>
          oven.ovenGroupId == null &&
          (stringUtils.unicodeStartsWith(oven.description ?? '', searchText) ||
            stringUtils.unicodeStartsWith(oven.serialNumber, searchText) ||
            stringUtils.unicodeIncludes(oven.description ?? '', ` ${searchText}`) ||
            stringUtils.unicodeIncludes(oven.serialNumber, ` ${searchText}`)),
      ) ?? []
    );
  }

  const isLoading =
    isLoadingCountry ||
    isLoadingCity ||
    isLoadingBakery ||
    isLoadingOvenModel ||
    isLoadingSchedule ||
    isLoadingCreateOrUpdateSchedules;

  return (
    <Fragment>
      <Box
        sx={{
          width: {md: '173px'},
          marginTop: {xs: '32px', md: '96px'},
          marginLeft: {xs: '60px', sm: '120px'},
        }}>
        <CreateCleaningScheduleMenu
          uiState={uiState}
          setUiState={setUiState}
          canFinish={scheduleId != null ? canFinish && hasChanges : canFinish}
          onFinish={handleCreateOrUpdateSchedules}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: {xs: 'calc(100vw - 120px)', sm: 'calc(100vw - 240px)', md: 'calc(100vw - 445px)'},
          marginTop: {xs: '16px', md: '24px'},
          marginLeft: {xs: '60px', sm: '120px', md: '32px'},
        }}>
        <ActionBanner
          color="custom.surfaceBlue"
          text={t('programming_cleaning_programming_title')}
          secondary={
            <IconButton
              IconComponent={DeclineIcon}
              inactiveColor="custom.iconsSecondary"
              activeColor="custom.iconsSecondary"
              onClick={handleCancel}
            />
          }
        />
        {uiState.currentStep === 'cleaning' && (
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px', marginTop: '32px'}}>
            <Typography
              sx={{
                paddingInline: '32px',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '28px',
              }}>
              {t('programming_cleaning_step_title')}
            </Typography>
            <CleaningList
              cleanings={cleanings}
              selectedCleaningId={scheduleData.cleaningId}
              onSelectCleaning={handleSelectCleaning}
              cleaningsNotFoundMessage={
                isLoadingCleanings ? '' : t('programming_cleanings_not_found_label')
              }
            />
          </Box>
        )}
        {uiState.currentStep === 'ovens' && (
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px', marginTop: '16px'}}>
            <SimpleSearch value={searchText} onChange={setSearchText} />
            <Typography
              sx={{
                paddingInline: '32px',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '28px',
              }}>
              {t('programming_ovens_step_title')}
            </Typography>
            <OvenList
              isCleaning
              ovenGroups={ovenModel?.ovenGroups ?? []}
              ovens={filterOvens()}
              selectedOvenPanelIds={selectedOvenPanelIds}
              onSelectOvenPanel={handleSelectOvenPanel}
              ovensNotFoundMessage={
                isLoadingOvenModel ? '' : t('programming_ovens_not_found_label')
              }
            />
          </Box>
        )}
        {uiState.currentStep === 'time' && (
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px', marginTop: '32px'}}>
            <Typography
              sx={{
                paddingInline: '32px',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '28px',
              }}>
              {t('programming_time_step_title')}
            </Typography>
            {hasOverlap && (
              <Typography
                sx={{
                  paddingInline: '32px',
                  fontSize: '20px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '28px',
                  color: 'custom.textBrand',
                }}>
                {t('programming_overlapping_times_error')}
              </Typography>
            )}
            {!isValidStartTime && scheduleData.startTime.length === 5 && !hasOverlap && (
              <Typography
                sx={{
                  paddingInline: '32px',
                  fontSize: '20px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '28px',
                  color: 'custom.textBrand',
                }}>
                {t('invalid_input_change_start_time')}
              </Typography>
            )}
            <SelectTime
              startDate={scheduleData.startDate}
              setStartDate={handleSetStartDate}
              startTime={scheduleData.startTime}
              endTime={endTime}
              setStartTime={handleSetStartTime}
              selectedWeekDays={scheduleData.weekDays}
              onSelectWeekDay={handleSelectWeekDay}
              error={!isValidStartTime}
              onStartTimeFocus={handleStartTimeFocus}
              onStartTimeBlur={handleStartTimeBlur}
            />
          </Box>
        )}
        <Box sx={{minHeight: '64px'}} />
      </Box>
      <LoadingBackdrop isLoading={isLoading} />
    </Fragment>
  );
}

export default CleaningSchedule;
