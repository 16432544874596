import {Box, Typography} from '@mui/material';
import {
  addMonths,
  addYears,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  isSameDay,
  isSameMonth,
  parse,
  startOfWeek,
} from 'date-fns';
import {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as ArrowLeftIcon} from '../../assets/icons/simple-arrow-left.svg';
import {ReactComponent as ArrowRightIcon} from '../../assets/icons/simple-arrow-right.svg';
import arrayUtils from '../../utils/arrays';
import dateUtils from '../../utils/dates';
import IconButton from './IconButton';

type CalendarProps = {
  selectedDate?: Date;
  onChangeSelectedDate?: (date: Date) => void;
};

function Calendar(props: CalendarProps) {
  const {t} = useTranslation();

  const [selectedYear, setSelectedYear] = useState(
    (props.selectedDate ?? new Date()).getFullYear(),
  );
  const [selectedMonth, setSelectedMonth] = useState(
    (props.selectedDate ?? new Date()).getMonth() + 1,
  );
  const [selectedDate, setSelectedDate] = useState(props.selectedDate ?? new Date());
  const firstDate = parse(`${selectedMonth} ${selectedYear}`, 'MM yyyy', new Date());

  useEffect(() => {
    if (props.selectedDate != null) {
      setSelectedYear(props.selectedDate.getFullYear());
      setSelectedMonth(props.selectedDate.getMonth() + 1);
      setSelectedDate(props.selectedDate);
    }
  }, [props.selectedDate]);

  const weeks = useMemo(() => {
    const days = eachDayOfInterval({
      start: startOfWeek(firstDate),
      end: endOfWeek(endOfMonth(firstDate)),
    });

    return arrayUtils.split(days, 7);
  }, [firstDate]);

  function handleChangeYear(offset: number) {
    const nextYear = addYears(firstDate, offset);
    setSelectedYear(nextYear.getFullYear());
    setSelectedMonth(nextYear.getMonth() + 1);
  }

  function handleChangeMonth(offset: number) {
    const nextMonth = addMonths(firstDate, offset);
    setSelectedYear(nextMonth.getFullYear());
    setSelectedMonth(nextMonth.getMonth() + 1);
  }

  function handleChangeDate(date: Date) {
    if (!isSameMonth(date, firstDate)) {
      return;
    }
    if (props.selectedDate == null) {
      setSelectedDate(date);
    }
    props.onChangeSelectedDate?.(date);
  }

  return (
    <Box sx={{backgroundColor: 'custom.surfaceWhite', borderRadius: '16px', paddingBottom: '8px'}}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '56px',
          padding: '8px 16px',
        }}>
        <Box sx={{flex: 1, display: 'flex', alignItems: 'center', gap: '4px'}}>
          <IconButton
            IconComponent={ArrowLeftIcon}
            iconSize="16px"
            onClick={() => handleChangeYear(-1)}
          />
          <Typography
            sx={{
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: 'normal',
            }}>{`${dateUtils.getMonthName(firstDate.getMonth(), t)} ${selectedYear}`}</Typography>
          <IconButton
            IconComponent={ArrowRightIcon}
            iconSize="16px"
            onClick={() => handleChangeYear(1)}
          />
        </Box>
        <Box sx={{display: 'flex', alignItems: 'center', gap: '16px'}}>
          <IconButton
            IconComponent={ArrowLeftIcon}
            iconSize="24px"
            onClick={() => handleChangeMonth(-1)}
          />
          <IconButton
            IconComponent={ArrowRightIcon}
            iconSize="24px"
            onClick={() => handleChangeMonth(1)}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '8px',
          padding: '0px 16px',
        }}>
        {[t('sun'), t('mon'), t('tue'), t('wed'), t('thu'), t('fri'), t('sat')].map((day) => (
          <Box
            key={day}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '44px',
              height: '44px',
            }}>
            <Typography
              sx={{
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '20px',
                color: 'custom.textBrand',
                textTransform: 'uppercase',
              }}>
              {day}
            </Typography>
          </Box>
        ))}
      </Box>
      {weeks.map((week, index) => (
        <Box
          key={index}
          sx={{display: 'flex', justifyContent: 'space-between', padding: '0px 16px'}}>
          {week.map((day, index) => (
            <Box
              key={index}
              sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '44px',
                height: '44px',
              }}
              onClick={() => handleChangeDate(day)}>
              <Typography
                sx={{
                  fontSize: '20px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '24px',
                  textTransform: 'uppercase',
                  cursor: 'pointer',
                  color: !isSameMonth(day, firstDate)
                    ? 'custom.textBlocked'
                    : isSameDay(day, selectedDate)
                    ? 'custom.textSecondary'
                    : isSameDay(day, new Date())
                    ? 'custom.textBrand'
                    : 'custom.textPrimary',
                  '&:hover': {
                    color: 'custom.textBrand',
                  },
                  ...(isSameDay(day, selectedDate) && {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '44px',
                    height: '44px',
                    backgroundColor: isSameMonth(day, firstDate)
                      ? 'custom.surfaceBrand'
                      : 'inherit',
                    borderRadius: '50%',
                    '&:hover': {
                      color: 'custom.textSecondary',
                    },
                  }),
                }}>
                {format(day, 'dd')}
              </Typography>
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
}

export default Calendar;
