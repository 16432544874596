import {useQuery, useQueryClient} from 'react-query';
import services from '../../services/provider';
import useAuthStore, {clearStores} from '../../state/auth';
import localStorageUtils from '../../utils/local-storage';

function useUser() {
  const queryClient = useQueryClient();

  const accessToken = useAuthStore((state) => state.accessToken);

  const {data: user, isLoading} = useQuery({
    enabled: accessToken != null,
    queryKey: ['user'],
    queryFn: () => services.user.getUser({params: {userId: 'me'}}),
    onError: () => {
      localStorageUtils.removeValue('token');
      services.base.removeAuthorizationHeader();
      queryClient.clear();
      clearStores();
    },
  });

  return {user, isLoading};
}

export default useUser;
