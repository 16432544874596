import {Box, Typography} from '@mui/material';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as CheckedIcon} from '../../../../../assets/icons/accept-simple.svg';
import {ReactComponent as UncheckedIcon} from '../../../../../assets/icons/decline-simple.svg';
import {OvenModelId} from '../../../../../models/entities/oven-model';
import stringUtils from '../../../../../utils/strings';
import Icon from '../../../../common/Icon';
import {SmallInput} from '../../../../common/Input';
import {SerialNumberStepProps} from './SerialNumberStep';

function ModulramSerialNumberStep(props: SerialNumberStepProps) {
  const {ovenChambers, setUiState, addOvenData, setAddOvenData} = props;
  const {t} = useTranslation();

  useEffect(() => {
    const isCompleted =
      addOvenData.ovenChamberSerialNumbers.every((serialNumber) =>
        ovenChambers.some(
          (ovenChamber) =>
            ovenChamber.ovenModelId === OvenModelId.Modulram &&
            ovenChamber.serialNumber === serialNumber,
        ),
      ) &&
      addOvenData.ovenChamberSerialNumbers.length ===
        new Set(addOvenData.ovenChamberSerialNumbers).size;

    setUiState((uiState) => ({
      ...uiState,
      isNextButtonVisible: !uiState.visibleSteps.includes('description') && isCompleted,
      currentStep: 'serial-number',
      nextStep: 'description',
    }));
  }, [setUiState, ovenChambers, addOvenData.ovenChamberSerialNumbers]);

  function handleChangeSerialNumber(valueIndex: number, value: string) {
    setAddOvenData((addOvenData) => ({
      ...addOvenData,
      ovenChamberSerialNumbers: addOvenData.ovenChamberSerialNumbers.map((serialNumber, index) =>
        index === valueIndex ? value : serialNumber,
      ),
    }));
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px', paddingInline: '32px'}}>
      <Typography sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 500, lineHeight: '28px'}}>
        {t('enter_the_serial_numbers')}
      </Typography>
      {addOvenData.ovenChamberSerialNumbers
        .map((serialNumber, index, arr) => {
          const ovenChamber = ovenChambers.find(
            (ovenChamber) =>
              ovenChamber.ovenModelId === OvenModelId.Modulram &&
              ovenChamber.serialNumber === serialNumber,
          );

          return (
            <Box key={index} sx={{width: '100%', height: '62px', textAlign: 'end'}}>
              <SmallInput
                fullWidth
                label={`${t('chamber')} ${index + 1}${
                  index === arr.length - 1
                    ? ` (${t('top')})`
                    : index === 0
                    ? ` (${t('bottom')})`
                    : ''
                }`}
                InputProps={{
                  endAdornment: !stringUtils.isNullOrWhiteSpace(serialNumber) && (
                    <Icon IconComponent={ovenChamber != null ? CheckedIcon : UncheckedIcon} />
                  ),
                }}
                value={serialNumber}
                onChange={(event) => handleChangeSerialNumber(index, event.target.value)}
              />
              {!stringUtils.isNullOrWhiteSpace(serialNumber) && ovenChamber == null && (
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '24px',
                    color: 'custom.textBrand',
                  }}>
                  {t('invalid_serial_number')}
                </Typography>
              )}
            </Box>
          );
        })
        .reverse()}
    </Box>
  );
}

export default ModulramSerialNumberStep;
