import {Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import useErrorStore from '../../state/errors';
import arrayUtils from '../../utils/arrays';
import errorUtils from '../../utils/errors';
import {SmallButton} from './Button';

function ErrorModal() {
  const {t} = useTranslation();

  const errors = useErrorStore((state) => state.errors);
  const hasErrors = !arrayUtils.isNullOrEmpty(errors);

  return (
    <Dialog
      PaperProps={{
        sx: {borderRadius: '32px'},
      }}
      open={hasErrors}>
      <DialogTitle sx={{display: 'flex', justifyContent: 'center'}}>
        <Typography
          sx={{
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '28px',
            color: 'custom.textBrand',
          }}>
          {t('error_label')}
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: '20vw',
          minHeight: '10vh',
        }}>
        {errors.map((error, index) => (
          <Typography
            key={index}
            sx={{fontSize: '16px', fontStyle: 'normal', fontWeight: 400, lineHeight: '24px'}}>
            {error}
          </Typography>
        ))}
      </DialogContent>
      <DialogActions sx={{display: 'flex', justifyContent: 'center'}}>
        <SmallButton onClick={() => errorUtils.clearErrors()}>Ok</SmallButton>
      </DialogActions>
    </Dialog>
  );
}

export default ErrorModal;
