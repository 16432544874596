import {Box} from '@mui/material';
import {Outlet} from 'react-router-dom';

function StatisticsLayout() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {xs: 'column', md: 'row'},
        width: '100vw',
        height: {xs: 'calc(100vh - 124px)', md: 'calc(100vh - 148px)'},
        overflow: 'auto',
      }}>
      <Outlet />
    </Box>
  );
}

export default StatisticsLayout;
