import {List, ListItem, Typography, useMediaQuery, useTheme} from '@mui/material';
import {ElementType, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as AcceptIcon} from '../../assets/icons/accept.svg';
import {ReactComponent as ArrowRightIcon} from '../../assets/icons/arrow-right.svg';
import {ReactComponent as NumberOneIcon} from '../../assets/icons/number-one.svg';
import {ReactComponent as NumberThreeIcon} from '../../assets/icons/number-three.svg';
import {ReactComponent as NumberTwoIcon} from '../../assets/icons/number-two.svg';
import Icon from '../common/Icon';
import {CleaningScheduleUiState} from './CleaningSchedule';

type MenuItemProps = {
  color?: string;
  IconComponent: ElementType<any>;
  title: string;
  isSelected?: boolean;
  onClick: () => void;
  disabled?: boolean;
};

function MenuItem(props: MenuItemProps) {
  const {
    color = 'custom.surfaceSecondary',
    IconComponent,
    title,
    isSelected,
    onClick,
    disabled,
  } = props;

  const [isHovered, setIsHovered] = useState(false);

  const isSelectedOrHovered = isSelected || isHovered;

  return (
    <ListItem
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '8px',
        width: 'fit-content',
        height: '40px',
        padding: '12px 16px',
        backgroundColor: color,
        borderRadius: '360px',
        opacity: disabled ? 0.5 : isSelected ? 1 : 0.5,
        cursor: disabled ? undefined : 'pointer',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}>
      {isSelectedOrHovered && (
        <Typography
          sx={{
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            color: 'custom.textSecondary',
          }}>
          {title}
        </Typography>
      )}
      <Icon IconComponent={IconComponent} color="custom.iconsSecondary" />
    </ListItem>
  );
}

type CreateCleaningScheduleMenuProps = {
  uiState: CleaningScheduleUiState;
  setUiState: (setter: (uiState: CleaningScheduleUiState) => CleaningScheduleUiState) => void;
  canFinish: boolean;
  onFinish: () => void;
};

function CreateCleaningScheduleMenu(props: CreateCleaningScheduleMenuProps) {
  const {uiState, setUiState, canFinish, onFinish} = props;
  const {t} = useTranslation();

  const theme = useTheme();
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <List
      sx={{
        display: 'flex',
        flexDirection: isLargerThanMd ? 'column' : 'row',
        alignItems: 'flex-end',
        gap: '16px',
        margin: 0,
        padding: 0,
      }}>
      {uiState.visibleSteps.includes('cleaning') && (
        <MenuItem
          IconComponent={NumberOneIcon}
          title={t('programming_cleaning_step_label')}
          isSelected={uiState.currentStep === 'cleaning'}
          onClick={() => setUiState((uiState) => ({...uiState, currentStep: 'cleaning'}))}
        />
      )}
      {uiState.visibleSteps.includes('ovens') && (
        <MenuItem
          IconComponent={NumberTwoIcon}
          title={t('programming_ovens_step_label')}
          isSelected={uiState.currentStep === 'ovens'}
          onClick={() => setUiState((uiState) => ({...uiState, currentStep: 'ovens'}))}
        />
      )}
      {uiState.visibleSteps.includes('time') && (
        <MenuItem
          IconComponent={NumberThreeIcon}
          title={t('programming_time_step_label')}
          isSelected={uiState.currentStep === 'time'}
          onClick={() => setUiState((uiState) => ({...uiState, currentStep: 'time'}))}
        />
      )}
      {uiState.isNextButtonVisible && (
        <MenuItem
          color="custom.surfaceBrand"
          IconComponent={ArrowRightIcon}
          title={t('programming_next_button_label')}
          isSelected
          onClick={() =>
            setUiState((uiState) => ({
              visibleSteps: [...uiState.visibleSteps, uiState.nextStep],
              isNextButtonVisible: false,
              currentStep: uiState.nextStep,
              nextStep: uiState.nextStep,
            }))
          }
        />
      )}
      {canFinish && (
        <MenuItem
          color="custom.surfaceBrand"
          IconComponent={AcceptIcon}
          title={t('programming_finish_button_label')}
          isSelected
          onClick={onFinish}
        />
      )}
    </List>
  );
}

export default CreateCleaningScheduleMenu;
