import {Box, Dialog, IconButton, Typography, useMediaQuery, useTheme} from '@mui/material';
import {useEffect} from 'react';
import {ReactComponent as AcceptIcon} from '../../assets/icons/accept.svg';
import {ReactComponent as DeclineIcon} from '../../assets/icons/decline.svg';
import useSplashScreen from '../../hooks/common/use-splash-screen';
import Icon from './Icon';

function SplashScreen() {
  const theme = useTheme();
  const {splashScreen, clear} = useSplashScreen();

  const {title, caption, subCaption, nextAction, acceptAction, declineAction} = splashScreen ?? {};

  const isQuestion = acceptAction != null;

  useEffect(() => {
    if (splashScreen != null && !isQuestion) {
      setTimeout(() => {
        nextAction?.();
        clear();
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [splashScreen]);

  function handleAccept() {
    acceptAction?.();
    clear();
  }

  function handleDecline() {
    declineAction?.();
    clear();
  }

  return (
    <Dialog
      PaperProps={{
        sx: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          backgroundColor: 'custom.surfaceBrand',
        },
      }}
      fullScreen
      open={splashScreen != null}
      keepMounted={false}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
          width: {xs: '504px', md: '994px'},
          maxWidth: '80vw',
        }}>
        <Typography
          sx={{
            fontSize: '32px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '40px',
            color: 'custom.textSecondary',
          }}>
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '28px',
            color: 'custom.textSecondary',
          }}>
          {caption}
        </Typography>
        <Typography
          sx={{
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '28px',
            color: 'custom.textSecondary',
          }}>
          {subCaption}
        </Typography>
      </Box>
      {isQuestion && (
        <Box sx={{position: 'absolute', bottom: '10vh', display: 'flex', gap: '56px'}}>
          <IconButton onClick={handleDecline}>
            <Icon IconComponent={DeclineIcon} size="56px" color="custom.iconsSecondary" />
          </IconButton>
          <IconButton onClick={handleAccept}>
            <Icon IconComponent={AcceptIcon} size="56px" color="custom.iconsSecondary" />
          </IconButton>
        </Box>
      )}
    </Dialog>
  );
}

export default SplashScreen;
