import {Box, BoxProps} from '@mui/material';
import {ReactNode, useCallback, useEffect, useRef, useState} from 'react';
import SimpleBar from 'simplebar-react';

type GradientOverflowProps = {
  hideScrollbar?: boolean;
  containerProps?: BoxProps;
  children?: ReactNode;
};

function GradientOverflow(props: GradientOverflowProps) {
  const {hideScrollbar, containerProps, children} = props;

  const [hasScrollTop, setHasScrollTop] = useState(false);
  const [hasScrollBottom, setHasScrollBottom] = useState(false);

  const scrollRef = useRef<any>(null);

  const recalculate = useCallback((scrollElement: HTMLDivElement) => {
    const {scrollTop, scrollHeight, clientHeight} = scrollElement as HTMLDivElement;
    setHasScrollTop(scrollTop > 0);
    setHasScrollBottom(scrollHeight - clientHeight - Math.ceil(scrollTop) > 0);
  }, []);

  useEffect(() => {
    let scrollElement = hideScrollbar ? scrollRef.current : scrollRef.current.getScrollElement();
    if (scrollElement == null) return;
    const recalculateCallback = () => recalculate(scrollElement);
    scrollElement.addEventListener('scroll', recalculateCallback);
    window.addEventListener('resize', recalculateCallback);
    return () => {
      scrollElement.removeEventListener('scroll', recalculateCallback);
      window.removeEventListener('resize', recalculateCallback);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let scrollElement = hideScrollbar ? scrollRef.current : scrollRef.current.getScrollElement();
    if (scrollElement == null) return;
    recalculate(scrollElement);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  return (
    <Box
      {...containerProps}
      sx={{
        ...containerProps?.sx,
        position: 'relative',
        width: '100%',
        height: '100%',
      }}>
      <Box
        sx={{
          display: hasScrollTop ? undefined : 'none',
          zIndex: 1000,
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
          background: `linear-gradient(to bottom, #F5F5F5, transparent 20%)`,
        }}
      />
      {hideScrollbar ? (
        <Box
          ref={scrollRef}
          sx={{
            width: '100%',
            height: '100%',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
          }}>
          {children}
        </Box>
      ) : (
        <SimpleBar ref={scrollRef} style={{width: '100%', height: '100%'}}>
          {children}
        </SimpleBar>
      )}
      <Box
        sx={{
          display: hasScrollBottom ? undefined : 'none',
          zIndex: 1000,
          position: 'absolute',
          bottom: 0,
          right: 0,
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
          background: `linear-gradient(to top, #F5F5F5, transparent 20%)`,
        }}
      />
    </Box>
  );
}

export default GradientOverflow;
