import {Box, Typography} from '@mui/material';
import Switch from '../../../../common/Switch';
import {useTranslation} from 'react-i18next';

type SteamExitValveOpenedRowProps = {
  steamExitValveOpened: boolean;
  onChange: (steamExitValveOpened: boolean) => void;
};

function SteamExitValveOpenedRow(props: SteamExitValveOpenedRowProps) {
  const {steamExitValveOpened, onChange} = props;
  const {t} = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '16px',
        height: '40px',
      }}>
      <Typography sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 500, lineHeight: '28px'}}>
        {t('recipe_settings_phases_steam_exit_valve_label')}
      </Typography>
      <Switch checked={steamExitValveOpened} onChange={(_, checked) => onChange(checked)} />
    </Box>
  );
}

export default SteamExitValveOpenedRow;
