import {Box, InputBase, List, ListItem, Typography} from '@mui/material';
import {Fragment, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery} from 'react-query';
import {ReactComponent as AcceptIcon} from '../../../../assets/icons/accept.svg';
import {ReactComponent as ArrowLeftIcon} from '../../../../assets/icons/arrow-left.svg';
import {ReactComponent as SearchIcon} from '../../../../assets/icons/search.svg';
import useUser from '../../../../hooks/common/use-user';
import City from '../../../../models/entities/city';
import Recipe from '../../../../models/entities/recipe';
import services from '../../../../services/provider';
import stringUtils from '../../../../utils/strings';
import ActionBanner from '../../../common/ActionBanner';
import GradientOverflow from '../../../common/GradientOverflow';
import Icon from '../../../common/Icon';
import LoadingBackdrop from '../../../common/LoadingBackdrop';
import Switch from '../../../common/Switch';
import SwitchInverted from '../../../common/SwitchInverted';

type LocationStepProps = {
  recipeBuild: Recipe;
  setRecipeBuild: (setter: (previousValue: Recipe) => Recipe) => void;
  onReturnToRecipeInfo: () => void;
  canFinish: boolean;
  onFinish: () => void;
  isFromLocation?: boolean;
};

function LocationStep(props: LocationStepProps) {
  const {recipeBuild, setRecipeBuild, onReturnToRecipeInfo, canFinish, onFinish, isFromLocation = false} = props;
  const {t} = useTranslation();

  const {user} = useUser();

  const [searchText, setSearchText] = useState('');
  const [selectedCity, setSelectedCity] = useState<City | null>(null);

  const {data: cities = [], isLoading} = useQuery({
    enabled: user != null,
    queryKey: ['companyCountries', user?.companyId, {ovenModelId: recipeBuild.ovenModelId}],
    queryFn: () =>
      services.company.getCompanyCountries({
        params: {
          companyId: user?.companyId ?? '',
        },
        query: {
          ovenModelId: recipeBuild.ovenModelId,
          expand: ['districts', 'cities', 'bakeries'],
        },
      }),
    select: (countries) => {
      const cities = new Map<number, City>();

      countries.forEach((country) =>
        country.districts?.forEach((district) =>
          district.cities?.forEach((city) => {
            if (city.bakeries?.some((bakery) => bakery.id !== user?.companyId)) {
              cities.set(city.id, {
                ...city,
                bakeries: city.bakeries?.filter((bakery) => bakery.id !== user?.companyId),
              });
            }
          }),
        ),
      );

      return Array.from(cities.values());
    },
  });

  const bakeries = useMemo(() => {
    return cities.flatMap((city) => city.bakeries ?? []);
  }, [cities]);

  const selectedBakeryIds = new Set(recipeBuild.bakeries?.map((bakery) => bakery.id));

  function handleSelectAll(checked: boolean) {
    if (!checked) {
      setRecipeBuild((recipeBuild) => ({...recipeBuild, bakeries: []}));
      return;
    }

    if (selectedCity != null) {
      selectedCity.bakeries?.forEach((bakery) => {
        if (checked) {
          selectedBakeryIds.add(bakery.id);
        } else {
          selectedBakeryIds.delete(bakery.id);
        }
      });
    }

    setRecipeBuild((recipeBuild) => ({
      ...recipeBuild,
      bakeries: bakeries.filter((bakery) => selectedBakeryIds.has(bakery.id)),
    }));
  }

  function handleSelectCity(cityId: number, checked: boolean) {
    const city = cities.find((city) => city.id === cityId);

    if (city == null) {
      return;
    }

    city.bakeries?.forEach((bakery) => {
      if (checked) {
        selectedBakeryIds.add(bakery.id);
      } else {
        selectedBakeryIds.delete(bakery.id);
      }
    });

    setRecipeBuild((recipeBuild) => ({
      ...recipeBuild,
      bakeries: bakeries.filter((bakery) => selectedBakeryIds.has(bakery.id)),
    }));
  }

  function handleSelectBakery(bakeryId: string, checked: boolean) {
    if (checked) {
      selectedBakeryIds.add(bakeryId);
    } else {
      selectedBakeryIds.delete(bakeryId);
    }

    setRecipeBuild((recipeBuild) => ({
      ...recipeBuild,
      bakeries: bakeries.filter((bakery) => selectedBakeryIds.has(bakery.id)),
    }));
  }

  function renderCitiesList() {
    const filteredCities =
      cities.filter(
        (city) =>
          stringUtils.unicodeStartsWith(city.name, searchText) ||
          stringUtils.unicodeIncludes(city.name, ` ${searchText}`),
      ) ?? [];

    return (
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          margin: 0,
          padding: 0,
          paddingInline: '32px',
        }}>
        {filteredCities.length === 0 ? (
          <ListItem sx={{margin: 0, padding: 0}}>
            <Typography
              sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 400, lineHeight: '28px'}}>
              {t('recipe_settings_location_step_cities_not_found_label')}
            </Typography>
          </ListItem>
        ) : (
          filteredCities.map((city) => {
            const totalSelectedBakeries =
              city.bakeries?.filter((bakery) => selectedBakeryIds.has(bakery.id))?.length ?? 0;

            return (
              <ListItem key={city.id} sx={{margin: 0, padding: 0, gap: '16px'}}>
                <Box
                  sx={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '16px',
                    cursor: 'pointer',
                    '&:hover': {
                      color: 'custom.textBrand',
                    },
                  }}
                  onClick={() => setSelectedCity(city)}>
                  <Typography
                    sx={{
                      fontSize: '20px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '28px',
                      color: 'inherit',
                    }}>
                    {city.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '24px',
                      color: 'inherit',
                    }}>
                    {totalSelectedBakeries > 0
                      ? `${totalSelectedBakeries}/${city.bakeries?.length ?? 0} ${t(
                          'recipe_settings_location_step_bakeries_label_secondary',
                        )}`
                      : `${city.bakeries?.length ?? 0} ${t(
                          'recipe_settings_location_step_bakeries_label',
                        )}`}
                  </Typography>
                </Box>
                <Switch
                  checked={Boolean(
                    city.bakeries?.every((bakery) => selectedBakeryIds.has(bakery.id)),
                  )}
                  onChange={(_, checked) => handleSelectCity(city.id, checked)}
                />
              </ListItem>
            );
          })
        )}
      </List>
    );
  }

  function renderBakeriesList() {
    const filteredBakeries =
      selectedCity?.bakeries?.filter(
        (bakery) =>
          bakery.id !== user?.companyId &&
          (stringUtils.unicodeStartsWith(bakery.name, searchText) ||
            stringUtils.unicodeIncludes(bakery.name, ` ${searchText}`)),
      ) ?? [];

    return (
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          margin: 0,
          padding: 0,
          paddingInline: '32px',
        }}>
        {filteredBakeries.length === 0 ? (
          <ListItem sx={{margin: 0, padding: 0}}>
            <Typography
              sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 400, lineHeight: '28px'}}>
              {t('recipe_settings_location_step_bakeries_not_found_label')}
            </Typography>
          </ListItem>
        ) : (
          filteredBakeries.map((bakery) => (
            <ListItem
              key={bakery.id}
              sx={{
                margin: 0,
                padding: 0,
                justifyContent: 'space-between',
                cursor: 'pointer',
                '&:hover': {
                  color: 'custom.textBrand',
                },
              }}
              onClick={() => handleSelectBakery(bakery.id, !selectedBakeryIds.has(bakery.id))}>
              <Typography
                sx={{
                  fontSize: '20px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '28px',
                  color: 'inherit',
                }}>
                {bakery.name}
              </Typography>
              <Switch checked={selectedBakeryIds.has(bakery.id)} />
            </ListItem>
          ))
        )}
      </List>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        marginTop: {xs: '0px', md: '24px'},
      }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          height: '40px',
          padding: '12px 32px',
          backgroundColor: 'custom.surfaceQuaternary',
          borderRadius: '360px',
        }}>
        {selectedCity != null && (
          <Fragment>
            <Typography
              sx={{fontSize: '16px', fontStyle: 'normal', fontWeight: 500, lineHeight: '24px'}}>
              {selectedCity.name}
            </Typography>
            <Typography sx={{marginLeft: '8px'}}>|</Typography>
          </Fragment>
        )}
        <InputBase
          sx={{flex: 1, fontSize: '16px', fontStyle: 'normal', fontWeight: 400, lineHeight: '24px'}}
          placeholder={t('bakeries_search_field_placeholder')}
          value={searchText}
          onChange={(event) => setSearchText(event.target.value)}
        />
        <Icon IconComponent={SearchIcon} />
      </Box>
      <ActionBanner
        text={t('recipe_settings_location_step_select_all_label')}
        secondary={
          <SwitchInverted
            checked={
              selectedCity != null
                ? selectedCity.bakeries?.every((bakery) => selectedBakeryIds.has(bakery.id))
                : bakeries.every((bakery) => selectedBakeryIds.has(bakery.id))
            }
            onChange={(_, checked) => handleSelectAll(checked)}
          />
        }
      />
      {!isFromLocation && (
        <ActionBanner
          sx={{display: {xs: 'flex', md: 'none'}}}
          color="custom.surfaceTertiary"
          text={t('recipe_settings_phase_recipe_info')}
          secondary={<Icon IconComponent={ArrowLeftIcon} color="custom.iconsSecondary" />}
          onClick={onReturnToRecipeInfo}
        />
      )}
      <ActionBanner
        sx={{
          display: {xs: 'flex', md: 'none'},
          opacity: canFinish ? 1 : 0.5,
          cursor: canFinish ? 'pointer' : undefined,
        }}
        color={'custom.surfaceBrand'}
        text={t('recipe_settings_phase_save')}
        secondary={<Icon IconComponent={AcceptIcon} color="custom.iconsSecondary" />}
        onClick={() => (canFinish ? onFinish() : null)}
      />
      <Box
        sx={{
          height: {xs: 'calc(100vh - 460px)', md: 'calc(100vh - 364px)'},
          minHeight: '256px',
          marginTop: '16px',
        }}>
        <GradientOverflow hideScrollbar>
          {selectedCity != null ? renderBakeriesList() : renderCitiesList()}
        </GradientOverflow>
      </Box>
      <LoadingBackdrop isLoading={isLoading} />
    </Box>
  );
}

export default LocationStep;
