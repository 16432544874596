import {alpha, Backdrop, CircularProgress} from '@mui/material';

type LoadingBackdropProps = {
  isLoading: boolean;
};

function LoadingBackdrop(props: LoadingBackdropProps) {
  return (
    <Backdrop
      sx={{
        backgroundColor: (theme) => alpha(theme.palette.custom.surfaceBackground, 0.8),
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={props.isLoading}>
      <CircularProgress sx={{color: 'custom.surfaceBrand'}} />
    </Backdrop>
  );
}

export default LoadingBackdrop;
