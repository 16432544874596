import {addSeconds, format, parse} from 'date-fns';
import Schedule from '../../models/entities/schedule';
import {
  CreateOrUpdateScheduleParams,
  CreateOrUpdateScheduleRequest,
  CreateOrUpdateSchedulesRequest,
  CreateScheduleRequest,
  DeleteScheduleParams,
  GetScheduleParams,
  GetScheduleQuery,
  GetSchedulesQuery,
  UpdateScheduleParams,
  UpdateScheduleRequest,
  ValidateScheduleRequest,
} from '../../models/requests/schedule';
import {
  CreateOrUpdateScheduleResponse,
  CreateOrUpdateSchedulesResponse,
  CreateScheduleResponse,
  DeleteScheduleResponse,
  GetScheduleResponse,
  GetSchedulesResponse,
  UpdateScheduleResponse,
  ValidateScheduleResponse,
} from '../../models/responses/schedule';
import {ApplicationError} from '../../utils/errors';
import {ScheduleService} from '../schedule';
import {data} from './data';

function validateSchedule(schedule: {
  recipeId?: string | null;
  cleaningId?: number | null;
  recurrences: {
    id: string;
    startDate: string;
    startTime: string;
    weekDay: Day;
    repeat: boolean;
  }[];
  ovenPanels: {id: string}[];
}) {
  const duration =
    schedule.recipeId != null
      ? data.recipePhases
          .filter((recipePhase) => recipePhase.recipeId === schedule.recipeId)
          .reduce((duration, recipePhase) => duration + recipePhase.duration, 0)
      : data.cleanings.find((cleaning) => cleaning.id === schedule.cleaningId)?.duration ?? 0;

  const startTime = schedule.recurrences[0].startTime;
  const endTime = format(
    addSeconds(parse(startTime, 'HH:mm:ss', new Date()), duration),
    'HH:mm:ss',
  );
  const weekDays = schedule.recurrences.map((recurrence) => recurrence.weekDay);
  const ovenPanelIds = new Set(schedule.ovenPanels.map((ovenPanel) => ovenPanel.id));

  const overlappingSchedules = data.schedules.filter(
    (schedule) =>
      data.ovenPanelSchedules.some(
        (ovenPanelSchedule) =>
          ovenPanelSchedule.scheduleId === schedule.id &&
          ovenPanelIds.has(ovenPanelSchedule.ovenPanelId),
      ) &&
      data.recurrences.some((recurrence) => {
        const recurrenceDuration =
          schedule.recipeId != null
            ? data.recipePhases
                .filter((recipePhase) => recipePhase.recipeId === schedule.recipeId)
                .reduce((duration, recipePhase) => duration + recipePhase.duration, 0)
            : data.cleanings.find((cleaning) => cleaning.id === schedule.cleaningId)?.duration ?? 0;

        const recurrenceEndTime = format(
          addSeconds(parse(recurrence.startTime, 'HH:mm:ss', new Date()), recurrenceDuration),
          'HH:mm:ss',
        );

        return (
          recurrence.scheduleId === schedule.id &&
          weekDays.includes(recurrence.weekDay) &&
          recurrence.startTime < endTime &&
          recurrenceEndTime > startTime
        );
      }),
  );

  return overlappingSchedules.length === 0;
}

const mockScheduleService: ScheduleService = {
  getSchedules: function (args: {query?: GetSchedulesQuery}): Promise<GetSchedulesResponse> {
    throw new Error('Method not implemented.');
  },
  getSchedule: function (args: {
    params: GetScheduleParams;
    query?: GetScheduleQuery;
  }): Promise<GetScheduleResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {params} = args;

        const schedule = data.schedules.find((schedule) => schedule.id === params.scheduleId);

        if (schedule == null) {
          reject(ApplicationError.notFound('Schedule'));
          return;
        }

        const recipe = data.recipes.find((recipe) => recipe.id === schedule.recipeId);
        const cleaning = data.cleanings.find((cleaning) => cleaning.id === schedule.cleaningId);

        resolve({
          ...schedule,
          recipe: recipe != null ? {...recipe} : null,
          cleaning: cleaning != null ? {...cleaning} : null,
          recurrences: data.recurrences
            .filter((recurrence) => recurrence.scheduleId === schedule.id)
            .map((recurrence) => ({...recurrence})),
          ovenPanels: data.ovenPanels
            .filter((ovenPanel) =>
              data.ovenPanelSchedules.some(
                (ovenPanelSchedule) =>
                  ovenPanelSchedule.ovenPanelId === ovenPanel.id &&
                  ovenPanelSchedule.scheduleId === schedule.id,
              ),
            )
            .map((ovenPanel) => {
              const ovenChamber = data.ovenChambers.find(
                (ovenChamber) => ovenChamber.id === ovenPanel.ovenChamberId,
              );
              const oven = data.ovens.find(
                (oven) => oven.id === ovenChamber?.id || oven.id === ovenPanel.ovenId,
              );
              const ovenGroup = data.ovenGroups.find(
                (ovenGroup) => ovenGroup.id === oven?.ovenGroupId,
              );

              return {
                ...ovenPanel,
                ovenChamber:
                  ovenChamber != null
                    ? {
                        ...ovenChamber,
                        oven:
                          ovenChamber.ovenId != null &&
                          oven != null &&
                          oven.id === ovenChamber.ovenId
                            ? {...oven, ovenGroup: ovenGroup != null ? {...ovenGroup} : null}
                            : null,
                      }
                    : null,
                oven:
                  ovenPanel.ovenId != null && oven != null && oven.id === ovenPanel.ovenId
                    ? {...oven, ovenGroup: ovenGroup != null ? {...ovenGroup} : null}
                    : null,
              };
            }),
        });
      }, 500),
    );
  },
  createSchedule: function (args: {
    request: CreateScheduleRequest;
  }): Promise<CreateScheduleResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {request} = args;

        const isValid = validateSchedule(request);

        if (!isValid) {
          reject(ApplicationError.scheduleOverlapping());
          return;
        }

        const schedule: Schedule = {
          id: request.id,
          recipeId: request.recipeId,
          cleaningId: request.cleaningId,
          companyId: request.companyId,
        };

        data.schedules.push(schedule);

        request.recurrences.forEach((requestRecurrence) =>
          data.recurrences.push({
            id: requestRecurrence.id,
            startDate: requestRecurrence.startDate,
            startTime: requestRecurrence.startTime,
            weekDay: requestRecurrence.weekDay,
            repeat: requestRecurrence.repeat,
            scheduleId: schedule.id,
            companyId: schedule.companyId,
          }),
        );

        request.ovenPanels.forEach((requestOvenPanel) =>
          data.ovenPanelSchedules.push({
            ovenPanelId: requestOvenPanel.id,
            scheduleId: schedule.id,
          }),
        );

        resolve(schedule.id);
      }, 500),
    );
  },
  updateSchedule: function (args: {
    params: UpdateScheduleParams;
    request: UpdateScheduleRequest;
  }): Promise<UpdateScheduleResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {params, request} = args;

        const schedule = data.schedules.find((schedule) => schedule.id === params.scheduleId);

        if (schedule == null) {
          reject(ApplicationError.notFound('Schedule'));
          return;
        }

        const isValid = validateSchedule(request);

        if (!isValid) {
          reject(ApplicationError.scheduleOverlapping());
          return;
        }

        if (request.recurrences != null) {
          data.recurrences = data.recurrences.filter(
            (recurrence) => recurrence.scheduleId !== schedule.id,
          );

          request.recurrences.forEach((requestRecurrence) =>
            data.recurrences.push({
              id: requestRecurrence.id,
              startDate: requestRecurrence.startDate,
              startTime: requestRecurrence.startTime,
              weekDay: requestRecurrence.weekDay,
              repeat: requestRecurrence.repeat,
              scheduleId: schedule.id,
              companyId: schedule.companyId,
            }),
          );
        }

        if (request.ovenPanels != null) {
          data.ovenPanelSchedules = data.ovenPanelSchedules.filter(
            (ovenPanelSchedule) => ovenPanelSchedule.scheduleId !== schedule.id,
          );

          request.ovenPanels.forEach((requestOvenPanel) =>
            data.ovenPanelSchedules.push({
              ovenPanelId: requestOvenPanel.id,
              scheduleId: schedule.id,
            }),
          );
        }

        resolve(schedule.id);
      }, 500),
    );
  },
  deleteSchedule: function (args: {params: DeleteScheduleParams}): Promise<DeleteScheduleResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {params} = args;

        const schedule = data.schedules.find((schedule) => schedule.id === params.scheduleId);

        if (schedule == null) {
          reject(ApplicationError.notFound('Schedule'));
          return;
        }

        data.ovenPanelSchedules = data.ovenPanelSchedules.filter(
          (ovenPanelSchedule) => ovenPanelSchedule.scheduleId !== schedule.id,
        );

        data.recurrences = data.recurrences.filter(
          (recurrence) => recurrence.scheduleId !== schedule.id,
        );

        data.schedules = data.schedules.filter((schedule) => schedule.id !== params.scheduleId);

        resolve(schedule.id);
      }, 500),
    );
  },
  validateSchedule: function (args: {
    request: ValidateScheduleRequest;
  }): Promise<ValidateScheduleResponse> {
    return new Promise((resolve) =>
      setTimeout(() => {
        const {request} = args;

        const isValid = validateSchedule(request);

        resolve(isValid);
      }, 500),
    );
  },
  createOrUpdateSchedule: function (args: {
    params: CreateOrUpdateScheduleParams;
    request: CreateOrUpdateScheduleRequest;
  }): Promise<CreateOrUpdateScheduleResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {params, request} = args;

        let schedule = data.schedules.find((schedule) => schedule.id === params.scheduleId);

        if (schedule == null) {
          schedule = {
            id: params.scheduleId,
            recipeId: request.recipeId,
            cleaningId: request.cleaningId,
            companyId: request.companyId,
          };
        }

        const isValid = validateSchedule(request);

        if (!isValid) {
          reject(ApplicationError.scheduleOverlapping());
          return;
        }

        if (request.recurrences != null) {
          data.recurrences = data.recurrences.filter(
            (recurrence) => recurrence.scheduleId !== schedule!.id,
          );

          request.recurrences.forEach((requestRecurrence) =>
            data.recurrences.push({
              id: requestRecurrence.id,
              startDate: requestRecurrence.startDate,
              startTime: requestRecurrence.startTime,
              weekDay: requestRecurrence.weekDay,
              repeat: requestRecurrence.repeat,
              scheduleId: schedule!.id,
              companyId: schedule!.companyId,
            }),
          );
        }

        if (request.ovenPanels != null) {
          data.ovenPanelSchedules = data.ovenPanelSchedules.filter(
            (ovenPanelSchedule) => ovenPanelSchedule.scheduleId !== schedule!.id,
          );

          request.ovenPanels.forEach((requestOvenPanel) =>
            data.ovenPanelSchedules.push({
              ovenPanelId: requestOvenPanel.id,
              scheduleId: schedule!.id,
            }),
          );
        }

        resolve(schedule.id);
      }, 500),
    );
  },
  createOrUpdateSchedules: function (args: {
    request: CreateOrUpdateSchedulesRequest;
  }): Promise<CreateOrUpdateSchedulesResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {request} = args;

        const scheduleIds: string[] = [];

        for (const requestSchedule of request) {
          scheduleIds.push(requestSchedule.id);

          const isValid = validateSchedule(requestSchedule);

          if (!isValid) {
            reject(ApplicationError.scheduleOverlapping());
            return;
          }

          let schedule = data.schedules.find((schedule) => schedule.id === requestSchedule.id);

          if (schedule == null) {
            schedule = {
              id: requestSchedule.id,
              recipeId: requestSchedule.recipeId,
              cleaningId: requestSchedule.cleaningId,
              companyId: requestSchedule.companyId,
            };
          }

          if (requestSchedule.recurrences != null) {
            data.recurrences = data.recurrences.filter(
              (recurrence) => recurrence.scheduleId !== schedule!.id,
            );

            requestSchedule.recurrences.forEach((requestRecurrence) =>
              data.recurrences.push({
                id: requestRecurrence.id,
                startDate: requestRecurrence.startDate,
                startTime: requestRecurrence.startTime,
                weekDay: requestRecurrence.weekDay,
                repeat: requestRecurrence.repeat,
                scheduleId: schedule!.id,
                companyId: schedule!.companyId,
              }),
            );
          }

          if (requestSchedule.ovenPanels != null) {
            data.ovenPanelSchedules = data.ovenPanelSchedules.filter(
              (ovenPanelSchedule) => ovenPanelSchedule.scheduleId !== schedule!.id,
            );

            requestSchedule.ovenPanels.forEach((requestOvenPanel) =>
              data.ovenPanelSchedules.push({
                ovenPanelId: requestOvenPanel.id,
                scheduleId: schedule!.id,
              }),
            );
          }
        }

        resolve(scheduleIds);
      }, 500),
    );
  },
};

export default mockScheduleService;
