import {Box, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import Switch from '../../../../common/Switch';

type TurbineSpeedRowProps = {
  turbineSpeed: number;
  onChange: (turbineSpeed: number) => void;
};

function TurbineSpeedRow(props: TurbineSpeedRowProps) {
  const {turbineSpeed, onChange} = props;
  const {t} = useTranslation();

  return (
    <Box sx={{display: 'flex', justifyContent: 'space-between', gap: '16px'}}>
      <Box>
        <Typography
          sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 500, lineHeight: '28px'}}>
          {t('recipe_settings_phases_turbine_speed_label')}
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            color: 'custom.textTertiary',
          }}>
          {t('recipe_settings_phases_turbine_speed_message')}
        </Typography>
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
        <Box sx={{display: 'flex', alignItems: 'center', gap: '8px'}}>
          <Typography
            sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 400, lineHeight: '28px'}}>
            {t('recipe_settings_phases_turbine_speed_action_label') + ' 1'}
          </Typography>
          <Switch
            checked={turbineSpeed === 1}
            onChange={(_, checked) => onChange(checked ? 1 : 2)}
          />
        </Box>
        <Box sx={{display: 'flex', alignItems: 'center', gap: '8px'}}>
          <Typography
            sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 400, lineHeight: '28px'}}
            noWrap>
            {t('recipe_settings_phases_turbine_speed_action_label') + ' 2'}
          </Typography>
          <Switch
            checked={turbineSpeed === 2}
            onChange={(_, checked) => onChange(checked ? 2 : 1)}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default TurbineSpeedRow;
