import {Box, List, ListItem, Typography} from '@mui/material';
import {ReactComponent as DeclineIcon} from '../../assets/icons/decline.svg';
import {OvenModelId} from '../../models/entities/oven-model';
import GradientOverflow from '../common/GradientOverflow';
import IconButton from '../common/IconButton';
import Span from '../common/Span';
import ConfirmPopover from '../common/ConfirmPopover';
import {useRef, useState} from 'react';
import {t} from 'i18next';

export type ScheduleListItemProps = {
  scheduleId: string;
  recipeId?: string | null;
  cleaningId?: number | null;
  linkedScheduleId?: string | null;
  ovenPanelId: string;
  program: string;
  location: string;
  startTime: string;
  endTime: string;
  items: {
    scheduleId: string;
    ovenPanelId: string;
    location: string;
    startTime: string;
    endTime: string;
  }[];
  recurrence: string;
  renderItems?: boolean;
};

export function ScheduleListItem(
  props: ScheduleListItemProps & {
    onSelectItem?: (scheduleId: string, linkedScheduleId?: string | null) => void;
    onDeleteItem?: (scheduleId: string, ovenPanelId: string) => void;
  },
) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const containerRef = useRef<HTMLLIElement | null>(null);

  if (props.items.length === 1 && !props.renderItems) {
    return (
      <ListItem ref={containerRef} sx={{margin: 0, padding: 0}}>
        <Box sx={{flex: 1}}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '16px',
              paddingInline: '32px',
              color: 'custom.textPrimary',
              cursor: props.onSelectItem != null ? 'pointer' : undefined,
              '&:hover': {
                color: props.onSelectItem != null ? 'custom.textBrand' : 'custom.textPrimary',
              },
            }}
            onClick={() => props.onSelectItem?.(props.scheduleId, props.linkedScheduleId)}>
            <Typography
              sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 400, lineHeight: '28px'}}>
              <Span
                sx={{
                  fontWeight: 500,
                  color: props.recipeId != null ? 'custom.textBrand' : 'custom.textBlue',
                }}>
                {props.program}{' '}
              </Span>
              <Span>{props.location}</Span>
            </Typography>
            <Typography
              sx={{fontSize: '16px', fontStyle: 'normal', fontWeight: 400, lineHeight: '24px'}}>
              {`${props.startTime} - ${props.endTime}`}
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
              textTransform: 'lowercase',
              color: 'custom.textTertiary',
              paddingInline: '32px',
            }}>
            {props.recurrence}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: {xs: '60px', sm: '120px'},
          }}>
          {props.onDeleteItem != null && (
            <IconButton
              IconComponent={DeclineIcon}
              onClick={() => setAnchorEl(containerRef.current)}
            />
          )}
        </Box>
        <ConfirmPopover
          isOpen={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{vertical: 'center', horizontal: 'left'}}
          transformOrigin={{vertical: 'center', horizontal: 'left'}}
          onChange={() => null}
          messageElement={
            <Typography
              sx={{
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px',
                color: 'custom.textSecondary',
              }}>
              {t('do_you_want_to_delete_the_selected_program')}
            </Typography>
          }
          onAccept={() => {
            setAnchorEl(null);
            props.onDeleteItem?.(props.scheduleId, props.ovenPanelId);
          }}
          onDecline={() => setAnchorEl(null)}
        />
      </ListItem>
    );
  }

  return (
    <ListItem ref={containerRef} sx={{margin: 0, padding: 0}}>
      <Box
        sx={{
          flex: 1,
          cursor: props.onSelectItem != null ? 'pointer' : undefined,
          '&:hover': {
            color: props.onSelectItem != null ? 'custom.textBrand' : 'custom.textPrimary',
          },
        }}
        onClick={() => props.onSelectItem?.(props.scheduleId, props.linkedScheduleId)}>
        <Typography
          sx={{
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '28px',
            paddingInline: '32px',
          }}>
          <Span
            sx={{
              fontWeight: 500,
              color: props.recipeId != null ? 'custom.textBrand' : 'custom.textBlue',
            }}>
            {props.program}{' '}
          </Span>
          <Span>{props.location}</Span>
        </Typography>
        {props.items.map((item, index) => (
          <>
            <Box key={index} sx={{display: 'flex'}}>
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: '16px',
                  paddingInline: '32px',
                }}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '24px',
                    color: 'custom.textPrimary',
                  }}>
                  {item.location}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '24px',
                    color: 'custom.textPrimary',
                  }}>
                  {`${item.startTime} - ${item.endTime}`}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: {xs: '60px', sm: '120px'},
                }}>
                {props.onDeleteItem != null && (
                  <IconButton
                    IconComponent={DeclineIcon}
                    onClick={() => setAnchorEl(containerRef.current)}
                  />
                )}
              </Box>
            </Box>
            <ConfirmPopover
              isOpen={Boolean(anchorEl)}
              anchorEl={anchorEl}
              anchorOrigin={{vertical: 'center', horizontal: 'left'}}
              transformOrigin={{vertical: 'center', horizontal: 'left'}}
              onChange={() => null}
              messageElement={
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '24px',
                    color: 'custom.textSecondary',
                  }}>
                  {t('do_you_want_to_delete_the_selected_program')}
                </Typography>
              }
              onAccept={() => {
                setAnchorEl(null);
                props.onDeleteItem?.(item.scheduleId, item.ovenPanelId);
              }}
              onDecline={() => setAnchorEl(null)}
            />
          </>
        ))}
        <Typography
          sx={{
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            textTransform: 'lowercase',
            color: 'custom.textTertiary',
            paddingInline: '32px',
          }}>
          {props.recurrence}
        </Typography>
      </Box>
    </ListItem>
  );
}

export type ScheduleListProps = {
  selectedOvenModelId?: number;
  items: ScheduleListItemProps[];
  onSelectItem?: (scheduleId: string, linkedScheduleId?: string | null) => void;
  onDeleteItem?: (scheduleId: string, ovenPanelId: string) => void;
  itemsNotFoundLabel?: string;
};

function ScheduleList(props: ScheduleListProps) {
  return (
    <Box
      sx={{
        height: {
          xs:
            props.selectedOvenModelId === OvenModelId.Turboram
              ? 'calc(100vh - 524px)'
              : 'calc(100vh - 468px)',
          md:
            props.selectedOvenModelId === OvenModelId.Turboram
              ? 'calc(100vh - 480px)'
              : 'calc(100vh - 424px)',
        },
        minHeight: '256px',
        marginTop: '32px',
      }}>
      <GradientOverflow hideScrollbar>
        <List sx={{display: 'flex', flexDirection: 'column', gap: '24px', margin: 0, padding: 0}}>
          {props.items.length === 0 ? (
            <ListItem sx={{margin: 0, padding: 0, paddingLeft: '32px'}}>
              <Typography
                sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 400, lineHeight: '28px'}}>
                {props.itemsNotFoundLabel}
              </Typography>
            </ListItem>
          ) : (
            props.items.map((item, index) => (
              <ScheduleListItem
                key={index}
                {...item}
                onSelectItem={props.onSelectItem}
                onDeleteItem={props.onDeleteItem}
              />
            ))
          )}
        </List>
      </GradientOverflow>
    </Box>
  );
}

export default ScheduleList;
