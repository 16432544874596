import {Box, Typography} from '@mui/material';
import Country from '../../models/entities/country';
import HorizontalGradientOverflow from '../common/HorizontalGradientOverflow';

export type CountryTabsProps = {
  countries: Country[];
  selectedCountryId?: number;
  onSelectCountry: (countryId: number) => void;
};

function CountryTabs(props: CountryTabsProps) {
  const {countries, selectedCountryId, onSelectCountry} = props;

  return (
    <HorizontalGradientOverflow>
      <Box sx={{display: 'flex', height: '60px'}}>
        <Box sx={{minWidth: '16px'}} />
        {countries.map((country) => (
          <Box key={country.id}>
            <Typography
              sx={{
                paddingInline: '16px',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: country.id === selectedCountryId ? 500 : 400,
                lineHeight: '28px',
                color:
                  country.id === props.selectedCountryId
                    ? 'custom.textPrimary'
                    : 'custom.textTertiary',
                cursor: 'pointer',
              }}
              noWrap
              onClick={() => onSelectCountry(country.id)}>
              {country.name}
            </Typography>
          </Box>
        ))}
        <Box sx={{minWidth: '16px'}} />
      </Box>
    </HorizontalGradientOverflow>
  );
}

export default CountryTabs;
