import {Box, ClickAwayListener, Collapse, List, ListItem, Typography} from '@mui/material';
import {useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as EditIcon} from '../../assets/icons/edit.svg';
import Oven from '../../models/entities/oven';
import OvenChamber from '../../models/entities/oven-chamber';
import OvenGroup from '../../models/entities/oven-group';
import {OvenModelId} from '../../models/entities/oven-model';
import dateUtils from '../../utils/dates';
import stringUtils from '../../utils/strings';
import {Language} from '../../utils/types';
import ConfirmPopover from '../common/ConfirmPopover';
import GradientOverflow from '../common/GradientOverflow';
import IconButton from '../common/IconButton';
import OvenPanelMenu, {OvenPanelMenuItem} from './OvenPanelMenu';

function UpdateDescriptionContainer({
  defaultDescription,
  onUpdateDescription,
}: {
  defaultDescription: string;
  onUpdateDescription: (description: string) => void;
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [description, setDescription] = useState(defaultDescription);

  const containerRef = useRef<HTMLDivElement | null>(null);

  function handleUpdateName() {
    if (!stringUtils.isNullOrWhiteSpace(description) && description !== defaultDescription) {
      onUpdateDescription(description);
    }
    setAnchorEl(null);
  }

  const isOpen = Boolean(anchorEl);

  return (
    <Box
      ref={containerRef}
      sx={{display: 'flex', justifyContent: 'center', width: {xs: '60px', sm: '120px'}}}>
      <IconButton
        sx={{zIndex: isOpen ? 1500 : undefined}}
        IconComponent={EditIcon}
        isActive={isOpen}
        onClick={() => {
          setDescription(defaultDescription);
          setAnchorEl(isOpen ? null : containerRef.current);
        }}
      />
      <ConfirmPopover
        isOpen={Boolean(anchorEl)}
        anchorEl={anchorEl}
        value={description}
        onChange={setDescription}
        onAccept={handleUpdateName}
        onDecline={() => {
          setDescription(defaultDescription);
          setAnchorEl(null);
        }}
      />
    </Box>
  );
}

type OvenGroupListItemProps = {
  ovenGroup: OvenGroup;
  selectedOvenGroupId: string;
  selectedOvenId: string;
  onSelectOvenGroup: (ovenGroupId: string) => void;
  onChangeOvenGroupDescription: (ovenGroup: OvenGroup) => void;
  onDeleteOven: (oven: Oven) => void;
  onOvenPanelMenuClick: (args: {
    menuItem: OvenPanelMenuItem;
    ovenPanelId: string;
    ovenChamberId?: string;
    ovenId: string;
    ovenGroupId?: string;
  }) => void;
};

function OvenGroupListItem(props: OvenGroupListItemProps) {
  const {
    ovenGroup,
    selectedOvenGroupId,
    selectedOvenId,
    onSelectOvenGroup,
    onChangeOvenGroupDescription,
    onDeleteOven,
    onOvenPanelMenuClick,
  } = props;
  const {t, i18n} = useTranslation();

  const isSelected = ovenGroup.id === selectedOvenGroupId;
  const hasSelectedAnother = (selectedOvenGroupId || selectedOvenId) && !isSelected;

  const ovens = useMemo(
    () =>
      Array.from(ovenGroup.ovens ?? []).sort(
        (ovenA, ovenB) => (ovenA.ovenGroupOrder ?? 1) - (ovenB.ovenGroupOrder ?? 1),
      ),
    [ovenGroup],
  );

  return (
    <ClickAwayListener onClickAway={() => onSelectOvenGroup('')}>
      <ListItem
        sx={{
          alignItems: 'flex-start',
          margin: 0,
          padding: 0,
          opacity: hasSelectedAnother ? 0.5 : 1,
        }}>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            paddingInline: '32px',
            fontWeight: 400,
            color: 'custom.textPrimary',
            cursor: 'pointer',
            '&:hover': {
              fontWeight: 500,
              color: 'custom.textBrand',
            },
          }}
          onClick={(event) => {
            event.stopPropagation();
            onSelectOvenGroup(ovenGroup.id);
          }}>
          <Box sx={{display: 'flex', justifyContent: 'space-between', gap: '16px'}}>
            <Typography
              sx={{
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '28px',
                color: isSelected ? 'custom.textBrand' : 'inherit',
              }}>
              {ovenGroup.description}
            </Typography>
            <Typography
              sx={{
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px',
                color: isSelected ? 'custom.textBrand' : 'inherit',
              }}>
              {ovenGroup.assignedAt != null
                ? dateUtils.toLocaleDateString(
                    new Date(ovenGroup.assignedAt),
                    i18n.language as Language,
                  )
                : ''}
            </Typography>
          </Box>
          {ovens.map((oven, ovenIndex) => (
            <Box key={oven.id}>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '24px',
                  color: 'custom.textPrimary',
                }}>
                {`${t('bakery_ovens_oven_serial_number_label')} ${oven.ovenGroupOrder ?? 1}: ${
                  oven.serialNumber
                }`}
              </Typography>
              <Collapse in={isSelected} unmountOnExit>
                {oven.ovenPanels?.map((ovenPanel) => (
                  <OvenPanelMenu
                    key={ovenPanel.id}
                    sx={{marginBottom: ovenIndex < ovens.length - 1 ? '32px' : '0px'}}
                    onClick={(menuItem) => {
                      if (menuItem === 'delete') {
                        onDeleteOven(oven);
                      } else {
                        onOvenPanelMenuClick({
                          menuItem,
                          ovenPanelId: ovenPanel.id,
                          ovenId: oven.id,
                          //ovenGroupId: ovenGroup.id,
                        });
                      }
                    }}
                  />
                ))}
              </Collapse>
            </Box>
          ))}
        </Box>
        <UpdateDescriptionContainer
          defaultDescription={ovenGroup.description ?? ''}
          onUpdateDescription={(description) =>
            onChangeOvenGroupDescription({...ovenGroup, description})
          }
        />
      </ListItem>
    </ClickAwayListener>
  );
}

type ChamberOvenListItemProps = {
  oven: Oven;
  selectedOvenGroupId: string;
  selectedOvenId: string;
  onSelectOven: (ovenId: string) => void;
  onChangeOvenDescription: (oven: Oven) => void;
  onDeleteOvenChamber: (ovenChamber: OvenChamber) => void;
  onOvenPanelMenuClick: (args: {
    menuItem: OvenPanelMenuItem;
    ovenPanelId: string;
    ovenChamberId?: string;
    ovenId: string;
    ovenGroupId?: string;
  }) => void;
};

function ChamberOvenListItem(props: ChamberOvenListItemProps) {
  const {
    oven,
    selectedOvenGroupId,
    selectedOvenId,
    onSelectOven,
    onChangeOvenDescription,
    onDeleteOvenChamber,
    onOvenPanelMenuClick,
  } = props;
  const {t, i18n} = useTranslation();

  const isSelected = oven.id === selectedOvenId;
  const hasSelectedAnother = (selectedOvenGroupId || selectedOvenId) && !isSelected;

  const ovenChambers = useMemo(
    () =>
      Array.from(oven.ovenChambers ?? []).sort(
        (ovenChamberA, ovenChamberB) =>
          (ovenChamberA.ovenOrder ?? 1) - (ovenChamberB.ovenOrder ?? 1),
      ),
    [oven],
  );

  return (
    <ClickAwayListener onClickAway={() => onSelectOven('')}>
      <ListItem
        sx={{
          alignItems: 'flex-start',
          margin: 0,
          padding: 0,
          opacity: hasSelectedAnother ? 0.5 : 1,
        }}>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            paddingInline: '32px',
            fontWeight: 400,
            color: 'custom.textPrimary',
            cursor: 'pointer',
            '&:hover': {
              fontWeight: 500,
              color: 'custom.textBrand',
            },
          }}
          onClick={(event) => {
            event.stopPropagation();
            onSelectOven(oven.id);
          }}>
          <Box sx={{display: 'flex', justifyContent: 'space-between', gap: '16px'}}>
            <Typography
              sx={{
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '28px',
                color: isSelected ? 'custom.textBrand' : 'inherit',
              }}>
              {oven.description}
            </Typography>
            <Typography
              sx={{
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px',
                color: isSelected ? 'custom.textBrand' : 'inherit',
              }}>
              {oven.assignedAt != null
                ? dateUtils.toLocaleDateString(new Date(oven.assignedAt), i18n.language as Language)
                : ''}
            </Typography>
          </Box>
          {ovenChambers.map((ovenChamber, ovenChamberIndex) => (
            <Box key={ovenChamber.id}>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '24px',
                  color: 'custom.textPrimary',
                }}>
                {`${t('bakery_ovens_chamber_serial_number_label')} ${ovenChamber.ovenOrder ?? 1}: ${
                  ovenChamber.serialNumber
                }`}
              </Typography>
              <Collapse in={isSelected} unmountOnExit>
                {ovenChamber.ovenPanels?.map((ovenPanel) => (
                  <OvenPanelMenu
                    key={ovenPanel.id}
                    sx={{marginBottom: ovenChamberIndex < ovenChambers.length - 1 ? '32px' : '0px'}}
                    onClick={(menuItem) => {
                      if (menuItem === 'delete') {
                        onDeleteOvenChamber(ovenChamber);
                      } else {
                        onOvenPanelMenuClick({
                          menuItem,
                          ovenPanelId: ovenPanel.id,
                          ovenId: oven.id,
                          ovenChamberId: ovenChamber.id,
                        });
                      }
                    }}
                  />
                ))}
              </Collapse>
            </Box>
          ))}
        </Box>
        <UpdateDescriptionContainer
          defaultDescription={oven.description ?? ''}
          onUpdateDescription={(description) => onChangeOvenDescription({...oven, description})}
        />
      </ListItem>
    </ClickAwayListener>
  );
}

type OvenListItemProps = {
  oven: Oven;
  selectedOvenGroupId: string;
  selectedOvenId: string;
  onSelectOven: (ovenId: string) => void;
  onChangeOvenDescription: (oven: Oven) => void;
  onDeleteOven: (oven: Oven) => void;
  onOvenPanelMenuClick: (args: {
    menuItem: OvenPanelMenuItem;
    ovenPanelId: string;
    ovenChamberId?: string;
    ovenId: string;
    ovenGroupId?: string;
  }) => void;
};

function OvenListItem(props: OvenListItemProps) {
  const {
    oven,
    selectedOvenGroupId,
    selectedOvenId,
    onSelectOven,
    onChangeOvenDescription,
    onDeleteOven,
    onOvenPanelMenuClick,
  } = props;
  const {t, i18n} = useTranslation();

  const isSelected = oven.id === selectedOvenId;
  const hasSelectedAnother = (selectedOvenGroupId || selectedOvenId) && !isSelected;

  const ovenPanels = useMemo(
    () =>
      Array.from(oven.ovenPanels ?? []).sort(
        (ovenPanelA, ovenPanelB) => (ovenPanelA.ovenOrder ?? 1) - (ovenPanelB.ovenOrder ?? 1),
      ),
    [oven],
  );

  return (
    <ClickAwayListener onClickAway={() => onSelectOven('')}>
      <ListItem
        sx={{
          alignItems: 'flex-start',
          margin: 0,
          padding: 0,
          opacity: hasSelectedAnother ? 0.5 : 1,
        }}>
        <Box sx={{flex: 1, paddingInline: '32px'}}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              fontWeight: 400,
              color: 'custom.textPrimary',
              cursor: 'pointer',
              '&:hover': {
                fontWeight: 500,
                color: 'custom.textBrand',
              },
            }}
            onClick={(event) => {
              event.stopPropagation();
              onSelectOven(oven.id);
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '16px',
              }}>
              <Typography
                sx={{
                  fontSize: '20px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '28px',
                  color: isSelected ? 'custom.textBrand' : 'inherit',
                }}>
                {oven.description ?? oven.serialNumber}
              </Typography>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '24px',
                  color: isSelected ? 'custom.textBrand' : 'inherit',
                }}>
                {oven.assignedAt != null
                  ? dateUtils.toLocaleDateString(
                      new Date(oven.assignedAt),
                      i18n.language as Language,
                    )
                  : ''}
              </Typography>
            </Box>
            <Typography
              sx={{
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px',
                color: 'custom.textPrimary',
              }}>
              {`${t('bakery_ovens_serial_number_label')}: ${oven.serialNumber}`}
            </Typography>
          </Box>
          <Collapse in={isSelected} unmountOnExit>
            {ovenPanels.map((ovenPanel) => (
              <OvenPanelMenu
                key={ovenPanel.id}
                hasMultiple={ovenPanels.length > 1}
                ovenOrder={ovenPanel.ovenOrder ?? 1}
                onClick={(menuItem) => {
                  if (menuItem === 'delete') {
                    onDeleteOven(oven);
                  } else {
                    onOvenPanelMenuClick({menuItem, ovenPanelId: ovenPanel.id, ovenId: oven.id});
                  }
                }}
              />
            ))}
          </Collapse>
        </Box>
        <UpdateDescriptionContainer
          defaultDescription={oven.description ?? ''}
          onUpdateDescription={(description) => onChangeOvenDescription({...oven, description})}
        />
      </ListItem>
    </ClickAwayListener>
  );
}

export type OvenTableProps = {
  ovenGroups: OvenGroup[];
  ovens: Oven[];
  selectedOvenGroupId: string;
  selectedOvenId: string;
  onSelectOvenGroup: (ovenGroupId: string) => void;
  onSelectOven: (ovenId: string) => void;
  onChangeOvenGroupDescription: (ovenGroup: OvenGroup) => void;
  onChangeOvenDescription: (oven: Oven) => void;
  onDeleteOven: (oven: Oven) => void;
  onDeleteOvenChamber: (ovenChamber: OvenChamber) => void;
  onOvenPanelMenuClick: (args: {
    menuItem: OvenPanelMenuItem;
    ovenPanelId: string;
    ovenChamberId?: string;
    ovenId: string;
    ovenGroupId?: string;
  }) => void;
};

function OvenList(props: OvenTableProps) {
  const {
    ovenGroups,
    ovens,
    selectedOvenGroupId,
    selectedOvenId,
    onSelectOvenGroup,
    onSelectOven,
    onChangeOvenGroupDescription,
    onChangeOvenDescription,
    onDeleteOven,
    onDeleteOvenChamber,
    onOvenPanelMenuClick,
  } = props;

  return (
    <Box
      sx={{
        width: {xs: 'calc(100vw - 60px)', sm: 'calc(100vw - 120px)', md: 'calc(100vw - 325px)'},
        height: {xs: 'calc(100vh - 404px)', md: 'calc(100vh - 364px)'},
        minHeight: '256px',
        marginTop: '32px',
      }}>
      <GradientOverflow hideScrollbar>
        <List
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '32px',
            margin: 0,
            padding: 0,
          }}>
          {ovens.map((oven) =>
            oven.ovenModelId === OvenModelId.Modulram ? (
              <ChamberOvenListItem
                key={oven.id}
                oven={oven}
                selectedOvenGroupId={selectedOvenGroupId}
                selectedOvenId={selectedOvenId}
                onSelectOven={onSelectOven}
                onChangeOvenDescription={onChangeOvenDescription}
                onDeleteOvenChamber={onDeleteOvenChamber}
                onOvenPanelMenuClick={onOvenPanelMenuClick}
              />
            ) : (
              <OvenListItem
                key={oven.id}
                oven={oven}
                selectedOvenGroupId={selectedOvenGroupId}
                selectedOvenId={selectedOvenId}
                onSelectOven={onSelectOven}
                onChangeOvenDescription={onChangeOvenDescription}
                onDeleteOven={onDeleteOven}
                onOvenPanelMenuClick={onOvenPanelMenuClick}
              />
            ),
          )}
          {ovenGroups.map((ovenGroup) => (
            <OvenGroupListItem
              key={ovenGroup.id}
              ovenGroup={ovenGroup}
              selectedOvenGroupId={selectedOvenGroupId}
              selectedOvenId={selectedOvenId}
              onSelectOvenGroup={onSelectOvenGroup}
              onChangeOvenGroupDescription={onChangeOvenGroupDescription}
              onDeleteOven={onDeleteOven}
              onOvenPanelMenuClick={onOvenPanelMenuClick}
            />
          ))}
        </List>
      </GradientOverflow>
    </Box>
  );
}

export default OvenList;
