import {Box, List, ListItem, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import Recipe from '../../models/entities/recipe';
import recipeUtils from '../../utils/recipes';
import GradientOverflow from '../common/GradientOverflow';

type RecipeListItemProps = {
  recipe: Recipe;
  isSelected: boolean;
  onSelectRecipe: () => void;
};

function RecipeListItem(props: RecipeListItemProps) {
  const {recipe, isSelected, onSelectRecipe} = props;
  const {t} = useTranslation();

  return (
    <ListItem
      sx={{
        margin: 0,
        padding: 0,
        justifyContent: 'space-between',
        gap: '32px',
        cursor: 'pointer',
        '&:hover': {
          color: 'custom.textBrand',
        },
      }}
      onClick={onSelectRecipe}>
      <Typography
        sx={{
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '28px',
          color: isSelected ? 'custom.textBrand' : 'inherit',
        }}>
        {recipe.name}
      </Typography>
      <Typography
        sx={{
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '24px',
          color: isSelected ? 'custom.textBrand' : 'inherit',
        }}>{`${t('programming_duration_label')}: ${recipeUtils.getDuration(recipe) / 60} ${t(
        'programming_minutes_label',
      )}`}</Typography>
    </ListItem>
  );
}

type RecipeListProps = {
  recipes: Recipe[];
  selectedRecipeId: string | null;
  onSelectRecipe: (recipeId: string) => void;
  recipesNotFoundMessage?: string;
};

function RecipeList(props: RecipeListProps) {
  const {recipes, selectedRecipeId, onSelectRecipe, recipesNotFoundMessage} = props;

  return (
    <Box
      sx={{
        height: {xs: 'calc(100vh - 480px)', md: 'calc(100vh - 440px)'},
        minHeight: '256px',
        marginTop: '16px',
      }}>
      <GradientOverflow hideScrollbar>
        <List
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
            margin: 0,
            padding: 0,
            paddingInline: '32px',
          }}>
          {recipes.length === 0 ? (
            <ListItem sx={{margin: 0, padding: 0}}>
              <Typography
                sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 400, lineHeight: '28px'}}>
                {recipesNotFoundMessage}
              </Typography>
            </ListItem>
          ) : (
            recipes.map((recipe) => (
              <RecipeListItem
                key={recipe.id}
                recipe={recipe}
                isSelected={recipe.id === selectedRecipeId}
                onSelectRecipe={() => onSelectRecipe(recipe.id)}
              />
            ))
          )}
        </List>
      </GradientOverflow>
    </Box>
  );
}

export default RecipeList;
