import {GetRecipeExecutionStatisticsQuery} from '../models/requests/telemetry';
import {GetRecipeExecutionStatisticsResponse} from '../models/responses/telemetry';
import baseService from './base';
import endpoints from './endpoints';

export type TelemetryService = {
  getRecipeExecutionStatistics: (args: {
    query: GetRecipeExecutionStatisticsQuery;
  }) => Promise<GetRecipeExecutionStatisticsResponse>;
};

const telemetryService: TelemetryService = {
  getRecipeExecutionStatistics: async function (args: {
    query: GetRecipeExecutionStatisticsQuery;
  }): Promise<GetRecipeExecutionStatisticsResponse> {
    const {query} = args;

    const result = await baseService.get<GetRecipeExecutionStatisticsResponse>(
      `${endpoints.telemetry}/recipe-execution/statistics`,
      query,
    );

    return result;
  },
};

export default telemetryService;
