import {Box, SxProps, Typography} from '@mui/material';
import {ReactNode} from 'react';

type ActionBannerProps = {
  sx?: SxProps;
  color?: string;
  text: string;
  secondary?: ReactNode;
  onClick?: () => void;
};

function ActionBanner(props: ActionBannerProps) {
  const {sx, color = 'custom.surfaceSecondary', text, secondary, onClick} = props;

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '8px',
        height: '40px',
        padding: '12px 32px',
        backgroundColor: color,
        borderRadius: '360px',
        cursor: props.onClick != null ? 'pointer' : undefined,
        ...sx,
      }}
      onClick={onClick}>
      <Typography
        sx={{
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: 'normal',
          color: 'custom.textSecondary',
        }}>
        {text}
      </Typography>
      {secondary}
    </Box>
  );
}

export default ActionBanner;
