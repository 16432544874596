import {Box, CircularProgress} from '@mui/material';

function LoadingBox() {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      }}>
      <CircularProgress sx={{color: 'custom.surfaceBrand'}} />
    </Box>
  );
}

export default LoadingBox;
