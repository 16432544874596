import {Box, Typography} from '@mui/material';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import RecipeType from '../../models/entities/recipe-type';
import recipeTypeUtils from '../../utils/recipe-types';
import {Language} from '../../utils/types';
import HorizontalGradientOverflow from '../common/HorizontalGradientOverflow';

export type RecipeTypeTabsProps = {
  recipeTypes: RecipeType[];
  selectedRecipeTypeId: string;
  onSelectRecipeType: (recipeTypeId: string) => void;
};

function RecipeTypeTabs(props: RecipeTypeTabsProps) {
  const {t, i18n} = useTranslation();

  const tabs = useMemo(() => {
    const tabs = props.recipeTypes.map((recipeType) => ({
      id: recipeType.id,
      label: recipeTypeUtils.getDescription(recipeType, i18n.language as Language),
    }));

    tabs.unshift({id: 'all', label: t('recipe_type_all_label')});

    return tabs;
  }, [props.recipeTypes]);

  return (
    <HorizontalGradientOverflow>
      <Box sx={{display: 'flex', height: '60px'}}>
        <Box sx={{minWidth: '16px'}} />
        {tabs.map((tab) => (
          <Box key={tab.id}>
            <Typography
              sx={{
                paddingInline: '16px',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: tab.id === props.selectedRecipeTypeId ? 500 : 400,
                lineHeight: '28px',
                color:
                  tab.id === props.selectedRecipeTypeId
                    ? 'custom.textPrimary'
                    : 'custom.textTertiary',
                cursor: 'pointer',
              }}
              noWrap
              onClick={() => props.onSelectRecipeType(tab.id)}>
              {tab.label}
            </Typography>
          </Box>
        ))}
        <Box sx={{minWidth: '16px'}} />
      </Box>
    </HorizontalGradientOverflow>
  );
}

export default RecipeTypeTabs;
