import {Box, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {OvenModelId} from '../../../../models/entities/oven-model';
import GradientOverflow from '../../../common/GradientOverflow';
import {AddOvenData, AddOvenUiState} from '../AddOven';

export type ModelStepProps = {
  setUiState: (uiState: AddOvenUiState) => void;
  addOvenData: AddOvenData;
  setAddOvenData: (setter: (addOvenData: AddOvenData) => AddOvenData) => void;
};

function ModelStep(props: ModelStepProps) {
  const {setUiState, addOvenData, setAddOvenData} = props;
  const {t} = useTranslation();

  function handleSelectOvenModel(ovenModelId: OvenModelId) {
    setAddOvenData(() => ({
      ovenModelId: ovenModelId,
      ovenSubModel: null,
      serialNumber: '',
      topOvenSerialNumber: '',
      bottomOvenSerialNumber: '',
      ovenChambersNumber: 0,
      ovenChamberSerialNumbers: [],
      description: '',
    }));

    setUiState({
      visibleSteps: ['model'],
      isNextButtonVisible: true,
      currentStep: 'model',
      nextStep:
        ovenModelId === OvenModelId.Turboram
          ? 'sub-model'
          : ovenModelId === OvenModelId.Modulram
          ? 'oven-chambers-number'
          : 'serial-number',
    });
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px', paddingInline: '32px'}}>
      <Typography sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 500, lineHeight: '28px'}}>
        {t('add_oven_select_model_label')}
      </Typography>
      <Box
        sx={{height: {xs: 'calc(100vh - 408px)', md: 'calc(100vh - 368px)'}, minHeight: '256px'}}>
        <GradientOverflow hideScrollbar>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px'}}>
            {[
              {id: OvenModelId.Compactram, label: 'compactram'},
              {id: OvenModelId.Electram, label: 'electram'},
              {id: OvenModelId.Modulram, label: 'modulram'},
              {id: OvenModelId.Rotoram, label: 'rotoram'},
              {id: OvenModelId.Turboram, label: 'turboram'},
            ].map((item) => (
              <Box
                key={item.id}
                sx={{
                  fontWeight: item.id === addOvenData.ovenModelId ? 500 : 400,
                  color:
                    item.id === addOvenData.ovenModelId ? 'custom.textBrand' : 'custom.textPrimary',
                  cursor: 'pointer',
                  '&:hover': {
                    fontWeight: 500,
                    color: 'custom.textBrand',
                  },
                }}
                onClick={() => handleSelectOvenModel(item.id)}>
                <Typography
                  sx={{
                    fontSize: '20px',
                    fontStyle: 'normal',
                    fontWeight: 'inherit',
                    lineHeight: '28px',
                    color: 'inherit',
                  }}>
                  {item.label}
                </Typography>
              </Box>
            ))}
          </Box>
        </GradientOverflow>
      </Box>
    </Box>
  );
}

export default ModelStep;
