import {Box, BoxProps} from '@mui/material';
import {ReactNode, useCallback, useEffect, useRef, useState} from 'react';
import SimpleBar from 'simplebar-react';

type HorizontalGradientOverflowProps = {
  scrollContainerId?: string;
  containerProps?: BoxProps;
  children?: ReactNode;
  gradientColor?: string;
  gradientPercentage?: number;
  hideScrollbar?: boolean;
};

function HorizontalGradientOverflow(props: HorizontalGradientOverflowProps) {
  const {
    scrollContainerId,
    containerProps,
    children,
    gradientColor = '#F5F5F5',
    gradientPercentage = 20,
    hideScrollbar,
  } = props;

  const [hasScrollLeft, setHasScrollLeft] = useState(false);
  const [hasScrollRight, setHasScrollRight] = useState(false);

  const scrollRef = useRef<any>(null);

  const recalculate = useCallback((scrollElement: HTMLDivElement) => {
    const {scrollLeft, scrollWidth, clientWidth} = scrollElement;
    setHasScrollLeft(scrollLeft > 0);
    setHasScrollRight(scrollWidth - clientWidth - Math.ceil(scrollLeft) > 0);
  }, []);

  useEffect(() => {
    const scrollElement = hideScrollbar ? scrollRef.current : scrollRef.current.getScrollElement();
    if (scrollElement == null) return;
    const recalculateCallback = () => recalculate(scrollElement);
    scrollElement.addEventListener('scroll', recalculateCallback);
    window.addEventListener('resize', recalculateCallback);
    return () => {
      scrollElement.removeEventListener('scroll', recalculateCallback);
      window.removeEventListener('resize', recalculateCallback);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const scrollElement = hideScrollbar ? scrollRef.current : scrollRef.current.getScrollElement();
    if (scrollElement == null) return;
    recalculate(scrollElement);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  useEffect(() => {
    const scrollElement = hideScrollbar ? scrollRef.current : scrollRef.current.getScrollElement();
    if (scrollElement == null) return;
    let isDragging = false;
    let startX = 0;
    let scrollLeft = 0;
    const handleMouseDown = (event: MouseEvent) => {
      isDragging = true;
      startX = event.pageX - scrollElement.offsetLeft;
      scrollLeft = scrollElement.scrollLeft;
    };
    const handleMouseUp = () => {
      isDragging = false;
    };
    const handleMouseMove = (event: MouseEvent) => {
      if (isDragging) {
        event.preventDefault();
        const x = event.pageX - scrollElement.offsetLeft;
        const scroll = x - startX;
        scrollElement.scrollLeft = scrollLeft - scroll;
      }
    };
    const handleMouseLeave = () => {
      isDragging = false;
    };
    scrollElement.addEventListener('mousedown', handleMouseDown, false);
    scrollElement.addEventListener('mouseup', handleMouseUp, false);
    scrollElement.addEventListener('mousemove', handleMouseMove);
    scrollElement.addEventListener('mouseleave', handleMouseLeave, false);
    return () => {
      scrollElement.removeEventListener('mousedown', handleMouseDown);
      scrollElement.removeEventListener('mouseup', handleMouseUp);
      scrollElement.removeEventListener('mousemove', handleMouseMove);
      scrollElement.removeEventListener('mouseleave', handleMouseLeave);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      {...containerProps}
      sx={{
        ...containerProps?.sx,
        position: 'relative',
        flex: 1,
        width: 'auto',
        overflow: 'hidden',
      }}>
      <Box
        sx={{
          display: hasScrollLeft ? undefined : 'none',
          zIndex: 1000,
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          pointerEvents: 'none',
          background: `linear-gradient(to right, ${gradientColor}, transparent ${gradientPercentage}%)`,
        }}
      />
      {hideScrollbar ? (
        <Box
          id={scrollContainerId}
          ref={scrollRef}
          sx={{
            width: '100%',
            overflowX: 'auto',
            overflowY: 'hidden',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
          }}>
          {children}
        </Box>
      ) : (
        <SimpleBar ref={scrollRef} style={{width: '100%'}}>
          {children}
        </SimpleBar>
      )}
      <Box
        sx={{
          display: hasScrollRight ? undefined : 'none',
          zIndex: 1000,
          position: 'absolute',
          top: 0,
          right: 0,
          height: '100%',
          width: '100%',
          pointerEvents: 'none',
          background: `linear-gradient(to left, ${gradientColor}, transparent ${gradientPercentage}%)`,
        }}
      />
    </Box>
  );
}

export default HorizontalGradientOverflow;
