import {useMediaQuery, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {ReactComponent as InfoIcon} from '../../assets/icons/info.svg';
import {ReactComponent as NotificationsIcon} from '../../assets/icons/notification.svg';
import {ReactComponent as SettingsIcon} from '../../assets/icons/settings.svg';
import paths from '../../routes/paths';
import Menu from '../common/Menu';

function ProfileMenu() {
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const {t} = useTranslation();

  const theme = useTheme();
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  // const {user} = useUser();

  // const {data: hasUnseenNotifications = false} = useQuery({
  //   enabled: user != null,
  //   queryKey: ['notifications'],
  //   queryFn: () =>
  //     services.notification.getNotifications({
  //       query: {userId: user?.id, companyId: user?.companyId},
  //     }),
  //   select: (notifications) => notifications.some((notification) => !notification.isSeen),
  // });

  return (
    <Menu
      direction={isLargerThanMd ? 'column' : 'row'}
      items={[
        {
          text: t('profile_menu_information_label'),
          hideText: !isLargerThanMd,
          IconComponent: InfoIcon,
          isSelected: pathname.startsWith(paths.profileInformation),
          onSelectItem: () => navigate(`${paths.profileInformation}/preferences`),
        },
        {
          text: t('profile_menu_notifications_label'),
          hideText: !isLargerThanMd,
          IconComponent: NotificationsIcon,
          isSelected: pathname.startsWith(paths.profileNotifications),
          onSelectItem: () => navigate(`${paths.profileNotifications}/warnings`),
        },
        {
          text: t('profile_menu_settings_label'),
          hideText: !isLargerThanMd,
          IconComponent: SettingsIcon,
          isSelected: pathname.startsWith(paths.profileSettings),
          onSelectItem: () => navigate(`${paths.profileSettings}/language`),
        },
      ]}
    />
  );
}

export default ProfileMenu;
