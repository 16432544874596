import {Box, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Link, useNavigate} from 'react-router-dom';
import paths from '../../routes/paths';
import {Button} from '../common/Button';

function Welcome() {
  const navigate = useNavigate();
  const {t} = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '168px',
        width: {xs: '328px', md: '378px'},
        maxWidth: '80vw',
      }}>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
        <Typography
          sx={{fontSize: '56px', fontStyle: 'normal', fontWeight: 400, lineHeight: '64px'}}>
          {t('welcome_title')}
        </Typography>
        <Typography
          sx={{fontSize: '24px', fontStyle: 'normal', fontWeight: 400, lineHeight: '32px'}}>
          {t('welcome_caption')}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '176px',
        }}>
        <Button onClick={() => navigate(paths.login)}>{t('welcome_login_button_label')}</Button>
        <Button onClick={() => navigate(paths.signup)}>{t('welcome_signup_button_label')}</Button>
        <Typography
          sx={{
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            width: '100%',
            textAlign: 'right',
          }}>
          <Link to={paths.home}>{t('welcome_terms_and_conditions_label')}</Link>
        </Typography>
      </Box>
    </Box>
  );
}

export default Welcome;
