import {Box, Typography, useMediaQuery, useTheme} from '@mui/material';
import {useMemo, useState} from 'react';
import {ReactComponent as Compactram} from '../../assets/icons/compactram.svg';
import {ReactComponent as Electram} from '../../assets/icons/electram.svg';
import {ReactComponent as Modulram} from '../../assets/icons/modulram.svg';
import {ReactComponent as Rotoram} from '../../assets/icons/rotoram.svg';
import {ReactComponent as Turboram} from '../../assets/icons/turboram.svg';
import OvenModel, {OvenModelId} from '../../models/entities/oven-model';
import GradientOverflow from '../common/GradientOverflow';
import HorizontalGradientOverflow from '../common/HorizontalGradientOverflow';

function getOven(ovenModelId: OvenModelId) {
  switch (ovenModelId) {
    case OvenModelId.Compactram:
      return <Compactram />;
    case OvenModelId.Electram:
      return <Electram />;
    case OvenModelId.Modulram:
      return <Modulram />;
    case OvenModelId.Rotoram:
      return <Rotoram />;
    case OvenModelId.Turboram:
      return <Turboram />;
  }
}

type OvenModelListItemProps = {
  ovenModel: OvenModel;
  isSelected?: boolean;
  onClick?: () => void;
};

function OvenModeListItem(props: OvenModelListItemProps) {
  const {ovenModel, isSelected, onClick} = props;

  const [isHovered, setIsHovered] = useState(false);

  const isSelectedOrHovered = isSelected || isHovered;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '8px',
        width: '96px',
        height: '103px',
        opacity: isSelected ? 1 : 0.5,
        cursor: 'pointer',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '71px',
          height: '71px',
        }}
        onClick={onClick}>
        {getOven(ovenModel.id)}
      </Box>
      {isSelectedOrHovered && (
        <Typography
          sx={{fontSize: '16px', fontStyle: 'normal', fontWeight: 400, lineHeight: '24px'}}>
          {ovenModel.description.toLowerCase()}
        </Typography>
      )}
    </Box>
  );
}

export type OvenModelListProps = {
  ovenModels: OvenModel[];
  selectedOvenModelId: OvenModelId;
  onSelectOvenModel: (ovenModelId: OvenModelId) => void;
};

function OvenModelList(props: OvenModelListProps) {
  const {ovenModels, selectedOvenModelId, onSelectOvenModel} = props;

  const theme = useTheme();
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  const sortedOvenModels = useMemo(
    () => Array.from(ovenModels).sort((ovenModelA, ovenModelB) => ovenModelA.id - ovenModelB.id),
    [ovenModels],
  );

  if (!isLargerThanMd) {
    return (
      <Box
        sx={{
          width: {xs: 'calc(100vw - 120px)', sm: 'calc(100vw - 240px)', md: 'calc(100vw - 445px)'},
          marginTop: '32px',
          marginLeft: {xs: '60px', sm: '120px'},
          paddingInline: '16px',
        }}>
        <HorizontalGradientOverflow hideScrollbar>
          <Box sx={{display: 'flex', gap: '32px'}}>
            {sortedOvenModels.map((ovenModel) => (
              <Typography
                key={ovenModel.id}
                sx={{
                  fontSize: '20px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '28px',
                  color:
                    ovenModel.id === selectedOvenModelId
                      ? 'custom.textBrand'
                      : 'custom.textPrimary',
                  cursor: 'pointer',
                }}
                onClick={() => onSelectOvenModel(ovenModel.id)}>
                {ovenModel.description.toLowerCase()}
              </Typography>
            ))}
          </Box>
        </HorizontalGradientOverflow>
      </Box>
    );
  }

  return (
    <Box sx={{width: '140px', marginTop: '100px', marginBottom: '64px', marginLeft: '120px'}}>
      <GradientOverflow>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px'}}>
          {sortedOvenModels.map((ovenModel) => (
            <OvenModeListItem
              key={ovenModel.id}
              ovenModel={ovenModel}
              isSelected={ovenModel.id === selectedOvenModelId}
              onClick={() => onSelectOvenModel?.(ovenModel.id)}
            />
          ))}
        </Box>
      </GradientOverflow>
    </Box>
  );
}

export default OvenModelList;
