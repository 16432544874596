import {Box, useMediaQuery, useTheme} from '@mui/material';
import {Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as GraphicIcon} from '../../assets/icons/graphic.svg';
import {ReactComponent as ListIcon} from '../../assets/icons/list.svg';
import {ReactComponent as MenuIcon} from '../../assets/icons/menu.svg';
import IconButton from '../common/IconButton';
import Menu from '../common/Menu';

export type StatisticsMenuItem = 'list' | 'graphic';

export type StatisticsMenuProps = {
  selectedItem: StatisticsMenuItem;
  onSelectItem: (item: StatisticsMenuItem) => void;
};

function StatisticsMenu(props: StatisticsMenuProps) {
  const {selectedItem, onSelectItem} = props;
  const {t} = useTranslation();

  const theme = useTheme();
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  if (!isLargerThanMd) {
    return (
      <Box
        sx={{
          display: {xs: 'flex', md: 'none'},
          flexDirection: 'column',
          gap: '12px',
          marginLeft: {xs: '12px', sm: '24px'},
        }}>
        <IconButton
          IconComponent={MenuIcon}
          iconSize="32px"
          inactiveColor="custom.iconsTertiary"
          activeColor="custom.iconsTertiary"
          onClick={() => setIsMenuOpen((isMenuOpen) => !isMenuOpen)}
        />
        {isMenuOpen && (
          <Fragment>
            <IconButton
              IconComponent={ListIcon}
              iconSize="32px"
              inactiveColor="custom.iconsTertiary"
              isActive={selectedItem === 'list'}
              onClick={() => onSelectItem('list')}
            />
            <IconButton
              IconComponent={GraphicIcon}
              iconSize="32px"
              inactiveColor="custom.iconsTertiary"
              isActive={selectedItem === 'graphic'}
              onClick={() => onSelectItem('graphic')}
            />
          </Fragment>
        )}
      </Box>
    );
  }

  return (
    <Menu
      items={[
        {
          text: t('list'),
          IconComponent: ListIcon,
          isSelected: selectedItem === 'list',
          onSelectItem: () => onSelectItem('list'),
        },
        {
          text: t('graphic'),
          IconComponent: GraphicIcon,
          isSelected: selectedItem === 'graphic',
          onSelectItem: () => onSelectItem('graphic'),
        },
      ]}
    />
  );
}

export default StatisticsMenu;
