import {Box, ClickAwayListener, Collapse, ListItem, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {ReactComponent as DeclineIcon} from '../../assets/icons/decline.svg';
import {ReactComponent as DeleteIcon} from '../../assets/icons/delete.svg';
import {ReactComponent as EditIcon} from '../../assets/icons/edit.svg';
import {ReactComponent as LocationIcon} from '../../assets/icons/location.svg';
import Recipe from '../../models/entities/recipe';
import dateUtils from '../../utils/dates';
import recipeUtils from '../../utils/recipes';
import {Language} from '../../utils/types';
import IconButton from '../common/IconButton';

export type RecipeListItemAction = 'update' | 'delete' | 'locate' | 'deleteRecipeTypeRecipe';

type RecipeListItemProps = {
  recipe: Recipe;
  isSelected?: boolean;
  onClick?: () => void;
  onActionClick?: (action: RecipeListItemAction) => void;
  onClickAway?: () => void;
  canRemoveFromRecipeType?: boolean;
};

function RecipesListItem(props: RecipeListItemProps) {
  const {recipe, isSelected, onClick, onActionClick, onClickAway, canRemoveFromRecipeType} = props;
  const {t, i18n} = useTranslation();

  return (
    <ClickAwayListener onClickAway={() => onClickAway?.()}>
      <ListItem sx={{flexDirection: 'column', alignItems: 'flex-start', margin: 0, padding: 0}}>
        <Box sx={{display: 'flex', alignItems: 'center', width: '100%', minHeight: '40px'}}>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '16px',
              paddingInline: '32px',
              fontWeight: 400,
              color: 'custom.textPrimary',
              cursor: 'pointer',
              '&:hover': {
                fontWeight: 500,
                color: 'custom.textBrand',
              },
            }}
            onClick={(event) => {
              event.stopPropagation();
              onClick?.();
            }}>
            <Typography
              sx={{
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: isSelected ? 500 : 'inherit',
                lineHeight: '28px',
                color: isSelected ? 'custom.textBrand' : 'inherit',
              }}>
              {recipeUtils.getName(recipe, i18n.language as Language)}
            </Typography>
            <Typography
              sx={{
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px',
                color: isSelected ? 'custom.textBrand' : 'inherit',
              }}>
              {`${t('recipe_modified_at_label')}: ${dateUtils.toLocaleDateString(
                new Date(recipe.modifiedAt!),
                i18n.language as Language,
              )}`}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: {xs: '60px', sm: '120px'},
            }}>
            {canRemoveFromRecipeType && (
              <IconButton
                IconComponent={DeclineIcon}
                onClick={() => onActionClick?.('deleteRecipeTypeRecipe')}
              />
            )}
          </Box>
        </Box>
        <Collapse in={isSelected} unmountOnExit>
          <Box sx={{display: 'flex', gap: '32px', marginTop: '24px', marginInline: '32px'}}>
            <IconButton IconComponent={EditIcon} onClick={() => onActionClick?.('update')} />
            <IconButton IconComponent={LocationIcon} onClick={() => onActionClick?.('locate')} />
            <IconButton IconComponent={DeleteIcon} onClick={() => onActionClick?.('delete')} />
          </Box>
        </Collapse>
      </ListItem>
    </ClickAwayListener>
  );
}

export default RecipesListItem;
