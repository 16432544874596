import {Box} from '@mui/material';
import {endOfMonth, isSameDay, startOfMonth} from 'date-fns';
import {TFunction} from 'i18next';
import {Fragment, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery} from 'react-query';
import useUser from '../../hooks/common/use-user';
import {CleaningId} from '../../models/entities/cleaning';
import Oven from '../../models/entities/oven';
import OvenGroup from '../../models/entities/oven-group';
import Schedule from '../../models/entities/schedule';
import services from '../../services/provider';
import recipeUtils from '../../utils/recipes';
import scheduleUtils from '../../utils/schedules';
import {Language} from '../../utils/types';
import LoadingBackdrop from '../common/LoadingBackdrop';
import ScheduleList, {ScheduleListItemProps} from '../programming/ScheduleList';

function getCleaningDescription(cleaningId: number, t: TFunction) {
  switch (cleaningId) {
    case CleaningId.Soft:
      return t('soft_wash_cycle');
    case CleaningId.Medium:
      return t('medium_wash_cycle');
    case CleaningId.High:
      return t('high_wash_cycle');
    default:
      return t('manual_wash_cycle');
  }
}

export type OvenSchedulesProps = {
  selectedOvenGroup?: OvenGroup;
  selectedOven?: Oven;
  selectedDate: Date;
};

function OvenSchedules(props: OvenSchedulesProps) {
  const {t, i18n} = useTranslation();

  const {user} = useUser();

  const {data: schedules = [], isLoading} = useQuery({
    enabled: user != null,
    queryKey: [
      'schedules',
      {
        from: startOfMonth(new Date()),
        to: endOfMonth(new Date()),
        ovenId: props.selectedOven?.id,
        ovenGroupId: props.selectedOvenGroup?.id,
        companyId: user?.companyId,
        expand: [
          'recurrences',
          'recipe',
          'recipePhases',
          'cleaning',
          'ovenPanels',
          'ovenChamber',
          'oven',
          'ovenGroup',
        ],
      },
    ],
    queryFn: () =>
      services.schedule.getSchedules({
        query: {
          from: startOfMonth(new Date()),
          to: endOfMonth(new Date()),
          ovenId: props.selectedOven?.id,
          ovenGroupId: props.selectedOvenGroup?.id,
          companyId: user?.companyId,
          expand: [
            'recurrences',
            'recipe',
            'recipePhases',
            'cleaning',
            'ovenPanels',
            'ovenChamber',
            'oven',
            'ovenGroup',
          ],
        },
      }),
  });

  const items = useMemo(() => {
    const schedulesMap = new Map<string, Schedule>();

    schedules.forEach((schedule) => {
      const isOnSelectDate = schedule.recurrences?.some(
        (recurrence) =>
          (recurrence.repeat &&
            new Date(recurrence.startDate) <= props.selectedDate &&
            recurrence.weekDay === props.selectedDate.getDay()) ||
          isSameDay(new Date(recurrence.startDate), props.selectedDate),
      );

      if (isOnSelectDate) {
        schedulesMap.set(schedule.id, schedule);
      }
    });

    schedules.forEach((schedule) => {
      if (schedule.linkedScheduleId != null && schedulesMap.has(schedule.linkedScheduleId)) {
        const parentSchedule = schedulesMap.get(schedule.linkedScheduleId);
        parentSchedule!.linkedSchedule = schedule;
        schedulesMap.delete(schedule.id);
      }
    });

    const items: ScheduleListItemProps[] = [];

    schedulesMap.forEach((schedule) => {
      const ovenGroupItems = new Map<string, ScheduleListItemProps>();
      const ovenItems = new Map<string, ScheduleListItemProps>();

      schedule.ovenPanels?.forEach((ovenPanel) => {
        const oven = ovenPanel.oven ?? ovenPanel.ovenChamber?.oven;
        const ovenGroup = oven?.ovenGroup;

        if (oven == null) return;

        if (ovenGroup != null) {
          let ovenGroupItem = ovenGroupItems.get(ovenGroup.id);

          if (ovenGroupItem == null) {
            ovenGroupItem = {
              scheduleId: schedule.id,
              recipeId: schedule.recipeId,
              cleaningId: schedule.cleaningId,
              linkedScheduleId: schedule.linkedSchedule?.id,
              ovenPanelId: ovenPanel.id,
              program:
                schedule.recipe != null
                  ? recipeUtils.getName(schedule.recipe, i18n.language as Language)
                  : schedule.cleaning != null
                  ? getCleaningDescription(schedule.cleaning.id, t)
                  : '',
              location: ovenGroup.description ?? '',
              startTime: scheduleUtils.getStartTime(schedule),
              endTime: scheduleUtils.getEndTime(schedule),
              items: [
                {
                  scheduleId: schedule.id,
                  ovenPanelId: ovenPanel.id,
                  location: `${t('oven')} ${oven.ovenGroupOrder}`,
                  startTime: scheduleUtils.getStartTime(schedule),
                  endTime: scheduleUtils.getEndTime(schedule),
                },
              ],
              recurrence: scheduleUtils.getDetailedRecurrenceLabel(schedule, t),
              renderItems: true,
            };

            ovenGroupItems.set(ovenGroup.id, ovenGroupItem);
          } else {
            ovenGroupItem.items.push({
              scheduleId: schedule.id,
              ovenPanelId: ovenPanel.id,
              location: `${t('oven')} ${oven.ovenGroupOrder}`,
              startTime: scheduleUtils.getStartTime(schedule),
              endTime: scheduleUtils.getEndTime(schedule),
            });
          }
        } else {
          let ovenItem = ovenItems.get(oven.id);

          if (ovenItem == null) {
            ovenItem = {
              scheduleId: schedule.id,
              recipeId: schedule.recipeId,
              cleaningId: schedule.cleaningId,
              linkedScheduleId: schedule.linkedSchedule?.id,
              ovenPanelId: ovenPanel.id,
              program:
                schedule.recipe != null
                  ? recipeUtils.getName(schedule.recipe, i18n.language as Language)
                  : schedule.cleaning != null
                  ? getCleaningDescription(schedule.cleaning.id, t)
                  : '',
              location: oven.description ?? oven.serialNumber,
              startTime: scheduleUtils.getStartTime(schedule),
              endTime: scheduleUtils.getEndTime(schedule),
              items: [
                {
                  scheduleId: schedule.id,
                  ovenPanelId: ovenPanel.id,
                  location:
                    ovenPanel.ovenChamber != null
                      ? `${t('chamber')} ${ovenPanel.ovenChamber.ovenOrder}`
                      : `${t('panel')} ${ovenPanel.ovenOrder}`,
                  startTime: scheduleUtils.getStartTime(schedule),
                  endTime: scheduleUtils.getEndTime(schedule),
                },
              ],
              recurrence: scheduleUtils.getDetailedRecurrenceLabel(schedule, t),
            };

            ovenItems.set(oven.id, ovenItem);
          } else {
            ovenItem.items.push({
              scheduleId: schedule.id,
              ovenPanelId: ovenPanel.id,
              location:
                ovenPanel.ovenChamber != null
                  ? `${t('chamber')} ${ovenPanel.ovenChamber.ovenOrder}`
                  : `${t('panel')} ${ovenPanel.ovenOrder}`,
              startTime: scheduleUtils.getStartTime(schedule),
              endTime: scheduleUtils.getEndTime(schedule),
            });
          }
        }
      });

      if (schedule.linkedSchedule != null) {
        const linkedSchedule = schedule.linkedSchedule;

        linkedSchedule.ovenPanels?.forEach((ovenPanel) => {
          const oven = ovenPanel.oven ?? ovenPanel.ovenChamber?.oven;
          const ovenGroup = oven?.ovenGroup;

          if (oven == null) return;

          if (ovenGroup != null) {
            let ovenGroupItem = ovenGroupItems.get(ovenGroup.id);

            if (ovenGroupItem == null) {
              ovenGroupItem = {
                scheduleId: linkedSchedule.id,
                recipeId: linkedSchedule.recipeId,
                cleaningId: linkedSchedule.cleaningId,
                linkedScheduleId: linkedSchedule.linkedSchedule?.id,
                ovenPanelId: ovenPanel.id,
                program:
                  linkedSchedule.recipe != null
                    ? recipeUtils.getName(linkedSchedule.recipe, i18n.language as Language)
                    : linkedSchedule.cleaning != null
                    ? getCleaningDescription(linkedSchedule.cleaning.id, t)
                    : '',
                location: ovenGroup.description ?? '',
                startTime: scheduleUtils.getStartTime(linkedSchedule),
                endTime: scheduleUtils.getEndTime(linkedSchedule),
                items: [
                  {
                    scheduleId: linkedSchedule.id,
                    ovenPanelId: ovenPanel.id,
                    location: `${t('oven')} ${oven.ovenGroupOrder}`,
                    startTime: scheduleUtils.getStartTime(linkedSchedule),
                    endTime: scheduleUtils.getEndTime(linkedSchedule),
                  },
                ],
                recurrence: scheduleUtils.getDetailedRecurrenceLabel(linkedSchedule, t),
              };

              ovenGroupItems.set(ovenGroup.id, ovenGroupItem);
            } else {
              ovenGroupItem.items.push({
                scheduleId: linkedSchedule.id,
                ovenPanelId: ovenPanel.id,
                location: `${t('oven')} ${oven.ovenGroupOrder}`,
                startTime: scheduleUtils.getStartTime(linkedSchedule),
                endTime: scheduleUtils.getEndTime(linkedSchedule),
              });
            }
          } else {
            let ovenItem = ovenItems.get(oven.id);

            if (ovenItem == null) {
              ovenItem = {
                scheduleId: linkedSchedule.id,
                recipeId: linkedSchedule.recipeId,
                cleaningId: linkedSchedule.cleaningId,
                linkedScheduleId: linkedSchedule.linkedSchedule?.id,
                ovenPanelId: linkedSchedule.id,
                program:
                  linkedSchedule.recipe != null
                    ? recipeUtils.getName(linkedSchedule.recipe, i18n.language as Language)
                    : linkedSchedule.cleaning != null
                    ? getCleaningDescription(linkedSchedule.cleaning.id, t)
                    : '',
                location: oven.description ?? oven.serialNumber,
                startTime: scheduleUtils.getStartTime(linkedSchedule),
                endTime: scheduleUtils.getEndTime(linkedSchedule),
                items: [
                  {
                    scheduleId: linkedSchedule.id,
                    ovenPanelId: ovenPanel.id,
                    location:
                      ovenPanel.ovenChamber != null
                        ? `${t('chamber')} ${ovenPanel.ovenChamber.ovenOrder}`
                        : `${t('panel')} ${ovenPanel.ovenOrder}`,
                    startTime: scheduleUtils.getStartTime(linkedSchedule),
                    endTime: scheduleUtils.getEndTime(linkedSchedule),
                  },
                ],
                recurrence: scheduleUtils.getDetailedRecurrenceLabel(linkedSchedule, t),
              };

              ovenItems.set(oven.id, ovenItem);
            } else {
              ovenItem.items.push({
                scheduleId: linkedSchedule.id,
                ovenPanelId: ovenPanel.id,
                location:
                  ovenPanel.ovenChamber != null
                    ? `${t('chamber')} ${ovenPanel.ovenChamber.ovenOrder}`
                    : `${t('panel')} ${ovenPanel.ovenOrder}`,
                startTime: scheduleUtils.getStartTime(linkedSchedule),
                endTime: scheduleUtils.getEndTime(linkedSchedule),
              });
            }
          }
        });
      }

      items.push(...Array.from(ovenGroupItems.values()));
      items.push(...Array.from(ovenItems.values()));
    });

    items.sort((itemA, itemB) => {
      if (itemA.startTime > itemB.startTime) return 1;
      if (itemA.startTime < itemB.startTime) return -1;
      return 0;
    });

    return items;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedules, props.selectedDate]);

  return (
    <Fragment>
      <Box
        sx={{height: {xs: 'calc(100vh - 464px)', md: 'calc(100vh - 424px)'}, minHeight: '256px'}}>
        <ScheduleList items={items} itemsNotFoundLabel={t('no_programs_were_found')} />
      </Box>
      <LoadingBackdrop isLoading={isLoading} />
    </Fragment>
  );
}

export default OvenSchedules;
