import {ListItem, Typography} from '@mui/material';
import {ElementType, useState} from 'react';
import Icon from './Icon';

export type MenuItemProps = {
  color?: string;
  text: string;
  showText?: boolean;
  hideText?: boolean;
  IconComponent?: ElementType<any>;
  isSelected?: boolean;
  onSelectItem?: () => void;
  disabled?: boolean;
  hidden?: boolean;
};

function MenuItem(props: MenuItemProps) {
  const {
    color,
    text,
    showText,
    hideText,
    IconComponent,
    isSelected,
    onSelectItem,
    disabled,
    hidden,
  } = props;

  const [isHovered, setIsHovered] = useState(false);

  function handleSelectItem() {
    if (!disabled && onSelectItem) {
      onSelectItem();
    }
  }

  const renderText = !hideText && (isHovered || isSelected || showText);

  return (
    <ListItem
      sx={{
        display: hidden ? 'none' : 'flex',
        justifyContent: 'flex-end',
        gap: '8px',
        width: 'fit-content',
        height: '40px',
        padding: '12px 16px',
        backgroundColor: color ?? 'custom.surfaceSecondary',
        opacity: isSelected ? 1 : 0.5,
        borderRadius: '360px',
        cursor: disabled ? undefined : 'pointer',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleSelectItem}>
      {renderText && (
        <Typography
          sx={{
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            color: 'custom.textSecondary',
          }}
          noWrap>
          {text}
        </Typography>
      )}
      {IconComponent != null && (
        <Icon IconComponent={IconComponent} color="custom.iconsSecondary" />
      )}
    </ListItem>
  );
}

export default MenuItem;
