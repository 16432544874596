import {Box, Typography} from '@mui/material';
import {ElementType, useState} from 'react';
import Icon from '../common/Icon';

type DashboardMenuItemProps = {
  icon: ElementType<any>;
  iconSize: string;
  text: string;
  onClick?: () => void;
  disabled?: boolean;
};

function DashboardMenuItem(props: DashboardMenuItemProps) {
  const {icon, iconSize, text, onClick, disabled = false} = props;

  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
        padding: '0px 8px',
        opacity: disabled ? 0.5 : 1,
        cursor: disabled ? undefined : 'pointer',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        if (!disabled) {
          onClick?.();
        }
      }}>
      <Icon IconComponent={icon} size={iconSize} color="custom.iconsBrand" />
      <Typography
        sx={{
          fontSize: '24px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '32px',
          color: disabled
            ? 'custom.textPrimary'
            : isHovered
            ? 'custom.textBrand'
            : 'custom.textPrimary',
        }}>
        {text}
      </Typography>
    </Box>
  );
}

export default DashboardMenuItem;
