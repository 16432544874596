import {Box, InputBase, styled, Typography} from '@mui/material';
import {ReactComponent as AddIcon} from '../../../../../assets/icons/add.svg';
import {ReactComponent as SubtractIcon} from '../../../../../assets/icons/subtract.svg';
import IconButton from '../../../../common/IconButton';

const InputBaseStyled = styled(InputBase)(() => ({
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
  '& input': {
    textAlign: 'center',
  },
}));

type PowerRowProps = {
  label: string;
  message: string;
  power: number;
  onChange: (power: number) => void;
};

function PowerRow(props: PowerRowProps) {
  const {label, message, power, onChange} = props;

  return (
    <Box sx={{display: 'flex', justifyContent: 'space-between', gap: '16px'}}>
      <Box>
        <Typography
          sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 500, lineHeight: '28px'}}>
          {label}
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            color: 'custom.textTertiary',
          }}>
          {message}
        </Typography>
      </Box>
      <InputBaseStyled
        sx={{
          width: '96px',
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '28px',
          '& .Mui-disabled': {
            color: 'custom.textPrimary',
            WebkitTextFillColor: (theme) => theme.palette.custom.textPrimary,
          },
        }}
        value={power}
        disabled
        startAdornment={
          power === -30 ? (
            <Box sx={{minWidth: '32px'}} />
          ) : (
            <IconButton IconComponent={SubtractIcon} onClick={() => onChange(power - 1)} />
          )
        }
        endAdornment={
          power === 30 ? (
            <Box sx={{minWidth: '32px'}} />
          ) : (
            <IconButton IconComponent={AddIcon} onClick={() => onChange(power + 1)} />
          )
        }
      />
    </Box>
  );
}

export default PowerRow;
