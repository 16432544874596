import {Box} from '@mui/material';
import {Outlet} from 'react-router-dom';

function AuthLayout() {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: {xs: 'calc(100vh - 124px)', md: 'calc(100vh - 148px)'},
        paddingBottom: '64px',
        overflow: 'auto',
      }}>
      <Outlet />
    </Box>
  );
}

export default AuthLayout;
