import {Box, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import DashboardMenu from './DashboardMenu';

function Dashboard() {
  const {t} = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        width: '100vw',
        height: {xs: 'calc(100vh - 124px)', md: 'calc(100vh - 148px)'},
        overflow: 'auto',
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '100px',
          width: {xs: '504px', md: '994px'},
          maxWidth: '80vw',
          marginBlock: 'auto',
          paddingBottom: '64px',
        }}>
        <Typography
          sx={{
            fontSize: {xs: '40px', md: '48px'},
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: {xs: '48px', md: '56px'},
            maxWidth: {md: '50%'},
          }}>
          {t('dashboard_title')}
        </Typography>
        <DashboardMenu />
      </Box>
    </Box>
  );
}

export default Dashboard;
