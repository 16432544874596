import {Box, InputBase, SvgIcon} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {ReactComponent as SearchIcon} from '../../assets/icons/search.svg';

export type SimpleSearchProps = {
  value: string;
  onChange: (value: string) => void;
};

function SimpleSearch(props: SimpleSearchProps) {
  const {t} = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: '40px',
        paddingBlock: '12px',
        paddingInline: '32px',
        backgroundColor: 'custom.surfaceQuaternary',
        borderRadius: '360px',
      }}>
      <InputBase
        sx={{
          width: '100%',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '24px',
        }}
        placeholder={t('bakeries_search_field_placeholder')}
        value={props.value}
        onChange={(event) => props.onChange(event.target.value)}
      />
      <SvgIcon sx={{fontSize: '32px'}} component={SearchIcon} inheritViewBox />
    </Box>
  );
}

export default SimpleSearch;
