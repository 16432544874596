import OvenGroup from '../../models/entities/oven-group';
import {
  CreateOvenGroupRequest,
  DeleteOvenGroupParams,
  UpdateOvenGroupParams,
  UpdateOvenGroupRequest,
} from '../../models/requests/oven-group';
import {
  CreateOvenGroupResponse,
  DeleteOvenGroupResponse,
  UpdateOvenGroupResponse,
} from '../../models/responses/oven-group';
import {ApplicationError} from '../../utils/errors';
import {OvenGroupService} from '../oven-group';
import {data} from './data';

const mockOvenGroupService: OvenGroupService = {
  createOvenGroup: function (args: {
    request: CreateOvenGroupRequest;
  }): Promise<CreateOvenGroupResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {request} = args;

        const ovenGroup: OvenGroup = {
          id: request.id,
          reference: request.reference,
          description: request.description,
          ovenModelId: request.ovenModelId,
          ovenSubModelId: request.ovenSubModelId,
          bakeryId: request.bakeryId,
          assignedAt: new Date().toISOString(),
          companyId: request.companyId,
        };

        data.ovenGroups.push(ovenGroup);

        for (const requestOven of request.ovens ?? []) {
          const oven = data.ovens.find((oven) => oven.id === requestOven.id);

          if (oven == null) {
            reject(ApplicationError.badRequest(['Oven not found.']));
            return;
          }

          oven.ovenGroupId = ovenGroup.id;
          oven.ovenGroupOrder = requestOven.ovenGroupOrder;
          oven.bakeryId = ovenGroup.bakeryId;
          oven.assignedAt = new Date().toDateString();
        }

        resolve(ovenGroup.id);
      }, 500),
    );
  },
  updateOvenGroup: function (args: {
    params: UpdateOvenGroupParams;
    request: UpdateOvenGroupRequest;
  }): Promise<UpdateOvenGroupResponse> {
    throw new Error('Function not implemented.');
  },
  deleteOvenGroup: function (args: {
    params: DeleteOvenGroupParams;
  }): Promise<DeleteOvenGroupResponse> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const {params} = args;

        const ovenGroup = data.ovenGroups.find((ovenGroup) => ovenGroup.id === params.ovenGroupId);

        if (ovenGroup == null) {
          reject(ApplicationError.notFound('Oven group'));
          return;
        }

        data.ovenGroups = data.ovenGroups.filter(
          (ovenGroup) => ovenGroup.id !== params.ovenGroupId,
        );

        resolve(params.ovenGroupId);
      }, 500),
    );
  },
};

export default mockOvenGroupService;
