import {addMinutes} from 'date-fns';
import config from '../../config';
import Bakery from '../../models/entities/bakery';
import City from '../../models/entities/city';
import Cleaning from '../../models/entities/cleaning';
import Company from '../../models/entities/company';
import Country from '../../models/entities/country';
import District from '../../models/entities/district';
import Notification from '../../models/entities/notification';
import NotificationType from '../../models/entities/notification-type';
import Oven from '../../models/entities/oven';
import OvenChamber from '../../models/entities/oven-chamber';
import OvenGroup from '../../models/entities/oven-group';
import OvenModel, {OvenModelId} from '../../models/entities/oven-model';
import OvenPanel from '../../models/entities/oven-panel';
import {OvenSubModelId} from '../../models/entities/oven-sub-model';
import Recipe from '../../models/entities/recipe';
import RecipePhase from '../../models/entities/recipe-phase';
import RecipeSchema from '../../models/entities/recipe-schema';
import RecipeType from '../../models/entities/recipe-type';
import Recurrence from '../../models/entities/recurrence';
import Schedule from '../../models/entities/schedule';
import User from '../../models/entities/user';
import UserNotificationType from '../../models/entities/user-notification-type';
import cryptoUtils from '../../utils/crypto';
import {
  compactramV1RecipePhaseSchema,
  compactramV1RecipeSchema,
  electramV1RecipePhaseSchema,
  electramV1RecipeSchema,
  modulramV1RecipePhaseSchema,
  modulramV1RecipeSchema,
  rotoramV1RecipePhaseSchema,
  rotoramV1RecipeSchema,
  turboramV1RecipePhaseSchema,
  turboramV1RecipeSchema,
} from './schemas';
import dateUtils from '../../utils/dates';

export const COMPANY_ID = 'db9c73d3-5a22-454a-8624-b02d42aa2a0d';
export const STOCK_BAKERY_ID = 'db9c73d3-5a22-454a-8624-b02d42aa2a0d';
export const BAKERY_ID = '7ba0e6ff-e152-4f98-becc-671bc8c77699';
export const COMPACTRAM_RECIPE_SCHEMA_ID = '80c88a66-a3be-4cd9-9b77-fbe4cab324a0';
export const ELECTRAM_RECIPE_SCHEMA_ID = 'd42b87af-63cc-47ab-8401-5b6257a7782d';
export const MODULRAM_RECIPE_SCHEMA_ID = '31f4c415-5e89-483d-9439-69a8c4591c1c';
export const ROTORAM_RECIPE_SCHEMA_ID = 'bdc8a08e-1cd9-4fa6-986a-97ec2c4baba9';
export const TURBORAM_RECIPE_SCHEMA_ID = 'cc683938-4cb3-4263-9a6c-70492b472d57';

export const data = {
  countries: [] as Country[],
  districts: [] as District[],
  cities: [] as City[],
  companies: [] as Company[],
  bakeries: [] as Bakery[],
  users: [] as User[],
  ovenModels: [] as OvenModel[],
  recipeSchemas: [] as RecipeSchema[],
  ovenGroups: [] as OvenGroup[],
  ovens: [] as Oven[],
  ovenChambers: [] as OvenChamber[],
  ovenPanels: [] as OvenPanel[],
  recipeTypes: [] as RecipeType[],
  recipes: [] as Recipe[],
  recipePhases: [] as RecipePhase[],
  recipeRecipeTypes: [] as {recipeId: string; recipeTypeId: string}[],
  ovenPanelRecipeTypes: [] as {ovenPanelId: string; recipeTypeId: string}[],
  ovenPanelRecipes: [] as {ovenPanelId: string; recipeId: string}[],
  cleanings: [] as Cleaning[],
  schedules: [] as Schedule[],
  recurrences: [] as Recurrence[],
  ovenPanelSchedules: [] as {ovenPanelId: string; scheduleId: string}[],
  notificationTypes: [] as NotificationType[],
  userNotificationTypes: [] as UserNotificationType[],
  notifications: [] as Notification[],
  generateData: function () {
    if (config.serviceType === 'web') {
      return;
    }

    // Countries
    this.countries.push(
      {id: 177, iso: 'PT', name: 'Portugal'},
      {id: 207, iso: 'ES', name: 'Spain'},
    );

    // Districts
    this.districts.push(
      {id: 2235, name: 'Aveiro', countryId: 177},
      {id: 2243, name: 'Porto', countryId: 177},
      {id: 1158, name: 'Madrid', countryId: 207},
      {id: 1175, name: 'Valencia', countryId: 207},
    );

    // Cities
    this.cities.push(
      {id: 89003, name: 'Aveiro', districtId: 2235, countryId: 177},
      {id: 89695, name: 'Águeda', districtId: 2235, countryId: 177},
      {id: 89425, name: 'Porto', districtId: 2243, countryId: 177},
      {id: 89270, name: 'Maia', districtId: 2243, countryId: 177},
      {id: 35186, name: 'Madrid', districtId: 1158, countryId: 207},
      {id: 35731, name: 'Navacerrada', districtId: 1158, countryId: 207},
      {id: 37816, name: 'Valencia', districtId: 1175, countryId: 207},
      {id: 33145, name: 'Calles', districtId: 1175, countryId: 207},
    );

    // Companies
    this.companies.push({id: COMPANY_ID, name: 'Feralbyte'});

    // Bakeries
    this.bakeries.push(
      {id: STOCK_BAKERY_ID, name: 'Stock', cityId: 89270, companyId: COMPANY_ID},
      {id: BAKERY_ID, name: 'Feralbyte', cityId: 89270, companyId: COMPANY_ID},
    );

    // Users
    this.users.push({
      id: '693b0d49-69ed-44e4-b0ec-7cf78a92ac52',
      name: 'teste feralbyte',
      email: 'teste@feralbyte.com',
      role: 1,
      companyId: 'db9c73d3-5a22-454a-8624-b02d42aa2a0d',
    });

    // Oven Models
    this.ovenModels.push(
      {id: OvenModelId.Compactram, description: 'Compactram'},
      {id: OvenModelId.Electram, description: 'Electram'},
      {id: OvenModelId.Modulram, description: 'Modulram'},
      {id: OvenModelId.Rotoram, description: 'Rotoram'},
      {id: OvenModelId.Turboram, description: 'Turboram'},
    );

    // Recipe Schemas
    this.recipeSchemas.push(
      {
        id: COMPACTRAM_RECIPE_SCHEMA_ID,
        version: 'v1',
        ovenModelId: OvenModelId.Compactram,
        ovenSubModelId: null,
        recipeSchema: compactramV1RecipeSchema,
        recipePhaseSchema: compactramV1RecipePhaseSchema,
      },
      {
        id: ELECTRAM_RECIPE_SCHEMA_ID,
        version: 'v1',
        ovenModelId: OvenModelId.Electram,
        ovenSubModelId: null,
        recipeSchema: electramV1RecipeSchema,
        recipePhaseSchema: electramV1RecipePhaseSchema,
      },
      {
        id: MODULRAM_RECIPE_SCHEMA_ID,
        version: 'v1',
        ovenModelId: OvenModelId.Modulram,
        ovenSubModelId: null,
        recipeSchema: modulramV1RecipeSchema,
        recipePhaseSchema: modulramV1RecipePhaseSchema,
      },
      {
        id: ROTORAM_RECIPE_SCHEMA_ID,
        version: 'v1',
        ovenModelId: OvenModelId.Rotoram,
        ovenSubModelId: null,
        recipeSchema: rotoramV1RecipeSchema,
        recipePhaseSchema: rotoramV1RecipePhaseSchema,
      },
      {
        id: TURBORAM_RECIPE_SCHEMA_ID,
        version: 'v1',
        ovenModelId: OvenModelId.Turboram,
        ovenSubModelId: null,
        recipeSchema: turboramV1RecipeSchema,
        recipePhaseSchema: turboramV1RecipePhaseSchema,
      },
    );

    // Ovens
    let ovenGroupNumber = 0;
    let ovenNumber = 0;
    let ovenChamberNumber = 0;
    let ovenPanelNumber = 0;

    // Compactram
    for (let i = 1; i <= 4; i++) {
      ovenNumber++;

      const oven: Oven = {
        id: cryptoUtils.uuid(),
        reference: '',
        serialNumber: `C0000${ovenNumber}`,
        description: i > 2 ? null : `Compactram ${ovenNumber}`,
        ovenModelId: OvenModelId.Compactram,
        ovenSubModelId: null,
        ovenGroupId: null,
        ovenGroupOrder: null,
        bakeryId: i > 2 ? STOCK_BAKERY_ID : BAKERY_ID,
        assignedAt: new Date('2024-05-01T08:00:00.000Z').toISOString(),
        companyId: COMPANY_ID,
      };

      this.ovens.push(oven);

      for (let j = 1; j <= 3; j++) {
        ovenPanelNumber++;

        const ovenPanel: OvenPanel = {
          id: cryptoUtils.uuid(),
          reference: '',
          serialNumber: `CP0000${ovenPanelNumber}`,
          ovenModelId: oven.ovenModelId,
          ovenSubModelId: oven.ovenSubModelId,
          recipeSchemaId: COMPACTRAM_RECIPE_SCHEMA_ID,
          ovenChamberId: null,
          ovenChamberOrder: null,
          ovenId: oven.id,
          ovenOrder: j,
          bakeryId: oven.bakeryId,
          companyId: oven.companyId,
          syncAt: new Date('2024-05-23T01:00:00.000Z').toISOString(),
        };

        this.ovenPanels.push(ovenPanel);
      }
    }

    // Electram
    ovenNumber = 0;
    ovenPanelNumber = 0;

    for (let i = 1; i <= 4; i++) {
      ovenNumber++;

      const oven: Oven = {
        id: cryptoUtils.uuid(),
        reference: '',
        serialNumber: `E0000${ovenNumber}`,
        description: i > 2 ? null : `Electram ${ovenNumber}`,
        ovenModelId: OvenModelId.Electram,
        ovenSubModelId: null,
        ovenGroupId: null,
        ovenGroupOrder: null,
        bakeryId: i > 2 ? STOCK_BAKERY_ID : BAKERY_ID,
        assignedAt: new Date('2024-05-01T08:00:00.000Z').toISOString(),
        companyId: COMPANY_ID,
      };

      this.ovens.push(oven);

      for (let j = 1; j <= 3; j++) {
        ovenPanelNumber++;

        const ovenPanel: OvenPanel = {
          id: cryptoUtils.uuid(),
          reference: '',
          serialNumber: `EP0000${ovenPanelNumber}`,
          ovenModelId: oven.ovenModelId,
          ovenSubModelId: oven.ovenSubModelId,
          recipeSchemaId: ELECTRAM_RECIPE_SCHEMA_ID,
          ovenChamberId: null,
          ovenChamberOrder: null,
          ovenId: oven.id,
          ovenOrder: j,
          bakeryId: oven.bakeryId,
          companyId: oven.companyId,
          syncAt: new Date('2024-05-23T01:00:00.000Z').toISOString(),
        };

        this.ovenPanels.push(ovenPanel);
      }
    }

    // Modulram
    ovenNumber = 0;
    ovenPanelNumber = 0;

    for (let i = 1; i <= 4; i++) {
      ovenNumber++;

      const oven: Oven = {
        id: cryptoUtils.uuid(),
        reference: '',
        serialNumber: `M0000${ovenNumber}`,
        description: i > 2 ? null : `Modulram ${ovenNumber}`,
        ovenModelId: OvenModelId.Modulram,
        ovenSubModelId: null,
        ovenGroupId: null,
        ovenGroupOrder: null,
        bakeryId: i > 2 ? STOCK_BAKERY_ID : BAKERY_ID,
        assignedAt: new Date('2024-05-01T08:00:00.000Z').toISOString(),
        companyId: COMPANY_ID,
      };

      this.ovens.push(oven);

      for (let j = 1; j <= 3; j++) {
        ovenChamberNumber++;
        ovenPanelNumber++;

        const ovenChamber: OvenChamber = {
          id: cryptoUtils.uuid(),
          reference: '',
          serialNumber: `MC0000${ovenChamberNumber}`,
          ovenModelId: oven.ovenModelId,
          ovenSubModelId: oven.ovenSubModelId,
          ovenId: oven.id,
          ovenOrder: j,
          assignedAt: new Date('2024-05-01T08:00:00.000Z').toISOString(),
          bakeryId: oven.bakeryId,
          companyId: oven.companyId,
        };

        const ovenPanel: OvenPanel = {
          id: cryptoUtils.uuid(),
          reference: '',
          serialNumber: `MP0000${ovenPanelNumber}`,
          ovenModelId: ovenChamber.ovenModelId,
          ovenSubModelId: ovenChamber.ovenSubModelId,
          recipeSchemaId: MODULRAM_RECIPE_SCHEMA_ID,
          ovenChamberId: ovenChamber.id,
          ovenChamberOrder: 1,
          ovenId: null,
          ovenOrder: null,
          bakeryId: ovenChamber.bakeryId,
          companyId: ovenChamber.companyId,
          syncAt: new Date('2024-05-23T01:00:00.000Z').toISOString(),
        };

        this.ovenChambers.push(ovenChamber);
        this.ovenPanels.push(ovenPanel);
      }
    }

    // Rotoram
    ovenNumber = 0;
    ovenPanelNumber = 0;

    for (let i = 1; i <= 4; i++) {
      ovenNumber++;
      ovenPanelNumber++;

      const oven: Oven = {
        id: cryptoUtils.uuid(),
        reference: '',
        serialNumber: `R0000${ovenNumber}`,
        description: i > 2 ? null : `Rotoram ${ovenNumber}`,
        ovenModelId: OvenModelId.Rotoram,
        ovenSubModelId: null,
        ovenGroupId: null,
        ovenGroupOrder: null,
        bakeryId: i > 2 ? STOCK_BAKERY_ID : BAKERY_ID,
        assignedAt: new Date('2024-05-01T08:00:00.000Z').toISOString(),
        companyId: COMPANY_ID,
      };

      const ovenPanel: OvenPanel = {
        id: cryptoUtils.uuid(),
        reference: '',
        serialNumber: `RP0000${ovenPanelNumber}`,
        ovenModelId: oven.ovenModelId,
        ovenSubModelId: oven.ovenSubModelId,
        recipeSchemaId: ROTORAM_RECIPE_SCHEMA_ID,
        ovenChamberId: null,
        ovenChamberOrder: null,
        ovenId: oven.id,
        ovenOrder: 1,
        bakeryId: oven.bakeryId,
        companyId: oven.companyId,
        syncAt: new Date('2024-05-23T01:00:00.000Z').toISOString(),
      };

      this.ovens.push(oven);
      this.ovenPanels.push(ovenPanel);
    }

    // Turboram
    ovenNumber = 0;
    ovenPanelNumber = 0;

    for (let i = 1; i <= 3; i++) {
      let ovenGroup: OvenGroup | null = null;

      if (i === 2) {
        ovenGroupNumber++;

        ovenGroup = {
          id: cryptoUtils.uuid(),
          reference: '',
          description: `Turboram Combi ${ovenGroupNumber}`,
          ovenModelId: OvenModelId.Turboram,
          ovenSubModelId: null,
          bakeryId: BAKERY_ID,
          assignedAt: new Date('2024-05-01T08:00:00.000Z').toISOString(),
          companyId: COMPANY_ID,
        };

        this.ovenGroups.push(ovenGroup);
      }

      for (let j = 1; j <= 2; j++) {
        ovenNumber++;
        ovenPanelNumber++;

        const oven: Oven = {
          id: cryptoUtils.uuid(),
          reference: '',
          serialNumber: `T0000${ovenNumber}`,
          description: j > 2 ? null : `Turboram ${ovenNumber}`,
          ovenModelId: OvenModelId.Turboram,
          ovenSubModelId: j % 2 === 0 ? OvenSubModelId.Turboram5T : OvenSubModelId.Turboram9T,
          ovenGroupId: ovenGroup?.id ?? null,
          ovenGroupOrder: ovenGroup != null ? j : null,
          bakeryId: i > 2 ? STOCK_BAKERY_ID : BAKERY_ID,
          assignedAt: new Date('2024-05-01T08:00:00.000Z').toISOString(),
          companyId: COMPANY_ID,
        };

        const ovenPanel: OvenPanel = {
          id: cryptoUtils.uuid(),
          reference: '',
          serialNumber: `TP0000${ovenPanelNumber}`,
          ovenModelId: oven.ovenModelId,
          ovenSubModelId: oven.ovenSubModelId,
          recipeSchemaId: TURBORAM_RECIPE_SCHEMA_ID,
          ovenChamberId: null,
          ovenChamberOrder: null,
          ovenId: oven.id,
          ovenOrder: 1,
          bakeryId: oven.bakeryId,
          companyId: oven.companyId,
          syncAt: new Date('2024-05-23T01:00:00.000Z').toISOString(),
        };

        this.ovens.push(oven);
        this.ovenPanels.push(ovenPanel);
      }
    }

    // Recipes
    // Compactram
    for (let i = 0; i < 2; i++) {
      const recipeType: RecipeType = {
        id: cryptoUtils.uuid(),
        description: i === 0 ? 'Padaria' : 'Pastelaria',
        descriptionEn: i === 0 ? 'Bakery' : 'Pastry',
        descriptionEs: i === 0 ? 'Panadería' : 'Pastelería',
        descriptionPt: i === 0 ? 'Padaria' : 'Pastelaria',
        descriptionFr: i === 0 ? 'Boulangerie' : 'Pâtisserie',
        descriptionPl: i === 0 ? 'Piekarnia' : 'Cukiernia',
        ovenModelId: OvenModelId.Compactram,
        ovenSubModelId: null,
        companyId: COMPANY_ID,
      };

      const recipe: Recipe = {
        id: cryptoUtils.uuid(),
        name: i === 0 ? 'Baguette' : 'Croissant',
        nameEn: i === 0 ? 'Baguette' : 'Croissant',
        nameEs: i === 0 ? 'Baguette' : 'Croissant',
        namePt: i === 0 ? 'Baguette' : 'Croissant',
        nameFr: i === 0 ? 'Baguette' : 'Croissant',
        namePl: i === 0 ? 'Baguette' : 'Croissant',
        description: i === 0 ? 'Baguette' : 'Croissant',
        procedure: '',
        ovenModelId: recipeType.ovenModelId,
        ovenSubModelId: recipeType.ovenSubModelId,
        recipeSchemaId: COMPACTRAM_RECIPE_SCHEMA_ID,
        companyId: recipeType.companyId,
        modifiedAt: new Date('2024-05-23T13:00:00.000Z').toISOString(),
      };

      this.recipeTypes.push(recipeType);
      this.recipes.push(recipe);
      this.recipeRecipeTypes.push({recipeId: recipe.id, recipeTypeId: recipeType.id});

      for (let j = 0; j < 2; j++) {
        const recipePhase: RecipePhase = {
          id: cryptoUtils.uuid(),
          index: j,
          duration: 300,
          temperature: null,
          topTemperature: 120,
          floorTemperature: 120,
          topPower: 10,
          floorPower: 10,
          steamInjectionNumber: null,
          steamInjectionDuration: 60,
          steamExitValveOpened: true,
          turbineSpeed: null,
          turbineRestSeconds: null,
          recipeId: recipe.id,
          companyId: recipe.companyId,
        };

        this.recipePhases.push(recipePhase);
      }

      const ovenPanels = this.ovenPanels.filter(
        (ovenPanel) => ovenPanel.ovenModelId === OvenModelId.Compactram,
      );

      for (const ovenPanel of ovenPanels) {
        this.ovenPanelRecipeTypes.push({ovenPanelId: ovenPanel.id, recipeTypeId: recipeType.id});
        this.ovenPanelRecipes.push({ovenPanelId: ovenPanel.id, recipeId: recipe.id});
      }
    }

    // Electram
    for (let i = 0; i < 2; i++) {
      const recipeType: RecipeType = {
        id: cryptoUtils.uuid(),
        description: i === 0 ? 'Padaria' : 'Pastelaria',
        descriptionEn: i === 0 ? 'Bakery' : 'Pastry',
        descriptionEs: i === 0 ? 'Panadería' : 'Pastelería',
        descriptionPt: i === 0 ? 'Padaria' : 'Pastelaria',
        descriptionFr: i === 0 ? 'Boulangerie' : 'Pâtisserie',
        descriptionPl: i === 0 ? 'Piekarnia' : 'Cukiernia',
        ovenModelId: OvenModelId.Electram,
        ovenSubModelId: null,
        companyId: COMPANY_ID,
      };

      const recipe: Recipe = {
        id: cryptoUtils.uuid(),
        name: i === 0 ? 'Baguette' : 'Croissant',
        nameEn: i === 0 ? 'Baguette' : 'Croissant',
        nameEs: i === 0 ? 'Baguette' : 'Croissant',
        namePt: i === 0 ? 'Baguette' : 'Croissant',
        nameFr: i === 0 ? 'Baguette' : 'Croissant',
        namePl: i === 0 ? 'Baguette' : 'Croissant',
        description: i === 0 ? 'Baguette' : 'Croissant',
        procedure: '',
        ovenModelId: recipeType.ovenModelId,
        ovenSubModelId: recipeType.ovenSubModelId,
        recipeSchemaId: ELECTRAM_RECIPE_SCHEMA_ID,
        companyId: recipeType.companyId,
        modifiedAt: new Date('2024-05-23T13:00:00.000Z').toISOString(),
      };

      this.recipeTypes.push(recipeType);
      this.recipes.push(recipe);
      this.recipeRecipeTypes.push({recipeId: recipe.id, recipeTypeId: recipeType.id});

      for (let j = 0; j < 2; j++) {
        const recipePhase: RecipePhase = {
          id: cryptoUtils.uuid(),
          index: j,
          duration: 300,
          temperature: null,
          topTemperature: 120,
          floorTemperature: 120,
          topPower: 10,
          floorPower: 10,
          steamInjectionNumber: null,
          steamInjectionDuration: 60,
          steamExitValveOpened: true,
          turbineSpeed: null,
          turbineRestSeconds: null,
          recipeId: recipe.id,
          companyId: recipe.companyId,
        };

        this.recipePhases.push(recipePhase);
      }

      const ovenPanels = this.ovenPanels.filter(
        (ovenPanel) => ovenPanel.ovenModelId === OvenModelId.Electram,
      );

      for (const ovenPanel of ovenPanels) {
        this.ovenPanelRecipeTypes.push({ovenPanelId: ovenPanel.id, recipeTypeId: recipeType.id});
        this.ovenPanelRecipes.push({ovenPanelId: ovenPanel.id, recipeId: recipe.id});
      }
    }

    // Modulram
    for (let i = 0; i < 2; i++) {
      const recipeType: RecipeType = {
        id: cryptoUtils.uuid(),
        description: i === 0 ? 'Padaria' : 'Pastelaria',
        descriptionEn: i === 0 ? 'Bakery' : 'Pastry',
        descriptionEs: i === 0 ? 'Panadería' : 'Pastelería',
        descriptionPt: i === 0 ? 'Padaria' : 'Pastelaria',
        descriptionFr: i === 0 ? 'Boulangerie' : 'Pâtisserie',
        descriptionPl: i === 0 ? 'Piekarnia' : 'Cukiernia',
        ovenModelId: OvenModelId.Modulram,
        ovenSubModelId: null,
        companyId: COMPANY_ID,
      };

      const recipe: Recipe = {
        id: cryptoUtils.uuid(),
        name: i === 0 ? 'Baguette' : 'Croissant',
        nameEn: i === 0 ? 'Baguette' : 'Croissant',
        nameEs: i === 0 ? 'Baguette' : 'Croissant',
        namePt: i === 0 ? 'Baguette' : 'Croissant',
        nameFr: i === 0 ? 'Baguette' : 'Croissant',
        namePl: i === 0 ? 'Baguette' : 'Croissant',
        description: i === 0 ? 'Baguette' : 'Croissant',
        procedure: '',
        ovenModelId: recipeType.ovenModelId,
        ovenSubModelId: recipeType.ovenSubModelId,
        recipeSchemaId: MODULRAM_RECIPE_SCHEMA_ID,
        companyId: recipeType.companyId,
        modifiedAt: new Date('2024-05-23T13:00:00.000Z').toISOString(),
      };

      this.recipeTypes.push(recipeType);
      this.recipes.push(recipe);
      this.recipeRecipeTypes.push({recipeId: recipe.id, recipeTypeId: recipeType.id});

      for (let j = 0; j < 2; j++) {
        const recipePhase: RecipePhase = {
          id: cryptoUtils.uuid(),
          index: j,
          duration: 300,
          temperature: null,
          topTemperature: 120,
          floorTemperature: 120,
          topPower: 10,
          floorPower: 10,
          steamInjectionNumber: null,
          steamInjectionDuration: 60,
          steamExitValveOpened: true,
          turbineSpeed: null,
          turbineRestSeconds: null,
          recipeId: recipe.id,
          companyId: recipe.companyId,
        };

        this.recipePhases.push(recipePhase);
      }

      const ovenPanels = this.ovenPanels.filter(
        (ovenPanel) => ovenPanel.ovenModelId === OvenModelId.Modulram,
      );

      for (const ovenPanel of ovenPanels) {
        this.ovenPanelRecipeTypes.push({ovenPanelId: ovenPanel.id, recipeTypeId: recipeType.id});
        this.ovenPanelRecipes.push({ovenPanelId: ovenPanel.id, recipeId: recipe.id});
      }
    }

    // Rotoram
    for (let i = 0; i < 2; i++) {
      const recipeType: RecipeType = {
        id: cryptoUtils.uuid(),
        description: i === 0 ? 'Padaria' : 'Pastelaria',
        descriptionEn: i === 0 ? 'Bakery' : 'Pastry',
        descriptionEs: i === 0 ? 'Panadería' : 'Pastelería',
        descriptionPt: i === 0 ? 'Padaria' : 'Pastelaria',
        descriptionFr: i === 0 ? 'Boulangerie' : 'Pâtisserie',
        descriptionPl: i === 0 ? 'Piekarnia' : 'Cukiernia',
        ovenModelId: OvenModelId.Rotoram,
        ovenSubModelId: null,
        companyId: COMPANY_ID,
      };

      const recipe: Recipe = {
        id: cryptoUtils.uuid(),
        name: i === 0 ? 'Baguette' : 'Croissant',
        nameEn: i === 0 ? 'Baguette' : 'Croissant',
        nameEs: i === 0 ? 'Baguette' : 'Croissant',
        namePt: i === 0 ? 'Baguette' : 'Croissant',
        nameFr: i === 0 ? 'Baguette' : 'Croissant',
        namePl: i === 0 ? 'Baguette' : 'Croissant',
        description: i === 0 ? 'Baguette' : 'Croissant',
        procedure: '',
        ovenModelId: recipeType.ovenModelId,
        ovenSubModelId: recipeType.ovenSubModelId,
        recipeSchemaId: ROTORAM_RECIPE_SCHEMA_ID,
        companyId: recipeType.companyId,
        modifiedAt: new Date('2024-05-23T13:00:00.000Z').toISOString(),
      };

      this.recipeTypes.push(recipeType);
      this.recipes.push(recipe);
      this.recipeRecipeTypes.push({recipeId: recipe.id, recipeTypeId: recipeType.id});

      for (let j = 0; j < 2; j++) {
        const recipePhase: RecipePhase = {
          id: cryptoUtils.uuid(),
          index: j,
          duration: 300,
          temperature: 180,
          topTemperature: null,
          floorTemperature: null,
          topPower: null,
          floorPower: null,
          steamInjectionNumber: 1,
          steamInjectionDuration: null,
          steamExitValveOpened: true,
          turbineSpeed: null,
          turbineRestSeconds: 30,
          recipeId: recipe.id,
          companyId: recipe.companyId,
        };

        this.recipePhases.push(recipePhase);
      }

      const ovenPanels = this.ovenPanels.filter(
        (ovenPanel) => ovenPanel.ovenModelId === OvenModelId.Rotoram,
      );

      for (const ovenPanel of ovenPanels) {
        this.ovenPanelRecipeTypes.push({ovenPanelId: ovenPanel.id, recipeTypeId: recipeType.id});
        this.ovenPanelRecipes.push({ovenPanelId: ovenPanel.id, recipeId: recipe.id});
      }
    }

    // Turboram
    for (let i = 0; i < 2; i++) {
      const recipeType: RecipeType = {
        id: cryptoUtils.uuid(),
        description: i === 0 ? 'Padaria' : 'Pastelaria',
        descriptionEn: i === 0 ? 'Bakery' : 'Pastry',
        descriptionEs: i === 0 ? 'Panadería' : 'Pastelería',
        descriptionPt: i === 0 ? 'Padaria' : 'Pastelaria',
        descriptionFr: i === 0 ? 'Boulangerie' : 'Pâtisserie',
        descriptionPl: i === 0 ? 'Piekarnia' : 'Cukiernia',
        ovenModelId: OvenModelId.Turboram,
        ovenSubModelId: null,
        companyId: COMPANY_ID,
      };

      const recipe: Recipe = {
        id: cryptoUtils.uuid(),
        name: i === 0 ? 'Baguette' : 'Croissant',
        nameEn: i === 0 ? 'Baguette' : 'Croissant',
        nameEs: i === 0 ? 'Baguette' : 'Croissant',
        namePt: i === 0 ? 'Baguette' : 'Croissant',
        nameFr: i === 0 ? 'Baguette' : 'Croissant',
        namePl: i === 0 ? 'Baguette' : 'Croissant',
        description: i === 0 ? 'Baguette' : 'Croissant',
        procedure: '',
        ovenModelId: recipeType.ovenModelId,
        ovenSubModelId: recipeType.ovenSubModelId,
        recipeSchemaId: TURBORAM_RECIPE_SCHEMA_ID,
        companyId: recipeType.companyId,
        modifiedAt: new Date('2024-05-23T13:00:00.000Z').toISOString(),
      };

      this.recipeTypes.push(recipeType);
      this.recipes.push(recipe);
      this.recipeRecipeTypes.push({recipeId: recipe.id, recipeTypeId: recipeType.id});

      for (let j = 0; j < 2; j++) {
        const recipePhase: RecipePhase = {
          id: cryptoUtils.uuid(),
          index: j,
          duration: 300,
          temperature: 180,
          topTemperature: null,
          floorTemperature: null,
          topPower: null,
          floorPower: null,
          steamInjectionNumber: 1,
          steamInjectionDuration: null,
          steamExitValveOpened: true,
          turbineSpeed: 2,
          turbineRestSeconds: null,
          recipeId: recipe.id,
          companyId: recipe.companyId,
        };

        this.recipePhases.push(recipePhase);
      }

      const ovenPanels = this.ovenPanels.filter(
        (ovenPanel) => ovenPanel.ovenModelId === OvenModelId.Turboram,
      );

      for (const ovenPanel of ovenPanels) {
        this.ovenPanelRecipeTypes.push({ovenPanelId: ovenPanel.id, recipeTypeId: recipeType.id});
        this.ovenPanelRecipes.push({ovenPanelId: ovenPanel.id, recipeId: recipe.id});
      }
    }

    // Cleanings
    this.cleanings.push(
      {
        id: 1,
        description: 'Light',
        duration: 2820,
        temperature: 70,
        waterQuantity: 20.5,
        ovenModelId: OvenModelId.Turboram,
        ovenSubModelId: null,
      },
      {
        id: 2,
        description: 'Medium',
        duration: 4500,
        temperature: 80,
        waterQuantity: 29.1,
        ovenModelId: OvenModelId.Turboram,
        ovenSubModelId: null,
      },
      {
        id: 3,
        description: 'Intense',
        duration: 6900,
        temperature: 90,
        waterQuantity: 46.3,
        ovenModelId: OvenModelId.Turboram,
        ovenSubModelId: null,
      },
      {
        id: 4,
        description: 'Manual',
        duration: 3480,
        temperature: 80,
        waterQuantity: 30.6,
        ovenModelId: OvenModelId.Turboram,
        ovenSubModelId: null,
      },
    );

    // Schedules
    for (const ovenModel of this.ovenModels) {
      const ovenModelRecipes = this.recipes.filter((recipe) => recipe.ovenModelId === ovenModel.id);
      const ovenModelOvenPanels = this.ovenPanels.filter(
        (ovenPanel) =>
          ovenPanel.ovenModelId === ovenModel.id && ovenPanel.bakeryId !== STOCK_BAKERY_ID,
      );

      for (const recipe of ovenModelRecipes) {
        const schedule: Schedule = {
          id: cryptoUtils.uuid(),
          recipeId: recipe.id,
          cleaningId: null,
          companyId: COMPANY_ID,
        };

        this.schedules.push(schedule);

        for (let weekDay = 0; weekDay < 5; weekDay++) {
          const recurrence: Recurrence = {
            id: cryptoUtils.uuid(),
            startDate: dateUtils.previousDateOfDay(new Date(), weekDay as Day).toISOString(),
            startTime: recipe.name === 'Baguette' ? '09:00:00' : '09:30:00',
            weekDay: weekDay as Day,
            repeat: true,
            scheduleId: schedule.id,
            companyId: schedule.companyId,
          };

          this.recurrences.push(recurrence);
        }

        for (const ovenPanel of ovenModelOvenPanels) {
          this.ovenPanelSchedules.push({ovenPanelId: ovenPanel.id, scheduleId: schedule.id});
        }
      }
    }

    // Notification Types
    this.notificationTypes.push(
      {
        id: 1,
        description: 'Oven panel has been turned on.',
        ovenModelId: null,
        ovenSubModelId: null,
      },
      {
        id: 2,
        description: 'Oven panel has been turned off.',
        ovenModelId: null,
        ovenSubModelId: null,
      },
      {
        id: 3,
        description: 'Oven panel has started a recipe.',
        ovenModelId: null,
        ovenSubModelId: null,
      },
      {
        id: 4,
        description: 'Oven panel has finished a recipe.',
        ovenModelId: null,
        ovenSubModelId: null,
      },
      {
        id: 5,
        description: 'Oven panel has started a washing cycle.',
        ovenModelId: null,
        ovenSubModelId: null,
      },
      {
        id: 6,
        description: 'Oven panel has finished a washing cycle.',
        ovenModelId: null,
        ovenSubModelId: null,
      },
      {
        id: 7,
        description: 'Oven panel has started a sync in process.',
        ovenModelId: null,
        ovenSubModelId: null,
      },
      {
        id: 8,
        description: 'Oven panel has finished a sync in process.',
        ovenModelId: null,
        ovenSubModelId: null,
      },
      {
        id: 9,
        description: 'Oven panel has failed a sync in process.',
        ovenModelId: null,
        ovenSubModelId: null,
      },
      {
        id: 10,
        description: 'Oven panel has started an update.',
        ovenModelId: null,
        ovenSubModelId: null,
      },
      {
        id: 11,
        description: 'Oven panel has finished an update.',
        ovenModelId: null,
        ovenSubModelId: null,
      },
      {
        id: 12,
        description: 'Oven panel has failed an update.',
        ovenModelId: null,
        ovenSubModelId: null,
      },
      {
        id: 13,
        description: 'Oven panel has started overheating.',
        ovenModelId: null,
        ovenSubModelId: null,
      },
      {
        id: 14,
        description: 'Oven panel has stopped overheating.',
        ovenModelId: null,
        ovenSubModelId: null,
      },
      {
        id: 15,
        description: 'Oven panel has started a turbine overheating.',
        ovenModelId: null,
        ovenSubModelId: null,
      },
      {
        id: 16,
        description: 'Oven panel has stopped a turbine overheating.',
        ovenModelId: null,
        ovenSubModelId: null,
      },
      {
        id: 17,
        description: 'Oven panel has started a power failure.',
        ovenModelId: null,
        ovenSubModelId: null,
      },
      {
        id: 18,
        description: 'Oven panel has stopped a power failure.',
        ovenModelId: null,
        ovenSubModelId: null,
      },
      {
        id: 19,
        description: 'Oven panel settings has been changed.',
        ovenModelId: null,
        ovenSubModelId: null,
      },
    );

    // Notifications
    let offset = 1000;
    for (const ovenPanel of this.ovenPanels.filter(
      (ovenPanel) => ovenPanel.bakeryId !== STOCK_BAKERY_ID,
    )) {
      const ovenChamber = this.ovenChambers.find(
        (ovenChamber) => ovenChamber.id === ovenPanel.ovenChamberId,
      );
      const oven = this.ovens.find(
        (oven) => oven.id === ovenChamber?.ovenId || oven.id === ovenPanel.ovenId,
      );
      const ovenGroup = this.ovenGroups.find((ovenGroup) => ovenGroup.id === oven?.ovenGroupId);
      const bakery = this.bakeries.find((bakery) => bakery.id === ovenPanel.bakeryId);

      this.notifications.push({
        id: cryptoUtils.uuid(),
        body: null,
        notificationTypeId: 1,
        ovenPanelId: ovenPanel.id,
        ovenChamberId: ovenChamber?.id ?? null,
        ovenId: oven?.id ?? null,
        ovenGroupId: ovenGroup?.id ?? null,
        bakeryId: ovenPanel.bakeryId,
        companyId: ovenPanel.companyId,
        userId: null,
        isSeen: false,
        isFlagged: false,
        notificationTypeDescription: 'Oven panel has been turned on.',
        ovenPanelOrder: ovenPanel.ovenChamberOrder ?? ovenPanel.ovenOrder ?? null,
        ovenChamberOrder: ovenChamber?.ovenOrder ?? null,
        ovenDescription: oven?.description ?? null,
        ovenOrder: oven?.ovenGroupOrder ?? null,
        ovenGroupDescription: ovenGroup?.description ?? null,
        bakeryName: bakery?.name ?? null,
        userName: null,
        createdAt: addMinutes(new Date(), --offset).toISOString(),
      });
    }
  },
};

data.generateData();
