import {Box, List, ListItem, Typography} from '@mui/material';
import {Fragment, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation, useQueryClient} from 'react-query';
import {useNavigate} from 'react-router-dom';
import {ReactComponent as EditIcon} from '../../assets/icons/edit.svg';
import {ReactComponent as LogoutIcon} from '../../assets/icons/logout.svg';
import {subscriptionToken} from '../../firebase/app';
import useUser from '../../hooks/common/use-user';
import paths from '../../routes/paths';
import services from '../../services/provider';
import {clearStores} from '../../state/auth';
import localStorageUtils from '../../utils/local-storage';
import stringUtils from '../../utils/strings';
import ConfirmPopover from '../common/ConfirmPopover';
import Icon from '../common/Icon';
import IconButton from '../common/IconButton';
import LoadingBackdrop from '../common/LoadingBackdrop';

function UpdateNameContainer({
  defaultName,
  onUpdateName,
}: {
  defaultName: string;
  onUpdateName: (name: string) => void;
}) {
  const {t} = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [name, setName] = useState(defaultName);

  const containerRef = useRef<HTMLDivElement>(null);

  function handleUpdateName() {
    if (!stringUtils.isNullOrWhiteSpace(name) && name !== defaultName) {
      onUpdateName(name);
    }
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);

  return (
    <Box
      ref={containerRef}
      sx={{display: 'flex', justifyContent: 'center', width: {xs: '60px', sm: '120px'}}}>
      <IconButton
        sx={{zIndex: open ? 1500 : undefined}}
        IconComponent={EditIcon}
        isActive={open}
        onClick={() => {
          setName(defaultName);
          setAnchorEl(open ? null : containerRef.current);
        }}
      />
      <ConfirmPopover
        isOpen={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placeHolder={t('recipe_add_recipe_type_label')}
        value={name}
        onChange={setName}
        onAccept={handleUpdateName}
        onDecline={() => {
          setName(defaultName);
          setAnchorEl(null);
        }}
      />
    </Box>
  );
}

function InformationList() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {t} = useTranslation();

  const {user} = useUser();

  const {mutate: updateUser, isLoading} = useMutation({
    mutationFn: services.user.updateUser,
    onSuccess: (_, {request: user}) => {
      queryClient.setQueryData(['user'], user);
    },
  });

  function handleUpdateName(name: string) {
    if (user != null) {
      updateUser({params: {userId: user.id}, request: {...user, name}});
    }
  }

  function handleLogout() {
    services.subscription
      .unsubscribe({request: {token: subscriptionToken}})
      .then(() => console.log('unsubscribed from push notifications'))
      .catch(() => console.log('could not unsubscribe from push notifications'))
      .finally(() => {
        localStorageUtils.removeValue('token');
        services.base.removeAuthorizationHeader();
        queryClient.clear();
        clearStores();
      });
  }

  return (
    <Fragment>
      <List sx={{display: 'flex', flexDirection: 'column', gap: '32px', margin: 0, padding: 0}}>
        <ListItem sx={{margin: 0, padding: 0, gap: '32px', paddingLeft: '32px'}}>
          <Typography
            sx={{
              flex: 1,
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '28px',
            }}>
            {t('profile_information_name_label')}
          </Typography>
          <Typography
            sx={{
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '248px',
            }}>
            {user?.name ?? ''}
          </Typography>
          <UpdateNameContainer defaultName={user?.name ?? ''} onUpdateName={handleUpdateName} />
        </ListItem>
        <ListItem sx={{margin: 0, padding: 0, gap: '32px', paddingLeft: '32px'}}>
          <Typography
            sx={{
              flex: 1,
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '28px',
            }}>
            {t('profile_information_email_label')}
          </Typography>
          <Typography
            sx={{
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '248px',
            }}>
            {user?.email}
          </Typography>
          <Box sx={{width: {xs: '60px', sm: '120px'}}} />
        </ListItem>
        <ListItem sx={{margin: 0, padding: 0, gap: '32px', paddingLeft: '32px'}}>
          <Typography
            sx={{
              flex: 1,
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '28px',
            }}>
            {t('profile_information_password_label')}
          </Typography>
          <Typography
            sx={{fontSize: '16px', fontStyle: 'normal', fontWeight: 400, lineHeight: '24px'}}>
            *********
          </Typography>
          <Box sx={{display: 'flex', justifyContent: 'center', width: {xs: '60px', sm: '120px'}}}>
            <IconButton
              IconComponent={EditIcon}
              onClick={() => navigate(paths.profileChangePassword)}
            />
          </Box>
        </ListItem>
        <ListItem sx={{margin: 0, padding: 0, gap: '32px'}}>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              height: '40px',
              paddingBlock: '12px',
              paddingInline: '32px',
              backgroundColor: 'custom.surfaceBrand',
              borderRadius: '360px',
              cursor: 'pointer',
            }}
            onClick={handleLogout}>
            <Typography
              sx={{
                width: '100%',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 'normal',
                color: 'custom.textSecondary',
              }}>
              {t('profile_information_logout_label')}
            </Typography>
            <Icon IconComponent={LogoutIcon} size="32px" color="custom.iconsSecondary" />
          </Box>
          <Box sx={{width: {xs: '28px', sm: '88px'}}} />
        </ListItem>
      </List>
      <LoadingBackdrop isLoading={isLoading} />
    </Fragment>
  );
}

export default InformationList;
