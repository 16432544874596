import {Box, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {OvenModelId} from '../../../../models/entities/oven-model';
import GradientOverflow from '../../../common/GradientOverflow';
import {AddOvenData, AddOvenUiState} from '../AddOven';

export type SubModelStepProps = {
  setUiState: (uiState: AddOvenUiState) => void;
  addOvenData: AddOvenData;
  setAddOvenData: (setter: (addOvenData: AddOvenData) => AddOvenData) => void;
};

function SubModelStep(props: SubModelStepProps) {
  const {setUiState, addOvenData, setAddOvenData} = props;
  const {t} = useTranslation();

  function handleSelectOvenSubModel(ovenSubModel: 'single' | 'combi' | 'xl') {
    setAddOvenData(() => ({
      ovenModelId: OvenModelId.Turboram,
      ovenSubModel,
      serialNumber: '',
      topOvenSerialNumber: '',
      bottomOvenSerialNumber: '',
      ovenChambersNumber: 0,
      ovenChamberSerialNumbers: [],
      description: '',
    }));

    setUiState({
      visibleSteps: ['model', 'sub-model'],
      isNextButtonVisible: true,
      currentStep: 'sub-model',
      nextStep: 'serial-number',
    });
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px', paddingInline: '32px'}}>
      <Typography sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 500, lineHeight: '28px'}}>
        {t('add_oven_select_sub_model_label')}
      </Typography>
      <Box
        sx={{height: {xs: 'calc(100vh - 408px)', md: 'calc(100vh - 368px)'}, minHeight: '256px'}}>
        <GradientOverflow hideScrollbar>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px'}}>
            {[
              {id: 'single', label: 'single'},
              {id: 'combi', label: 'combi'},
              {id: 'xl', label: 'xl'},
            ].map((item) => (
              <Box
                key={item.id}
                sx={{
                  fontWeight: item.id === addOvenData.ovenSubModel ? 500 : 400,
                  color:
                    item.id === addOvenData.ovenSubModel
                      ? 'custom.textBrand'
                      : 'custom.textPrimary',
                  cursor: 'pointer',
                  '&:hover': {
                    fontWeight: 500,
                    color: 'custom.textBrand',
                  },
                }}
                onClick={() => handleSelectOvenSubModel(item.id as 'single' | 'combi' | 'xl')}>
                <Typography
                  sx={{
                    fontSize: '20px',
                    fontStyle: 'normal',
                    fontWeight: 'inherit',
                    lineHeight: '28px',
                    color: 'inherit',
                  }}>
                  {item.label}
                </Typography>
              </Box>
            ))}
          </Box>
        </GradientOverflow>
      </Box>
    </Box>
  );
}

export default SubModelStep;
