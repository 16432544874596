import {Box, Typography} from '@mui/material';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation} from 'react-query';
import {useNavigate} from 'react-router-dom';
import {queryClient} from '../../App';
import {subscriptionToken} from '../../firebase/app';
import useSplashScreen from '../../hooks/common/use-splash-screen';
import paths from '../../routes/paths';
import services from '../../services/provider';
import useAuthStore from '../../state/auth';
import cryptoUtils from '../../utils/crypto';
import errorUtils, {ApplicationErrorCode} from '../../utils/errors';
import stringUtils from '../../utils/strings';
import {Button} from '../common/Button';
import {Input} from '../common/Input';
import LoadingBackdrop from '../common/LoadingBackdrop';

function Signup() {
  const navigate = useNavigate();
  const {splash} = useSplashScreen();
  const {t} = useTranslation();

  const setAccessToken = useAuthStore((state) => state.setAccessToken);

  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [invalidEmail, setInvalidEmail] = useState(false);

  const {mutate: signup, isLoading} = useMutation({
    mutationFn: services.auth.signup,
    onSuccess: (result) => {
      services.base.setAuthorizationHeader(`Bearer ${result.token}`);

      setAccessToken(result.token);

      queryClient.setQueryData(['user'], result.user);

      services.subscription
        .subscribe({request: {token: subscriptionToken}})
        .then(() => console.log('subscribed to push notifications'))
        .catch(() => console.log('could not subscribe to push notifications'));

      splash({
        title: t('splash_screen_create_bakery_title'),
        caption: t('splash_screen_create_bakery_caption'),
        nextAction: () => navigate(`${paths.bakeriesCreate}?fromSignup=true`),
      });
    },
    onError: (error) =>
      errorUtils.handleMatch(
        error,
        [ApplicationErrorCode.InvalidEmailOrPassword, ApplicationErrorCode.InvalidInviteCode],
        () => setInvalidEmail(true),
      ),
  });

  function handleSignup() {
    signup({request: {name, code, email, password: cryptoUtils.sha256(password), language: 'pt'}});
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: {xs: '504px', md: '790px'},
        maxWidth: '80vw',
        height: '544px',
      }}>
      <Box>
        <Typography
          sx={{fontSize: '32px', fontStyle: 'normal', fontWeight: 400, lineHeight: '40px'}}>
          {t('signup_title')}
        </Typography>
        {invalidEmail && (
          <Typography
            sx={{
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
              color: 'custom.textBrand',
            }}>
            {t('signup_invalid_email_or_code_label')}
          </Typography>
        )}
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: '48px'}}>
        <Input
          label={t('signup_name_input_label')}
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <Input
          label={t('signup_code_input_label')}
          value={code}
          onChange={(event) => setCode(event.target.value)}
        />
        <Input
          type="email"
          label={t('signup_email_input_label')}
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        <Input
          type="password"
          label={t('signup_password_input_label')}
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          onKeyUp={(event) => (event.key === 'Enter' ? handleSignup() : null)}
        />
      </Box>
      <Button
        onClick={handleSignup}
        disabled={stringUtils.anyIsNullOrWhiteSpace(name, code, email, password)}>
        {t('signup_next_button_label')}
      </Button>
      <LoadingBackdrop isLoading={isLoading} />
    </Box>
  );
}

export default Signup;
