import {Box, List, ListItem, SxProps, Typography} from '@mui/material';
import {Fragment, forwardRef, useRef} from 'react';
import useEffectOnce from '../../hooks/common/use-effect-once';
import useSearchableScroll from '../../hooks/common/use-searchable-scroll';

export type SimpleListOption = string | {id: string; primary: string; secondary?: string};

type SimpleListItemProps = {
  typographySx?: SxProps;
  option: SimpleListOption;
  onClick?: () => void;
  isSelected?: boolean;
  disableSelectedStyle?: boolean;
};

const SimpleListItem = forwardRef<HTMLLIElement, SimpleListItemProps>((props, ref) => {
  const {typographySx, option, onClick, isSelected, disableSelectedStyle} = props;

  return (
    <ListItem
      ref={ref}
      sx={{
        margin: 0,
        padding: 0,
        gap: '16px',
        cursor: 'pointer',
        '&.Mui-selected': {
          backgroundColor: 'inherit',
        },
        '&:hover': {
          color: 'custom.textBrand',
        },
      }}
      onClick={onClick}>
      {typeof option === 'string' ? (
        <Typography
          sx={{
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '28px',
            color: disableSelectedStyle ? 'inherit' : isSelected ? 'custom.textBrand' : 'inherit',
            ...typographySx,
          }}>
          {option}
        </Typography>
      ) : (
        <Fragment>
          <Typography
            sx={{
              flex: 1,
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '28px',
              color: disableSelectedStyle ? 'inherit' : isSelected ? 'custom.textBrand' : 'inherit',
              ...typographySx,
            }}>
            {option.primary}
          </Typography>
          {option.secondary && (
            <Typography
              sx={{
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '28px',
                color: disableSelectedStyle
                  ? 'inherit'
                  : isSelected
                  ? 'custom.textBrand'
                  : 'inherit',
                ...typographySx,
              }}>
              {option.secondary}
            </Typography>
          )}
        </Fragment>
      )}
    </ListItem>
  );
});

export type SearchableSimpleListProps = SimpleListProps & {
  onSearchOption?: (option: SimpleListOption, searchText: string) => boolean;
};

export function SearchableSimpleList(props: SearchableSimpleListProps) {
  const {typographySx, options, selectedOption, onChangeSelectedOption, disableSelectedStyle} =
    props;

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffectOnce(() => {
    containerRef.current?.focus();
  });

  const {refs, handleKeyDown} = useSearchableScroll<SimpleListOption, HTMLLIElement>({
    items: options,
    searchPredicate: (option, searchText) => {
      if (typeof option === 'string') {
        return option.toLowerCase().startsWith(searchText.toLowerCase());
      }
      return option.primary.toLowerCase().startsWith(searchText.toLowerCase());
    },
  });

  function handleChangeSelectedOption(option: SimpleListOption) {
    if (typeof option === 'string') {
      onChangeSelectedOption?.(option);
      return;
    }
    onChangeSelectedOption?.(option.id);
  }

  return (
    <Box
      ref={containerRef}
      sx={{'&:focus': {outline: 'none'}}}
      tabIndex={0}
      onKeyDown={handleKeyDown}>
      <List sx={{display: 'flex', flexDirection: 'column', gap: '24px', margin: 0, padding: 0}}>
        {options.map((option, index) => {
          const isSelected = (typeof option === 'string' ? option : option.id) === selectedOption;
          return (
            <SimpleListItem
              key={index}
              ref={refs[index]}
              typographySx={typographySx}
              option={option}
              isSelected={isSelected}
              disableSelectedStyle={disableSelectedStyle}
              onClick={() => handleChangeSelectedOption(option)}
            />
          );
        })}
      </List>
    </Box>
  );
}

export type SimpleListProps = {
  typographySx?: SxProps;
  options: SimpleListOption[];
  selectedOption?: string;
  onChangeSelectedOption?: (selectedOption: string) => void;
  disableSelectedStyle?: boolean;
  entriesNotFoundMessage?: string;
};

function SimpleList(props: SimpleListProps) {
  const {
    typographySx,
    options,
    selectedOption,
    onChangeSelectedOption,
    disableSelectedStyle,
    entriesNotFoundMessage,
  } = props;

  function handleChangeSelectedOption(option: SimpleListOption) {
    if (typeof option === 'string') {
      onChangeSelectedOption?.(option);
      return;
    }
    onChangeSelectedOption?.(option.id);
  }

  return (
    <List sx={{display: 'flex', flexDirection: 'column', gap: '24px', margin: 0, padding: 0}}>
      {options.length === 0 && (
        <ListItem sx={{margin: 0, padding: 0}}>
          <Typography
            sx={{
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '28px',
              ...typographySx,
            }}>
            {entriesNotFoundMessage}
          </Typography>
        </ListItem>
      )}
      {options.map((option, index) => {
        const selected = (typeof option === 'string' ? option : option.id) === selectedOption;
        return (
          <SimpleListItem
            key={index}
            typographySx={typographySx}
            option={option}
            isSelected={selected}
            disableSelectedStyle={disableSelectedStyle}
            onClick={() => handleChangeSelectedOption(option)}
          />
        );
      })}
    </List>
  );
}

export default SimpleList;
