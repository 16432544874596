import {Box, Typography} from '@mui/material';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation} from 'react-query';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {queryClient} from '../../App';
import {subscriptionToken} from '../../firebase/app';
import useLanguage from '../../hooks/common/use-language';
import useSplashScreen from '../../hooks/common/use-splash-screen';
import paths from '../../routes/paths';
import services from '../../services/provider';
import useAuthStore from '../../state/auth';
import cryptoUtils from '../../utils/crypto';
import errorUtils, {ApplicationErrorCode} from '../../utils/errors';
import localStorageUtils from '../../utils/local-storage';
import stringUtils from '../../utils/strings';
import {Button} from '../common/Button';
import {Input} from '../common/Input';
import LoadingBackdrop from '../common/LoadingBackdrop';

function PasswordReset() {
  const navigate = useNavigate();
  const {splash} = useSplashScreen();
  const [searchParams] = useSearchParams();
  const {t} = useTranslation();

  const setAccessToken = useAuthStore((state) => state.setAccessToken);

  const [, setLanguage] = useLanguage();

  const [recoveryCode, setRecoveryCode] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const [invalidCredentials, setInvalidCredentials] = useState(false);

  const {mutate: resetPassword, isLoading} = useMutation({
    mutationFn: services.auth.resetPassword,
    onSuccess: (result) => {
      services.base.setAuthorizationHeader(`Bearer ${result.token}`);

      const rememberMe = localStorageUtils.getValue('rememberme', false);

      if (rememberMe) {
        localStorageUtils.setValue('token', result.token);
      }

      setAccessToken(result.token);

      queryClient.setQueryData(['user'], result.user);

      services.subscription
        .subscribe({request: {token: subscriptionToken}})
        .then(() => console.log('subscribed to push notifications'))
        .catch(() => console.log('could not subscribe to push notifications'));

      const language = localStorageUtils.getValue('language', 'pt');

      setLanguage(result.user.language ?? language);

      splash({
        title: t('splash_screen_password_recovered_title'),
        caption: t('splash_screen_password_recovered_caption'),
        nextAction: () => navigate(paths.dashboard),
      });
    },
    onError: (error) =>
      errorUtils.handleMatch(error, [ApplicationErrorCode.InvalidEmailOrRecoveryCode], () =>
        setInvalidCredentials(true),
      ),
  });

  function handleResetPassword() {
    const email = searchParams.get('email') ?? '';
    resetPassword({request: {email, recoveryCode, newPassword: cryptoUtils.sha256(newPassword)}});
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '72px',
        width: {xs: '504px', md: '790px'},
        maxWidth: '80vw',
      }}>
      <Box>
        <Typography
          sx={{fontSize: '32px', fontStyle: 'normal', fontWeight: 400, lineHeight: '40px'}}>
          {t('password_recovery_login_title')}
        </Typography>
        {invalidCredentials && (
          <Typography
            sx={{
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
              custom: 'custom.textBrand',
            }}>
            {t('password_recovery_login_invalid_email_or_code_label')}
          </Typography>
        )}
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: '48px'}}>
        <Input
          label={t('password_recovery_login_code_input_label')}
          value={recoveryCode}
          onChange={(event) => setRecoveryCode(event.target.value)}
        />
        <Input
          type="password"
          label={t('password_recovery_login_new_password_input_label')}
          value={newPassword}
          onChange={(event) => setNewPassword(event.target.value)}
          onKeyUp={(event) => (event.key === 'Enter' ? handleResetPassword() : null)}
        />
      </Box>
      <Button
        onClick={handleResetPassword}
        disabled={stringUtils.anyIsNullOrWhiteSpace(recoveryCode, newPassword)}>
        {t('password_recovery_login_login_button_label')}
      </Button>
      <LoadingBackdrop isLoading={isLoading} />
    </Box>
  );
}

export default PasswordReset;
