import {Box, Typography} from '@mui/material';
import {endOfMonth, format, isSameDay, isValid, parse, startOfMonth} from 'date-fns';
import {TFunction} from 'i18next';
import {Fragment, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {ReactComponent as AddIcon} from '../../assets/icons/add.svg';
import useUser from '../../hooks/common/use-user';
import {CleaningId} from '../../models/entities/cleaning';
import {OvenModelId} from '../../models/entities/oven-model';
import Schedule from '../../models/entities/schedule';
import paths from '../../routes/paths';
import services from '../../services/provider';
import useBreadcrumbsStore, {Breadcrumb} from '../../state/breadcrumbs';
import arrayUtils from '../../utils/arrays';
import countryUtils from '../../utils/countries';
import numberUtils from '../../utils/numbers';
import recipeUtils from '../../utils/recipes';
import scheduleUtils from '../../utils/schedules';
import {Language} from '../../utils/types';
import ActionBanner from '../common/ActionBanner';
import Icon from '../common/Icon';
import LoadingBackdrop from '../common/LoadingBackdrop';
import BakeryList from './BakeryList';
import CityList from './CityList';
import CountryTabs from './CountryTabs';
import DateSelector from './DateSelector';
import OvenModelList from './OvenModelList';
import ScheduleList, {ScheduleListItemProps} from './ScheduleList';
import SchedulesMenu from './SchedulesMenu';
import SearchField from './SearchField';

function getCleaningDescription(cleaningId: number, t: TFunction) {
  switch (cleaningId) {
    case CleaningId.Soft:
      return t('soft_wash_cycle');
    case CleaningId.Medium:
      return t('medium_wash_cycle');
    case CleaningId.High:
      return t('high_wash_cycle');
    default:
      return t('manual_wash_cycle');
  }
}

export type ScheduleOrderBy = 'time' | 'oven';

function Schedules() {
  const navigate = useNavigate();
  const {countryId, cityId, bakeryId, ovenModelId} = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsDate = parse(searchParams.get('date') ?? '', 'MM-dd', new Date());
  const selectedDate = isValid(searchParamsDate) ? searchParamsDate : new Date();
  const queryClient = useQueryClient();
  const {t, i18n} = useTranslation();

  const setBreadcrumbs = useBreadcrumbsStore((state) => state.setBreadcrumbs);

  const {user} = useUser();

  const [searchText, setSearchText] = useState('');
  const [orderBy, setOrderBy] = useState<ScheduleOrderBy>('time');

  const {data: countries = [], isLoading: isLoadingCountries} = useQuery({
    enabled: user != null,
    queryKey: ['companyCountries', user?.companyId],
    queryFn: () =>
      services.company.getCompanyCountries({
        params: {
          companyId: user?.companyId ?? '',
        },
        query: {
          expand: ['districts', 'cities', 'bakeries'],
        },
      }),
    select: (countries) => countryUtils.filterStockBakeries(countries, user),
  });

  const {selectedCountry, cities, selectedCity, bakeries, selectedBakery} = useMemo(() => {
    const selectedCountryId = numberUtils.parseInt(countryId);
    const selectedCountry = countries.find((country) => country.id === selectedCountryId);
    const cities = selectedCountry?.districts?.flatMap((district) => district.cities ?? []) ?? [];
    const selectedCityId = numberUtils.parseInt(cityId);
    const selectedCity = cities.find((city) => city.id === selectedCityId);
    const bakeries = selectedCity?.bakeries ?? [];
    const selectedBakery = bakeries.find((bakery) => bakery.id === bakeryId);
    return {selectedCountry, cities, selectedCity, bakeries, selectedBakery};
  }, [countries, countryId, cityId, bakeryId]);

  const {data: ovenModels = [], isLoading: isLoadingOvenModels} = useQuery({
    enabled: user != null && selectedBakery != null,
    queryKey: ['bakeryOvenModels', selectedBakery?.id],
    queryFn: () =>
      services.bakery.getBakeryOvenModels({
        params: {
          bakeryId: selectedBakery!.id,
        },
      }),
  });

  const selectedOvenModel = ovenModels.find(
    (ovenModel) => ovenModel.id === numberUtils.parseInt(ovenModelId),
  );

  const {data: schedules = [], isLoading: isLoadingSchedules} = useQuery({
    enabled: user != null && selectedOvenModel != null,
    queryKey: [
      'schedules',
      {
        ovenModelId: selectedOvenModel?.id,
        from: startOfMonth(new Date()),
        to: endOfMonth(new Date()),
        companyId: user?.companyId,
        expand: [
          'recurrences',
          'recipe',
          'recipePhases',
          'cleaning',
          'ovenPanels',
          'ovenChamber',
          'oven',
          'ovenGroup',
        ],
      },
    ],
    queryFn: () =>
      services.schedule.getSchedules({
        query: {
          ovenModelId: selectedOvenModel!.id,
          from: startOfMonth(new Date()),
          to: endOfMonth(new Date()),
          companyId: user!.companyId,
          expand: [
            'recurrences',
            'recipe',
            'recipePhases',
            'cleaning',
            'ovenPanels',
            'ovenChamber',
            'oven',
            'ovenGroup',
          ],
        },
      }),
  });

  const {mutate: deleteOvenPanelSchedule, isLoading: isLoadingDeleteOvenPanelSchedule} =
    useMutation({
      mutationFn: services.ovenPanel.deleteOvenPanelSchedule,
      onSuccess: () => {
        queryClient.invalidateQueries('schedules');
      },
    });

  const items = useMemo(() => {
    const schedulesMap = new Map<string, Schedule>();

    schedules.forEach((schedule) => {
      const isOnSelectDate = schedule.recurrences?.some(
        (recurrence) =>
          (recurrence.repeat &&
            new Date(recurrence.startDate) <= selectedDate &&
            recurrence.weekDay === selectedDate.getDay()) ||
          isSameDay(new Date(recurrence.startDate), selectedDate),
      );

      if (isOnSelectDate) {
        schedulesMap.set(schedule.id, schedule);
      }
    });

    schedules.forEach((schedule) => {
      if (schedule.linkedScheduleId != null && schedulesMap.has(schedule.linkedScheduleId)) {
        const parentSchedule = schedulesMap.get(schedule.linkedScheduleId);
        parentSchedule!.linkedSchedule = schedule;
        schedulesMap.delete(schedule.id);
      }
    });

    const items: ScheduleListItemProps[] = [];

    schedulesMap.forEach((schedule) => {
      const ovenGroupItems = new Map<string, ScheduleListItemProps>();
      const ovenItems = new Map<string, ScheduleListItemProps>();

      schedule.ovenPanels?.forEach((ovenPanel) => {
        const oven = ovenPanel.oven ?? ovenPanel.ovenChamber?.oven;
        const ovenGroup = oven?.ovenGroup;

        if (oven == null) return;

        if (ovenGroup != null) {
          let ovenGroupItem = ovenGroupItems.get(ovenGroup.id);

          if (ovenGroupItem == null) {
            ovenGroupItem = {
              scheduleId: schedule.id,
              recipeId: schedule.recipeId,
              cleaningId: schedule.cleaningId,
              linkedScheduleId: schedule.linkedSchedule?.id,
              ovenPanelId: ovenPanel.id,
              program:
                schedule.recipe != null
                  ? recipeUtils.getName(schedule.recipe, i18n.language as Language)
                  : schedule.cleaning != null
                  ? getCleaningDescription(schedule.cleaning.id, t)
                  : '',
              location: ovenGroup.description ?? '',
              startTime: scheduleUtils.getStartTime(schedule),
              endTime: scheduleUtils.getEndTime(schedule),
              items: [
                {
                  scheduleId: schedule.id,
                  ovenPanelId: ovenPanel.id,
                  location: `${t('oven')} ${oven.ovenGroupOrder}`,
                  startTime: scheduleUtils.getStartTime(schedule),
                  endTime: scheduleUtils.getEndTime(schedule),
                },
              ],
              recurrence: scheduleUtils.getDetailedRecurrenceLabel(schedule, t),
              renderItems: true,
            };

            ovenGroupItems.set(ovenGroup.id, ovenGroupItem);
          } else {
            ovenGroupItem.items.push({
              scheduleId: schedule.id,
              ovenPanelId: ovenPanel.id,
              location: `${t('oven')} ${oven.ovenGroupOrder}`,
              startTime: scheduleUtils.getStartTime(schedule),
              endTime: scheduleUtils.getEndTime(schedule),
            });
          }
        } else {
          let ovenItem = ovenItems.get(oven.id);

          if (ovenItem == null) {
            ovenItem = {
              scheduleId: schedule.id,
              recipeId: schedule.recipeId,
              cleaningId: schedule.cleaningId,
              linkedScheduleId: schedule.linkedSchedule?.id,
              ovenPanelId: ovenPanel.id,
              program:
                schedule.recipe != null
                  ? recipeUtils.getName(schedule.recipe, i18n.language as Language)
                  : schedule.cleaning != null
                  ? getCleaningDescription(schedule.cleaning.id, t)
                  : '',
              location: oven.description ?? oven.serialNumber,
              startTime: scheduleUtils.getStartTime(schedule),
              endTime: scheduleUtils.getEndTime(schedule),
              items: [
                {
                  scheduleId: schedule.id,
                  ovenPanelId: ovenPanel.id,
                  location:
                    ovenPanel.ovenChamber != null
                      ? `${t('chamber')} ${ovenPanel.ovenChamber.ovenOrder}`
                      : `${t('panel')} ${ovenPanel.ovenOrder}`,
                  startTime: scheduleUtils.getStartTime(schedule),
                  endTime: scheduleUtils.getEndTime(schedule),
                },
              ],
              recurrence: scheduleUtils.getDetailedRecurrenceLabel(schedule, t),
            };

            ovenItems.set(oven.id, ovenItem);
          } else {
            ovenItem.items.push({
              scheduleId: schedule.id,
              ovenPanelId: ovenPanel.id,
              location:
                ovenPanel.ovenChamber != null
                  ? `${t('chamber')} ${ovenPanel.ovenChamber.ovenOrder}`
                  : `${t('panel')} ${ovenPanel.ovenOrder}`,
              startTime: scheduleUtils.getStartTime(schedule),
              endTime: scheduleUtils.getEndTime(schedule),
            });
          }
        }
      });

      if (schedule.linkedSchedule != null) {
        const linkedSchedule = schedule.linkedSchedule;

        linkedSchedule.ovenPanels?.forEach((ovenPanel) => {
          const oven = ovenPanel.oven ?? ovenPanel.ovenChamber?.oven;
          const ovenGroup = oven?.ovenGroup;

          if (oven == null) return;

          if (ovenGroup != null) {
            let ovenGroupItem = ovenGroupItems.get(ovenGroup.id);

            if (ovenGroupItem == null) {
              ovenGroupItem = {
                scheduleId: linkedSchedule.id,
                recipeId: linkedSchedule.recipeId,
                cleaningId: linkedSchedule.cleaningId,
                linkedScheduleId: linkedSchedule.linkedSchedule?.id,
                ovenPanelId: ovenPanel.id,
                program:
                  linkedSchedule.recipe != null
                    ? recipeUtils.getName(linkedSchedule.recipe, i18n.language as Language)
                    : linkedSchedule.cleaning != null
                    ? getCleaningDescription(linkedSchedule.cleaning.id, t)
                    : '',
                location: ovenGroup.description ?? '',
                startTime: scheduleUtils.getStartTime(linkedSchedule),
                endTime: scheduleUtils.getEndTime(linkedSchedule),
                items: [
                  {
                    scheduleId: linkedSchedule.id,
                    ovenPanelId: ovenPanel.id,
                    location: `${t('oven')} ${oven.ovenGroupOrder}`,
                    startTime: scheduleUtils.getStartTime(linkedSchedule),
                    endTime: scheduleUtils.getEndTime(linkedSchedule),
                  },
                ],
                recurrence: scheduleUtils.getDetailedRecurrenceLabel(linkedSchedule, t),
              };

              ovenGroupItems.set(ovenGroup.id, ovenGroupItem);
            } else {
              ovenGroupItem.items.push({
                scheduleId: linkedSchedule.id,
                ovenPanelId: ovenPanel.id,
                location: `${t('oven')} ${oven.ovenGroupOrder}`,
                startTime: scheduleUtils.getStartTime(linkedSchedule),
                endTime: scheduleUtils.getEndTime(linkedSchedule),
              });
            }
          } else {
            let ovenItem = ovenItems.get(oven.id);

            if (ovenItem == null) {
              ovenItem = {
                scheduleId: linkedSchedule.id,
                recipeId: linkedSchedule.recipeId,
                cleaningId: linkedSchedule.cleaningId,
                linkedScheduleId: linkedSchedule.linkedSchedule?.id,
                ovenPanelId: linkedSchedule.id,
                program:
                  linkedSchedule.recipe != null
                    ? recipeUtils.getName(linkedSchedule.recipe, i18n.language as Language)
                    : linkedSchedule.cleaning != null
                    ? getCleaningDescription(linkedSchedule.cleaning.id, t)
                    : '',
                location: oven.description ?? oven.serialNumber,
                startTime: scheduleUtils.getStartTime(linkedSchedule),
                endTime: scheduleUtils.getEndTime(linkedSchedule),
                items: [
                  {
                    scheduleId: linkedSchedule.id,
                    ovenPanelId: ovenPanel.id,
                    location:
                      ovenPanel.ovenChamber != null
                        ? `${t('chamber')} ${ovenPanel.ovenChamber.ovenOrder}`
                        : `${t('panel')} ${ovenPanel.ovenOrder}`,
                    startTime: scheduleUtils.getStartTime(linkedSchedule),
                    endTime: scheduleUtils.getEndTime(linkedSchedule),
                  },
                ],
                recurrence: scheduleUtils.getDetailedRecurrenceLabel(linkedSchedule, t),
              };

              ovenItems.set(oven.id, ovenItem);
            } else {
              ovenItem.items.push({
                scheduleId: linkedSchedule.id,
                ovenPanelId: ovenPanel.id,
                location:
                  ovenPanel.ovenChamber != null
                    ? `${t('chamber')} ${ovenPanel.ovenChamber.ovenOrder}`
                    : `${t('panel')} ${ovenPanel.ovenOrder}`,
                startTime: scheduleUtils.getStartTime(linkedSchedule),
                endTime: scheduleUtils.getEndTime(linkedSchedule),
              });
            }
          }
        });
      }

      items.push(...Array.from(ovenGroupItems.values()));
      items.push(...Array.from(ovenItems.values()));
    });

    if (orderBy === 'time') {
      items.sort((itemA, itemB) => {
        if (itemA.startTime > itemB.startTime) return 1;
        if (itemA.startTime < itemB.startTime) return -1;
        return 0;
      });
    } else {
      items.sort((itemA, itemB) => {
        if (itemA.location < itemB.location) return -1;
        if (itemA.location > itemB.location) return 1;
        return 0;
      });
    }

    return items;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedules, selectedDate, orderBy]);

  useEffect(() => {
    if (selectedCountry == null && !arrayUtils.isNullOrEmpty(countries)) {
      navigate(`${paths.programming}/${countries[0].id}`, {replace: true});
    }
  }, [countries, selectedCountry, navigate]);

  useEffect(() => {
    if (
      selectedCountry != null &&
      selectedCity != null &&
      selectedBakery != null &&
      selectedOvenModel == null &&
      !arrayUtils.isNullOrEmpty(ovenModels)
    ) {
      navigate(
        `${paths.programming}/${selectedCountry.id}/${selectedCity.id}/${selectedBakery.id}/${ovenModels[0].id}`,
        {replace: true},
      );
    }
  }, [ovenModels, selectedCountry, selectedCity, selectedBakery, selectedOvenModel, navigate]);

  useEffect(() => {
    const breadcrumbs: Breadcrumb[] = [];
    breadcrumbs.push({
      title: t('programming_breadcrumb'),
      onClick: () => navigate(paths.programming),
    });
    if (selectedCountry != null) {
      breadcrumbs.push({
        title: selectedCountry.name,
        onClick: () => navigate(`${paths.programming}/${selectedCountry.id}`),
      });

      if (selectedCity != null) {
        breadcrumbs.push({
          title: selectedCity.name,
          onClick: () => navigate(`${paths.programming}/${selectedCountry.id}/${selectedCity.id}`),
        });

        if (selectedBakery != null) {
          breadcrumbs.push({
            title: selectedBakery.name,
            onClick: () =>
              navigate(
                `${paths.programming}/${selectedCountry.id}/${selectedCity.id}/${selectedBakery.id}`,
              ),
          });

          if (selectedOvenModel != null) {
            breadcrumbs.push({
              title: selectedOvenModel.description,
            });
          }
        }
      }
    }
    setBreadcrumbs(breadcrumbs);
    return () => setBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry, selectedCity, selectedBakery, selectedOvenModel]);

  function handleUpdateSchedule(scheduleId: string) {
    const schedule = schedules.find((schedule) => schedule.id === scheduleId);
    if (schedule?.recipeId != null) {
      navigate(
        `${paths.programming}/${selectedCountry?.id}/${selectedCity?.id}/${selectedBakery?.id}/${selectedOvenModel?.id}/recipe/${scheduleId}`,
      );
    }
    if (schedule?.cleaningId != null) {
      navigate(
        `${paths.programming}/${selectedCountry?.id}/${selectedCity?.id}/${selectedBakery?.id}/${selectedOvenModel?.id}/cleaning/${scheduleId}`,
      );
    }
  }

  const isLoading =
    isLoadingCountries ||
    isLoadingOvenModels ||
    isLoadingSchedules ||
    isLoadingDeleteOvenPanelSchedule;
  const renderCountryTabs = selectedOvenModel == null && countries.length > 1;
  const renderDayTabs = selectedOvenModel != null;
  const renderCityList = selectedCountry != null && selectedCity == null;
  const renderBakeryList = selectedCity != null && selectedBakery == null;
  const renderOvenModelList = selectedBakery != null;
  const renderSchedules = selectedOvenModel != null;
  const renderOvensNotFoundLabel =
    renderOvenModelList && arrayUtils.isNullOrEmpty(ovenModels) && !isLoading;

  return (
    <Fragment>
      {renderOvenModelList && (
        <OvenModelList
          ovenModels={ovenModels}
          selectedOvenModelId={selectedOvenModel?.id ?? -1}
          onSelectOvenModel={(ovenModelId) =>
            navigate(
              `${paths.programming}/${selectedCountry?.id}/${selectedCity?.id}/${selectedBakery?.id}/${ovenModelId}`,
            )
          }
        />
      )}
      <Box
        sx={{
          marginTop: {xs: '32px', md: '24px'},
          marginLeft: {xs: '60px', sm: '120px', md: selectedBakery != null ? '65px' : '325px'},
        }}>
        <Box sx={{display: 'flex'}}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              width: {
                xs: 'calc(100vw - 120px)',
                sm: 'calc(100vw - 240px)',
                md: 'calc(100vw - 445px)',
              },
            }}>
            {renderCountryTabs && (
              <CountryTabs
                countries={countries}
                selectedCountryId={selectedCountry?.id}
                onSelectCountry={(countryId) => navigate(`${paths.programming}/${countryId}`)}
              />
            )}
            {renderDayTabs && (
              <DateSelector
                selectedDate={selectedDate}
                onChangeSelectedDate={(date) => setSearchParams({date: format(date, 'MM-dd')})}
              />
            )}
            <SearchField
              searchText={searchText}
              onChangeSearchText={setSearchText}
              selectedCity={selectedCity}
              selectedBakery={selectedBakery}
              onReturnToBakeries={() =>
                navigate(`${paths.programming}/${selectedCountry?.id}/${selectedCity?.id}`)
              }
              onReturnToCountries={() => navigate(`${paths.programming}/${selectedCountry?.id}`)}
            />
            {renderSchedules && (
              <Fragment>
                <ActionBanner
                  color="custom.surfaceBrand"
                  text={t('programming_add_recipe_schedule_label')}
                  secondary={<Icon IconComponent={AddIcon} color="custom.iconsSecondary" />}
                  onClick={() =>
                    navigate(
                      `${paths.programming}/${selectedCountry?.id}/${selectedCity?.id}/${
                        selectedBakery?.id
                      }/${ovenModelId}/recipe?date=${format(selectedDate, 'MM-dd')}`,
                    )
                  }
                />
                {selectedOvenModel?.id === OvenModelId.Turboram && (
                  <ActionBanner
                    color="custom.surfaceBlue"
                    text={t('programming_add_cleaning_schedule_label')}
                    secondary={<Icon IconComponent={AddIcon} color="custom.iconsSecondary" />}
                    onClick={() =>
                      navigate(
                        `${paths.programming}/${selectedCountry?.id}/${selectedCity?.id}/${
                          selectedBakery?.id
                        }/${ovenModelId}/cleaning?date=${format(selectedDate, 'MM-dd')}`,
                      )
                    }
                  />
                )}
              </Fragment>
            )}
          </Box>
          {renderSchedules && (
            <Box sx={{width: {xs: '60px', sm: '120px'}}}>
              <SchedulesMenu orderBy={orderBy} setOrderBy={setOrderBy} />
            </Box>
          )}
        </Box>
        {renderCityList && (
          <CityList
            searchText={searchText}
            cities={cities}
            onSelectCity={(cityId) =>
              navigate(`${paths.programming}/${selectedCountry?.id}/${cityId}`)
            }
            citiesNotFoundMessage={
              selectedCountry == null ? '' : t('programming_cities_not_found_label')
            }
          />
        )}
        {renderBakeryList && (
          <BakeryList
            searchText={searchText}
            bakeries={bakeries}
            onSelectBakery={(bakeryId) =>
              navigate(
                `${paths.programming}/${selectedCountry?.id}/${selectedCity?.id}/${bakeryId}`,
              )
            }
            bakeriesNotFoundMessage={
              selectedCity == null ? '' : t('programming_bakeries_not_found_label')
            }
          />
        )}
        {renderOvensNotFoundLabel && (
          <Box sx={{marginTop: '32px', paddingInline: '32px'}}>
            <Typography
              sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 400, lineHeight: '28px'}}>
              {t('programming_ovens_not_found_label')}
            </Typography>
          </Box>
        )}
        {renderSchedules && (
          <ScheduleList
            selectedOvenModelId={selectedOvenModel?.id}
            items={items}
            onSelectItem={handleUpdateSchedule}
            onDeleteItem={(scheduleId, ovenPanelId) =>
              deleteOvenPanelSchedule({params: {ovenPanelId, scheduleId}})
            }
            itemsNotFoundLabel={isLoading ? '' : t('programming_schedules_not_found_label')}
          />
        )}
        <Box sx={{minHeight: '64px'}} />
      </Box>
      <LoadingBackdrop isLoading={isLoading} />
    </Fragment>
  );
}

export default Schedules;
