import {Box, Typography} from '@mui/material';
import {ChangeEvent, Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as CheckedIcon} from '../../../../../assets/icons/accept-simple.svg';
import {ReactComponent as UncheckedIcon} from '../../../../../assets/icons/decline-simple.svg';
import {OvenModelId} from '../../../../../models/entities/oven-model';
import {OvenSubModelId} from '../../../../../models/entities/oven-sub-model';
import stringUtils from '../../../../../utils/strings';
import Icon from '../../../../common/Icon';
import {SmallInput} from '../../../../common/Input';
import {SerialNumberStepProps} from './SerialNumberStep';

function TurboramSerialNumberStep(props: SerialNumberStepProps) {
  const {ovens, setUiState, addOvenData, setAddOvenData} = props;
  const {t} = useTranslation();

  const [isValidSerialNumber, setIsValidSerialNumber] = useState(false);
  const [isValidTopOvenSerialNumber, setIsValidTopOvenSerialNumber] = useState(false);
  const [isValidBottomOvenSerialNumber, setIsValidBottomOvenSerialNumber] = useState(false);

  function handleChangeSerialNumber(event: ChangeEvent<HTMLInputElement>) {
    const serialNumber = event.target.value;

    setAddOvenData((addOvenData) => ({...addOvenData, serialNumber}));

    const oven = ovens.find(
      (oven) =>
        oven.ovenModelId === OvenModelId.Turboram &&
        [OvenSubModelId.Turboram5T, OvenSubModelId.Turboram9T, OvenSubModelId.TurboramXL].includes(
          oven.ovenSubModelId as any,
        ) &&
        oven.serialNumber === serialNumber,
    );

    setIsValidSerialNumber(oven != null);

    setUiState((uiState) => ({
      ...uiState,
      isNextButtonVisible: !uiState.visibleSteps.includes('description') && oven != null,
      currentStep: 'serial-number',
      nextStep: 'description',
    }));
  }

  function handleChangeTopOvenSerialNumber(event: ChangeEvent<HTMLInputElement>) {
    const serialNumber = event.target.value;

    setAddOvenData((addOvenData) => ({...addOvenData, topOvenSerialNumber: serialNumber}));

    const topOven = ovens.find(
      (oven) =>
        oven.ovenModelId === OvenModelId.Turboram &&
        [OvenSubModelId.Turboram5T, OvenSubModelId.Turboram9T].includes(
          oven.ovenSubModelId as any,
        ) &&
        oven.serialNumber === serialNumber,
    );

    const bottomOven = ovens.find(
      (oven) =>
        oven.ovenModelId === OvenModelId.Turboram &&
        [OvenSubModelId.Turboram5T, OvenSubModelId.Turboram9T].includes(
          oven.ovenSubModelId as any,
        ) &&
        oven.serialNumber === addOvenData.bottomOvenSerialNumber,
    );

    setIsValidTopOvenSerialNumber(topOven != null);

    setUiState((uiState) => ({
      ...uiState,
      isNextButtonVisible:
        !uiState.visibleSteps.includes('description') && topOven != null && bottomOven != null,
      currentStep: 'serial-number',
      nextStep: 'description',
    }));
  }

  function handleChangeBottomOvenSerialNumber(event: ChangeEvent<HTMLInputElement>) {
    const serialNumber = event.target.value;

    setAddOvenData((addOvenData) => ({...addOvenData, bottomOvenSerialNumber: serialNumber}));

    const topOven = ovens.find((oven) => oven.serialNumber === addOvenData.topOvenSerialNumber);
    const bottomOven = ovens.find((oven) => oven.serialNumber === serialNumber);

    setIsValidBottomOvenSerialNumber(bottomOven != null);

    setUiState((uiState) => ({
      ...uiState,
      isNextButtonVisible:
        !uiState.visibleSteps.includes('description') && topOven != null && bottomOven != null,
      currentStep: 'serial-number',
      nextStep: 'description',
    }));
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px', paddingInline: '32px'}}>
      <Typography sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 500, lineHeight: '28px'}}>
        {addOvenData.ovenSubModel === 'combi'
          ? t('enter_the_serial_numbers')
          : t('enter_the_serial_number')}
      </Typography>
      {addOvenData.ovenSubModel === 'combi' ? (
        <Fragment>
          <Box sx={{width: '100%', height: '62px', textAlign: 'end'}}>
            <SmallInput
              fullWidth
              label={t('top_oven')}
              InputProps={{
                endAdornment: !stringUtils.isNullOrWhiteSpace(addOvenData.topOvenSerialNumber) && (
                  <Icon IconComponent={isValidTopOvenSerialNumber ? CheckedIcon : UncheckedIcon} />
                ),
              }}
              value={addOvenData.topOvenSerialNumber}
              onChange={handleChangeTopOvenSerialNumber}
            />
            {!stringUtils.isNullOrWhiteSpace(addOvenData.topOvenSerialNumber) &&
              !isValidTopOvenSerialNumber && (
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '24px',
                    color: 'custom.textBrand',
                  }}>
                  {t('invalid_serial_number')}
                </Typography>
              )}
          </Box>
          <Box sx={{width: '100%', height: '62px', textAlign: 'end'}}>
            <SmallInput
              fullWidth
              label={t('base_oven')}
              InputProps={{
                endAdornment: !stringUtils.isNullOrWhiteSpace(
                  addOvenData.bottomOvenSerialNumber,
                ) && (
                  <Icon
                    IconComponent={isValidBottomOvenSerialNumber ? CheckedIcon : UncheckedIcon}
                  />
                ),
              }}
              value={addOvenData.bottomOvenSerialNumber}
              onChange={handleChangeBottomOvenSerialNumber}
            />
            {!stringUtils.isNullOrWhiteSpace(addOvenData.bottomOvenSerialNumber) &&
              !isValidBottomOvenSerialNumber && (
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '24px',
                    color: 'custom.textBrand',
                  }}>
                  {t('invalid_serial_number')}
                </Typography>
              )}
          </Box>
        </Fragment>
      ) : (
        <Box sx={{width: '100%', height: '62px', textAlign: 'end'}}>
          <SmallInput
            fullWidth
            label={t('oven')}
            InputProps={{
              endAdornment: !stringUtils.isNullOrWhiteSpace(addOvenData.serialNumber) && (
                <Icon IconComponent={isValidSerialNumber ? CheckedIcon : UncheckedIcon} />
              ),
            }}
            value={addOvenData.serialNumber}
            onChange={handleChangeSerialNumber}
          />
          {!stringUtils.isNullOrWhiteSpace(addOvenData.serialNumber) && !isValidSerialNumber && (
            <Typography
              sx={{
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px',
                color: 'custom.textBrand',
              }}>
              {t('invalid_serial_number')}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
}

export default TurboramSerialNumberStep;
