const endpoints = {
  signup: '/signup',
  login: '/login',
  recoverPassword: '/password-recovery',
  resetPassword: '/password-reset',
  countries: '/countries',
  districts: '/districts',
  cities: '/cities',
  companies: '/companies',
  bakeries: '/bakeries',
  users: '/users',
  notificationTypes: '/notification-types',
  notifications: '/notifications',
  subscriptions: '/subscriptions',
  ovenModels: '/oven-models',
  ovenGroups: '/oven-groups',
  ovens: '/ovens',
  ovenChambers: '/oven-chambers',
  ovenPanels: '/oven-panels',
  recipeTypes: '/recipe-types',
  recipes: '/recipes',
  recipeSchemas: '/recipe-schemas',
  cleanings: '/cleanings',
  schedules: '/schedules',
  telemetry: '/telemetry',
};

export default endpoints;
