import {Box, Typography} from '@mui/material';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation} from 'react-query';
import {useNavigate} from 'react-router-dom';
import useSplashScreen from '../../hooks/common/use-splash-screen';
import paths from '../../routes/paths';
import services from '../../services/provider';
import errorUtils, {ApplicationErrorCode} from '../../utils/errors';
import stringUtils from '../../utils/strings';
import {Button} from '../common/Button';
import {Input} from '../common/Input';
import LoadingBackdrop from '../common/LoadingBackdrop';

function PasswordRecovery() {
  const navigate = useNavigate();
  const {splash} = useSplashScreen();
  const {t} = useTranslation();

  const [email, setEmail] = useState('');

  const [invalidEmail, setInvalidEmail] = useState(false);

  const {mutate: recoverPassword, isLoading} = useMutation({
    mutationFn: services.auth.recoverPassword,
    onSuccess: () =>
      splash({
        title: t('splash_screen_recovery_code_title'),
        caption: t('splash_screen_recovery_code_caption'),
        nextAction: () => navigate(`${paths.passwordReset}?email=${email}`),
      }),
    onError: (error) =>
      errorUtils.handleMatch(error, [ApplicationErrorCode.InvalidEmailOrPassword], () =>
        setInvalidEmail(true),
      ),
  });

  function handleRecoverPassword() {
    recoverPassword({request: {email}});
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '72px',
        width: {xs: '504px', md: '790px'},
        maxWidth: '80vw',
      }}>
      <Box>
        <Typography
          sx={{fontSize: '32px', fontStyle: 'normal', fontWeight: 400, lineHeight: '40px'}}>
          {t('password_recovery_title')}
        </Typography>
        {invalidEmail && (
          <Typography
            sx={{
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
              color: 'custom.textBrand',
            }}>
            {t('password_recovery_invalid_email_label')}
          </Typography>
        )}
      </Box>
      <Input
        type="email"
        label={t('password_recovery_email_input_label')}
        value={email}
        onChange={(event) => setEmail(event.target.value)}
        onKeyUp={(event) => (event.key === 'Enter' ? handleRecoverPassword() : null)}
      />
      <Button onClick={handleRecoverPassword} disabled={stringUtils.isNullOrWhiteSpace(email)}>
        {t('password_recovery_recovery_button_label')}
      </Button>
      <LoadingBackdrop isLoading={isLoading} />
    </Box>
  );
}

export default PasswordRecovery;
