import {List} from '@mui/material';
import MenuItem, {MenuItemProps} from './MenuItem';

type MenuProps = {
  items: MenuItemProps[];
  direction?: 'row' | 'column';
  showUnselectedText?: boolean;
};

function Menu(props: MenuProps) {
  const {items, direction = 'column', showUnselectedText} = props;

  return (
    <List
      sx={{
        display: 'flex',
        flexDirection: direction,
        alignItems: 'flex-end',
        gap: '16px',
        margin: 0,
        padding: 0,
      }}>
      {items.map((item, index) => (
        <MenuItem key={index} showText={showUnselectedText} {...item} />
      ))}
    </List>
  );
}

export default Menu;
