import {Box, Typography} from '@mui/material';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import Oven from '../../models/entities/oven';
import OvenChamber from '../../models/entities/oven-chamber';
import OvenGroup from '../../models/entities/oven-group';
import OvenModel from '../../models/entities/oven-model';
import OvenList from './OvenList';
import {OvenPanelMenuItem} from './OvenPanelMenu';

type BakeryOvensProps = {
  ovenModels: OvenModel[];
  selectedOvenModel: OvenModel;
  selectedOvenGroupId?: string;
  selectedOvenId?: string;
  onSelectOvenGroup: (ovenGroupId: string) => void;
  onSelectOven: (ovenId: string) => void;
  onChangeOvenGroupDescription: (ovenGroup: OvenGroup) => void;
  onChangeOvenDescription: (oven: Oven) => void;
  onDeleteOven: (oven: Oven) => void;
  onDeleteOvenChamber: (ovenChamber: OvenChamber) => void;
  onOvenPanelMenuClick: (args: {
    menuItem: OvenPanelMenuItem;
    ovenPanelId: string;
    ovenChamberId?: string;
    ovenId: string;
    ovenGroupId?: string;
  }) => void;
};

function BakeryOvens(props: BakeryOvensProps) {
  const {
    ovenModels,
    selectedOvenModel,
    selectedOvenGroupId,
    selectedOvenId,
    onSelectOvenGroup,
    onSelectOven,
    onChangeOvenGroupDescription,
    onChangeOvenDescription,
    onDeleteOven,
    onDeleteOvenChamber,
    onOvenPanelMenuClick,
  } = props;

  const {t} = useTranslation();

  return (
    <Fragment>
      {ovenModels.length === 0 ? (
        <Box sx={{marginTop: '32px', paddingInline: '32px'}}>
          <Typography
            sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 400, lineHeight: '28px'}}>
            {t('bakery_ovens_not_found_label')}
          </Typography>
        </Box>
      ) : (
        <OvenList
          ovenGroups={selectedOvenModel.ovenGroups ?? []}
          ovens={selectedOvenModel.ovens?.filter((oven) => oven.ovenGroupId == null) ?? []}
          selectedOvenGroupId={selectedOvenGroupId ?? ''}
          selectedOvenId={selectedOvenId ?? ''}
          onSelectOvenGroup={onSelectOvenGroup}
          onSelectOven={onSelectOven}
          onChangeOvenGroupDescription={onChangeOvenGroupDescription}
          onChangeOvenDescription={onChangeOvenDescription}
          onDeleteOven={onDeleteOven}
          onDeleteOvenChamber={onDeleteOvenChamber}
          onOvenPanelMenuClick={onOvenPanelMenuClick}
        />
      )}
    </Fragment>
  );
}

export default BakeryOvens;
