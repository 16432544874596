import {IconButton as MuiIconButton, SxProps} from '@mui/material';
import {ElementType, MouseEvent, useState} from 'react';
import Icon from './Icon';

export type IconButtonProps = {
  sx?: SxProps;
  IconComponent: ElementType<any>;
  size?: 'small' | 'medium' | 'large';
  iconSize?: string;
  isActive?: boolean;
  disabled?: boolean;
  activeColor?: string;
  inactiveColor?: string;
  disabledColor?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
};

function IconButton(props: IconButtonProps) {
  const {
    sx,
    IconComponent,
    iconSize,
    isActive,
    disabled,
    activeColor = 'custom.iconsBrand',
    inactiveColor = 'custom.iconsPrimary',
    disabledColor = 'custom.iconsQuaternary',
    onClick,
  } = props;

  const [isHovered, setIsHovered] = useState(false);

  return (
    <MuiIconButton
      sx={{margin: 0, padding: 0, ...sx}}
      disableRipple
      disabled={disabled}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <Icon
        IconComponent={IconComponent}
        size={iconSize}
        color={
          disabled
            ? disabledColor
            : isActive
            ? activeColor
            : isHovered
            ? activeColor
            : inactiveColor
        }
      />
    </MuiIconButton>
  );
}

export default IconButton;
