import {Box} from '@mui/material';
import {Fragment, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';
import useUser from '../../hooks/common/use-user';
import paths from '../../routes/paths';
import services from '../../services/provider';
import useBreadcrumbsStore, {Breadcrumb} from '../../state/breadcrumbs';
import arrayUtils from '../../utils/arrays';
import countryUtils from '../../utils/countries';
import numberUtils from '../../utils/numbers';
import LoadingBackdrop from '../common/LoadingBackdrop';
import BakeryList from './BakeryList';
import CityList from './CityList';
import CountryTabs from './CountryTabs';
import PeriodSelector from './PeriodSelector';
import RecipeStatistics from './RecipeStatistics';
import SearchField from './SearchField';
import StatisticsMenu, {StatisticsMenuItem} from './StatisticsMenu';

function parseMenuItem(value?: string): StatisticsMenuItem {
  switch (value) {
    case 'list':
    case 'graphic':
      return value;
    default:
      return 'list';
  }
}

function Statistics() {
  const navigate = useNavigate();
  const {countryId, cityId, bakeryId, menuItem} = useParams();
  const selectedMenuItem = parseMenuItem(menuItem);
  const {t} = useTranslation();

  const {user} = useUser();

  const setBreadcrumbs = useBreadcrumbsStore((state) => state.setBreadcrumbs);

  const [searchText, setSearchText] = useState('');
  const [period, setPeriod] = useState<{startDate: Date; endDate: Date}>({
    startDate: new Date(),
    endDate: new Date(),
  });

  const {data: countries = [], isLoading} = useQuery({
    enabled: user != null,
    queryKey: ['companyCountries', user?.companyId],
    queryFn: () =>
      services.company.getCompanyCountries({
        params: {
          companyId: user!.companyId,
        },
        query: {
          expand: ['districts', 'cities', 'bakeries'],
        },
      }),
    select: (countries) => countryUtils.filterStockBakeries(countries, user),
  });

  const {selectedCountry, cities, selectedCity, bakeries, selectedBakery} = useMemo(() => {
    const selectedCountryId = numberUtils.parseInt(countryId);
    const selectedCountry = countries.find((country) => country.id === selectedCountryId);
    const cities = selectedCountry?.districts?.flatMap((district) => district.cities ?? []) ?? [];
    const selectedCityId = numberUtils.parseInt(cityId);
    const selectedCity = cities.find((city) => city.id === selectedCityId);
    const bakeries = selectedCity?.bakeries ?? [];
    const selectedBakery = bakeries.find((bakery) => bakery.id === bakeryId);
    return {selectedCountry, cities, selectedCity, bakeries, selectedBakery};
  }, [countries, countryId, cityId, bakeryId]);

  useEffect(() => {
    if (selectedCountry == null && !arrayUtils.isNullOrEmpty(countries)) {
      navigate(`${paths.statistics}/${countries[0].id}`, {replace: true});
    }
  }, [countries, selectedCountry, navigate]);

  useEffect(() => {
    const breadcrumbs: Breadcrumb[] = [];

    breadcrumbs.push({
      title: t('statistics_breadcrumb'),
      onClick: () => navigate(paths.statistics),
    });

    if (selectedCountry != null) {
      breadcrumbs.push({
        title: selectedCountry.name,
        onClick: () => navigate(`${paths.statistics}/${selectedCountry.id}`),
      });

      if (selectedCity != null) {
        breadcrumbs.push({
          title: selectedCity.name,
          onClick: () => navigate(`${paths.statistics}/${selectedCountry.id}/${selectedCity.id}`),
        });

        if (selectedBakery != null) {
          breadcrumbs.push({title: selectedBakery.name});
        }
      }
    }

    setBreadcrumbs(breadcrumbs);

    return () => setBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry, selectedCity, selectedBakery]);

  const renderCountryTabs = selectedBakery == null && countries.length > 1;
  const renderCityList = selectedCity == null;
  const renderBakeryList = selectedCity != null && selectedBakery == null;
  const renderStatisticsMenu =
    selectedCountry != null && selectedCity != null && selectedBakery != null;
  const renderRecipeStatistics = selectedBakery != null;

  return (
    <Fragment>
      {renderStatisticsMenu && (
        <Box
          sx={{
            display: {xs: 'none', md: 'block'},
            width: {md: '173px'},
            marginTop: {xs: '32px', md: '96px'},
            marginLeft: {xs: '60px', sm: '120px'},
          }}>
          <StatisticsMenu
            selectedItem={selectedMenuItem}
            onSelectItem={(menuItem) =>
              navigate(
                `${paths.statistics}/${selectedCountry.id}/${selectedCity.id}/${selectedBakery.id}/${menuItem}`,
              )
            }
          />
        </Box>
      )}
      <Box sx={{display: 'flex'}}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            width: {
              xs: 'calc(100vw - 120px)',
              sm: 'calc(100vw - 240px)',
              md: 'calc(100vw - 445px)',
            },
            marginTop: {xs: '32px', md: '24px'},
            marginLeft: {xs: '60px', sm: '120px', md: renderStatisticsMenu ? '32px' : '325px'},
          }}>
          {renderCountryTabs && (
            <CountryTabs
              countries={countries}
              selectedCountryId={selectedCountry?.id}
              onSelectCountry={(countryId) => navigate(`${paths.statistics}/${countryId}`)}
            />
          )}
          <SearchField
            searchText={searchText}
            setSearchText={setSearchText}
            selectedCity={selectedCity}
            selectedBakery={selectedBakery}
            onReturnToBakeries={() =>
              navigate(`${paths.statistics}/${selectedCountry?.id}/${selectedCity?.id}`)
            }
            onReturnToCountries={() => navigate(`${paths.statistics}/${selectedCountry?.id}`)}
          />
          {renderRecipeStatistics && <PeriodSelector onPeriodChanged={setPeriod} />}
          {renderCityList && (
            <CityList
              searchText={searchText}
              cities={cities}
              onSelectCity={(cityId) =>
                navigate(`${paths.statistics}/${selectedCountry?.id}/${cityId}`)
              }
              citiesNotFoundMessage={
                selectedCountry == null ? '' : t('statistics_cities_not_found_label')
              }
            />
          )}
          {renderBakeryList && (
            <BakeryList
              searchText={searchText}
              bakeries={bakeries}
              onSelectBakery={(bakeryId) =>
                navigate(
                  `${paths.statistics}/${selectedCountry?.id}/${selectedCity?.id}/${bakeryId}/${selectedMenuItem}`,
                )
              }
              bakeriesNotFoundMessage={
                selectedCity == null ? '' : t('statistics_bakeries_not_found_label')
              }
            />
          )}
          {renderRecipeStatistics && (
            <RecipeStatistics
              visualizationType={selectedMenuItem}
              bakeryId={selectedBakery.id}
              period={period}
            />
          )}
          <Box sx={{minHeight: '64px'}} />
        </Box>
        {renderStatisticsMenu && (
          <Box sx={{display: {xs: 'block', md: 'none'}, marginTop: '96px'}}>
            <StatisticsMenu
              selectedItem={selectedMenuItem}
              onSelectItem={(menuItem) =>
                navigate(
                  `${paths.statistics}/${selectedCountry.id}/${selectedCity.id}/${selectedBakery.id}/${menuItem}`,
                )
              }
            />
          </Box>
        )}
      </Box>
      <LoadingBackdrop isLoading={isLoading} />
    </Fragment>
  );
}

export default Statistics;
