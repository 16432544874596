import {Button as MuiButton, ButtonProps} from '@mui/material';

export function Button(props: ButtonProps) {
  const {sx, variant, disabled, ...rest} = props;
  return (
    <MuiButton
      sx={{
        padding: '16px 32px !important',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '24px',
        color: 'custom.textSecondary',
        textTransform: 'uppercase !important' as any,
        backgroundColor: 'custom.surfaceBrand',
        borderRadius: '360px !important',
        '&:hover': {
          color: 'custom.textSecondary',
          backgroundColor: 'custom.surfaceBrand',
        },
        '&.Mui-disabled': {
          color: 'custom.textSecondary',
          backgroundColor: 'custom.surfaceBrand',
          opacity: '0.5',
        },
        ...sx,
      }}
      variant="contained"
      disableRipple
      disableElevation
      disabled={disabled}
      {...rest}>
      {props.children}
    </MuiButton>
  );
}

export function SmallButton(props: ButtonProps) {
  const {sx, variant, disabled, ...rest} = props;
  return (
    <MuiButton
      sx={{
        padding: '4px 8px !important',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '24px',
        color: 'custom.textSecondary',
        textTransform: 'uppercase !important' as any,
        backgroundColor: 'custom.surfaceBrand',
        borderRadius: '360px !important',
        '&:hover': {
          color: 'custom.textSecondary',
          backgroundColor: 'custom.surfaceBrand',
        },
        '&.Mui-disabled': {
          color: 'custom.textSecondary',
          backgroundColor: 'custom.surfaceBrand',
          opacity: '0.5',
        },
        ...sx,
      }}
      variant="contained"
      disableRipple
      disableElevation
      disabled={disabled}
      {...rest}>
      {props.children}
    </MuiButton>
  );
}
