import {Box, Typography} from '@mui/material';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {ReactComponent as AddIcon} from '../../../../assets/icons/add.svg';
import useUser from '../../../../hooks/common/use-user';
import Recipe from '../../../../models/entities/recipe';
import services from '../../../../services/provider';
import arrayUtils from '../../../../utils/arrays';
import cryptoUtils from '../../../../utils/crypto';
import stringUtils from '../../../../utils/strings';
import GradientOverflow from '../../../common/GradientOverflow';
import IconButton from '../../../common/IconButton';
import {SmallInput} from '../../../common/Input';
import LoadingBackdrop from '../../../common/LoadingBackdrop';
import Span from '../../../common/Span';
import Switch from '../../../common/Switch';

type GeneralStepProps = {
  recipeBuild: Recipe;
  setRecipeBuild: (setter: (recipeBuild: Recipe) => Recipe) => void;
};

function GeneralStep(props: GeneralStepProps) {
  const {recipeBuild, setRecipeBuild} = props;
  const recipeBuildRecipeTypeIdsSet = new Set(
    recipeBuild.recipeTypes?.map((recipeType) => recipeType.id) ?? [],
  );

  const queryClient = useQueryClient();
  const {t, i18n} = useTranslation();

  const {user} = useUser();

  const [recipeTypeDescription, setRecipeTypeDescription] = useState('');

  const {data: recipeTypes = [], isLoading: isLoadingRecipeTypes} = useQuery({
    enabled: user != null,
    queryKey: ['recipeTypes', {ovenModelId: recipeBuild.ovenModelId}],
    queryFn: () =>
      services.recipeType.getRecipeTypes({
        query: {
          ovenModelId: recipeBuild.ovenModelId,
          companyId: user!.companyId,
        },
      }),
  });

  const {mutate: createRecipeType, isLoading: isLoadingCreateRecipeType} = useMutation({
    mutationFn: services.recipeType.createRecipeType,
    onSuccess: (_, {request: recipeType}) => {
      queryClient.invalidateQueries(['recipeTypes', {ovenModelId: recipeBuild.ovenModelId}]);

      setRecipeBuild((recipeBuild) => ({
        ...recipeBuild,
        recipeTypes: [...(recipeBuild.recipeTypes ?? []), recipeType],
      }));

      setRecipeTypeDescription('');
    },
  });

  function handleCreateRecipeType() {
    if (user == null) {
      return;
    }

    createRecipeType({
      request: {
        id: cryptoUtils.uuid(),
        description: recipeTypeDescription,
        descriptionEn: i18n.language === 'en' ? recipeTypeDescription : undefined,
        descriptionEs: i18n.language === 'es' ? recipeTypeDescription : undefined,
        descriptionPt: i18n.language === 'pt' ? recipeTypeDescription : undefined,
        descriptionFr: i18n.language === 'fr' ? recipeTypeDescription : undefined,
        descriptionPl: i18n.language === 'pl' ? recipeTypeDescription : undefined,
        ovenModelId: recipeBuild.ovenModelId,
        ovenSubModelId: null,
        companyId: user.companyId,
      },
    });
  }

  function handleChangeRecipeName(recipeName: string) {
    setRecipeBuild((recipeBuild) => ({
      ...recipeBuild,
      name: recipeName,
      nameEn: i18n.language === 'en' ? recipeName : undefined,
      nameEs: i18n.language === 'es' ? recipeName : undefined,
      namePt: i18n.language === 'pt' ? recipeName : undefined,
      nameFr: i18n.language === 'fr' ? recipeName : undefined,
      namePl: i18n.language === 'pl' ? recipeName : undefined,
      description: recipeName,
    }));
  }

  function handleCheckRecipeType(recipeTypeId: string, checked: boolean) {
    const recipeType = recipeTypes.find((recipeType) => recipeType.id === recipeTypeId);

    if (recipeType == null) {
      return;
    }

    const updatedRecipeTypes = checked
      ? [...(recipeBuild.recipeTypes ?? []), recipeType]
      : recipeBuild.recipeTypes?.filter((recipeType) => recipeType.id !== recipeTypeId);

    setRecipeBuild((recipeBuild) => ({...recipeBuild, recipeTypes: updatedRecipeTypes}));
  }

  const isLoading = isLoadingRecipeTypes || isLoadingCreateRecipeType;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
        marginTop: '32px',
        paddingInline: '32px',
      }}>
      <SmallInput
        fullWidth
        label={t('recipe_settings_general_step_recipe_name')}
        value={recipeBuild.name}
        onChange={(event) => handleChangeRecipeName(event.target.value)}
      />
      <Typography sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 400, lineHeight: '28px'}}>
        {t('recipe_settings_general_step_recipe_type_title')}
        <Span
          sx={{
            color: arrayUtils.isNullOrEmpty(recipeBuild.recipeTypes)
              ? 'custom.textBrand'
              : 'custom.textTertiary',
          }}>
          {` ${t('recipe_settings_general_step_recipe_type_caption')}`}
        </Span>
      </Typography>
      <Box
        sx={{height: {xs: 'calc(100vh - 636px)', md: 'calc(100vh - 568px)'}, minHeight: '256px'}}>
        <GradientOverflow hideScrollbar>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <Typography
                sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 400, lineHeight: '28px'}}>
                {t('recipe_type_all_label')}
              </Typography>
              <Switch checked />
            </Box>
            {recipeTypes.map((recipeType) => (
              <Box
                key={recipeType.id}
                sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <Typography
                  sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 400, lineHeight: '28px'}}>
                  {recipeType.description}
                </Typography>
                <Switch
                  checked={recipeBuildRecipeTypeIdsSet.has(recipeType.id)}
                  onChange={(_, checked) => handleCheckRecipeType(recipeType.id, checked)}
                />
              </Box>
            ))}
          </Box>
        </GradientOverflow>
      </Box>
      <Box sx={{display: 'flex', alignItems: 'center', gap: '16px'}}>
        <SmallInput
          fullWidth
          label={t('recipe_settings_general_step_add_recipe_type')}
          value={recipeTypeDescription}
          onChange={(event) => setRecipeTypeDescription(event.target.value)}
          onKeyUp={(event) => (event.key === 'Enter' ? handleCreateRecipeType() : null)}
        />
        <IconButton
          IconComponent={AddIcon}
          onClick={handleCreateRecipeType}
          disabled={stringUtils.isNullOrWhiteSpace(recipeTypeDescription)}
        />
      </Box>
      <LoadingBackdrop isLoading={isLoading} />
    </Box>
  );
}

export default GeneralStep;
