import {Box, useMediaQuery, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useQuery} from 'react-query';
import {useNavigate} from 'react-router-dom';
import {ReactComponent as BakeriesIcon} from '../../assets/icons/bakery.svg';
import {ReactComponent as ProfileNotificationIcon} from '../../assets/icons/profile-notification.svg';
import {ReactComponent as ProfileIcon} from '../../assets/icons/profile.svg';
import {ReactComponent as ProgrammingIcon} from '../../assets/icons/programming.svg';
import {ReactComponent as RecipesIcon} from '../../assets/icons/recipes.svg';
import {ReactComponent as StatisticsIcon} from '../../assets/icons/statistics.svg';
import useUser from '../../hooks/common/use-user';
import paths from '../../routes/paths';
import services from '../../services/provider';
import DashboardMenuItem from './DashboardMenuItem';

function DashboardMenu() {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const {user} = useUser();

  const theme = useTheme();
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  const {data: hasUnseenNotifications = false} = useQuery({
    enabled: user != null,
    queryKey: ['notifications'],
    queryFn: () =>
      services.notification.getNotifications({
        query: {userId: user?.id, companyId: user?.companyId},
      }),
    select: (notifications) => notifications.some((notification) => !notification.isSeen),
  });

  const iconSize = isLargerThanMd ? '88px' : '72px';

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: {xs: 'repeat(2, 1fr)', sm: 'repeat(3, 1fr)', md: 'repeat(5, 1fr)'},
        gap: '48px 16px',
        width: '100%',
      }}>
      <DashboardMenuItem
        icon={RecipesIcon}
        iconSize={iconSize}
        text={t('menu_recipes_label')}
        onClick={() => navigate(paths.recipes)}
      />
      <DashboardMenuItem
        icon={ProgrammingIcon}
        iconSize={iconSize}
        text={t('menu_programming_label')}
        onClick={() => navigate(paths.programming)}
      />
      <DashboardMenuItem
        icon={BakeriesIcon}
        iconSize={iconSize}
        text={t('menu_bakeries_label')}
        onClick={() => navigate(paths.bakeries)}
      />
      <DashboardMenuItem
        icon={StatisticsIcon}
        iconSize={iconSize}
        text={t('menu_statistics_label')}
        onClick={() => navigate(paths.statistics)}
      />
      <DashboardMenuItem
        icon={hasUnseenNotifications ? ProfileNotificationIcon : ProfileIcon}
        iconSize={iconSize}
        text={t('menu_profile_label')}
        onClick={() => navigate(paths.profile)}
      />
      {!isLargerThanMd && <Box sx={{width: '140px'}} />}
    </Box>
  );
}

export default DashboardMenu;
