import {AppBar, Box, Toolbar, Typography} from '@mui/material';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {ReactComponent as LogoIcon} from '../../assets/icons/logo.svg';
import {ReactComponent as DotsMenuIcon} from '../../assets/icons/menu.svg';
import paths from '../../routes/paths';
import useBreadcrumbsStore from '../../state/breadcrumbs';
import menuUtils from '../../utils/menus';
import stringUtils from '../../utils/strings';
import Icon from '../common/Icon';
import IconButton from '../common/IconButton';
import DrawerMenu from './DrawerMenu';
import Span from '../common/Span';

export function AuthNavbar() {
  const navigate = useNavigate();

  return (
    <Box sx={{height: {xs: '124px', md: '148px'}}}>
      <AppBar
        position="fixed"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          height: {xs: '124px', md: '148px'},
          paddingInline: {xs: '24px', sm: '32px', md: '80px'},
          boxShadow: 'none',
          backgroundColor: 'custom.surfaceBackground',
        }}>
        <Toolbar
          sx={{
            paddingLeft: '0px !important',
            paddingRight: '0px !important',
            display: 'flex',
            alignItems: 'flex-start',
            height: '84px',
          }}>
          <IconButton
            IconComponent={LogoIcon}
            iconSize="40px"
            inactiveColor="custom.iconsBrand"
            activeColor="custom.iconsBrand"
            onClick={() => navigate(paths.home)}
          />
        </Toolbar>
      </AppBar>
    </Box>
  );
}

function Breadcrumbs() {
  const breadcrumbs = useBreadcrumbsStore((state) => state.breadcrumbs);

  const [hoveredIndex, setHoveredIndex] = useState(-1);

  return (
    <Typography
      sx={{fontSize: '16px', fontStyle: 'normal', fontWeight: 400, lineHeight: '24px'}}
      noWrap>
      {breadcrumbs.map(({title, onClick}, index) => (
        <Span
          key={index}
          sx={{
            color:
              hoveredIndex < breadcrumbs.length - 1
                ? index <= hoveredIndex
                  ? 'custom.textBrand'
                  : 'custom.textTertiary'
                : 'custom.textTertiary',
            cursor: hoveredIndex < breadcrumbs.length - 1 ? 'pointer' : undefined,
          }}
          onMouseEnter={() => setHoveredIndex(index)}
          onMouseLeave={() => setHoveredIndex(-1)}
          onClick={index < breadcrumbs.length - 1 ? onClick : undefined}>
          {`${stringUtils.removeWhiteSpaces(title).toLowerCase()}${
            index < breadcrumbs.length - 1 ? '/' : ''
          }`}
        </Span>
      ))}
    </Typography>
  );
}

export function DashboardNavbar() {
  const location = useLocation();
  const {t} = useTranslation();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const menu = menuUtils.getMenuFromPath(location.pathname);
  const menuLabel = menuUtils.getMenuLabel(menu);
  const MenuIcon = menuUtils.getMenuIcon(menu);

  useEffect(() => {
    setDrawerOpen(false);
  }, [location.pathname]);

  return (
    <Box sx={{height: {xs: '124px', md: '148px'}}}>
      <AppBar
        position="fixed"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          height: {xs: '124px', md: '148px'},
          paddingInline: {xs: '24px', sm: '32px', md: '80px'},
          boxShadow: 'none',
          backgroundColor: 'custom.surfaceBackground',
        }}>
        <Toolbar
          sx={{
            paddingLeft: '0px !important',
            paddingRight: '0px !important',
            display: 'flex',
            alignItems: 'flex-start',
            height: {xs: '60px', md: '84px'},
          }}>
          <Box
            sx={{
              display: 'flex',
              gap: {xs: '24px', sm: '48px', md: '31px'},
            }}>
            <IconButton
              IconComponent={DotsMenuIcon}
              iconSize="40px"
              activeColor="custom.iconsPrimary"
              onClick={() => setDrawerOpen(true)}
            />
            {MenuIcon && <Icon IconComponent={MenuIcon} color="custom.iconsBrand" size="40px" />}
          </Box>
          {menuLabel.length > 0 && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '4px',
                paddingInline: {xs: '24px', sm: '32px'},
                overflow: 'hidden',
              }}>
              <Typography
                sx={{
                  fontSize: '24px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '32px',
                  color: 'custom.textBrand',
                }}>
                {t(menuLabel)}
              </Typography>
              <Breadcrumbs />
            </Box>
          )}
          <DrawerMenu open={drawerOpen} onClose={() => setDrawerOpen(false)} />
        </Toolbar>
      </AppBar>
    </Box>
  );
}
