import {InputAdornment, TextField, TextFieldProps, Typography} from '@mui/material';
import {useState} from 'react';

export function Input(props: TextFieldProps) {
  const {variant, InputProps, label, ...rest} = props;

  const [isFocused, setIsFocused] = useState(false);

  return (
    <TextField
      variant={variant ?? 'standard'}
      InputProps={{
        sx: {
          fontSize: '24px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '32px',
          color: 'custom.textTertiary',
          ...InputProps?.sx,
        },
        startAdornment: (
          <InputAdornment position="start">
            <Typography
              sx={{
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '32px',
                color: isFocused ? 'custom.textBrand' : 'custom.textPrimary',
              }}>
              {label}
            </Typography>
          </InputAdornment>
        ),
        ...InputProps,
      }}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      {...rest}
    />
  );
}

export function SmallInput(props: TextFieldProps) {
  const {variant, InputProps, label, ...rest} = props;

  const [isFocused, setIsFocused] = useState(false);

  return (
    <TextField
      variant={variant ?? 'standard'}
      InputProps={{
        sx: {
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '28px',
          color: 'custom.textTertiary',
          ...InputProps?.sx,
        },
        startAdornment: (
          <InputAdornment position="start">
            <Typography
              sx={{
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '28px',
                color: isFocused ? 'custom.textBrand' : 'custom.textPrimary',
              }}>
              {label}
            </Typography>
          </InputAdornment>
        ),
        ...InputProps,
      }}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      {...rest}
    />
  );
}
