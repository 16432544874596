import {Box, SxProps} from '@mui/material';
import {ElementType} from 'react';
import {ReactComponent as DeleteIcon} from '../../assets/icons/delete.svg';
import {ReactComponent as NumberFiveIcon} from '../../assets/icons/number-five.svg';
import {ReactComponent as NumberFourIcon} from '../../assets/icons/number-four.svg';
import {ReactComponent as NumberOneIcon} from '../../assets/icons/number-one.svg';
import {ReactComponent as NumberThreeIcon} from '../../assets/icons/number-three.svg';
import {ReactComponent as NumberTwoIcon} from '../../assets/icons/number-two.svg';
import {ReactComponent as ProgrammingIcon} from '../../assets/icons/programming.svg';
import {ReactComponent as StatisticsIcon} from '../../assets/icons/statistics.svg';
import IconButton from '../common/IconButton';

function getNumberIcon(index: number): ElementType<any> {
  switch (index) {
    case 2:
      return NumberTwoIcon;
    case 3:
      return NumberThreeIcon;
    case 4:
      return NumberFourIcon;
    case 5:
      return NumberFiveIcon;
    default:
      return NumberOneIcon;
  }
}

export type OvenPanelMenuItem = 'programs' | 'statistics' | 'delete';

export type OvenPanelMenuProps = {
  sx?: SxProps;
  ovenOrder?: number;
  hasMultiple?: boolean;
  onClick?: (menuItem: OvenPanelMenuItem) => void;
};

function OvenPanelMenu(props: OvenPanelMenuProps) {
  const {sx, ovenOrder, hasMultiple, onClick} = props;

  const renderNumberIcon = hasMultiple && ovenOrder != null;

  return (
    <Box sx={{display: 'flex', gap: '32px', marginTop: '32px', ...sx}}>
      {renderNumberIcon && (
        <IconButton
          IconComponent={getNumberIcon(ovenOrder)}
          disabledColor="custom.iconsTertiary"
          disabled
        />
      )}
      <IconButton
        IconComponent={ProgrammingIcon}
        onClick={(event) => {
          event.stopPropagation();
          onClick?.('programs');
        }}
      />
      <IconButton
        IconComponent={StatisticsIcon}
        onClick={(event) => {
          event.stopPropagation();
          onClick?.('statistics');
        }}
      />
      <IconButton
        IconComponent={DeleteIcon}
        onClick={(event) => {
          event.stopPropagation();
          onClick?.('delete');
        }}
      />
    </Box>
  );
}

export default OvenPanelMenu;
