import {Box, InputBase, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import Recipe from '../../../../models/entities/recipe';

type ProcedureStepProps = {
  recipeBuild: Recipe;
  setRecipeBuild: (setter: (recipeBuild: Recipe) => Recipe) => void;
};

function ProcedureStep(props: ProcedureStepProps) {
  const {recipeBuild, setRecipeBuild} = props;
  const {t} = useTranslation();

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px', padding: '32px'}}>
      <Typography sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 500, lineHeight: '28px'}}>
        {t('recipe_settings_procedures_step_procedures')}
      </Typography>
      <InputBase
        sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 400, lineHeight: '28px'}}
        fullWidth
        autoFocus
        multiline
        value={recipeBuild.procedure ?? ''}
        onChange={(event) =>
          setRecipeBuild((recipeBuild) => ({...recipeBuild, procedure: event.target.value}))
        }
      />
    </Box>
  );
}

export default ProcedureStep;
