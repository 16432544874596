import {Box, List, ListItem, Typography} from '@mui/material';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as ClockIcon} from '../../assets/icons/clock.svg';
import stringUtils from '../../utils/strings';
import {Day} from '../../utils/types';
import Calendar from '../common/Calendar';
import Icon from '../common/Icon';
import Switch from '../common/Switch';
import TimeInput from './TimeInput';

type SelectTimeProps = {
  startDate: Date;
  setStartDate: (date: Date) => void;
  startTime: string;
  endTime: string;
  setStartTime: (time: string) => void;
  selectedWeekDays: Day[];
  onSelectWeekDay: (weekDay: Day) => void;
  error?: boolean;
  onStartTimeFocus?: () => void;
  onStartTimeBlur?: () => void;
  showStartTimePlaceholder?: boolean;
};

function SelectTime(props: SelectTimeProps) {
  const {
    startDate,
    setStartDate,
    startTime,
    endTime,
    setStartTime,
    selectedWeekDays,
    onSelectWeekDay,
    error,
    onStartTimeFocus,
    onStartTimeBlur,
    showStartTimePlaceholder,
  } = props;

  const {t} = useTranslation();

  const color = error ? 'custom.textBrand' : 'custom.textPrimary';

  return (
    <Fragment>
      <Box sx={{display: 'flex', alignItems: 'center', gap: '8px', paddingInline: '32px'}}>
        <Icon sx={{marginRight: '8px'}} IconComponent={ClockIcon} color={color} />
        <TimeInput
          showPlaceholder={showStartTimePlaceholder}
          color={color}
          value={startTime.replace(':', '')}
          onChange={(value) => setStartTime(value.replace(/(\d{2})(\d{2})/, '$1:$2'))}
          onFocus={onStartTimeFocus}
          onBlur={onStartTimeBlur}
        />
        {!stringUtils.isNullOrWhiteSpace(endTime) && (
          <Fragment>
            <Typography
              sx={{
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '28px',
                color,
              }}>
              -
            </Typography>
            <Box sx={{opacity: error ? 0.5 : 1}}>
              <TimeInput color={color} value={endTime.replace(':', '')} onChange={() => {}} />
            </Box>
          </Fragment>
        )}
      </Box>
      <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, gap: '32px'}}>
        <Calendar selectedDate={startDate} onChangeSelectedDate={setStartDate} />
        <List
          sx={{
            width: '100%',
            margin: 0,
            padding: 0,
            paddingInline: {xs: '32px', md: '0px'},
            marginRight: '32px',
          }}>
          {[
            {key: 'sunday', value: 0},
            {key: 'monday', value: 1},
            {key: 'tuesday', value: 2},
            {key: 'wednesday', value: 3},
            {key: 'thursday', value: 4},
            {key: 'friday', value: 5},
            {key: 'saturday', value: 6},
          ].map((weekDay) => (
            <ListItem
              key={weekDay.key}
              sx={{
                margin: 0,
                padding: 0,
                justifyContent: 'space-between',
                gap: '16px',
                height: '40px',
                cursor: 'pointer',
                '&:hover': {
                  color: 'custom.textBrand',
                },
              }}
              onClick={() => onSelectWeekDay(weekDay.value as Day)}>
              <Typography
                sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 400, lineHeight: '28px'}}>
                {t(weekDay.key)}
              </Typography>
              <Switch checked={selectedWeekDays.includes(weekDay.value as Day)} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Fragment>
  );
}

export default SelectTime;
