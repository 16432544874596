import {Box, Typography} from '@mui/material';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation} from 'react-query';
import {Link, useNavigate} from 'react-router-dom';
import {queryClient} from '../../App';
import {subscriptionToken} from '../../firebase/app';
import useLanguage from '../../hooks/common/use-language';
import useLocalStorageState from '../../hooks/common/use-local-storage-state';
import paths from '../../routes/paths';
import services from '../../services/provider';
import useAuthStore from '../../state/auth';
import cryptoUtils from '../../utils/crypto';
import errorUtils, {ApplicationErrorCode} from '../../utils/errors';
import localStorageUtils from '../../utils/local-storage';
import stringUtils from '../../utils/strings';
import {Button} from '../common/Button';
import {Input} from '../common/Input';
import LoadingBackdrop from '../common/LoadingBackdrop';
import Switch from '../common/Switch';

function Login() {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const setAccessToken = useAuthStore((state) => state.setAccessToken);

  const [, setLanguage] = useLanguage();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [invalidEmailOrPassword, setInvalidEmailOrPassword] = useState(false);

  const [rememberMe, setRememberMe] = useLocalStorageState('rememberme', false);

  const {mutate: login, isLoading} = useMutation({
    mutationFn: services.auth.login,
    onSuccess: (result) => {
      services.base.setAuthorizationHeader(`Bearer ${result.token}`);

      if (rememberMe) {
        localStorageUtils.setValue('token', result.token);
      } else {
        localStorageUtils.removeValue('token');
      }

      setAccessToken(result.token);

      queryClient.setQueryData(['user'], result.user);

      services.subscription
        .subscribe({request: {token: subscriptionToken}})
        .then(() => console.log('subscribed to push notifications'))
        .catch(() => console.log('could not subscribe to push notifications'));

      const language = localStorageUtils.getValue('language', 'pt');

      setLanguage(result.user.language ?? language);

      navigate(paths.dashboard);
    },
    onError: (error) =>
      errorUtils.handleMatch(error, [ApplicationErrorCode.InvalidEmailOrPassword], () =>
        setInvalidEmailOrPassword(true),
      ),
  });

  function handleLogin() {
    login({request: {email, password: cryptoUtils.sha256(password)}});
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '72px',
        width: {xs: '504px', md: '790px'},
        maxWidth: '80vw',
      }}>
      <Box>
        <Typography
          sx={{fontSize: '32px', fontStyle: 'normal', fontWeight: 400, lineHeight: '40px'}}>
          {t('login_title')}
        </Typography>
        {invalidEmailOrPassword && (
          <Typography
            sx={{
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
              color: 'custom.textBrand',
            }}>
            {t('login_invalid_email_or_password_label')}
          </Typography>
        )}
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px'}}>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '48px'}}>
          <Input
            type="email"
            label={t('login_email_input_label')}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
            <Input
              type="password"
              label={t('login_password_input_label')}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              onKeyUp={(event) => (event.key === 'Enter' ? handleLogin() : null)}
            />
            <Typography
              sx={{
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px',
                textAlign: 'right',
              }}>
              <Link to={paths.passwordRecovery}>{t('login_forgot_password_label')}</Link>
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
            gap: '8px',
            height: '40px',
          }}>
          <Typography
            sx={{fontSize: '16px', fontStyle: 'normal', fontWeight: 400, lineHeight: '24px'}}>
            {t('login_remember_password_label')}
          </Typography>
          <Switch checked={rememberMe} onChange={(_, checked) => setRememberMe(checked)} />
        </Box>
      </Box>
      <Button onClick={handleLogin} disabled={stringUtils.anyIsNullOrWhiteSpace(email, password)}>
        {t('login_login_button_label')}
      </Button>
      <LoadingBackdrop isLoading={isLoading} />
    </Box>
  );
}

export default Login;
