import {createTheme} from '@mui/material';

export default createTheme({
  palette: {
    primary: {
      main: '#E20613',
    },
    secondary: {
      main: '#989797',
    },
    text: {
      primary: '#989797',
    },
    background: {
      default: '#F5F5F5',
    },
    custom: {
      surfaceSecondary: '#989797',
      surfaceTertiary: '#C0C0C0',
      surfaceQuaternary: '#D6D6D6',
      surfaceBrand: '#E20613',
      surfaceBlue: '#7CA7D0',
      surfaceWhite: '#FFFFFF',
      surfaceBackground: '#F5F5F5',
      textPrimary: '#989797',
      textSecondary: '#FFFFFF',
      textTertiary: '#C0C0C0',
      textBrand: '#E20613',
      textBlue: '#7CA7D0',
      textBlocked: '#D6D6D6',
      iconsPrimary: '#989797',
      iconsSecondary: '#FFFFFF',
      iconsTertiary: '#C0C0C0',
      iconsQuaternary: '#D6D6D6',
      iconsBrand: '#E20613',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        style: {
          padding: '6px',
          fontSize: '18px',
          textTransform: 'none',
          borderRadius: '10px',
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        spellCheck: false,
      },
    },
    MuiTableCell: {
      defaultProps: {
        style: {
          border: 'none',
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 744,
      md: 960,
      lg: 1440,
      xl: 1920,
    },
  }
});
