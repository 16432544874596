import {SvgIcon, SxProps} from '@mui/material';
import {ElementType} from 'react';

export type IconProps = {
  sx?: SxProps;
  IconComponent: ElementType<any>;
  size?: string;
  color?: string;
};

function Icon(props: IconProps) {
  const {sx, IconComponent, size = '32px', color = 'custom.iconsPrimary'} = props;
  return (
    <SvgIcon
      sx={{width: size, height: size, color: color, ...sx}}
      component={IconComponent}
      inheritViewBox
    />
  );
}

export default Icon;
