export enum CleaningId {
  Soft = 1,
  Medium = 2,
  High = 3,
  Manual = 4,
}

type Cleaning = {
  id: number;
  description: string;
  duration: number;
  temperature: number;
  waterQuantity: number;
  ovenModelId: number;
  ovenSubModelId?: number | null;
};

export default Cleaning;
