import {Box, Typography} from '@mui/material';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation} from 'react-query';
import {useNavigate} from 'react-router-dom';
import useSplashScreen from '../../hooks/common/use-splash-screen';
import useUser from '../../hooks/common/use-user';
import paths from '../../routes/paths';
import services from '../../services/provider';
import cryptoUtils from '../../utils/crypto';
import errorUtils, {ApplicationErrorCode} from '../../utils/errors';
import stringUtils from '../../utils/strings';
import {Button} from '../common/Button';
import {Input} from '../common/Input';
import LoadingBackdrop from '../common/LoadingBackdrop';

function ChangePassword() {
  const navigate = useNavigate();
  const {splash} = useSplashScreen();
  const {t} = useTranslation();

  const {user} = useUser();

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const [invalidPassword, setInvalidPassword] = useState(false);

  const {mutate: changePassword, isLoading} = useMutation({
    mutationFn: services.user.changeUserPassword,
    onSuccess: () =>
      splash({
        title: t('splash_screen_password_changed_title'),
        caption: t('splash_screen_password_changed_caption'),
        nextAction: () => navigate(`${paths.profileInformation}/preferences`),
      }),
    onError: (error) =>
      errorUtils.handleMatch(error, [ApplicationErrorCode.InvalidEmailOrPassword], () =>
        setInvalidPassword(true),
      ),
  });

  function handleChangePassword() {
    if (user != null) {
      changePassword({
        params: {userId: user.id},
        request: {
          currentPassword: cryptoUtils.sha256(currentPassword),
          newPassword: cryptoUtils.sha256(newPassword),
        },
      });
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '72px',
        width: {xs: '504px', md: '790px'},
        maxWidth: '80vw',
      }}>
      <Box>
        <Typography
          sx={{fontSize: '32px', fontStyle: 'normal', fontWeight: 400, lineHeight: '40px'}}>
          {t('profile_change_password_title')}
        </Typography>
        {invalidPassword && (
          <Typography
            sx={{
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
              color: 'custom.textBrand',
            }}>
            {t('profile_change_password_invalid_password_label')}
          </Typography>
        )}
      </Box>
      <Input
        type="password"
        label={t('profile_change_password_current_password_input_label')}
        value={currentPassword}
        onChange={(event) => setCurrentPassword(event.target.value)}
      />
      <Input
        type="password"
        label={t('profile_change_password_new_password_input_label')}
        value={newPassword}
        onChange={(event) => setNewPassword(event.target.value)}
        onKeyUp={(event) => (event.key === 'Enter' ? handleChangePassword() : null)}
      />
      <Button
        onClick={handleChangePassword}
        disabled={stringUtils.anyIsNullOrWhiteSpace(currentPassword, newPassword)}>
        {t('profile_change_password_change_password_button_label')}
      </Button>
      <LoadingBackdrop isLoading={isLoading} />
    </Box>
  );
}

export default ChangePassword;
