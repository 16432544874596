import RecipeType from '../models/entities/recipe-type';
import {Language} from './types';

const recipeTypeUtils = {
  getDescription: function (recipeType: RecipeType, language: Language) {
    switch (language) {
      case 'en':
        return recipeType.descriptionEn ?? recipeType.description;
      case 'es':
        return recipeType.descriptionEs ?? recipeType.description;
      case 'pt':
        return recipeType.descriptionPt ?? recipeType.description;
      case 'fr':
        return recipeType.descriptionFr ?? recipeType.description;
      case 'pl':
        return recipeType.descriptionPl ?? recipeType.description;
      default:
        return recipeType.description;
    }
  },
};

export default recipeTypeUtils;
