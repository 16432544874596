import {Box} from '@mui/material';
import {Outlet} from 'react-router-dom';
import ProfileMenu from './ProfileMenu';

function ProfileLayout() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {xs: 'column', md: 'row'},
        width: '100vw',
        height: {xs: 'calc(100vh - 124px)', md: 'calc(100vh - 148px)'},
        overflow: 'auto',
      }}>
      <Box
        sx={{
          width: {md: '173px'},
          marginTop: {xs: '32px', md: '96px'},
          marginLeft: {xs: '60px', sm: '120px'},
        }}>
        <ProfileMenu />
      </Box>
      <Outlet />
    </Box>
  );
}

export default ProfileLayout;
