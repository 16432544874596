import {Box} from '@mui/material';
import {PropsWithChildren} from 'react';
import {Outlet} from 'react-router-dom';
import {FirebaseSubscriber} from '../../firebase/Subscriber';
import {getSubscriptionToken} from '../../firebase/app';
import useEffectOnce from '../../hooks/common/use-effect-once';
import ErrorModal from '../common/ErrorModal';
import LoadingBackdrop from '../common/LoadingBackdrop';
import SplashScreen from '../common/SplashScreen';
import {AuthNavbar, DashboardNavbar} from '../navigation/Navbar';
import useUser from '../../hooks/common/use-user';

function AppContainer(props: PropsWithChildren) {
  const {isLoading} = useUser();

  useEffectOnce(() => {
    getSubscriptionToken();
  });

  return (
    <FirebaseSubscriber>
      {isLoading ? <LoadingBackdrop isLoading /> : props.children}
    </FirebaseSubscriber>
  );
}

function Main({auth = false}) {
  return (
    <AppContainer>
      {auth ? <AuthNavbar /> : <DashboardNavbar />}
      <Box component="main">
        <Outlet />
      </Box>
      <ErrorModal />
      <SplashScreen />
    </AppContainer>
  );
}

export default Main;
