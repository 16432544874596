import {Box, List, ListItem, Typography} from '@mui/material';
import {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation, useQueryClient} from 'react-query';
import {useNavigate} from 'react-router-dom';
import {ReactComponent as DeleteIcon} from '../../assets/icons/delete.svg';
import {ReactComponent as EditIcon} from '../../assets/icons/edit.svg';
import {ReactComponent as UploadIcon} from '../../assets/icons/upload.svg';
import useSplashScreen from '../../hooks/common/use-splash-screen';
import useUser from '../../hooks/common/use-user';
import Bakery from '../../models/entities/bakery';
import City from '../../models/entities/city';
import Country from '../../models/entities/country';
import paths from '../../routes/paths';
import services from '../../services/provider';
import stringUtils from '../../utils/strings';
import ActionBanner from '../common/ActionBanner';
import ConfirmPopover from '../common/ConfirmPopover';
import Icon from '../common/Icon';
import IconButton from '../common/IconButton';

function UpdateNameContainer({
  defaultName,
  onUpdateName,
}: {
  defaultName: string;
  onUpdateName: (name: string) => void;
}) {
  const {t} = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [name, setName] = useState(defaultName);

  const containerRef = useRef<HTMLDivElement | null>(null);

  function handleUpdateName() {
    if (!stringUtils.isNullOrWhiteSpace(name) && name !== defaultName) {
      onUpdateName(name);
    }
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);

  return (
    <Box
      ref={containerRef}
      sx={{display: 'flex', justifyContent: 'center', width: {xs: '60px', sm: '120px'}}}>
      <IconButton
        sx={{zIndex: open ? 1500 : undefined}}
        IconComponent={EditIcon}
        isActive={open}
        onClick={() => {
          setName(defaultName);
          setAnchorEl(open ? null : containerRef.current);
        }}
      />
      <ConfirmPopover
        isOpen={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placeHolder={t('recipe_add_recipe_type_label')}
        value={name}
        onChange={setName}
        onAccept={handleUpdateName}
        onDecline={() => {
          setName(defaultName);
          setAnchorEl(null);
        }}
      />
    </Box>
  );
}

export type BakerySettingsProps = {
  selectedCountry: Country;
  selectedCity: City;
  selectedBakery: Bakery;
};

function BakerySettings(props: BakerySettingsProps) {
  const {selectedCountry, selectedCity, selectedBakery} = props;
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {t} = useTranslation();
  const {splash} = useSplashScreen();

  const {user} = useUser();

  const {mutate: updateBakery} = useMutation({
    mutationFn: services.bakery.updateBakery,
    onMutate: async ({request}) => {
      await queryClient.cancelQueries('companyCountries');
      const previousData = queryClient.getQueryData<Country[]>([
        'companyCountries',
        user?.companyId,
      ]);
      queryClient.setQueryData<Country[] | undefined>(
        ['companyCountries', user?.companyId],
        (countries) =>
          countries?.map((country) => ({
            ...country,
            districts: country.districts?.map((district) => ({
              ...district,
              cities: district.cities?.map((city) => ({
                ...city,
                bakeries: city.bakeries?.map((bakery) => ({
                  ...bakery,
                  name: bakery.id === selectedBakery.id ? request.name : bakery.name,
                })),
              })),
            })),
          })),
      );
      return {previousData};
    },
    onError: (error, request, context) => {
      queryClient.setQueryData(['companyCountries', user?.companyId], context?.previousData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries('companyCountries');
    },
  });

  const {mutate: deleteBakery} = useMutation({
    mutationFn: services.bakery.deleteBakery,
    onMutate: async ({params}) => {
      await queryClient.cancelQueries('companyCountries');
      const previousData = queryClient.getQueryData<Country[]>([
        'companyCountries',
        user?.companyId,
      ]);
      queryClient.setQueryData<Country[] | undefined>(
        ['companyCountries', user?.companyId],
        (countries) =>
          countries?.map((country) => ({
            ...country,
            districts: country.districts?.map((district) => ({
              ...district,
              cities: district.cities?.map((city) => ({
                ...city,
                bakeries: city.bakeries?.filter((bakery) => bakery.id !== params.bakeryId),
              })),
            })),
          })),
      );
      return {previousData};
    },
    onError: (error, request, context) => {
      queryClient.setQueryData(['companyCountries', user?.companyId], context?.previousData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries('companyCountries');
      navigate(`${paths.bakeries}/${selectedCountry.id}/${selectedCity.id}`);
    },
  });

  const {mutate: syncBakery} = useMutation({
    mutationFn: services.bakery.syncBakery,
  });

  function handleUpdateName(name: string) {
    updateBakery({
      params: {bakeryId: selectedBakery.id},
      request: {...selectedBakery, name},
    });
  }

  function handleDeleteBakery() {
    splash({
      title: t('splash_screen_delete_bakery_title'),
      caption: t('splash_screen_delete_bakery_caption'),
      acceptAction: () => deleteBakery({params: {bakeryId: selectedBakery.id}}),
    });
  }

  function handleSyncBakery() {
    syncBakery({
      params: {
        bakeryId: selectedBakery.id,
      },
    });
  }

  return (
    <List
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
        width: {xs: 'calc(100vw - 60px)', sm: 'calc(100vw - 120px)', md: 'calc(100vw - 325px)'},
        margin: 0,
        padding: 0,
        marginTop: '32px',
      }}>
      <ListItem
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '32px',
          margin: 0,
          padding: 0,
          paddingLeft: '32px',
        }}>
        <Typography
          sx={{
            flex: 1,
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '28px',
          }}>
          {t('bakery_settings_name_label')}
        </Typography>
        <Typography
          sx={{fontSize: '16px', fontStyle: 'normal', fontWeight: 400, lineHeight: '24px'}}>
          {selectedBakery.name}
        </Typography>
        <UpdateNameContainer defaultName={selectedBakery.name} onUpdateName={handleUpdateName} />
      </ListItem>
      <ListItem
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '32px',
          margin: 0,
          padding: 0,
          paddingLeft: '32px',
          paddingRight: {xs: '92px', sm: '152px'},
        }}>
        <Typography
          sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 400, lineHeight: '28px'}}>
          {t('bakery_settings_country_label')}
        </Typography>
        <Typography
          sx={{fontSize: '16px', fontStyle: 'normal', fontWeight: 400, lineHeight: '24px'}}>
          {selectedCountry.name}
        </Typography>
      </ListItem>
      <ListItem
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '32px',
          margin: 0,
          padding: 0,
          paddingLeft: '32px',
          paddingRight: {xs: '92px', sm: '152px'},
        }}>
        <Typography
          sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 400, lineHeight: '28px'}}>
          {t('bakery_settings_city_label')}
        </Typography>
        <Typography
          sx={{fontSize: '16px', fontStyle: 'normal', fontWeight: 400, lineHeight: '24px'}}>
          {selectedCity.name}
        </Typography>
      </ListItem>
      <ListItem
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '32px',
          margin: 0,
          padding: 0,
          paddingRight: {xs: '60px', sm: '120px'},
        }}>
        <ActionBanner
          color="custom.surfaceBrand"
          text={t('bakery_settings_delete_bakery_label')}
          secondary={<Icon IconComponent={DeleteIcon} color="custom.iconsSecondary" />}
          onClick={handleDeleteBakery}
        />
      </ListItem>
      <ListItem
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '32px',
          margin: 0,
          padding: 0,
          paddingRight: {xs: '60px', sm: '120px'},
        }}>
        <ActionBanner
          color="custom.surfaceBrand"
          text={t('bakery_settings_sync_ovens_label')}
          secondary={<Icon IconComponent={UploadIcon} color="custom.iconsSecondary" />}
          onClick={handleSyncBakery}
        />
      </ListItem>
    </List>
  );
}

export default BakerySettings;
