import {Navigate, Outlet} from 'react-router-dom';
import useAuthStore from '../state/auth';
import paths from './paths';

function PrivateRoute() {
  const accessToken = useAuthStore((state) => state.accessToken);
  const isAuthenticated = accessToken != null;
  return isAuthenticated ? <Outlet /> : <Navigate to={paths.welcome} replace />;
}

export default PrivateRoute;
