import {Box, Typography} from '@mui/material';
import {ChangeEvent, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as CheckedIcon} from '../../../../../assets/icons/accept-simple.svg';
import {ReactComponent as UncheckedIcon} from '../../../../../assets/icons/decline-simple.svg';
import {OvenModelId} from '../../../../../models/entities/oven-model';
import stringUtils from '../../../../../utils/strings';
import Icon from '../../../../common/Icon';
import {SmallInput} from '../../../../common/Input';
import {SerialNumberStepProps} from './SerialNumberStep';

function CompactramSerialNumberStep(props: SerialNumberStepProps) {
  const {ovens, setUiState, addOvenData, setAddOvenData} = props;
  const {t} = useTranslation();

  const [isValidSerialNumber, setIsValidSerialNumber] = useState(false);

  function handleChangeSerialNumber(event: ChangeEvent<HTMLInputElement>) {
    const serialNumber = event.target.value;

    setAddOvenData((addOvenData) => ({...addOvenData, serialNumber}));

    const oven = ovens.find(
      (oven) => oven.ovenModelId === OvenModelId.Compactram && oven.serialNumber === serialNumber,
    );

    setIsValidSerialNumber(oven != null);

    setUiState((uiState) => ({
      ...uiState,
      isNextButtonVisible: !uiState.visibleSteps.includes('description') && oven != null,
      currentStep: 'serial-number',
      nextStep: 'description',
    }));
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px', paddingInline: '32px'}}>
      <Typography sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 500, lineHeight: '28px'}}>
        {t('enter_the_serial_number')}
      </Typography>
      <Box sx={{width: '100%', height: '62px', textAlign: 'end'}}>
        <SmallInput
          fullWidth
          label={t('oven')}
          InputProps={{
            endAdornment: !stringUtils.isNullOrWhiteSpace(addOvenData.serialNumber) && (
              <Icon IconComponent={isValidSerialNumber ? CheckedIcon : UncheckedIcon} />
            ),
          }}
          value={addOvenData.serialNumber}
          onChange={handleChangeSerialNumber}
        />
        {!stringUtils.isNullOrWhiteSpace(addOvenData.serialNumber) && !isValidSerialNumber && (
          <Typography
            sx={{
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
              color: 'custom.textBrand',
            }}>
            {t('invalid_serial_number')}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default CompactramSerialNumberStep;
