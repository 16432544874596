import {format} from 'date-fns';
import {TFunction} from 'i18next';
import {Day, Language} from './types';

type DayObject = {key: string; value: Day};

export const weekDays: Record<Day, DayObject> = {
  0: {key: 'sunday', value: 0},
  1: {key: 'monday', value: 1},
  2: {key: 'tuesday', value: 2},
  3: {key: 'wednesday', value: 3},
  4: {key: 'thursday', value: 4},
  5: {key: 'friday', value: 5},
  6: {key: 'saturday', value: 6},
};

export const weekDaysArray: DayObject[] = [
  weekDays[1],
  weekDays[2],
  weekDays[3],
  weekDays[4],
  weekDays[5],
  weekDays[6],
  weekDays[0],
];

export const dateUtils = {
  parseWeekDay: function (weekDay: string | null | undefined, fallbackValue: Day = 0): Day {
    const parsed = parseInt(weekDay ?? '');
    return !isNaN(parsed) && parsed >= 0 && parsed <= 6 ? (parsed as Day) : fallbackValue;
  },
  toLocaleDateString: function (date: Date, language: Language) {
    switch (language) {
      case 'en':
        return format(date, 'MM/dd/yyyy');
      case 'es':
        return format(date, 'dd/MM/yyyy');
      case 'pt':
        return format(date, 'dd/MM/yyyy');
      case 'fr':
        return format(date, 'dd/MM/yyyy');
      case 'pl':
        return format(date, 'yyyy-MM-dd');
    }
  },
  setTime: function (date: Date, time: string) {
    const result = new Date(date);
    const parts = time.split(':');
    if (parts[0] != null) {
      result.setHours(Number(parts[0]));
    }
    if (parts[1] != null) {
      result.setMinutes(Number(parts[1]));
    }
    result.setSeconds(0);
    return result;
  },
  nextDateOfDay: function (date: Date, day: Day) {
    const result = new Date(date);
    result.setDate(date.getDate() + ((day + 7 - date.getDay()) % 7));
    return result;
  },
  previousDateOfDay: function (date: Date, day: Day) {
    const result = new Date(date);
    result.setDate(date.getDate() - ((date.getDay() + 7 - day) % 7));
    return result;
  },
  getMonthName: function (month: number, t: TFunction) {
    return [
      t('january'),
      t('february'),
      t('march'),
      t('april'),
      t('may'),
      t('june'),
      t('july'),
      t('august'),
      t('september'),
      t('october'),
      t('november'),
      t('december'),
    ][month];
  },
  getMonthNameAbbreviation: function (month: number, t: TFunction) {
    return [
      t('jan'),
      t('feb'),
      t('mar'),
      t('apr'),
      t('may-short'),
      t('jun'),
      t('jul'),
      t('aug'),
      t('sep'),
      t('oct'),
      t('nov'),
      t('dec'),
    ][month];
  },
  getWeekDayName: function (weekDay: Day, t: TFunction) {
    return [
      t('sunday'),
      t('monday'),
      t('tuesday'),
      t('wednesday'),
      t('thursday'),
      t('friday'),
      t('saturday'),
    ][weekDay];
  },
};

export default dateUtils;
