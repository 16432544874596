import {Box} from '@mui/material';
import {Fragment, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation, useQuery} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';
import {queryClient} from '../../App';
import {ReactComponent as AddIcon} from '../../assets/icons/add.svg';
import {ReactComponent as DeclineIcon} from '../../assets/icons/decline.svg';
import useSplashScreen from '../../hooks/common/use-splash-screen';
import useUser from '../../hooks/common/use-user';
import Oven from '../../models/entities/oven';
import OvenChamber from '../../models/entities/oven-chamber';
import OvenGroup from '../../models/entities/oven-group';
import paths from '../../routes/paths';
import services from '../../services/provider';
import useBreadcrumbsStore, {Breadcrumb} from '../../state/breadcrumbs';
import arrayUtils from '../../utils/arrays';
import countryUtils from '../../utils/countries';
import numberUtils from '../../utils/numbers';
import ActionBanner from '../common/ActionBanner';
import Icon from '../common/Icon';
import IconButton from '../common/IconButton';
import LoadingBackdrop from '../common/LoadingBackdrop';
import DateSelector from '../programming/DateSelector';
import BakeryList from './BakeryList';
import BakeryMenu, {BakeryMenuItem} from './BakeryMenu';
import BakeryOvenModels from './BakeryOvenModels';
import BakeryOvens from './BakeryOvens';
import BakeryRecipes from './BakeryRecipes';
import BakerySettings from './BakerySettings';
import CityList from './CityList';
import CountryTabs from './CountryTabs';
import {OvenPanelMenuItem} from './OvenPanelMenu';
import OvenSchedules from './OvenSchedules';
import SearchField from './SearchField';

function parseMenuItem(value?: string): BakeryMenuItem {
  switch (value) {
    case 'ovens':
    case 'settings':
    case 'recipes':
      return value;
    default:
      return 'settings';
  }
}

function Bakeries() {
  const navigate = useNavigate();
  const {countryId, cityId, bakeryId, menuItem, ovenModelId, ovenGroupOrOvenId, ovenPanelMenuItem} =
    useParams();
  const {t} = useTranslation();
  const {splash} = useSplashScreen();

  const setBreadcrumbs = useBreadcrumbsStore((state) => state.setBreadcrumbs);

  const {user} = useUser();

  const [searchText, setSearchText] = useState('');
  const [ovenGroupId, setOvenGroupId] = useState('');
  const [ovenId, setOvenId] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());

  const {data: countries = [], isLoading: isLoadingCountries} = useQuery({
    enabled: user != null,
    queryKey: ['companyCountries', user?.companyId],
    queryFn: () =>
      services.company.getCompanyCountries({
        params: {
          companyId: user!.companyId,
        },
        query: {
          expand: ['districts', 'cities', 'bakeries'],
        },
      }),
    select: (countries) => countryUtils.filterStockBakeries(countries, user),
  });

  const {selectedCountry, cities, selectedCity, bakeries, selectedBakery} = useMemo(() => {
    const selectedCountryId = numberUtils.parseInt(countryId);
    const selectedCountry = countries.find((country) => country.id === selectedCountryId);
    const cities = selectedCountry?.districts?.flatMap((district) => district.cities ?? []) ?? [];
    const selectedCityId = numberUtils.parseInt(cityId);
    const selectedCity = cities.find((city) => city.id === selectedCityId);
    const bakeries = selectedCity?.bakeries ?? [];
    const selectedBakery = bakeries.find((bakery) => bakery.id === bakeryId);
    return {selectedCountry, cities, selectedCity, bakeries, selectedBakery};
  }, [countries, countryId, cityId, bakeryId]);

  const {data: ovenModels = [], isLoading: isLoadingOvenModels} = useQuery({
    enabled: user != null && selectedBakery != null,
    queryKey: ['bakeryOvenModels', selectedBakery?.id],
    queryFn: () =>
      services.bakery.getBakeryOvenModels({
        params: {
          bakeryId: selectedBakery!.id,
        },
        query: {
          expand: ['ovenGroups', 'ovens', 'ovenChambers', 'ovenPanels'],
        },
      }),
  });

  const selectedMenuItem = parseMenuItem(menuItem);
  const selectedOvenModel = ovenModels.find(
    (ovenModel) => ovenModel.id === numberUtils.parseInt(ovenModelId),
  );
  const paramsSelectedOvenGroup = selectedOvenModel?.ovenGroups?.find(
    (ovenGroup) => ovenGroup.id === ovenGroupOrOvenId,
  );
  const paramsSelectedOven = selectedOvenModel?.ovens?.find(
    (oven) => oven.id === ovenGroupOrOvenId,
  );

  const {mutate: updateOvenGroup, isLoading: isLoadingUpdateOvenGroup} = useMutation({
    mutationFn: services.ovenGroup.updateOvenGroup,
    onSuccess: () => {
      queryClient.invalidateQueries('bakeryOvenModels');
    },
  });

  const {mutate: updateOven, isLoading: isLoadingUpdateOven} = useMutation({
    mutationFn: services.oven.updateOven,
    onSuccess: () => {
      queryClient.invalidateQueries('bakeryOvenModels');
    },
  });

  const {mutate: updateOvenChamber, isLoading: isLoadingUpdateOvenChamber} = useMutation({
    mutationFn: services.ovenChamber.updateOvenChamber,
    onSuccess: () => {
      queryClient.invalidateQueries('bakeryOvenModels');
    },
  });

  useEffect(() => {
    if (selectedCountry == null && !arrayUtils.isNullOrEmpty(countries)) {
      navigate(`${paths.bakeries}/${countries[0].id}`, {replace: true});
    }
  }, [countries, selectedCountry, navigate]);

  useEffect(() => {
    const breadcrumbs: Breadcrumb[] = [];

    breadcrumbs.push({
      title: t('bakeries_breadcrumb'),
      onClick: () => navigate(paths.bakeries),
    });

    if (selectedCountry != null) {
      breadcrumbs.push({
        title: selectedCountry.name,
        onClick: () => navigate(`${paths.bakeries}/${selectedCountry.id}`),
      });

      if (selectedCity != null) {
        breadcrumbs.push({
          title: selectedCity.name,
          onClick: () => navigate(`${paths.bakeries}/${selectedCountry.id}/${selectedCity.id}`),
        });

        if (selectedBakery != null) {
          breadcrumbs.push({
            title: selectedBakery.name,
            onClick: () =>
              navigate(
                `${paths.bakeries}/${selectedCountry.id}/${selectedCity.id}/${selectedBakery.id}`,
              ),
          });

          const menuItemBreadcrumb =
            selectedMenuItem === 'settings'
              ? t('bakery_settings_breadcrumb')
              : selectedMenuItem === 'ovens'
              ? t('bakery_ovens_breadcrumb')
              : t('bakery_recipes_breadcrumb');

          breadcrumbs.push({
            title: menuItemBreadcrumb,
            onClick: () =>
              navigate(
                `${paths.bakeries}/${selectedCountry.id}/${selectedCity.id}/${
                  selectedBakery.id
                }/${selectedMenuItem}${
                  selectedMenuItem === 'ovens' && ovenModels.length > 0
                    ? `/${ovenModels[0].id}`
                    : ''
                }`,
              ),
          });

          if (selectedMenuItem === 'ovens' && selectedOvenModel != null) {
            breadcrumbs.push({
              title: selectedOvenModel.description,
            });
          }
        }
      }
    }

    setBreadcrumbs(breadcrumbs);

    return () => setBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedCountry,
    selectedCity,
    selectedBakery,
    selectedMenuItem,
    ovenModels,
    selectedOvenModel,
    selectedMenuItem,
  ]);

  function handleChangeOvenGroupDescription(ovenGroup: OvenGroup) {
    updateOvenGroup({
      params: {ovenGroupId: ovenGroup.id},
      request: ovenGroup,
    });
  }

  function handleChangeOvenDescription(oven: Oven) {
    updateOven({
      params: {ovenId: oven.id},
      request: oven,
    });
  }

  function handleDeleteOven(oven: Oven) {
    splash({
      title: t('delete_oven_message'),
      caption: t('delete_oven_caption'),
      acceptAction: () =>
        updateOven({
          params: {ovenId: oven.id},
          request: {
            ...oven,
            ovenGroupId: null,
            ovenGroupOrder: null,
            bakeryId: user?.companyId,
          },
        }),
    });
  }

  function handleDeleteOvenChamber(ovenChamber: OvenChamber) {
    splash({
      title: t('delete_oven_message'),
      caption: t('delete_oven_caption'),
      acceptAction: () =>
        updateOvenChamber({
          params: {ovenChamberId: ovenChamber.id},
          request: {
            ...ovenChamber,
            ovenId: null,
            ovenOrder: null,
            bakeryId: user?.companyId,
          },
        }),
    });
  }

  function handleOvenPanelMenuClick(args: {
    menuItem: OvenPanelMenuItem;
    ovenPanelId: string;
    ovenChamberId?: string;
    ovenId: string;
    ovenGroupId?: string;
  }) {
    if (args.menuItem === 'programs') {
      navigate(
        `${paths.bakeries}/${selectedCountry?.id}/${selectedCity?.id}/${
          selectedBakery?.id
        }/${selectedMenuItem}/${selectedOvenModel?.id}/${args.ovenGroupId ?? args.ovenId}/programs`,
      );
    } else if (args.menuItem === 'statistics') {
      navigate(
        `${paths.statistics}/${selectedCountry?.id}/${selectedCity?.id}/${selectedBakery?.id}`,
      );
    }
  }

  const isLoading =
    isLoadingCountries ||
    isLoadingOvenModels ||
    isLoadingUpdateOvenGroup ||
    isLoadingUpdateOven ||
    isLoadingUpdateOvenChamber;

  const renderCountryTabs = selectedBakery == null && countries.length > 1;
  const renderCityList = selectedCity == null;
  const renderBakeryList = selectedCity != null && selectedBakery == null;
  const renderBakeryMenu = selectedBakery != null;
  const renderSearchField = selectedMenuItem !== 'recipes' || selectedOvenModel == null;
  const renderAddBakeryBanner = selectedBakery == null;
  const renderBakerySettings =
    selectedCountry != null &&
    selectedCity != null &&
    selectedBakery != null &&
    selectedMenuItem === 'settings';
  const renderBakeryOvenModels =
    selectedBakery != null &&
    selectedOvenModel == null &&
    (selectedMenuItem === 'ovens' || selectedMenuItem === 'recipes');
  const renderOvenModelBanner =
    selectedBakery != null && selectedOvenModel != null && selectedMenuItem !== 'settings';
  const renderOvenBanner = paramsSelectedOvenGroup != null || paramsSelectedOven != null;
  const renderBakeryOvens =
    selectedBakery != null &&
    selectedOvenModel != null &&
    selectedMenuItem === 'ovens' &&
    paramsSelectedOvenGroup == null &&
    paramsSelectedOven == null;
  const renderBakeryRecipes =
    selectedBakery != null && selectedOvenModel != null && selectedMenuItem === 'recipes';
  const renderOvenSchedules =
    selectedBakery != null &&
    selectedOvenModel != null &&
    selectedMenuItem === 'ovens' &&
    (paramsSelectedOvenGroup != null || paramsSelectedOven != null) &&
    ovenPanelMenuItem === 'programs';

  return (
    <Fragment>
      {renderBakeryMenu && (
        <Box
          sx={{
            width: {md: '173px'},
            marginTop: {xs: '32px', md: '96px'},
            marginLeft: {xs: '60px', sm: '120px'},
          }}>
          <BakeryMenu
            selectedItem={selectedMenuItem}
            onSelectItem={(menuItem) =>
              navigate(
                `${paths.bakeries}/${selectedCountry?.id}/${selectedCity?.id}/${selectedBakery?.id}/${menuItem}`,
              )
            }
          />
        </Box>
      )}
      <Box sx={{marginLeft: {xs: '60px', sm: '120px', md: renderBakeryMenu ? '32px' : '325px'}}}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            width: {
              xs: 'calc(100vw - 120px)',
              sm: 'calc(100vw - 240px)',
              md: 'calc(100vw - 445px)',
            },
            marginTop: {xs: renderBakeryMenu ? '16px' : '32px', md: '24px'},
          }}>
          {renderCountryTabs && (
            <CountryTabs
              countries={countries}
              selectedCountryId={selectedCountry?.id}
              onSelectCountry={(countryId) => navigate(`${paths.bakeries}/${countryId}`)}
            />
          )}
          {renderOvenSchedules && (
            <DateSelector selectedDate={selectedDate} onChangeSelectedDate={setSelectedDate} />
          )}
          {renderSearchField && (
            <SearchField
              searchText={searchText}
              setSearchText={setSearchText}
              selectedCity={selectedCity}
              selectedBakery={selectedBakery}
              onReturnToBakeries={() =>
                navigate(`${paths.bakeries}/${selectedCountry?.id}/${selectedCity?.id}`)
              }
              onReturnToCountries={() => navigate(`${paths.bakeries}/${selectedCountry?.id}`)}
            />
          )}
          {renderAddBakeryBanner && (
            <ActionBanner
              color="custom.surfaceBrand"
              text={t('bakeries_create_bakery_button_label')}
              secondary={<Icon IconComponent={AddIcon} color="custom.iconsSecondary" />}
              onClick={() => navigate(paths.bakeriesCreate)}
            />
          )}
          {renderOvenModelBanner && (
            <Box sx={{display: 'flex', flexDirection: {xs: 'column', sm: 'row'}, gap: '16px'}}>
              <ActionBanner
                color="custom.surfaceTertiary"
                text={selectedOvenModel.description}
                secondary={
                  <IconButton
                    IconComponent={DeclineIcon}
                    inactiveColor="custom.iconsSecondary"
                    activeColor="custom.iconsSecondary"
                    onClick={() =>
                      navigate(
                        `${paths.bakeries}/${selectedCountry?.id}/${selectedCity?.id}/${selectedBakery.id}/${selectedMenuItem}`,
                      )
                    }
                  />
                }
              />
              {renderOvenBanner && (
                <ActionBanner
                  color="custom.surfaceTertiary"
                  text={
                    paramsSelectedOvenGroup?.description ?? paramsSelectedOven?.description ?? ''
                  }
                  secondary={
                    <IconButton
                      IconComponent={DeclineIcon}
                      inactiveColor="custom.iconsSecondary"
                      activeColor="custom.iconsSecondary"
                      onClick={() =>
                        navigate(
                          `${paths.bakeries}/${selectedCountry?.id}/${selectedCity?.id}/${selectedBakery.id}/${selectedMenuItem}/${selectedOvenModel.id}`,
                        )
                      }
                    />
                  }
                />
              )}
            </Box>
          )}
        </Box>
        {renderCityList && (
          <CityList
            searchText={searchText}
            cities={cities}
            onSelectCity={(cityId) =>
              navigate(`${paths.bakeries}/${selectedCountry?.id}/${cityId}`)
            }
            citiesNotFoundMessage={
              selectedCountry == null ? '' : t('bakeries_cities_not_found_label')
            }
          />
        )}
        {renderBakeryList && (
          <BakeryList
            searchText={searchText}
            bakeries={bakeries}
            onSelectBakery={(bakeryId) =>
              navigate(
                `${paths.bakeries}/${selectedCountry?.id}/${selectedCity?.id}/${bakeryId}/${selectedMenuItem}`,
              )
            }
            bakeriesNotFoundMessage={
              selectedCity == null ? '' : t('bakeries_bakeries_not_found_label')
            }
          />
        )}
        {renderBakerySettings && (
          <BakerySettings
            selectedCountry={selectedCountry}
            selectedCity={selectedCity}
            selectedBakery={selectedBakery}
          />
        )}
        {renderBakeryOvenModels && (
          <BakeryOvenModels
            ovenModels={ovenModels}
            selectedMenuItem={selectedMenuItem}
            onSelectOvenModel={(ovenModel) =>
              navigate(
                `${paths.bakeries}/${selectedCountry?.id}/${selectedCity?.id}/${selectedBakery?.id}/${selectedMenuItem}/${ovenModel.id}`,
              )
            }
            onAddOven={() =>
              navigate(
                `${paths.bakeries}/${selectedCountry?.id}/${selectedCity?.id}/${selectedBakery?.id}/settings/add-oven`,
              )
            }
          />
        )}
        {renderBakeryOvens && (
          <BakeryOvens
            ovenModels={ovenModels}
            selectedOvenModel={selectedOvenModel}
            selectedOvenGroupId={ovenGroupId}
            selectedOvenId={ovenId}
            onSelectOvenGroup={(ovenGroupId) =>
              setOvenGroupId((selectedOvenGroupId) =>
                ovenGroupId === selectedOvenGroupId ? '' : ovenGroupId,
              )
            }
            onSelectOven={(ovenId) =>
              setOvenId((selectedOvenId) => (ovenId === selectedOvenId ? '' : ovenId))
            }
            onChangeOvenGroupDescription={handleChangeOvenGroupDescription}
            onChangeOvenDescription={handleChangeOvenDescription}
            onDeleteOven={handleDeleteOven}
            onDeleteOvenChamber={handleDeleteOvenChamber}
            onOvenPanelMenuClick={handleOvenPanelMenuClick}
          />
        )}
        {renderOvenSchedules && (
          <OvenSchedules
            selectedOvenGroup={paramsSelectedOvenGroup}
            selectedOven={paramsSelectedOven}
            selectedDate={selectedDate}
          />
        )}
        {renderBakeryRecipes && (
          <BakeryRecipes
            selectedOvenModelId={selectedOvenModel.id}
            selectedBakeryId={selectedBakery.id}
          />
        )}
        <Box sx={{minHeight: '64px'}} />
      </Box>
      <LoadingBackdrop isLoading={isLoading} />
    </Fragment>
  );
}

export default Bakeries;
