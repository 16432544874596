import {
  CreateOvenGroupRequest,
  DeleteOvenGroupParams,
  UpdateOvenGroupParams,
  UpdateOvenGroupRequest,
} from '../models/requests/oven-group';
import {
  CreateOvenGroupResponse,
  DeleteOvenGroupResponse,
  UpdateOvenGroupResponse,
} from '../models/responses/oven-group';
import baseService from './base';
import endpoints from './endpoints';

export type OvenGroupService = {
  createOvenGroup: (args: {request: CreateOvenGroupRequest}) => Promise<CreateOvenGroupResponse>;
  updateOvenGroup: (args: {
    params: UpdateOvenGroupParams;
    request: UpdateOvenGroupRequest;
  }) => Promise<UpdateOvenGroupResponse>;
  deleteOvenGroup: (args: {params: DeleteOvenGroupParams}) => Promise<DeleteOvenGroupResponse>;
};

const ovenGroupService: OvenGroupService = {
  createOvenGroup: async function (args: {
    request: CreateOvenGroupRequest;
  }): Promise<CreateOvenGroupResponse> {
    const {request} = args;

    const result = baseService.post<CreateOvenGroupResponse, CreateOvenGroupRequest>(
      endpoints.ovenGroups,
      request,
    );

    return result;
  },
  updateOvenGroup: async function (args: {
    params: UpdateOvenGroupParams;
    request: UpdateOvenGroupRequest;
  }): Promise<UpdateOvenGroupResponse> {
    const {params, request} = args;

    const result = baseService.patch<UpdateOvenGroupResponse, UpdateOvenGroupRequest>(
      `${endpoints.ovenGroups}/${params.ovenGroupId}`,
      request,
    );

    return result;
  },
  deleteOvenGroup: async function (args: {
    params: DeleteOvenGroupParams;
  }): Promise<DeleteOvenGroupResponse> {
    const {params} = args;

    const result = baseService.delete<DeleteOvenGroupResponse>(
      `${endpoints.ovenGroups}/${params.ovenGroupId}`,
    );

    return result;
  },
};

export default ovenGroupService;
