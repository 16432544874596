import {Box} from '@mui/material';
import Bakery from '../../models/entities/bakery';
import stringUtils from '../../utils/strings';
import GradientOverflow from '../common/GradientOverflow';
import SimpleList, {SimpleListOption} from '../common/SimpleList';

export type BakeryListProps = {
  searchText: string;
  bakeries: Bakery[];
  onSelectBakery: (bakeryId: string) => void;
  bakeriesNotFoundMessage?: string;
};

function BakeryList(props: BakeryListProps) {
  const {searchText, bakeries, onSelectBakery, bakeriesNotFoundMessage} = props;

  function getListOptions(): SimpleListOption[] {
    return bakeries
      .filter(
        (bakery) =>
          stringUtils.unicodeStartsWith(bakery.name, searchText) ||
          stringUtils.unicodeIncludes(bakery.name, ` ${searchText}`),
      )
      .map((bakery) => ({id: bakery.id, primary: bakery.name}));
  }

  return (
    <Box
      sx={{
        width: {xs: 'calc(100vw - 120px)', sm: 'calc(100vw - 240px)', md: 'calc(100vw - 445px)'},
        height: {xs: 'calc(100vh - 368px)', md: 'calc(100vh - 384px)'},
        minHeight: '256px',
        marginTop: '32px',
        paddingInline: '32px',
      }}>
      <GradientOverflow hideScrollbar>
        <SimpleList
          disableSelectedStyle
          options={getListOptions()}
          onChangeSelectedOption={onSelectBakery}
          entriesNotFoundMessage={bakeriesNotFoundMessage}
        />
      </GradientOverflow>
    </Box>
  );
}

export default BakeryList;
