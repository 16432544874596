import {Box} from '@mui/material';
import {Schema, Validator} from 'jsonschema';
import {useTranslation} from 'react-i18next';
import {OvenModelId} from '../../../../models/entities/oven-model';
import Recipe from '../../../../models/entities/recipe';
import numberUtils from '../../../../utils/numbers';
import {getEmptyRecipePhase} from '../RecipeSettings';
import DurationRow from './phase/DurationRow';
import PowerRow from './phase/PowerRow';
import SteamExitValveOpenedRow from './phase/SteamExitValveOpenedRow';
import SteamInjectionDurationRow from './phase/SteamInjectionDurationRow';
import SteamInjectionNumberRow from './phase/SteamInjectionNumberRow';
import TemperatureRow from './phase/TemperatureRow';
import TurbineRestSecondsRow from './phase/TurbineRestSecondsRow';
import TurbineSpeedRow from './phase/TurbineSpeedRow';

export type PhaseStepProps = {
  recipePhaseSchema: Schema;
  selectedRecipePhaseIndex: number;
  recipeBuild: Recipe;
  setRecipeBuild: (setter: (recipeBuild: Recipe) => Recipe) => void;
};

function PhaseStep(props: PhaseStepProps) {
  const {recipePhaseSchema, selectedRecipePhaseIndex, recipeBuild, setRecipeBuild} = props;
  const selectedRecipePhase =
    recipeBuild.recipePhases?.[selectedRecipePhaseIndex] ??
    getEmptyRecipePhase(1, recipeBuild.id, recipeBuild.companyId);
  const errors = new Validator().validate(selectedRecipePhase, recipePhaseSchema).errors;

  const {t} = useTranslation();

  function handleChangeTemperature(temperature: string) {
    setRecipeBuild((recipeBuild) => ({
      ...recipeBuild,
      recipePhases: recipeBuild.recipePhases?.map((recipePhase, index) => ({
        ...recipePhase,
        temperature:
          index === selectedRecipePhaseIndex
            ? numberUtils.parseInt(temperature)
            : recipePhase.temperature,
      })),
    }));
  }

  function handleChangeTopTemperature(topTemperature: string) {
    setRecipeBuild((recipeBuild) => ({
      ...recipeBuild,
      recipePhases: recipeBuild.recipePhases?.map((recipePhase, index) => ({
        ...recipePhase,
        topTemperature:
          index === selectedRecipePhaseIndex
            ? numberUtils.parseInt(topTemperature)
            : recipePhase.topTemperature,
      })),
    }));
  }

  function handleChangeFloorTemperature(floorTemperature: string) {
    setRecipeBuild((recipeBuild) => ({
      ...recipeBuild,
      recipePhases: recipeBuild.recipePhases?.map((recipePhase, index) => ({
        ...recipePhase,
        floorTemperature:
          index === selectedRecipePhaseIndex
            ? numberUtils.parseInt(floorTemperature)
            : recipePhase.floorTemperature,
      })),
    }));
  }

  function handleChangeTopPower(topPower: number) {
    setRecipeBuild((recipeBuild) => ({
      ...recipeBuild,
      recipePhases: recipeBuild.recipePhases?.map((recipePhase, index) => ({
        ...recipePhase,
        topPower: index === selectedRecipePhaseIndex ? topPower : recipePhase.topPower,
      })),
    }));
  }

  function handleChangeFloorPower(floorPower: number) {
    setRecipeBuild((recipeBuild) => ({
      ...recipeBuild,
      recipePhases: recipeBuild.recipePhases?.map((recipePhase, index) => ({
        ...recipePhase,
        floorPower: index === selectedRecipePhaseIndex ? floorPower : recipePhase.floorPower,
      })),
    }));
  }

  function handleChangeDuration(duration: string) {
    setRecipeBuild((recipeBuild) => ({
      ...recipeBuild,
      recipePhases: recipeBuild.recipePhases?.map((recipePhase, index) => ({
        ...recipePhase,
        duration:
          index === selectedRecipePhaseIndex
            ? numberUtils.parseInt(duration) * 60
            : recipePhase.duration,
      })),
    }));
  }

  function handleChangeSteamInjectionNumber(steamInjectionNumber: number) {
    setRecipeBuild((recipeBuild) => ({
      ...recipeBuild,
      recipePhases: recipeBuild.recipePhases?.map((recipePhase, index) => ({
        ...recipePhase,
        steamInjectionNumber:
          index === selectedRecipePhaseIndex
            ? steamInjectionNumber
            : recipePhase.steamInjectionNumber,
      })),
    }));
  }

  function handleChangeSteamInjectionDuration(steamInjectionDuration: number) {
    setRecipeBuild((recipeBuild) => ({
      ...recipeBuild,
      recipePhases: recipeBuild.recipePhases?.map((recipePhase, index) => ({
        ...recipePhase,
        steamInjectionDuration:
          index === selectedRecipePhaseIndex
            ? steamInjectionDuration
            : recipePhase.steamInjectionDuration,
      })),
    }));
  }

  function handleChangeSteamExitValveOpened(steamExitValveOpened: boolean) {
    setRecipeBuild((recipeBuild) => ({
      ...recipeBuild,
      recipePhases: recipeBuild.recipePhases?.map((recipePhase, index) => ({
        ...recipePhase,
        steamExitValveOpened:
          index === selectedRecipePhaseIndex
            ? steamExitValveOpened
            : recipePhase.steamExitValveOpened,
      })),
    }));
  }

  function handleChangeTurbineSpeed(turbineSpeed: number) {
    setRecipeBuild((recipeBuild) => ({
      ...recipeBuild,
      recipePhases: recipeBuild.recipePhases?.map((recipePhase, index) => ({
        ...recipePhase,
        turbineSpeed: index === selectedRecipePhaseIndex ? turbineSpeed : recipePhase.turbineSpeed,
      })),
    }));
  }

  function handleChangeTurbineRestSeconds(turbineRestSeconds: string) {
    setRecipeBuild((recipeBuild) => ({
      ...recipeBuild,
      recipePhases: recipeBuild.recipePhases?.map((recipePhase, index) => ({
        ...recipePhase,
        turbineRestSeconds:
          index === selectedRecipePhaseIndex
            ? numberUtils.parseInt(turbineRestSeconds)
            : recipePhase.turbineRestSeconds,
      })),
    }));
  }

  if (
    recipeBuild.ovenModelId === OvenModelId.Compactram ||
    recipeBuild.ovenModelId === OvenModelId.Electram ||
    recipeBuild.ovenModelId === OvenModelId.Modulram
  ) {
    return (
      <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px', padding: '32px'}}>
        <TemperatureRow
          label={t('recipe_settings_phases_top_temperature_label')}
          message={t('recipe_settings_phases_top_temperature_message')}
          temperature={(selectedRecipePhase?.topTemperature ?? 0).toString()}
          onChange={handleChangeTopTemperature}
          error={errors.some((error) => error.property === 'instance.topTemperature')}
        />
        <TemperatureRow
          label={t('recipe_settings_phases_floor_temperature_label')}
          message={t('recipe_settings_phases_floor_temperature_message')}
          temperature={(selectedRecipePhase?.floorTemperature ?? 0).toString()}
          onChange={handleChangeFloorTemperature}
          error={errors.some((error) => error.property === 'instance.floorTemperature')}
        />
        <PowerRow
          label={t('recipe_settings_phases_top_power_label')}
          message={t('recipe_settings_phases_top_power_message')}
          power={selectedRecipePhase?.topPower ?? 0}
          onChange={handleChangeTopPower}
        />
        <PowerRow
          label={t('recipe_settings_phases_floor_power_label')}
          message={t('recipe_settings_phases_floor_power_message')}
          power={selectedRecipePhase?.floorPower ?? 0}
          onChange={handleChangeFloorPower}
        />
        <DurationRow
          duration={(selectedRecipePhase.duration / 60).toString()}
          onChange={(value) => handleChangeDuration(value)}
          error={errors.some((error) => error.property === 'instance.duration')}
        />
        <SteamInjectionDurationRow
          steamInjectionDuration={selectedRecipePhase.steamInjectionDuration ?? 0}
          onChange={handleChangeSteamInjectionDuration}
        />
        <SteamExitValveOpenedRow
          steamExitValveOpened={selectedRecipePhase.steamExitValveOpened ?? false}
          onChange={handleChangeSteamExitValveOpened}
        />
      </Box>
    );
  }

  if (recipeBuild.ovenModelId === OvenModelId.Rotoram) {
    return (
      <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px', padding: '32px'}}>
        <TemperatureRow
          label={t('recipe_settings_phases_temperature_label')}
          message={t('recipe_settings_phases_temperature_message')}
          temperature={(selectedRecipePhase?.temperature ?? 0).toString()}
          onChange={handleChangeTemperature}
          error={errors.some((error) => error.property === 'instance.temperature')}
        />
        <DurationRow
          duration={(selectedRecipePhase.duration / 60).toString()}
          onChange={(value) => handleChangeDuration(value)}
          error={errors.some((error) => error.property === 'instance.duration')}
        />
        <SteamInjectionNumberRow
          steamInjectionNumber={selectedRecipePhase.steamInjectionNumber ?? 0}
          onChange={handleChangeSteamInjectionNumber}
        />
        <SteamExitValveOpenedRow
          steamExitValveOpened={selectedRecipePhase.steamExitValveOpened ?? false}
          onChange={handleChangeSteamExitValveOpened}
        />
        <TurbineRestSecondsRow
          turbineRestSeconds={(selectedRecipePhase?.turbineRestSeconds ?? 0).toString()}
          onChange={handleChangeTurbineRestSeconds}
          error={errors.some((error) => error.property === 'instance.turbineRestSeconds')}
        />
      </Box>
    );
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px', padding: '32px'}}>
      <TemperatureRow
        label={t('recipe_settings_phases_temperature_label')}
        message={t('recipe_settings_phases_temperature_message')}
        temperature={(selectedRecipePhase?.temperature ?? 0).toString()}
        onChange={handleChangeTemperature}
        error={errors.some((error) => error.property === 'instance.temperature')}
      />
      <DurationRow
        duration={(selectedRecipePhase.duration / 60).toString()}
        onChange={(value) => handleChangeDuration(value)}
        error={errors.some((error) => error.property === 'instance.duration')}
      />
      <SteamInjectionNumberRow
        steamInjectionNumber={selectedRecipePhase.steamInjectionNumber ?? 0}
        onChange={handleChangeSteamInjectionNumber}
      />
      <SteamExitValveOpenedRow
        steamExitValveOpened={selectedRecipePhase.steamExitValveOpened ?? false}
        onChange={handleChangeSteamExitValveOpened}
      />
      <TurbineSpeedRow
        turbineSpeed={selectedRecipePhase.turbineSpeed ?? 0}
        onChange={handleChangeTurbineSpeed}
      />
    </Box>
  );
}

export default PhaseStep;
