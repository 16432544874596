import {Box, Typography} from '@mui/material';
import {ReactComponent as LogoIcon} from '../../assets/icons/logo.svg';
import Icon from './Icon';

function NotFound() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '16px',
        width: '100vw',
        height: '100vh',
        backgroundColor: 'custom.surfaceBrand',
      }}>
      <Icon IconComponent={LogoIcon} size="80px" color="custom.iconsSecondary" />
      <Typography
        sx={{
          fontSize: '32px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: 'normal',
          color: 'custom.textSecondary',
        }}>
        404 - Page Not Found
      </Typography>
      <Typography
        sx={{
          fontSize: '24px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: 'normal',
          color: 'custom.textSecondary',
        }}>
        The page you are looking for does not exist.
      </Typography>
    </Box>
  );
}

export default NotFound;
