import {Box, List, ListItem, Typography, useTheme} from '@mui/material';
import ReactECharts from 'echarts-for-react';
import {useTranslation} from 'react-i18next';
import {useQuery} from 'react-query';
import useUser from '../../hooks/common/use-user';
import services from '../../services/provider';
import recipeUtils from '../../utils/recipes';
import {Language} from '../../utils/types';
import GradientOverflow from '../common/GradientOverflow';
import LoadingBackdrop from '../common/LoadingBackdrop';

export type RecipeStatisticsProps = {
  visualizationType: 'list' | 'graphic';
  bakeryId: string;
  period: {
    startDate: Date;
    endDate: Date;
  };
};

function RecipeStatistics(props: RecipeStatisticsProps) {
  const {i18n} = useTranslation();
  const theme = useTheme();

  const {user} = useUser();

  const {data: recipes = [], isLoading} = useQuery({
    enabled: user != null,
    queryKey: [
      'recipeExecutionStatistics',
      {from: props.period.startDate, to: props.period.endDate, bakeryId: props.bakeryId},
    ],
    queryFn: () =>
      services.telemetry.getRecipeExecutionStatistics({
        query: {
          from: props.period.startDate,
          to: props.period.endDate,
          bakeryId: props.bakeryId,
          companyId: user!.companyId,
        },
      }),
  });

  if (props.visualizationType === 'graphic') {
    return (
      <Box
        sx={{
          height: {xs: 'calc(100vh - 408px)', md: 'calc(100vh - 424px)'},
          minHeight: '256px',
          marginTop: '16px',
        }}>
        <ReactECharts
          style={{width: '100%', height: '100%'}}
          option={{
            tooltip: {
              trigger: 'item',
              textStyle: {
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
                color: theme.palette.custom.textPrimary,
              },
              formatter: (params: any) =>
                `${params.marker} 
                <span style="display:inline-block;vertical-align:middle;margin-right:8px;max-width:200px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">${params.data.displayName}</span>
                <span style="display:inline-block;vertical-align:middle;font-weight:600;">${params.value}</span>`,
            },
            legend: {
              bottom: '0%',
              left: 'center',
              textStyle: {
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                color: theme.palette.custom.textPrimary,
              },
              formatter: (name: string) => name.split('<break/>')[0],
            },
            series: [
              {
                type: 'pie',
                radius: ['45%', '75%'],
                center: ['50%', '40%'],
                padAngle: 2,
                itemStyle: {borderRadius: 10},
                label: {
                  show: false,
                  formatter: (params: any) => params.data.displayName,
                },
                labelLine: {show: false},
                data: recipes.map((recipe) => {
                  const name = recipeUtils.getName(recipe, i18n.language as Language);
                  return {
                    value: recipe.totalExecutions,
                    name: `${name}<break/>${recipe.id}`,
                    displayName: name,
                  };
                }),
              },
            ],
          }}
        />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: {xs: 'calc(100vh - 408px)', md: 'calc(100vh - 424px)'},
        minHeight: '256px',
        marginTop: '16px',
      }}>
      <GradientOverflow hideScrollbar>
        <List sx={{display: 'flex', flexDirection: 'column', gap: '24px', margin: 0, padding: 0}}>
          {recipes.map((recipe) => (
            <ListItem
              key={recipe.id}
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '8px',
                height: '40px',
                margin: 0,
                padding: '12px 32px',
                backgroundColor: 'custom.surfaceTertiary',
                borderRadius: '360px',
              }}>
              <Typography
                sx={{
                  flex: 1,
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: 'normal',
                  color: 'custom.textSecondary',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  maxWidth: '300px',
                }}>
                {recipeUtils.getName(recipe, i18n.language as Language)}
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '24px',
                  color: 'custom.textSecondary',
                }}>
                {recipe.totalExecutions}
              </Typography>
            </ListItem>
          ))}
        </List>
      </GradientOverflow>
      <LoadingBackdrop isLoading={isLoading} />
    </Box>
  );
}

export default RecipeStatistics;
