import {Box, List, ListItem, Typography} from '@mui/material';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as DeclineIcon} from '../../assets/icons/decline.svg';
import Notification from '../../models/entities/notification';
import notificationUtils from '../../utils/notifications';
import stringUtils from '../../utils/strings';
import GradientOverflow from '../common/GradientOverflow';
import IconButton from '../common/IconButton';
import Span from '../common/Span';
import Switch from '../common/Switch';

type NotificationListItemProps = {
  notification: Notification;
  onSelect: () => void;
  onCheck: (checked: boolean) => void;
  onDelete: () => void;
  isSelectingMultiple: boolean;
  selected: boolean;
};

function NotificationListItem(props: NotificationListItemProps) {
  const {notification, onSelect, onCheck, onDelete, isSelectingMultiple, selected} = props;
  const {t} = useTranslation();

  const [isHovered, setIsHovered] = useState(false);

  return (
    <ListItem
      sx={{alignItems: 'center', gap: '16px', margin: 0, padding: 0, paddingInline: '32px'}}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          height: '32px',
          cursor: 'pointer',
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={onSelect}>
        <Typography
          sx={{
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
            color: isHovered
              ? 'custom.textBrand'
              : notification.isSeen
              ? 'custom.textTertiary'
              : 'custom.textPrimary',
          }}>
          <Span sx={{fontWeight: 500}}>{`${notification.ovenDescription ?? ''} | `}</Span>
          <Span>{`${notification.bakeryName ?? ''} | `}</Span>
          <Span sx={{fontWeight: 300}}>
            {stringUtils.format(
              t(notificationUtils.getLabel(notification)),
              notification.ovenPanelOrder ?? 1,
            )}
          </Span>
        </Typography>
      </Box>
      {isSelectingMultiple ? (
        <Switch checked={selected} onChange={(_, checked) => onCheck?.(checked)} />
      ) : (
        <IconButton IconComponent={DeclineIcon} onClick={onDelete} />
      )}
    </ListItem>
  );
}

type NotificationListProps = {
  notifications: Notification[];
  onSelectNotification: (notificationId: string) => void;
  onCheckNotification: (notificationId: string, checked: boolean) => void;
  onDeleteNotification: (notificationId: string) => void;
  isSelectingMultiple: boolean;
  selectedNotificationIds: string[];
  notificationsNotFoundLabel: string;
};

function NotificationList(props: NotificationListProps) {
  const {
    notifications,
    onSelectNotification,
    onDeleteNotification,
    onCheckNotification,
    isSelectingMultiple,
    selectedNotificationIds,
    notificationsNotFoundLabel,
  } = props;

  return (
    <Box
      sx={{
        height: {xs: 'calc(100vh - 404px)', md: 'calc(100vh - 364px)'},
        minHeight: '256px',
        marginTop: '16px',
      }}>
      <GradientOverflow hideScrollbar>
        <List sx={{display: 'flex', flexDirection: 'column', gap: '24px', margin: 0, padding: 0}}>
          {notifications.length === 0 ? (
            <ListItem sx={{alignItems: 'center', margin: 0, padding: 0, paddingInline: '32px'}}>
              <Typography
                sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 400, lineHeight: '28px'}}>
                {notificationsNotFoundLabel}
              </Typography>
            </ListItem>
          ) : (
            notifications.map((notification) => (
              <NotificationListItem
                key={notification.id}
                notification={notification}
                onSelect={() => onSelectNotification(notification.id)}
                onCheck={(checked) => onCheckNotification(notification.id, checked)}
                onDelete={() => onDeleteNotification(notification.id)}
                isSelectingMultiple={isSelectingMultiple}
                selected={selectedNotificationIds.includes(notification.id)}
              />
            ))
          )}
        </List>
      </GradientOverflow>
    </Box>
  );
}

export default NotificationList;
