import {GetRecipeExecutionStatisticsQuery} from '../../models/requests/telemetry';
import {GetRecipeExecutionStatisticsResponse} from '../../models/responses/telemetry';
import {TelemetryService} from '../telemetry';

const mockTelemetryService: TelemetryService = {
  getRecipeExecutionStatistics: function (args: {
    query: GetRecipeExecutionStatisticsQuery;
  }): Promise<GetRecipeExecutionStatisticsResponse> {
    throw new Error('Function not implemented.');
  },
};

export default mockTelemetryService;
