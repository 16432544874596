import {Box} from '@mui/material';
import {useTranslation} from 'react-i18next';
import City from '../../models/entities/city';
import stringUtils from '../../utils/strings';
import GradientOverflow from '../common/GradientOverflow';
import SimpleList, {SimpleListOption} from '../common/SimpleList';

export type CityListProps = {
  searchText: string;
  cities: City[];
  onSelectCity: (cityId: number) => void;
  citiesNotFoundMessage?: string;
};

function CityList(props: CityListProps) {
  const {searchText, cities, onSelectCity, citiesNotFoundMessage} = props;
  const {t} = useTranslation();

  function getListOptions(): SimpleListOption[] {
    return cities
      .filter(
        (city) =>
          stringUtils.unicodeStartsWith(city.name, searchText) ||
          stringUtils.unicodeIncludes(city.name, ` ${searchText}`),
      )
      .map((city) => ({
        id: city.id.toString(),
        primary: city.name,
        secondary: `${city.bakeries?.length ?? 0} ${t('bakeries_available_bakeries_label')}`,
      }));
  }

  return (
    <Box
      sx={{
        height: {xs: 'calc(100vh - 368px)', md: 'calc(100vh - 384px)'},
        minHeight: '256px',
        marginTop: '16px',
        paddingInline: '32px',
      }}>
      <GradientOverflow hideScrollbar>
        <SimpleList
          disableSelectedStyle
          options={getListOptions()}
          onChangeSelectedOption={(cityId) => onSelectCity(Number(cityId))}
          entriesNotFoundMessage={citiesNotFoundMessage}
        />
      </GradientOverflow>
    </Box>
  );
}

export default CityList;
