import {Box, Typography} from '@mui/material';
import {addSeconds, format, isValid, parse} from 'date-fns';
import {Fragment, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {ReactComponent as DeclineIcon} from '../../assets/icons/decline.svg';
import useSplashScreen from '../../hooks/common/use-splash-screen';
import useUser from '../../hooks/common/use-user';
import Recurrence from '../../models/entities/recurrence';
import paths from '../../routes/paths';
import services from '../../services/provider';
import useBreadcrumbsStore, {Breadcrumb} from '../../state/breadcrumbs';
import arrayUtils from '../../utils/arrays';
import cryptoUtils from '../../utils/crypto';
import dateUtils from '../../utils/dates';
import errorUtils, {ApplicationErrorCode} from '../../utils/errors';
import numberUtils from '../../utils/numbers';
import recipeUtils from '../../utils/recipes';
import scheduleUtils from '../../utils/schedules';
import stringUtils from '../../utils/strings';
import ActionBanner from '../common/ActionBanner';
import IconButton from '../common/IconButton';
import LoadingBackdrop from '../common/LoadingBackdrop';
import SimpleSearch from '../common/SimpleSearch';
import Tabs from '../common/Tabs';
import OvenList from './OvenList';
import RecipeList from './RecipeList';
import CreateRecipeScheduleMenu from './RecipeScheduleMenu';
import SelectTime from './SelectTime';

function getEndTime(startTime: string, duration: number) {
  const timeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
  if (!timeRegex.test(startTime)) {
    return '-';
  }
  const endTime = format(addSeconds(parse(startTime, 'HH:mm', new Date()), duration), 'HH:mm');
  return endTime;
}

function getRecurrences(
  startDate: Date,
  startTime: string,
  weekDays: Day[],
  scheduleId: string,
  companyId: string,
) {
  const recurrences: Recurrence[] = [];

  if (!weekDays.some((weekDay) => weekDay === startDate.getDay())) {
    const recurrenceStartDate = dateUtils.setTime(startDate, startTime);

    recurrences.push({
      id: cryptoUtils.uuid(),
      startDate: format(recurrenceStartDate, 'yyyy-MM-dd'),
      startTime: startTime + ':00',
      weekDay: recurrenceStartDate.getDay() as Day,
      repeat: false,
      scheduleId,
      companyId,
    });
  }

  weekDays.forEach((day) => {
    let recurrenceStartDate = dateUtils.setTime(startDate, startTime);
    recurrenceStartDate = dateUtils.nextDateOfDay(startDate, day);

    recurrences.push({
      id: cryptoUtils.uuid(),
      startDate: format(recurrenceStartDate, 'yyyy-MM-dd'),
      startTime: startTime + ':00',
      weekDay: day,
      repeat: true,
      scheduleId,
      companyId,
    });
  });

  return recurrences;
}

export type RecipeScheduleStep = 'recipe' | 'ovens' | 'time';

export type RecipeScheduleUiState = {
  visibleSteps: RecipeScheduleStep[];
  isNextButtonVisible: boolean;
  currentStep: RecipeScheduleStep;
  nextStep: RecipeScheduleStep;
};

export type OvenPanelData = {
  id: string;
  ovenChamberId?: string | null;
  ovenId?: string | null;
  ovenGroupId?: string | null;
};

type RecipeScheduleData = {
  recipeId: string | null;
  ovenPanels: OvenPanelData[];
  startDate: Date;
  startTime: string;
  weekDays: Day[];
};

function RecipeSchedule() {
  const navigate = useNavigate();
  const {countryId, cityId, bakeryId, ovenModelId, scheduleId} = useParams();
  const [searchParams] = useSearchParams();
  const searchParamsDate = parse(searchParams.get('date') ?? '', 'MM-dd', new Date());
  const queryClient = useQueryClient();
  const {t} = useTranslation();

  const {user} = useUser();
  const {splash} = useSplashScreen();

  const setBreadcrumbs = useBreadcrumbsStore((state) => state.setBreadcrumbs);

  const [uiState, setUiState] = useState<RecipeScheduleUiState>({
    visibleSteps: ['recipe'],
    isNextButtonVisible: false,
    currentStep: 'recipe',
    nextStep: 'ovens',
  });

  const [previousScheduleData, setPreviousScheduleData] = useState<RecipeScheduleData>({
    recipeId: null,
    ovenPanels: [],
    startDate: new Date(),
    startTime: '00:00',
    weekDays: [],
  });

  const [scheduleData, setScheduleData] = useState<RecipeScheduleData>({
    recipeId: null,
    ovenPanels: [],
    startDate: isValid(searchParamsDate) ? searchParamsDate : new Date(),
    startTime: '00:00',
    weekDays: [],
  });

  const [selectedRecipeTypeIndex, setSelectedRecipeTypeIndex] = useState(0);
  const [recipeSearchText, setRecipeSearchText] = useState('');
  const [ovenSearchText, setOvenSearchText] = useState('');
  const [endTime, setEndTime] = useState('00:00');
  const [shadowStartTime, setShadowStartTime] = useState('00:00');
  const [suggestedStartTime, setSuggestedStartTime] = useState('00:00');
  const [isValidStartTime, setIsValidStartTime] = useState(true);
  const [hasOverlap, setHasOverlap] = useState(false);

  const selectedOvenPanelIds = scheduleData.ovenPanels.map((ovenPanel) => ovenPanel.id);
  const selectedOvenPanelIdsSet = new Set(selectedOvenPanelIds);
  const selectedWeekDaysSet = new Set(scheduleData.weekDays);

  const {data: country, isLoading: isLoadingCountry} = useQuery({
    enabled: user != null && countryId != null,
    queryKey: ['countries', countryId],
    queryFn: () =>
      services.country.getCountry({
        params: {
          countryId: numberUtils.parseInt(countryId),
        },
      }),
  });

  const {data: city, isLoading: isLoadingCity} = useQuery({
    enabled: user != null && cityId != null,
    queryKey: ['cities', cityId],
    queryFn: () =>
      services.city.getCity({
        params: {
          cityId: numberUtils.parseInt(cityId),
        },
      }),
  });

  const {data: bakery, isLoading: isLoadingBakery} = useQuery({
    enabled: user != null && bakeryId != null,
    queryKey: ['bakeries', bakeryId],
    queryFn: () =>
      services.bakery.getBakery({
        params: {
          bakeryId: bakeryId!,
        },
      }),
  });

  const {data: ovenModel, isLoading: isLoadingOvenModel} = useQuery({
    enabled: user != null && bakery != null,
    queryKey: ['bakeryOvenModels', bakery?.id, ovenModelId],
    queryFn: () =>
      services.bakery.getBakeryOvenModel({
        params: {
          bakeryId: bakery!.id,
          ovenModelId: numberUtils.parseInt(ovenModelId, 1),
        },
        query: {
          expand: ['ovenGroups', 'ovens', 'ovenChambers', 'ovenPanels'],
        },
      }),
  });

  const {isFetching: isLoadingSchedule} = useQuery({
    enabled: user != null && scheduleId != null,
    queryFn: () =>
      services.schedule.getSchedule({
        params: {scheduleId: scheduleId!},
        query: {expand: ['recurrences', 'recipe', 'recipePhases', 'ovenPanels']},
      }),
    onSuccess: (schedule) => {
      if (schedule.recipeId == null) {
        return;
      }

      const startTime = scheduleUtils.getStartTime(schedule);
      const endTime = getEndTime(startTime, recipeUtils.getDuration(schedule.recipe!));

      const scheduleData: RecipeScheduleData = {
        recipeId: schedule.recipeId,
        ovenPanels:
          schedule.ovenPanels?.map((ovenPanel) => ({
            id: ovenPanel.id,
            ovenChamberId: ovenPanel.ovenChamberId,
            ovenId: ovenPanel.ovenChamber?.ovenId ?? ovenPanel.ovenId,
            ovenGroupId: ovenPanel.ovenChamber?.oven?.ovenGroupId ?? ovenPanel.oven?.ovenGroupId,
          })) ?? [],
        startDate: new Date(schedule.recurrences![0].startDate),
        startTime,
        weekDays:
          schedule.recurrences
            ?.filter((recurrence) => recurrence.repeat)
            .map((recurrence) => recurrence.weekDay) ?? [],
      };

      setPreviousScheduleData(scheduleData);
      setScheduleData(scheduleData);

      setEndTime(endTime);
      setSuggestedStartTime(startTime);

      setUiState({
        visibleSteps: ['recipe', 'ovens', 'time'],
        isNextButtonVisible: false,
        currentStep: 'recipe',
        nextStep: 'recipe',
      });
    },
    onError: () => navigate(paths.programming),
  });

  const {data: recipeTypes = [], isLoading: isLoadingRecipeTypes} = useQuery({
    enabled: user != null,
    queryKey: ['recipeTypes', {ovenModelId: ovenModel?.id}],
    queryFn: () =>
      services.recipeType.getRecipeTypes({
        query: {
          ovenModelId: ovenModel?.id,
          companyId: user!.companyId,
        },
      }),
  });

  const {data: recipes = [], isLoading: isLoadingRecipes} = useQuery({
    enabled: user != null,
    queryKey: ['recipes', {ovenModel: ovenModel?.id, expand: ['recipeTypes', 'recipePhases']}],
    queryFn: () =>
      services.recipe.getRecipes({
        query: {
          ovenModelId: ovenModel?.id,
          companyId: user!.companyId,
          expand: ['recipeTypes', 'recipePhases'],
        },
      }),
  });

  const selectedRecipe = recipes.find((recipe) => recipe.id === scheduleData.recipeId);

  const {mutate: createOrUpdateSchedule, isLoading: isLoadingCreateOrUpdateSchedule} = useMutation({
    mutationFn: services.schedule.createOrUpdateSchedule,
    onError: (error) =>
      errorUtils.handleMatch(error, [ApplicationErrorCode.ScheduleOverlapping], () =>
        setHasOverlap(true),
      ),
  });

  useEffect(() => {
    const breadcrumbs: Breadcrumb[] = [];
    breadcrumbs.push({
      title: t('programming_breadcrumb'),
      onClick: () => navigate(paths.programming),
    });
    if (country != null) {
      breadcrumbs.push({
        title: country.name,
        onClick: () => navigate(`${paths.programming}/${country.id}`),
      });

      if (city != null) {
        breadcrumbs.push({
          title: city.name,
          onClick: () => navigate(`${paths.programming}/${country.id}/${city.id}`),
        });

        if (bakery != null) {
          breadcrumbs.push({
            title: bakery.name,
            onClick: () => navigate(`${paths.programming}/${country.id}/${city.id}/${bakery.id}`),
          });

          if (ovenModel != null) {
            breadcrumbs.push({
              title: ovenModel.description,
              onClick: () =>
                navigate(
                  `${paths.programming}/${country.id}/${city.id}/${bakery.id}/${ovenModel.id}`,
                ),
            });

            breadcrumbs.push({title: t('recipe')});
          }
        }
      }
    }
    setBreadcrumbs(breadcrumbs);
    return () => setBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, city, bakery, ovenModel]);

  function getRecipeTypeTabs() {
    const tabs = recipeTypes.map((recipeType) => recipeType.description);
    tabs.unshift(t('recipe_type_all_label'));
    return tabs;
  }

  function filterRecipes() {
    const selectedRecipeTypeId =
      selectedRecipeTypeIndex === 0 ? 'all' : recipeTypes[selectedRecipeTypeIndex - 1].id;

    return recipes.filter(
      (recipe) =>
        (stringUtils.unicodeStartsWith(recipe.name, recipeSearchText) ||
          stringUtils.unicodeIncludes(recipe.name, ` ${recipeSearchText}`)) &&
        (stringUtils.isNullOrWhiteSpace(selectedRecipeTypeId) ||
          selectedRecipeTypeId === 'all' ||
          recipe.recipeTypes?.some((recipeType) => recipeType.id === selectedRecipeTypeId)),
    );
  }

  function filterOvenGroups() {
    return (
      ovenModel?.ovenGroups?.filter(
        (ovenGroup) =>
          stringUtils.unicodeStartsWith(ovenGroup.description ?? '', ovenSearchText) ||
          stringUtils.unicodeIncludes(ovenGroup.description ?? '', ` ${ovenSearchText}`),
      ) ?? []
    );
  }

  function filterOvens() {
    return (
      ovenModel?.ovens?.filter(
        (oven) =>
          oven.ovenGroupId == null &&
          (stringUtils.unicodeStartsWith(oven.description ?? '', ovenSearchText) ||
            stringUtils.unicodeStartsWith(oven.serialNumber, ovenSearchText) ||
            stringUtils.unicodeIncludes(oven.description ?? '', ` ${ovenSearchText}`) ||
            stringUtils.unicodeIncludes(oven.serialNumber, ` ${ovenSearchText}`)),
      ) ?? []
    );
  }

  function handleSelectRecipe(recipeId: string) {
    const selectedRecipe = recipes.find((recipe) => recipe.id === recipeId);

    if (selectedRecipe != null) {
      setScheduleData((scheduleData) => ({...scheduleData, recipeId}));

      const endTime = getEndTime(scheduleData.startTime, recipeUtils.getDuration(selectedRecipe));
      setEndTime(endTime);

      if (!uiState.visibleSteps.includes('ovens')) {
        setUiState({
          visibleSteps: ['recipe'],
          isNextButtonVisible: true,
          currentStep: 'recipe',
          nextStep: 'ovens',
        });
      }
    }
  }

  function handleSelectOvenPanel(ovenPanel: OvenPanelData) {
    if (selectedOvenPanelIdsSet.has(ovenPanel.id)) {
      selectedOvenPanelIdsSet.delete(ovenPanel.id);

      setScheduleData((scheduleData) => ({
        ...scheduleData,
        ovenPanels: scheduleData.ovenPanels.filter(
          (selectedOvenPanel) => selectedOvenPanel.id !== ovenPanel.id,
        ),
      }));
    } else {
      selectedOvenPanelIdsSet.add(ovenPanel.id);

      setScheduleData((scheduleData) => ({
        ...scheduleData,
        ovenPanels: [...scheduleData.ovenPanels, ovenPanel],
      }));
    }

    if (!uiState.visibleSteps.includes('time')) {
      setUiState({
        visibleSteps: ['recipe', 'ovens'],
        isNextButtonVisible: selectedOvenPanelIdsSet.size > 0,
        currentStep: 'ovens',
        nextStep: 'time',
      });
    }
  }

  function validateStartTime(startTime: string) {
    const timeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;

    if (selectedRecipe != null && timeRegex.test(startTime)) {
      const endTime = getEndTime(startTime, recipeUtils.getDuration(selectedRecipe));

      if (endTime > startTime) {
        setEndTime(endTime);
        setIsValidStartTime(true);
      }
    }
  }

  function handleSetStartTime(time: string) {
    setScheduleData((scheduleData) => ({...scheduleData, startTime: time}));
    setShadowStartTime('');
    setIsValidStartTime(false);
    validateStartTime(time);
  }

  function handleStartTimeFocus() {
    setShadowStartTime(scheduleData.startTime);
    setScheduleData((scheduleData) => ({...scheduleData, startTime: ''}));
    setIsValidStartTime(false);
  }

  function handleStartTimeBlur() {
    if (stringUtils.isNullOrWhiteSpace(scheduleData.startTime)) {
      if (!stringUtils.isNullOrWhiteSpace(shadowStartTime)) {
        setScheduleData((scheduleData) => ({...scheduleData, startTime: shadowStartTime}));
        validateStartTime(shadowStartTime);
        return;
      }
      setScheduleData((scheduleData) => ({...scheduleData, startTime: suggestedStartTime}));
      validateStartTime(suggestedStartTime);
    }
  }

  function handleSetStartDate(date: Date) {
    if (date > new Date()) {
      setScheduleData((scheduleData) => ({...scheduleData, startDate: date}));
    }
  }

  function handleSelectWeekDay(weekDay: Day) {
    if (selectedWeekDaysSet.has(weekDay)) {
      selectedWeekDaysSet.delete(weekDay);
    } else {
      selectedWeekDaysSet.add(weekDay);
    }

    setScheduleData((scheduleData) => ({
      ...scheduleData,
      weekDays: Array.from(selectedWeekDaysSet),
    }));
  }

  function handleCreateOrUpdateSchedule() {
    if (user == null) {
      return;
    }

    const id = scheduleId ?? cryptoUtils.uuid();

    const schedule = {
      id,
      recipeId: scheduleData.recipeId,
      companyId: user.companyId,
      recurrences: getRecurrences(
        scheduleData.startDate,
        scheduleData.startTime,
        scheduleData.weekDays,
        id,
        user.companyId,
      ),
      ovenPanels: selectedOvenPanelIds.map((ovenPanelId) => ({id: ovenPanelId})),
    };

    createOrUpdateSchedule(
      {params: {scheduleId: schedule.id}, request: schedule},
      {
        onSuccess: () => {
          queryClient.invalidateQueries('schedules');

          if (scheduleId != null) {
            splash({
              title: t('your_programs_have_been_updated'),
              nextAction: () =>
                navigate(
                  `${paths.programming}/${country?.id}/${city?.id}/${bakery?.id}/${
                    ovenModel?.id
                  }?date=${format(scheduleData.startDate, 'MM-dd')}`,
                ),
            });
          } else {
            navigate(
              `${paths.programming}/${country?.id}/${city?.id}/${bakery?.id}/${
                ovenModel?.id
              }?date=${format(scheduleData.startDate, 'MM-dd')}`,
            );
          }
        },
      },
    );
  }

  const canFinish =
    !uiState.isNextButtonVisible &&
    selectedRecipe != null &&
    !arrayUtils.isNullOrEmpty(selectedOvenPanelIds) &&
    isValidStartTime;
  const hasChanges =
    scheduleData.recipeId !== previousScheduleData.recipeId ||
    !arrayUtils.isEqual(
      scheduleData.ovenPanels,
      previousScheduleData.ovenPanels,
      (a, b) => a.id === b.id,
    ) ||
    scheduleData.startDate !== previousScheduleData.startDate ||
    scheduleData.startTime !== previousScheduleData.startTime ||
    !arrayUtils.isEqual(scheduleData.weekDays, previousScheduleData.weekDays, (a, b) => a === b);

  function handleCancel() {
    if (scheduleId != null && hasChanges) {
      splash({
        title: t('discard_changes_message'),
        caption: t('discard_changes_caption'),
        acceptAction: () =>
          navigate(
            `${paths.programming}/${country?.id}/${city?.id}/${bakery?.id}/${
              ovenModel?.id
            }?date=${format(scheduleData.startDate, 'MM-dd')}`,
          ),
      });
    } else {
      navigate(
        `${paths.programming}/${country?.id}/${city?.id}/${bakery?.id}/${
          ovenModel?.id
        }?date=${format(scheduleData.startDate, 'MM-dd')}`,
      );
    }
  }

  const isLoading =
    isLoadingCountry ||
    isLoadingCity ||
    isLoadingBakery ||
    isLoadingOvenModel ||
    isLoadingSchedule ||
    isLoadingRecipeTypes ||
    isLoadingRecipes ||
    isLoadingCreateOrUpdateSchedule;

  return (
    <Fragment>
      <Box
        sx={{
          width: {md: '173px'},
          marginTop: {xs: '32px', md: '96px'},
          marginLeft: {xs: '60px', sm: '120px'},
        }}>
        <CreateRecipeScheduleMenu
          uiState={uiState}
          setUiState={setUiState}
          canFinish={scheduleId != null ? canFinish && hasChanges : canFinish}
          onFinish={handleCreateOrUpdateSchedule}
        />
      </Box>
      <Box
        sx={{
          width: {xs: 'calc(100vw - 120px)', sm: 'calc(100vw - 240px)', md: 'calc(100vw - 445px)'},
          marginTop: {xs: '16px', md: '24px'},
          marginLeft: {xs: '60px', sm: '120px', md: '32px'},
        }}>
        <ActionBanner
          color="custom.surfaceBrand"
          text={t('programming_recipe_programming_title')}
          secondary={
            <IconButton
              IconComponent={DeclineIcon}
              inactiveColor="custom.iconsSecondary"
              activeColor="custom.iconsSecondary"
              onClick={handleCancel}
            />
          }
        />
        {uiState.currentStep === 'recipe' && (
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px', marginTop: '16px'}}>
            <Tabs
              values={getRecipeTypeTabs()}
              selectedTabIndex={selectedRecipeTypeIndex}
              onTabClick={setSelectedRecipeTypeIndex}
            />
            <SimpleSearch value={recipeSearchText} onChange={setRecipeSearchText} />
            <RecipeList
              recipes={filterRecipes()}
              selectedRecipeId={scheduleData.recipeId}
              onSelectRecipe={handleSelectRecipe}
              recipesNotFoundMessage={
                isLoadingRecipes ? '' : t('programming_recipes_not_found_label')
              }
            />
          </Box>
        )}
        {uiState.currentStep === 'ovens' && (
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px', marginTop: '16px'}}>
            <SimpleSearch value={ovenSearchText} onChange={setOvenSearchText} />
            <Typography
              sx={{
                paddingInline: '32px',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '28px',
              }}>
              {t('programming_ovens_step_title')}
            </Typography>
            <OvenList
              ovenGroups={filterOvenGroups()}
              ovens={filterOvens()}
              selectedOvenPanelIds={selectedOvenPanelIds}
              onSelectOvenPanel={handleSelectOvenPanel}
              ovensNotFoundMessage={
                isLoadingOvenModel ? '' : t('programming_ovens_not_found_label')
              }
            />
          </Box>
        )}
        {uiState.currentStep === 'time' && (
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px', marginTop: '32px'}}>
            <Typography
              sx={{
                paddingInline: '32px',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '28px',
              }}>
              {t('programming_time_step_title')}
            </Typography>
            {hasOverlap && (
              <Typography
                sx={{
                  paddingInline: '32px',
                  fontSize: '20px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '28px',
                  color: 'custom.textBrand',
                }}>
                {t('programming_overlapping_times_error')}
              </Typography>
            )}
            {!isValidStartTime && scheduleData.startTime.length === 5 && !hasOverlap && (
              <Typography
                sx={{
                  paddingInline: '32px',
                  fontSize: '20px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '28px',
                  color: 'custom.textBrand',
                }}>
                {t('invalid_input_change_start_time')}
              </Typography>
            )}
            <SelectTime
              startDate={scheduleData.startDate}
              setStartDate={handleSetStartDate}
              startTime={scheduleData.startTime}
              endTime={endTime}
              setStartTime={handleSetStartTime}
              selectedWeekDays={scheduleData.weekDays}
              onSelectWeekDay={handleSelectWeekDay}
              error={!isValidStartTime}
              onStartTimeFocus={handleStartTimeFocus}
              onStartTimeBlur={handleStartTimeBlur}
            />
          </Box>
        )}
        <Box sx={{minHeight: '64px'}} />
      </Box>
      <LoadingBackdrop isLoading={isLoading} />
    </Fragment>
  );
}

export default RecipeSchedule;
