import axios, {AxiosError, AxiosResponse} from 'axios';
import config from '../config';
import localStorageUtils from './local-storage';

axios.defaults.baseURL = config.apiBaseUrl;
axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common.Authorization = localStorageUtils.getValue('token', '');

const httpClient = {
  setAuthorizationHeader: function (value: string) {
    axios.defaults.headers.common.Authorization = value;
  },
  get: async function <TResponse, TQuery = unknown>(
    url: string,
    query?: TQuery,
  ): Promise<TResponse> {
    return this.getResponse(axios.get(url, {params: query}));
  },
  post: async function <TResponse, TRequest = unknown, TQuery = unknown>(
    url: string,
    request: TRequest,
    query?: TQuery,
  ): Promise<TResponse> {
    return this.getResponse(axios.post(url, request, {params: query}));
  },
  patch: async function <TResponse, TRequest = unknown, TQuery = unknown>(
    url: string,
    request: TRequest,
    query?: TQuery,
  ): Promise<TResponse> {
    return this.getResponse(axios.patch(url, request, {params: query}));
  },
  put: async function <TResponse, TRequest = unknown, TQuery = unknown>(
    url: string,
    request: TRequest,
    query?: TQuery,
  ): Promise<TResponse> {
    return this.getResponse(axios.put(url, request, {params: query}));
  },
  delete: async function <TResponse, TQuery = unknown, TRequest = unknown>(
    url: string,
    query?: TQuery,
    request?: TRequest,
  ): Promise<TResponse> {
    return this.getResponse(axios.delete(url, {params: query, data: request}));
  },
  getResponse: async function <TResponse>(
    request: Promise<AxiosResponse<TResponse>>,
  ): Promise<TResponse> {
    try {
      const axiosResponse = await request;
      return axiosResponse.data as TResponse;
    } catch (error) {
      const axiosError = error as AxiosError<TResponse>;

      if (
        axiosError.response?.data != null &&
        typeof axiosError.response.data === 'object' // TODO: review validation
      ) {
        return axiosError.response.data;
      }

      throw error;
    }
  },
};

export default httpClient;
