import {Box, List, ListItem, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import useUser from '../../hooks/common/use-user';
import UserNotificationType from '../../models/entities/user-notification-type';
import services from '../../services/provider';
import notificationUtils from '../../utils/notifications';
import GradientOverflow from '../common/GradientOverflow';
import LoadingBackdrop from '../common/LoadingBackdrop';
import Switch from '../common/Switch';

function NotificationSettings() {
  const queryClient = useQueryClient();
  const {t} = useTranslation();

  const {user} = useUser();

  const {data: userNotificationTypes = [], isLoading} = useQuery({
    enabled: user != null,
    queryKey: ['userNotificationTypes'],
    queryFn: () =>
      services.user.getUserNotificationTypes({
        params: {userId: user?.id ?? ''},
      }),
  });

  const {mutate: updateUserNotificationType} = useMutation({
    mutationFn: services.user.updateUserNotificationType,
    onMutate: async ({request: updatedUserNotificationType}) => {
      await queryClient.cancelQueries('userNotificationTypes');
      const previousUserNotificationTypes = queryClient.getQueryData<UserNotificationType[]>([
        'userNotificationTypes',
      ]);
      queryClient.setQueryData<UserNotificationType[] | undefined>(
        ['userNotificationTypes'],
        (userNotificationTypes) =>
          userNotificationTypes?.map((userNotificationType) =>
            userNotificationType.notificationTypeId ===
            updatedUserNotificationType.notificationTypeId
              ? updatedUserNotificationType
              : userNotificationType,
          ),
      );
      return {previousUserNotificationTypes};
    },
    onError: (error, request, context) => {
      queryClient.setQueryData(['userNotificationTypes'], context?.previousUserNotificationTypes);
    },
    onSuccess: () => {
      queryClient.invalidateQueries('notifications');
    },
    onSettled: () => {
      queryClient.invalidateQueries('userNotificationTypes');
    },
  });

  function handleCheckUserNotificationType(userNotificationType: UserNotificationType) {
    updateUserNotificationType({
      params: {
        userId: userNotificationType.userId,
        notificationTypeId: userNotificationType.notificationTypeId,
      },
      request: {...userNotificationType, notify: !userNotificationType.notify},
    });
  }

  return (
    <Box
      sx={{
        height: {xs: 'calc(100vh - 348px)', md: 'calc(100vh - 308px)'},
        minHeight: '256px',
        marginTop: '32px',
        paddingInline: '32px',
      }}>
      <GradientOverflow hideScrollbar>
        <Typography
          sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 500, lineHeight: '28px'}}>
          {t('notification_settings_title')}
        </Typography>
        <List
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
            margin: 0,
            padding: 0,
            marginTop: '32px',
          }}>
          {userNotificationTypes.map((userNotificationType) => (
            <ListItem
              key={userNotificationType.notificationTypeId}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: 0,
                padding: 0,
                cursor: 'pointer',
                '&:hover': {
                  color: 'custom.textBrand',
                },
              }}
              onClick={() => handleCheckUserNotificationType(userNotificationType)}>
              <Typography
                sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 400, lineHeight: '28px'}}>
                {t(notificationUtils.getSettingsLabel(userNotificationType))}
              </Typography>
              <Switch checked={userNotificationType.notify} />
            </ListItem>
          ))}
        </List>
      </GradientOverflow>
      <Box sx={{minHeight: '64px'}} />
      <LoadingBackdrop isLoading={isLoading} />
    </Box>
  );
}

export default NotificationSettings;
