import {alpha, Box, Drawer, List} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {ReactComponent as BakeriesIcon} from '../../assets/icons/bakery.svg';
import {ReactComponent as ProfileIcon} from '../../assets/icons/profile.svg';
import {ReactComponent as ProgrammingIcon} from '../../assets/icons/programming.svg';
import {ReactComponent as RecipesIcon} from '../../assets/icons/recipes.svg';
import {ReactComponent as StatisticsIcon} from '../../assets/icons/statistics.svg';
import paths from '../../routes/paths';
import GradientOverflow from '../common/GradientOverflow';
import DrawerMenuItem from './DrawerMenuItem';

type DrawerMenuProps = {
  open: boolean;
  onClose: () => void;
};

function DrawerMenu(props: DrawerMenuProps) {
  const {open, onClose} = props;

  const navigate = useNavigate();
  const {t} = useTranslation();

  return (
    <Drawer
      anchor="left"
      BackdropProps={{invisible: true}}
      PaperProps={{
        sx: {
          boxShadow: 'none',
          background: (theme) =>
            `linear-gradient(270deg, transparent 0%, ${alpha(
              theme.palette.custom.surfaceBackground,
              0.95,
            )} 50.34%, ${theme.palette.custom.surfaceBackground} 100%)`,
        },
        onClick: onClose,
      }}
      open={open}
      onClose={onClose}
      keepMounted>
      <Box
        sx={{
          width: {xs: '80vw', sm: '70vw', md: '60vw'},
          maxWidth: '806px',
          height: '100vh',
          paddingBlock: '64px',
          paddingLeft: {xs: '32px', sm: '64px', md: '120px'},
          display: 'flex',
          flexDirection: 'column',
        }}>
        <Box
          sx={{
            marginTop: 'auto',
            marginBottom: 'auto',
            width: '100%',
            maxHeight: '100%',
          }}>
          <GradientOverflow hideScrollbar containerProps={{sx: {maxHeight: '100%'}}}>
            <List
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '72px',
              }}
              onClick={onClose}>
              <DrawerMenuItem
                icon={RecipesIcon}
                text={t('menu_recipes_label')}
                onClick={() => navigate(paths.recipes)}
              />
              <DrawerMenuItem
                icon={ProgrammingIcon}
                text={t('menu_programming_label')}
                onClick={() => navigate(paths.programming)}
              />
              <DrawerMenuItem
                icon={BakeriesIcon}
                text={t('menu_bakeries_label')}
                onClick={() => navigate(paths.bakeries)}
              />
              <DrawerMenuItem
                icon={StatisticsIcon}
                text={t('menu_statistics_label')}
                onClick={() => navigate(paths.statistics)}
              />
              <DrawerMenuItem
                icon={ProfileIcon}
                text={t('menu_profile_label')}
                onClick={() => navigate(paths.profile)}
              />
            </List>
          </GradientOverflow>
        </Box>
      </Box>
    </Drawer>
  );
}

export default DrawerMenu;
