type NullableArray<TValue> = TValue[] | null | undefined;

const arrayUtils = {
  count: function <TValue>(array: TValue[], predicate: (item: TValue, index: number) => boolean) {
    let count = 0;

    array.forEach((item, index) => {
      if (predicate(item, index)) {
        count++;
      }
    });

    return count;
  },
  isNullOrEmpty: function <TValue>(array: NullableArray<TValue>) {
    return array == null || array.length === 0;
  },
  hasMultipleItems: function <TValue>(array: NullableArray<TValue>) {
    return array != null && array.length > 1;
  },
  split: function <TValue>(array: TValue[], chunkSize: number) {
    const result: TValue[][] = [];

    for (let index = 0; index < array.length; index += chunkSize) {
      result.push(array.slice(index, index + chunkSize));
    }

    return result;
  },
  isEqual: function <TValue>(
    arrayA: TValue[],
    arrayB: TValue[],
    predicate: (itemA: TValue, itemB: TValue, index: number) => boolean,
  ) {
    if (arrayA.length !== arrayB.length) {
      return false;
    }

    for (let index = 0; index < arrayA.length; index++) {
      if (!predicate(arrayA[index], arrayB[index], index)) {
        return false;
      }
    }
    return true;
  },
};

export default arrayUtils;
