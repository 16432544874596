import {Box, ClickAwayListener, Typography} from '@mui/material';
import {Fragment, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as AddIcon} from '../../assets/icons/add.svg';
import {ReactComponent as DeleteIcon} from '../../assets/icons/delete.svg';
import {ReactComponent as EditIcon} from '../../assets/icons/edit.svg';
import {ReactComponent as Menu} from '../../assets/icons/menu.svg';
import useUser from '../../hooks/common/use-user';
import OvenModel from '../../models/entities/oven-model';
import RecipeType from '../../models/entities/recipe-type';
import cryptoUtils from '../../utils/crypto';
import stringUtils from '../../utils/strings';
import ConfirmPopover from '../common/ConfirmPopover';
import IconButton from '../common/IconButton';
import Span from '../common/Span';

function CreateRecipeTypeMenuItem({
  ovenModelId,
  ovenSubModelId,
  companyId,
  onCreateRecipeType,
}: {
  ovenModelId: number;
  ovenSubModelId: number | null;
  companyId: string;
  onCreateRecipeType: (recipeType: RecipeType) => void;
}) {
  const {t, i18n} = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [description, setDescription] = useState('');

  const containerRef = useRef<HTMLDivElement | null>(null);

  function handleCreateRecipeType() {
    if (!stringUtils.isNullOrWhiteSpace(description)) {
      onCreateRecipeType({
        id: cryptoUtils.uuid(),
        description,
        descriptionEn: i18n.language === 'en' ? description : undefined,
        descriptionEs: i18n.language === 'es' ? description : undefined,
        descriptionPt: i18n.language === 'pt' ? description : undefined,
        descriptionFr: i18n.language === 'fr' ? description : undefined,
        descriptionPl: i18n.language === 'pl' ? description : undefined,
        ovenModelId,
        ovenSubModelId,
        companyId,
      });
    }
    setDescription('');
    setAnchorEl(null);
  }

  const isOpen = Boolean(anchorEl);

  return (
    <Box ref={containerRef} sx={{display: 'flex', justifyContent: 'center', width: '100%'}}>
      <IconButton
        sx={{zIndex: isOpen ? 1500 : undefined}}
        IconComponent={AddIcon}
        inactiveColor="custom.iconsTertiary"
        isActive={isOpen}
        onClick={() => {
          setDescription('');
          setAnchorEl(isOpen ? null : containerRef.current);
        }}
      />
      <ConfirmPopover
        isOpen={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placeHolder={t('recipe_add_recipe_type_label')}
        value={description}
        onChange={setDescription}
        onAccept={handleCreateRecipeType}
        onDecline={() => {
          setDescription('');
          setAnchorEl(null);
        }}
      />
    </Box>
  );
}

function UpdateRecipeTypeMenuItem({
  recipeType,
  onUpdateRecipeType,
}: {
  recipeType: RecipeType;
  onUpdateRecipeType: (recipeType: RecipeType) => void;
}) {
  const {t, i18n} = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [description, setDescription] = useState(recipeType.description);

  const containerRef = useRef<HTMLDivElement | null>(null);

  function handleUpdateRecipeType() {
    if (!stringUtils.isNullOrWhiteSpace(description)) {
      onUpdateRecipeType({
        ...recipeType,
        description,
        descriptionEn: i18n.language === 'en' ? description : undefined,
        descriptionEs: i18n.language === 'es' ? description : undefined,
        descriptionPt: i18n.language === 'pt' ? description : undefined,
        descriptionFr: i18n.language === 'fr' ? description : undefined,
        descriptionPl: i18n.language === 'pl' ? description : undefined,
        modifiedAt: undefined,
      });
    }
    setAnchorEl(null);
  }

  const isOpen = Boolean(anchorEl);

  return (
    <Box ref={containerRef} sx={{display: 'flex', justifyContent: 'center', width: '100%'}}>
      <IconButton
        sx={{zIndex: isOpen ? 1500 : undefined}}
        IconComponent={EditIcon}
        inactiveColor="custom.iconsTertiary"
        isActive={isOpen}
        onClick={() => {
          setDescription(recipeType.description);
          setAnchorEl(isOpen ? null : containerRef.current);
        }}
      />
      <ConfirmPopover
        isOpen={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placeHolder={t('recipe_add_recipe_type_label')}
        value={description}
        onChange={setDescription}
        onAccept={handleUpdateRecipeType}
        onDecline={() => {
          setDescription(recipeType.description);
          setAnchorEl(null);
        }}
      />
    </Box>
  );
}

function DeleteRecipeTypeMenuItem({
  recipeType,
  onDeleteRecipeType,
}: {
  recipeType: RecipeType;
  onDeleteRecipeType: (recipeTypeId: string) => void;
}) {
  const {t} = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const containerRef = useRef<HTMLDivElement | null>(null);

  function handleDeleteRecipeType() {
    onDeleteRecipeType(recipeType.id);
    setAnchorEl(null);
  }

  const isOpen = Boolean(anchorEl);

  return (
    <Box ref={containerRef} sx={{display: 'flex', justifyContent: 'center', width: '100%'}}>
      <IconButton
        sx={{zIndex: isOpen ? 1500 : undefined}}
        IconComponent={DeleteIcon}
        inactiveColor="custom.iconsTertiary"
        isActive={isOpen}
        onClick={() => setAnchorEl(isOpen ? null : containerRef.current)}
      />
      <ConfirmPopover
        isOpen={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placeHolder={t('recipe_add_recipe_type_label')}
        messageElement={
          <Typography
            sx={{
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
              color: 'custom.textSecondary',
            }}>
            <Span>{t('recipe_delete_recipe_type_label')}</Span>
            <Span sx={{fontWeight: 500}}>{` ${recipeType.description}`}</Span>
            <Span>?</Span>
          </Typography>
        }
        onAccept={handleDeleteRecipeType}
        onDecline={() => setAnchorEl(null)}
      />
    </Box>
  );
}

export type RecipeTypeMenuProps = {
  selectedOvenModel: OvenModel;
  selectedRecipeType?: RecipeType;
  onCreateRecipeType: (recipeType: RecipeType) => void;
  onUpdateRecipeType: (recipeType: RecipeType) => void;
  onDeleteRecipeType: (recipeTypeId: string) => void;
};

function RecipeTypeMenu(props: RecipeTypeMenuProps) {
  const {
    selectedOvenModel,
    selectedRecipeType,
    onCreateRecipeType,
    onUpdateRecipeType,
    onDeleteRecipeType,
  } = props;

  const {user} = useUser();

  const [isOpen, setIsOpen] = useState(false);

  function handleCreateRecipeType(recipeType: RecipeType) {
    onCreateRecipeType(recipeType);
    setIsOpen(false);
  }

  function handleUpdateRecipeType(recipeType: RecipeType) {
    onUpdateRecipeType(recipeType);
    setIsOpen(false);
  }

  function handleDeleteRecipeType(recipeTypeId: string) {
    onDeleteRecipeType(recipeTypeId);
    setIsOpen(false);
  }

  const renderMenu = isOpen;
  const renderUpdateRecipeTypeMenuItem = selectedRecipeType != null;
  const renderDeleteRecipeTypeMenuItem = selectedRecipeType != null;

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '12px',
        }}>
        <IconButton
          IconComponent={Menu}
          iconSize="40px"
          inactiveColor="custom.iconsTertiary"
          activeColor="custom.iconsTertiary"
          onClick={() => setIsOpen((previousValue) => !previousValue)}
        />
        {renderMenu && (
          <Fragment>
            <CreateRecipeTypeMenuItem
              ovenModelId={selectedOvenModel.id}
              ovenSubModelId={null}
              companyId={user?.companyId ?? ''}
              onCreateRecipeType={handleCreateRecipeType}
            />
            {renderUpdateRecipeTypeMenuItem && (
              <UpdateRecipeTypeMenuItem
                recipeType={selectedRecipeType}
                onUpdateRecipeType={handleUpdateRecipeType}
              />
            )}
            {renderDeleteRecipeTypeMenuItem && (
              <DeleteRecipeTypeMenuItem
                recipeType={selectedRecipeType}
                onDeleteRecipeType={handleDeleteRecipeType}
              />
            )}
          </Fragment>
        )}
      </Box>
    </ClickAwayListener>
  );
}

export default RecipeTypeMenu;
