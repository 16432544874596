import {Box, List, ListItem, Typography} from '@mui/material';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery} from 'react-query';
import useUser from '../../hooks/common/use-user';
import services from '../../services/provider';
import recipeUtils from '../../utils/recipes';
import stringUtils from '../../utils/strings';
import {Language} from '../../utils/types';
import GradientOverflow from '../common/GradientOverflow';
import LoadingBackdrop from '../common/LoadingBackdrop';
import SimpleSearch from '../common/SimpleSearch';
import RecipeTypeTabs from '../recipes/RecipeTypeTabs';

export type BakeryRecipesProps = {
  selectedOvenModelId: number;
  selectedBakeryId: string;
};

function BakeryRecipes(props: BakeryRecipesProps) {
  const {t, i18n} = useTranslation();

  const {user} = useUser();

  const [selectedRecipeTypeId, setSelectedRecipeTypeId] = useState('all');
  const [searchText, setSearchText] = useState('');

  const {data: recipeTypes = [], isLoading: isLoadingRecipeTypes} = useQuery({
    enabled: user != null,
    queryKey: [
      'recipeTypes',
      {ovenModelId: props.selectedOvenModelId, bakeryId: props.selectedBakeryId},
    ],
    queryFn: () =>
      services.recipeType.getRecipeTypes({
        query: {
          ovenModelId: props.selectedOvenModelId,
          bakeryId: props.selectedBakeryId,
          companyId: user!.companyId,
        },
      }),
  });

  const {data: recipes = [], isLoading: isLoadingRecipes} = useQuery({
    enabled: user != null,
    queryKey: [
      'recipes',
      {
        ovenModelId: props.selectedOvenModelId,
        bakeryId: props.selectedBakeryId,
        expand: ['recipeTypes'],
      },
    ],
    queryFn: () =>
      services.recipe.getRecipes({
        query: {
          ovenModelId: props.selectedOvenModelId,
          bakeryId: props.selectedBakeryId,
          companyId: user!.companyId,
          expand: ['recipeTypes'],
        },
      }),
  });

  const filteredRecipes = recipes.filter(
    (recipe) =>
      (stringUtils.unicodeStartsWith(recipe.name, searchText) ||
        stringUtils.unicodeIncludes(recipe.name, ` ${searchText}`)) &&
      (stringUtils.isNullOrWhiteSpace(selectedRecipeTypeId) ||
        selectedRecipeTypeId === 'all' ||
        recipe.recipeTypes?.some((recipeType) => recipeType.id === selectedRecipeTypeId)),
  );

  const isLoading = isLoadingRecipeTypes || isLoadingRecipes;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        width: {xs: 'calc(100vw - 120px)', sm: 'calc(100vw - 240px)', md: 'calc(100vw - 445px)'},
        marginTop: '32px',
      }}>
      <RecipeTypeTabs
        recipeTypes={recipeTypes}
        selectedRecipeTypeId={selectedRecipeTypeId}
        onSelectRecipeType={setSelectedRecipeTypeId}
      />
      <SimpleSearch value={searchText} onChange={setSearchText} />
      <Box
        sx={{
          height: {xs: 'calc(100vh - 496px)', md: 'calc(100vh - 456px)'},
          minHeight: '256px',
          marginTop: '16px',
        }}>
        <GradientOverflow hideScrollbar>
          <List
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '24px',
              margin: 0,
              padding: 0,
              paddingInline: '32px',
            }}>
            {filteredRecipes.length === 0 && (
              <ListItem sx={{margin: 0, padding: 0}}>
                <Typography
                  sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 400, lineHeight: '28px'}}>
                  {t('no_recipes_were_found')}
                </Typography>
              </ListItem>
            )}
            {filteredRecipes.map((recipe) => (
              <ListItem key={recipe.id} sx={{margin: 0, padding: 0}}>
                <Typography
                  sx={{fontSize: '20px', fontStyle: 'normal', fontWeight: 400, lineHeight: '28px'}}>
                  {recipeUtils.getName(recipe, i18n.language as Language)}
                </Typography>
              </ListItem>
            ))}
          </List>
        </GradientOverflow>
      </Box>
      <LoadingBackdrop isLoading={isLoading} />
    </Box>
  );
}

export default BakeryRecipes;
