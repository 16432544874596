import {addSeconds, format, parse} from 'date-fns';
import {TFunction} from 'i18next';
import Schedule from '../models/entities/schedule';
import arrayUtils from './arrays';
import dateUtils from './dates';

const scheduleUtils = {
  getStartTime: function (schedule: Schedule) {
    if (!arrayUtils.isNullOrEmpty(schedule.recurrences)) {
      return schedule.recurrences![0].startTime.slice(0, 5);
    }
    return '00:00';
  },
  getEndTime: function (schedule: Schedule) {
    const startTime = this.getStartTime(schedule);
    let duration = 0;
    if (schedule.recipe != null) {
      duration =
        schedule.recipe.recipePhases?.reduce(
          (duration, recipePhase) => duration + recipePhase.duration,
          0,
        ) ?? 0;
    } else if (schedule.cleaning != null) {
      duration = schedule.cleaning.duration;
    }
    return format(addSeconds(parse(startTime, 'HH:mm', new Date()), duration), 'HH:mm');
  },
  getDetailedRecurrenceLabel: function (schedule: Schedule, t: TFunction) {
    if (arrayUtils.isNullOrEmpty(schedule.recurrences)) {
      return '-';
    }

    const labels: string[] = [];
    const repeatDays = new Set<Day>();

    schedule.recurrences?.forEach((recurrence) => {
      if (!recurrence.repeat) {
        const startDate = new Date(recurrence.startDate);
        labels.push(`${dateUtils.getMonthName(startDate.getMonth(), t)} ${startDate.getDate()}`);
      } else {
        repeatDays.add(recurrence.weekDay);
      }
    });

    if (repeatDays.size === 0) {
      // do nothing
    } else if (repeatDays.size === 7) {
      labels.push(t('daily'));
    } else if (
      repeatDays.size === 5 &&
      [1, 2, 3, 4, 5].every((day) => repeatDays.has(day as Day))
    ) {
      labels.push(t('weekdays'));
    } else if (repeatDays.size === 2 && [0, 6].every((day) => repeatDays.has(day as Day))) {
      labels.push(t('weekends'));
    } else if (repeatDays.size < 3) {
      const translations = Array.from(repeatDays).map(
        (day) =>
          [
            t('sundays'),
            t('mondays'),
            t('tuesdays'),
            t('wednesdays'),
            t('thursdays'),
            t('fridays'),
            t('saturdays'),
          ][day],
      );

      if (translations.length === 1) {
        labels.push(translations[0]);
      } else {
        labels.push(`${translations[0]} ${t('and')} ${translations[1]}`);
      }
    } else {
      const translations = Array.from(repeatDays).map(
        (day) => [t('sun'), t('mon'), t('tue'), t('wed'), t('thu'), t('fri'), t('sat')][day],
      );

      labels.push(translations.slice(0, -1).join(`, `) + ` ${t('and')} ` + translations.slice(-1));
    }

    return labels.join(' & ');
  },
};

export default scheduleUtils;
