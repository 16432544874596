import {Box, Typography} from '@mui/material';
import {Fragment, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';
import {ReactComponent as AddIcon} from '../../assets/icons/add.svg';
import useUser from '../../hooks/common/use-user';
import Recipe from '../../models/entities/recipe';
import RecipeType from '../../models/entities/recipe-type';
import paths from '../../routes/paths';
import services from '../../services/provider';
import useBreadcrumbsStore, {Breadcrumb} from '../../state/breadcrumbs';
import arrayUtils from '../../utils/arrays';
import numberUtils from '../../utils/numbers';
import ovenModelUtils from '../../utils/oven-models';
import ActionBanner from '../common/ActionBanner';
import Icon from '../common/Icon';
import LoadingBackdrop from '../common/LoadingBackdrop';
import SimpleSearch from '../common/SimpleSearch';
import OvenModelList from './OvenModelList';
import RecipeTypeMenu from './RecipeTypeMenu';
import RecipeTypeTabs from './RecipeTypeTabs';
import RecipesList from './RecipesList';

function Recipes() {
  const navigate = useNavigate();
  const {ovenModelId, recipeTypeId} = useParams();
  const queryClient = useQueryClient();
  const {t} = useTranslation();

  const setBreadcrumbs = useBreadcrumbsStore((state) => state.setBreadcrumbs);

  const {user} = useUser();

  const [searchText, setSearchText] = useState('');
  const [selectedRecipeId, setSelectedRecipeId] = useState<string>('');

  const {data: ovenModels = [], isLoading: isLoadingOvenModels} = useQuery({
    enabled: user != null,
    queryKey: ['companyOvenModels', user?.companyId, {expand: ['ovenGroups', 'ovens']}],
    queryFn: () =>
      services.company.getCompanyOvenModels({
        params: {companyId: user?.companyId ?? ''},
        query: {
          expand: ['ovenGroups', 'ovens'],
        },
      }),
    select: (ovenModels) => ovenModelUtils.filterStockOvens(ovenModels, user),
  });

  const selectedOvenModel = ovenModels.find(
    (ovenModel) => ovenModel.id === numberUtils.parseInt(ovenModelId),
  );

  const {data: recipeTypes = [], isLoading: isLoadingRecipeTypes} = useQuery({
    enabled: user != null && selectedOvenModel != null,
    queryKey: ['recipeTypes', {ovenModelId: selectedOvenModel?.id}],
    queryFn: () =>
      services.recipeType.getRecipeTypes({
        query: {
          ovenModelId: selectedOvenModel!.id,
          companyId: user!.companyId,
        },
      }),
  });

  const selectedRecipeType = recipeTypes.find((recipeType) => recipeType.id === recipeTypeId);

  const {data: recipes = [], isLoading: isLoadingRecipes} = useQuery({
    enabled: user != null && selectedOvenModel != null,
    queryKey: ['recipes', {ovenModelId: selectedOvenModel?.id, expand: ['recipeTypes']}],
    queryFn: () =>
      services.recipe.getRecipes({
        query: {
          ovenModelId: selectedOvenModel!.id,
          companyId: user!.companyId,
          expand: ['recipeTypes'],
        },
      }),
  });

  const {mutate: createRecipeType, isLoading: isLoadingCreateRecipeType} = useMutation({
    mutationFn: services.recipeType.createRecipeType,
    onSuccess: (_, {request: recipeType}) => {
      queryClient.setQueryData<RecipeType[] | undefined>(
        ['recipeTypes', {ovenModelId: selectedOvenModel?.id}],
        (recipeTypes) => (recipeTypes != null ? [...recipeTypes, recipeType] : [recipeType]),
      );
      queryClient.invalidateQueries('recipeTypes');
      navigate(`${paths.recipes}/${selectedOvenModel?.id}/${recipeType.id}`);
    },
  });

  const {mutate: updateRecipeType, isLoading: isLoadingUpdateRecipeType} = useMutation({
    mutationFn: services.recipeType.updateRecipeType,
    onSuccess: (_, {request: updatedRecipeType}) => {
      queryClient.setQueryData<RecipeType[] | undefined>(
        ['recipeTypes', {ovenModelId: selectedOvenModel?.id}],
        (recipeTypes) =>
          recipeTypes?.map((recipeType) =>
            recipeType.id === updatedRecipeType.id ? updatedRecipeType : recipeType,
          ),
      );
      queryClient.invalidateQueries('recipeTypes');
      setSelectedRecipeId('');
    },
  });

  const {mutate: deleteRecipeType, isLoading: isLoadingDeleteRecipeType} = useMutation({
    mutationFn: services.recipeType.deleteRecipeType,
    onSuccess: (recipeTypeId) => {
      queryClient.setQueryData<RecipeType[] | undefined>(
        ['recipeTypes', {ovenModelId: selectedOvenModel?.id}],
        (recipeTypes) => recipeTypes?.filter((recipeType) => recipeType.id !== recipeTypeId),
      );
      queryClient.invalidateQueries('recipeTypes');
      navigate(`${paths.recipes}/${selectedOvenModel?.id}/${recipeTypeId}`);
    },
  });

  const {mutate: deleteRecipe, isLoading: isLoadingDeleteRecipe} = useMutation({
    mutationFn: services.recipe.deleteRecipe,
    onSuccess: (recipeId) => {
      queryClient.setQueryData<Recipe[] | undefined>(
        ['recipes', {ovenModelId: selectedOvenModel?.id, expand: ['recipeTypes']}],
        (recipes) => recipes?.filter((recipe) => recipe.id !== recipeId),
      );
      queryClient.invalidateQueries('recipes');
      setSelectedRecipeId('');
    },
  });

  const {mutate: deleteRecipeTypeRecipe, isLoading: isLoadingDeleteRecipeTypeRecipe} = useMutation({
    mutationFn: services.recipeType.deleteRecipeTypeRecipe,
    onSuccess: (recipeId, {params}) => {
      queryClient.setQueryData<Recipe[] | undefined>(
        ['recipes', {ovenModelId: selectedOvenModel?.id, expand: ['recipeTypes']}],
        (recipes) =>
          recipes?.map((recipe) => ({
            ...recipe,
            recipeTypes:
              recipe.id === recipeId
                ? recipe.recipeTypes?.filter((recipeType) => recipeType.id !== params.recipeTypeId)
                : recipe.recipeTypes,
          })),
      );
      queryClient.invalidateQueries('recipes');
    },
  });

  useEffect(() => {
    if (selectedOvenModel == null && !arrayUtils.isNullOrEmpty(ovenModels)) {
      navigate(`${paths.recipes}/${ovenModels[0].id}/all`, {replace: true});
    }
  }, [ovenModels, selectedOvenModel, navigate]);

  useEffect(() => {
    const breadcrumbs: Breadcrumb[] = [];
    breadcrumbs.push({
      title: t('recipes_breadcrumb'),
      onClick: () => navigate(paths.recipes),
    });
    if (selectedOvenModel != null) {
      breadcrumbs.push({
        title: selectedOvenModel.description,
        onClick: () => navigate(`${paths.recipes}/${selectedOvenModel.id}/all`),
      });

      if (selectedRecipeType != null) {
        breadcrumbs.push({title: selectedRecipeType.description});
      }
    }
    setBreadcrumbs(breadcrumbs);
    return () => setBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOvenModel, selectedRecipeType]);

  function handleSelectRecipeType(recipeTypeId: string) {
    setSearchText('');
    setSelectedRecipeId('');
    navigate(`${paths.recipes}/${selectedOvenModel?.id}/${recipeTypeId}`);
  }

  const isLoading =
    isLoadingOvenModels ||
    isLoadingRecipeTypes ||
    isLoadingRecipes ||
    isLoadingCreateRecipeType ||
    isLoadingUpdateRecipeType ||
    isLoadingDeleteRecipeType ||
    isLoadingDeleteRecipe ||
    isLoadingDeleteRecipeTypeRecipe;

  const renderRecipeTypeMenu = selectedOvenModel != null && !isLoadingRecipeTypes;
  const renderRecipeList = selectedOvenModel != null && !isLoadingRecipes;
  const renderOvenModelsNotFoundMessage =
    arrayUtils.isNullOrEmpty(ovenModels) && !isLoadingOvenModels;

  return (
    <Fragment>
      <OvenModelList
        ovenModels={ovenModels}
        selectedOvenModelId={selectedOvenModel?.id ?? -1}
        onSelectOvenModel={(ovenModelId) => navigate(`${paths.recipes}/${ovenModelId}/all`)}
      />
      <Box
        sx={{
          marginTop: {xs: '32px', md: '24px'},
          marginLeft: {xs: '60px', sm: '120px', md: '65px'},
        }}>
        <Box sx={{display: 'flex'}}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              width: {
                xs: 'calc(100vw - 120px)',
                sm: 'calc(100vw - 240px)',
                md: 'calc(100vw - 445px)',
              },
            }}>
            <RecipeTypeTabs
              recipeTypes={recipeTypes}
              selectedRecipeTypeId={selectedRecipeType?.id ?? 'all'}
              onSelectRecipeType={handleSelectRecipeType}
            />
            <SimpleSearch value={searchText} onChange={setSearchText} />
            {selectedOvenModel != null && (
              <ActionBanner
                color="custom.surfaceBrand"
                text={t('recipe_create_recipe_label')}
                secondary={<Icon IconComponent={AddIcon} color="custom.iconsSecondary" />}
                onClick={() =>
                  navigate(
                    `${paths.recipes}/${selectedOvenModel?.id}/${
                      selectedRecipeType?.id ?? 'all'
                    }/create`,
                  )
                }
              />
            )}
          </Box>
          <Box sx={{width: {xs: '60px', sm: '120px'}}}>
            {renderRecipeTypeMenu && (
              <RecipeTypeMenu
                selectedOvenModel={selectedOvenModel}
                selectedRecipeType={selectedRecipeType}
                onCreateRecipeType={(recipeType) => createRecipeType({request: recipeType})}
                onUpdateRecipeType={(recipeType) =>
                  updateRecipeType({params: {recipeTypeId: recipeType.id}, request: recipeType})
                }
                onDeleteRecipeType={(recipeTypeId) => deleteRecipeType({params: {recipeTypeId}})}
              />
            )}
          </Box>
        </Box>
        {renderOvenModelsNotFoundMessage && (
          <Typography
            sx={{
              marginTop: '32px',
              paddingInline: '32px',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '28px',
            }}>
            {t('recipes_oven_models_not_found_label')}
          </Typography>
        )}
        {renderRecipeList && (
          <RecipesList
            searchText={searchText}
            selectedOvenModelId={selectedOvenModel?.id}
            selectedRecipeTypeId={selectedRecipeType?.id ?? 'all'}
            recipes={recipes}
            selectedRecipeId={selectedRecipeId}
            setSelectedRecipeId={setSelectedRecipeId}
            onDeleteRecipe={(recipeId) => deleteRecipe({params: {recipeId}})}
            onDeleteRecipeTypeRecipe={(recipeTypeId, recipeId) =>
              deleteRecipeTypeRecipe({params: {recipeTypeId, recipeId}})
            }
          />
        )}
        <Box sx={{minHeight: '64px'}} />
      </Box>
      <LoadingBackdrop isLoading={isLoading} />
    </Fragment>
  );
}

export default Recipes;
