import {Box, Typography, useMediaQuery, useTheme} from '@mui/material';
import {
  eachDayOfInterval,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  isSameDay,
  parse,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import {Fragment, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import arrayUtils from '../../utils/arrays';
import dateUtils from '../../utils/dates';
import HorizontalGradientOverflow from '../common/HorizontalGradientOverflow';
import Span from '../common/Span';

export type PeriodSelectorProps = {
  onPeriodChanged: (period: {startDate: Date; endDate: Date}) => void;
};

function PeriodSelector(props: PeriodSelectorProps) {
  const {onPeriodChanged} = props;

  const {t} = useTranslation();

  const theme = useTheme();
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  const [selectedMenu, setSelectedMenu] = useState('day');
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedWeekFirstDate, setSelectedWeekFirstDate] = useState(startOfWeek(new Date()));
  const [selectedDate, setSelectedDate] = useState(new Date());

  const firstDate = parse(
    `${selectedMonth + 1} ${new Date().getFullYear()}`,
    'MM yyyy',
    new Date(),
  );

  const {weeks, weekMonths} = useMemo(() => {
    const days = eachDayOfInterval({
      start: startOfWeek(firstDate),
      end: endOfWeek(endOfMonth(firstDate)),
    });

    const weeks = arrayUtils.split(days, 7);

    const months = new Set<number>();

    weeks.forEach((week) => {
      months.add(week[0].getMonth());
      months.add(week[6].getMonth());
    });

    return {weeks, weekMonths: Array.from(months)};
  }, [firstDate]);

  const dates = useMemo(
    () =>
      eachDayOfInterval({
        start: startOfMonth(firstDate),
        end: endOfMonth(firstDate),
      }),
    [firstDate],
  );

  useEffect(() => {
    const scrollContainerElementId =
      selectedMenu === 'day'
        ? 'dates-scroll-container'
        : selectedMenu === 'week'
        ? 'weeks-scroll-container'
        : 'months-scroll-container';
    const selectedElementId =
      selectedMenu === 'day'
        ? `date-${format(selectedDate, 'yyyy-MM-dd')}`
        : selectedMenu === 'week'
        ? `week-${format(selectedWeekFirstDate, 'yyyy-MM-dd')}`
        : `month-${selectedMonth}`;

    const scrollContainerElement = document.getElementById(scrollContainerElementId);
    const selectedElement = document.getElementById(selectedElementId);

    if (scrollContainerElement != null && selectedElement != null) {
      scrollContainerElement.style.scrollBehavior = 'smooth';
      const scrollContainerElementWidth = scrollContainerElement.offsetWidth;
      const selectedElementLeft = selectedElement.offsetLeft;
      const selectedElementWidth = selectedElement.offsetWidth;

      const scrollPosition =
        selectedElementLeft - scrollContainerElementWidth / 2 + selectedElementWidth / 2;

      scrollContainerElement.scrollLeft = scrollPosition;
      scrollContainerElement.style.scrollBehavior = 'auto';
    }
  }, [selectedMenu, selectedMonth, selectedWeekFirstDate, selectedDate]);

  useEffect(() => {
    switch (selectedMenu) {
      case 'day':
        onPeriodChanged({startDate: startOfDay(selectedDate), endDate: endOfDay(selectedDate)});
        break;
      case 'week':
        onPeriodChanged({
          startDate: selectedWeekFirstDate,
          endDate: endOfWeek(selectedWeekFirstDate),
        });
        break;
      case 'month':
        const firstDate = parse(
          `${selectedMonth + 1} ${new Date().getFullYear()}`,
          'MM yyyy',
          new Date(),
        );
        onPeriodChanged({startDate: startOfMonth(firstDate), endDate: endOfMonth(firstDate)});
        break;
    }
  }, [onPeriodChanged, selectedMenu, selectedMonth, selectedDate, selectedWeekFirstDate]);

  function handleChangeSelectedMonth(month: number) {
    const firstDate = parse(`${month + 1} ${new Date().getFullYear()}`, 'MM yyyy', new Date());
    setSelectedMonth(month);
    setSelectedWeekFirstDate(startOfWeek(firstDate));
    setSelectedDate(firstDate);
  }

  return (
    <Fragment>
      <Box sx={{display: 'flex', gap: '16px'}}>
        {['day', 'week', 'month'].map((menu) => (
          <Box
            key={menu}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '40px',
              padding: '12px 16px',
              opacity: menu === selectedMenu ? 1 : 0.5,
              backgroundColor: 'custom.surfaceSecondary',
              borderRadius: '360px',
              cursor: 'pointer',
            }}
            onClick={() => setSelectedMenu(menu)}>
            <Typography
              sx={{
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px',
                color: 'custom.textSecondary',
              }}>
              {t(menu)}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box sx={{display: 'flex', gap: '32px', paddingInline: '32px'}}>
        {selectedMenu === 'day' && (
          <Fragment>
            <HorizontalGradientOverflow scrollContainerId="dates-scroll-container" hideScrollbar>
              <Box sx={{display: 'flex', gap: '32px'}}>
                {dates.map((date, index) => (
                  <Box
                    id={`date-${format(date, 'yyyy-MM-dd')}`}
                    key={index}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      minWidth: '44px',
                      minHeight: '44px',
                      cursor: 'pointer',
                      '&:hover': {
                        color: 'custom.textBrand',
                      },
                    }}
                    onClick={() => setSelectedDate(date)}>
                    <Typography
                      sx={{
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: '24px',
                        color: isSameDay(date, selectedDate)
                          ? 'custom.textBrand'
                          : 'custom.textPrimary',
                      }}>
                      {format(date, 'dd')}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </HorizontalGradientOverflow>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '44px',
              }}>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: 'normal',
                  color: 'custom.textBrand',
                  textTransform: 'lowercase',
                }}>
                {dateUtils.getMonthName(selectedMonth, t)}
              </Typography>
            </Box>
          </Fragment>
        )}
        {selectedMenu === 'week' && (
          <Fragment>
            <HorizontalGradientOverflow scrollContainerId="weeks-scroll-container" hideScrollbar>
              <Box sx={{display: 'flex', gap: '32px'}}>
                {weeks.map((week, index) => (
                  <Box
                    id={`week-${format(week[0], 'yyyy-MM-dd')}`}
                    key={index}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      minWidth: '56px',
                      minHeight: '44px',
                      cursor: 'pointer',
                      '&:hover': {
                        color: 'custom.textBrand',
                      },
                    }}
                    onClick={() => setSelectedWeekFirstDate(week[0])}>
                    <Typography
                      sx={{
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: '24px',
                        color: isSameDay(week[0], selectedWeekFirstDate)
                          ? 'custom.textBrand'
                          : 'custom.textPrimary',
                      }}
                      noWrap>
                      {`${format(week[0], 'dd')}-${format(week[6], 'dd')}`}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </HorizontalGradientOverflow>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '44px',
              }}>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: 'normal',
                  textTransform: 'lowercase',
                }}>
                <Span
                  sx={{
                    color:
                      selectedWeekFirstDate.getMonth() === weekMonths[0]
                        ? 'custom.textBrand'
                        : 'custom.textBlocked',
                  }}>
                  {isLargerThanMd
                    ? dateUtils.getMonthName(weekMonths[0], t)
                    : dateUtils.getMonthNameAbbreviation(weekMonths[0], t)}
                </Span>
                {weekMonths.length > 1 && (
                  <Fragment>
                    <Span
                      sx={{
                        color:
                          selectedWeekFirstDate.getMonth() === weekMonths[0] &&
                          (selectedWeekFirstDate.getMonth() === weekMonths[1] ||
                            endOfWeek(selectedWeekFirstDate).getMonth() === weekMonths[1])
                            ? 'custom.textBrand'
                            : 'custom.textBlocked',
                      }}>
                      {' - '}
                    </Span>
                    <Span
                      sx={{
                        color:
                          selectedWeekFirstDate.getMonth() === weekMonths[1] ||
                          endOfWeek(selectedWeekFirstDate).getMonth() === weekMonths[1]
                            ? 'custom.textBrand'
                            : 'custom.textBlocked',
                      }}>
                      {isLargerThanMd
                        ? dateUtils.getMonthName(weekMonths[1], t)
                        : dateUtils.getMonthNameAbbreviation(weekMonths[1], t)}
                    </Span>
                  </Fragment>
                )}
                {weekMonths.length > 2 && (
                  <Fragment>
                    <Span
                      sx={{
                        color:
                          (selectedWeekFirstDate.getMonth() === weekMonths[1] ||
                            endOfWeek(selectedWeekFirstDate).getMonth() === weekMonths[1]) &&
                          (selectedWeekFirstDate.getMonth() === weekMonths[2] ||
                            endOfWeek(selectedWeekFirstDate).getMonth() === weekMonths[2])
                            ? 'custom.textBrand'
                            : 'custom.textBlocked',
                      }}>
                      {' - '}
                    </Span>
                    <Span
                      sx={{
                        color:
                          selectedWeekFirstDate.getMonth() === weekMonths[2] ||
                          endOfWeek(selectedWeekFirstDate).getMonth() === weekMonths[2]
                            ? 'custom.textBrand'
                            : 'custom.textBlocked',
                      }}>
                      {isLargerThanMd
                        ? dateUtils.getMonthName(weekMonths[2], t)
                        : dateUtils.getMonthNameAbbreviation(weekMonths[2], t)}
                    </Span>
                  </Fragment>
                )}
              </Typography>
            </Box>
          </Fragment>
        )}
        {selectedMenu === 'month' && (
          <HorizontalGradientOverflow scrollContainerId="months-scroll-container" hideScrollbar>
            <Box sx={{display: 'flex', gap: '32px'}}>
              {Array.from({length: 12}, (_, month) => (
                <Box
                  id={`month-${month}`}
                  key={month}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minWidth: '44px',
                    minHeight: '44px',
                    cursor: 'pointer',
                    '&:hover': {
                      color: 'custom.textBrand',
                    },
                  }}
                  onClick={() => handleChangeSelectedMonth(month)}>
                  <Typography
                    sx={{
                      fontSize: '20px',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      lineHeight: '24px',
                      color: month === selectedMonth ? 'custom.textBrand' : 'custom.textPrimary',
                    }}>
                    {(month + 1).toString().padStart(2, '0')}
                  </Typography>
                </Box>
              ))}
            </Box>
          </HorizontalGradientOverflow>
        )}
      </Box>
    </Fragment>
  );
}

export default PeriodSelector;
