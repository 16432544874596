import {Box, Typography} from '@mui/material';
import {eachDayOfInterval, endOfMonth, format, isSameDay, startOfMonth} from 'date-fns';
import {useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import dateUtils from '../../utils/dates';
import HorizontalGradientOverflow from '../common/HorizontalGradientOverflow';

export type DateSelectorProps = {
  selectedDate: Date;
  onChangeSelectedDate: (date: Date) => void;
};

function DateSelector(props: DateSelectorProps) {
  const {selectedDate, onChangeSelectedDate} = props;
  const {t} = useTranslation();

  const dates = useMemo(() => {
    return eachDayOfInterval({
      start: startOfMonth(new Date()),
      end: endOfMonth(new Date()),
    });
  }, []);

  useEffect(() => {
    const scrollContainerElement = document.getElementById('dates-scroll-container');
    const selectedElement = document.getElementById(`date-${format(selectedDate, 'yyyy-MM-dd')}`);

    if (scrollContainerElement != null && selectedElement != null) {
      scrollContainerElement.style.scrollBehavior = 'smooth';
      const scrollContainerElementWidth = scrollContainerElement.offsetWidth;
      const selectedElementLeft = selectedElement.offsetLeft;
      const selectedElementWidth = selectedElement.offsetWidth;

      const scrollPosition =
        selectedElementLeft - scrollContainerElementWidth / 2 + selectedElementWidth / 2;

      scrollContainerElement.scrollLeft = scrollPosition;
      scrollContainerElement.style.scrollBehavior = 'auto';
    }
  }, [selectedDate]);

  return (
    <Box sx={{display: 'flex', gap: '32px', paddingInline: '32px'}}>
      <HorizontalGradientOverflow scrollContainerId="dates-scroll-container" hideScrollbar>
        <Box sx={{display: 'flex', gap: '32px'}}>
          {dates.map((day, index) => (
            <Box
              key={index}
              id={`date-${format(day, 'yyyy-MM-dd')}`}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: '44px',
                minHeight: '44px',
                cursor: 'pointer',
                '&:hover': {
                  color: 'custom.textBrand',
                },
              }}
              onClick={() => onChangeSelectedDate(day)}>
              <Typography
                sx={{
                  fontSize: '20px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '24px',
                  color: isSameDay(day, selectedDate) ? 'custom.textBrand' : 'custom.textPrimary',
                }}>
                {day.getDate()}
              </Typography>
            </Box>
          ))}
        </Box>
      </HorizontalGradientOverflow>
      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '44px'}}>
        <Typography
          sx={{
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '24px',
            color: 'custom.textBrand',
            textTransform: 'lowercase',
          }}>
          {dateUtils.getMonthName(new Date().getMonth(), t)}
        </Typography>
      </Box>
    </Box>
  );
}

export default DateSelector;
