import {create} from 'zustand';
import localStorageUtils from '../utils/local-storage';
import {clearBreadcrumbsStore} from './breadcrumbs';
import {clearErrorStore} from './errors';

type AuthStore = {
  accessToken: string | null;
  setAccessToken: (accessToken: string) => void;
};

const useAuthStore = create<AuthStore>((set) => ({
  accessToken: localStorageUtils.getValue('token', null as string | null),
  setAccessToken: (accessToken: string) => set({accessToken}),
}));

function clearAuthStore() {
  useAuthStore.setState({accessToken: null});
}

export function clearStores() {
  clearAuthStore();
  clearErrorStore();
  clearBreadcrumbsStore();
}

export default useAuthStore;
