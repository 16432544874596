import {Box, Typography} from '@mui/material';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import paths from '../../routes/paths';
import useBreadcrumbsStore from '../../state/breadcrumbs';
import InformationList from './InformationList';

function Information() {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const setBreadcrumbs = useBreadcrumbsStore((state) => state.setBreadcrumbs);

  useEffect(() => {
    setBreadcrumbs([
      {title: t('profile_breadcrumb'), onClick: () => navigate(paths.profile)},
      {
        title: t('profile_information_breadcrumb'),
        onClick: () => navigate(paths.profileInformation),
      },
      {title: t('profile_information_tabs_preferences_label')},
    ]);
    return () => setBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
        width: {xs: 'calc(100vw - 60px)', sm: 'calc(100vw - 120px)', md: 'calc(100vw - 325px)'},
        marginTop: {xs: '32px', md: '24px'},
        marginLeft: {xs: '60px', sm: '120px', md: '32px'},
      }}>
      <Typography
        sx={{
          paddingLeft: '32px',
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '28px',
        }}>
        {t('profile_information_title_label')}
      </Typography>
      <InformationList />
      <Box sx={{minHeight: '64px'}} />
    </Box>
  );
}

export default Information;
