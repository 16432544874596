import {Box, InputBase, Popover, PopoverOrigin, SvgIcon} from '@mui/material';
import {ReactNode, useState} from 'react';
import {ReactComponent as AcceptIcon} from '../../assets/icons/accept.svg';
import {ReactComponent as DeclineIcon} from '../../assets/icons/decline.svg';

type ConfirmPopoverProps = {
  isOpen: boolean;
  anchorEl?: HTMLElement | null;
  anchorReference?: 'anchorEl' | 'anchorPosition';
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  anchorPosition?: {top: number; left: number};
  placeHolder?: string;
  value?: string;
  onChange?: (value: string) => void;
  onAccept: () => void;
  onDecline: () => void;

  messageElement?: ReactNode;
};

function ConfirmPopover(props: ConfirmPopoverProps) {
  const {
    isOpen,
    anchorEl,
    anchorReference,
    anchorOrigin = {vertical: 'center', horizontal: 'left'},
    transformOrigin = {vertical: 'center', horizontal: 'right'},
    anchorPosition,
    placeHolder,
    value,
    onChange,
    onAccept,
    onDecline,
    messageElement,
  } = props;

  const [showPlaceholder, setShowPlaceholder] = useState(true);

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      anchorReference={anchorReference}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      anchorPosition={anchorPosition}
      PaperProps={{
        elevation: 4,
        sx: {
          borderRadius: '360px',
          backgroundColor: 'custom.surfaceBrand',
          boxShadow: 'none',
        },
      }}
      BackdropProps={{
        invisible: false,
        sx: {backgroundColor: 'RGBA(255, 255, 255, 0.8)'},
      }}
      onClose={onDecline}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '8px',
          width: {
            xs: 'calc(100vw - 120px)',
            sm: 'calc(100vw - 240px)',
            md: 'calc(100vw - 445px)',
          },
          height: '40px',
          paddingInline: '32px',
          paddingBlock: '12px',
        }}>
        {messageElement != null ? (
          messageElement
        ) : (
          <InputBase
            sx={{
              width: '100%',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
              color: 'custom.textSecondary',
              input: {
                '&::placeholder': {
                  opacity: 1,
                },
              },
            }}
            placeholder={showPlaceholder ? placeHolder : ''}
            value={value}
            onChange={(event) => onChange?.(event.target.value)}
            onKeyUp={(event) => (event.key === 'Enter' ? onAccept() : null)}
            onFocus={() => setShowPlaceholder(false)}
            onBlur={() => setShowPlaceholder(true)}
          />
        )}
        <Box sx={{display: 'flex', gap: '8px'}}>
          <SvgIcon
            sx={{fontSize: '32px', color: 'custom.iconsSecondary', cursor: 'pointer'}}
            inheritViewBox
            component={DeclineIcon}
            onClick={onDecline}
          />
          <SvgIcon
            sx={{fontSize: '32px', color: 'custom.iconsSecondary', cursor: 'pointer'}}
            inheritViewBox
            component={AcceptIcon}
            onClick={onAccept}
          />
        </Box>
      </Box>
    </Popover>
  );
}

export default ConfirmPopover;
